
/**
 * API url end point group by modules
 *
 */
const API_URLS = {
  // Auth
  emailVerificationCode: "/session/request-verification-code",
  candidateSignUp: "/session/sign-up/candidate",
  changePassword: "/session/change-password",
  forgotPassword: "/session/forgot-password",
  companySignUp: "/session/sign-up/company",
  resetPassword: "/session/reset-password",
  verifyUserEmail: "/session/verify",
  login: "/session/login",
  fetchReferFriendEmail: "/session/refer-email",
  logout: "/session/logout",
  sessionProfile: "/session/profile",
  sessionCompanyProfile: "/session/company/profile",
  feedback: "/feedback",
  createCompany: "/companies/create-page",
  listCompanyJobs: "/companies/jobs",
  subscribe: "/news-letter/subscribe",
  userSessions: "/session/user-sessions",

  // Candidate
  search: "/search-and-connect/search",
  searchAll: "/search-and-connect/search-all",
  sendInvitation: "/search-and-connect/send-connection",
  getCandidateProfile: "/candidates/profile/public",
  resumeUpload: "/candidates/resume/upload",
  deleteUpload: (id: number) => `/candidates/resume/delete/${id}`,
  followCompany: "/search-and-connect/company-connection",
  getReceivedConnectionRequests: "/search-and-connect/connection-requests",
  updateCandidateConnection: "/search-and-connect/update-connection",
  removeCandidateConnection: (id: number) =>
    `/search-and-connect/remove-connection/${id}`,
  connectionNotificationList: "/connection-notification",
  clearNotification: "/connection-notification",
  posts: "/posts",
  notification: "/notification",

  //My Network
  searchAllConnected: "/my-network/search-all/connected",
  searchAllCompanies: "/my-network/search-all-companies",
  removeConnection: "/my-network/remove-connection",
  companyPublicView: "/companies/profile",

  // Company
  jobs: "/jobs",
  public: "/extended",
  people: "/people",
  jobPublicUrl: "/companies/profile/public/:slug/jobs",
  jobDetailsPublicUrl: "/jobs/details/:slug",
  peoplePublicUrl: "/companies/profile/public/:slug/people",
  currencies: "/currencies",
  jobTypes: "/job-types",
  company: "/company",
  companies: "/companies",
  jobLevels: "/job-levels",
  industries: "/industries",
  jobTitles: "/job-titles/",
  candidates: "/candidates/",
  departments: "/departments/",
  jobDepartments: "/job-departments",
  jobStatuses: "/job-statuses",
  assetUpload: "/asset-upload/",
  employeeSize: "/employee-size",
  salaryRanges: "/job-salary-ranges",
  jobApplications: "/job-applications/",
  videoStudio: "/video-studio",
  messages: "/messages",
  findInvitation: "/messages/invitation/find",
  addInvitation: "/messages/invitation/add",
  fetchInvitations: "/messages/invitation/fetch",
  removeInvitations: "/messages/invitation/remove",
  //affirmations
  affirmations: "/affirmation",
  locations: "/locations",

  //scheduler
  addWorkingHours: "/scheduler/working-hours/add",
  getWorkingHours: "/scheduler/working-hours/get",
  addEventType: "/scheduler/event-type/add",
  updateEventType: "/scheduler/event-type/update",
  removeEventType: "/scheduler/event-type/remove",
  removeMultipleEventTypes: "/scheduler/event-type/remove-multiple",
  getAllEventTypes: "/scheduler/event-type/get-all",
  getEventTypeBySug: "/scheduler/event-type/get",
  addSchedule: "/scheduler/schedule/add",
  getAssignedSlots: "/scheduler/invitation/get-slots",

  //marketing-solution
  addSubscriber: "/marketing-solution/subscriber/add",
  addNewTag: "/marketing-solution/tag/add",
  getListTag: "/marketing-solution/tag",
  bulkContacts: "/marketing-solution/bulk-contacts/add",
  parseContactsFile: "/marketing-solution/parse-file",
  saveContacts: "/marketing-solution/save-contacts",
  fetchContacts: "/marketing-solution/get-contacts",

  // Refer a friend
  fetchReferFriendInvites: "/refer-friend/fetch-invites",
  inviteReferFriendInvites: "/refer-friend/invite",
  deleteUploadInviteRefer: (id: number) => `/refer-friend/cancel-invite/${id}`,
  resendInviteRefer: (id: number) => `/refer-friend/resend-invite/${id}`,
  article: "/article/add",
  fetchArticle: (slug: string) => `article/${slug}`,
  articleUpdate: (id: String) => `article/${id}`,
  viewArticle: (slug: string) => `article/view/${slug}`,
  fetchArticleComment: "article-comment/comment",
  fetchArticleReply: "article-comment/replies",
  addComment: "article-comment/addComment",
  deleteComment: "article-comment/:id",
  editComment: "article-comment/editComment",
  likeComment: "article-comment/like",
  generateSlots: "/scheduler/generate-slot",
  invokeAwsBedRock: "/posts/generate-ai",
  postIncidenceReports: "/posts/post-incidence-reports",
  fetchScheduledPost: "/posts/fetch/scheduled",
  report: (slug: string) => `/report-abuse/scope:capture/${slug}`,
  captcha: "/session/captcha",
  developerApiList: "/developer-api",
  generateDeveloperApi: "/developer-api/generate",
  removeDeveloperApi: (id: number) => `/developer-api/${id}`

  //-->disable
  // twoStepVerification: "/session/two-step-verification",
  // verifyToken: "/session/verify-token",
  // verifyPassword: "/session/verify-password",
  // disableTwoFA: "/session/disable-two-factor",

};

export default API_URLS;
