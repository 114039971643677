import httpRequest from "../utils/httpRequest";
import { get, omit } from "lodash";
import API_URLS from "../utils/apiUrls";
import { objectToParams } from "../utils/helper";

const _ = { get, omit };

const fetchUserList = (queryObj: {}) => {
  let queryString = "";

  if (typeof queryObj === "object") {
    queryString = objectToParams(queryObj);
  }

  return httpRequest()
    .get(`${API_URLS.messages}/users-list?${queryString}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const checkChatRoom = (payload: any) => {
  return httpRequest()
    .post(`${API_URLS.messages}/check/chat-room/${payload.id}`, {
      companySlug: payload.companySlug || "",
    })
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const findInvitation = (formData: any) => {
  return httpRequest()
    .post(`${API_URLS.findInvitation}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const addInvitation = (formData: any) => {
  return httpRequest()
    .post(`${API_URLS.addInvitation}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const fetchInvitationList = () => {
  return httpRequest()
    .post(`${API_URLS.fetchInvitations}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const removeInvitation = (id: string, reason: string) => {
  const formData = { reason };
  return httpRequest()
    .patch(`${API_URLS.removeInvitations}/${id}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const messagesServices = {
  fetchUserList,
  checkChatRoom,
  addInvitation,
  findInvitation,
  fetchInvitationList,
  removeInvitation,
};

export default messagesServices;
