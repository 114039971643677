import React from "react";
import { useSelector } from "react-redux";
import doubleQuotes from "../../../../assets/svg/quote-icon.svg";
import { get } from "lodash";
import Image from "../../../../components/common/Image";

const _ = { get };

const About = () => {

	const { companyPublicProfile: { data } } = useSelector(({ company }: any) => company);
	const whoWeAre = _.get(data, 'companyProfile.who_we_are', "");
	const missionAndVision = _.get(data, 'companyProfile.mission_and_vision', "");
	const diversityAndInclusion = _.get(data, 'companyProfile.diversity_and_inclusion', "");

	return (
		<div className="company-info">
			{/* <Container maxWidth="sm"> */}
			<div className="company-quote mb-40">
				<h3 className="compnay-public-title">Who we are</h3>
				<div className="white-box">
					<Image src={doubleQuotes} height={"20px"} alt="double quotes icon" />
					<p>{whoWeAre}</p>
					<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
						standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
						a type specimen book.</p>
				</div>
			</div>
			{/* </Container> */}
			<div className="about-container">
				<div className="mis-wrap mb-40">
					<div className="mission-statement">
						<h3 className="compnay-public-title">Our Mission And Vision</h3>
						<div className="white-box">
							<p>
								{missionAndVision}
							</p>
							<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
								standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
								a type specimen book.</p>
						</div>
					</div>
				</div>
				<div className="dis-wrap mb-40">
					<div className="di-statement">
						<h3 className="compnay-public-title">Our Diversity And Inclusion</h3>
						<div className="white-box">
							<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
								standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
								a type specimen book.</p>
							<p> {diversityAndInclusion} </p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default About;
