import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createCompanyAdminRequest,
  searchAdminRequest,
} from "../../../reducers/company/companyAdmins.reducer";
import {
  Grid,
  InputAdornment,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isEmpty, get, isNull, forEach } from "lodash";
import { useForm } from "react-hook-form";
import {
  Modal,
  Input,
  Button,
  FlashMessage,
  Radio
} from "../../../components/common";
import { rootReducersState } from "../../../reducers";
import { companyUserAccessLevel } from "../../../utils/appConstants";
import Avatar from "../../../components/common/AvtarImage";
let searchDelay: any = null;
type Inputs = {
  companyHandlerId: number;
  accessLevel: "";
  email: string;
  confirm_email: string;
  first_name: string;
  last_name: string;
};
interface FormProps {
  handleClose: () => void;
  showModal: boolean;
}

const _ = { isEmpty, get, isNull, forEach };

const AddAdminForm = (props: FormProps) => {
  const { showModal, handleClose } = props;
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const confirm_email = useRef({});

  const {
    companyAdmins: {
      createFlag,
      createLoading,
      createMessage,
      searchLoading,
      searchAdminData,
      data: companyAdminData,
      error,
    },
  } = useSelector(({ company }: rootReducersState) => company);
  const [companyHandlerId, setCompanyHandlerId] = useState<number>();
  const [accessLevel, setAccessLevel] = useState<string>("admin");
  const [status, setStatus] = useState<string>("internal");
  const { register, handleSubmit, errors, reset, control, watch, setError } =
    useForm<Inputs>();
  confirm_email.current = watch("email", "");

  const resetForm = () => {
    reset(
      {
        accessLevel: "",
        companyHandlerId: undefined,
      },
      {
        errors: true, // errors will not be reset
        dirtyFields: true, // dirtyFields will not be reset
        isDirty: true, // dirty will not be reset
        isSubmitted: false,
        touched: false,
        isValid: false,
        submitCount: false,
      }
    );
  };

  const typeOptions = [
    { label: "Internal", value: "internal" },
    { label: "Invite", value: "invite" },
  ];

  useEffect(() => {
    if (createFlag === "success") {
      resetForm();
      handleClose();
      setStatus("internal");
      FlashMessage(createMessage);
    } else {
      if (createFlag) FlashMessage(createMessage, "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFlag, createMessage]);

  const handleAdminSearch = (text: string) =>
    new Promise((resolve) => {
      clearTimeout(searchDelay);
      searchDelay = setTimeout(async () => {
        if (!_.isEmpty(text)) {
          resolve(dispatch(searchAdminRequest(text)));
        }
      }, 1000);
    });

  const onSubmit = (formDataPayload) => {
    if (status === "internal") {
      // Check if User already exists in the list
      const userExists = companyAdminData?.find(
        (row) => row.id === companyHandlerId
      );

      if (userExists) {
        FlashMessage(
          "User already has permission, please select different",
          "error"
        );
      } else {
        dispatch(
          createCompanyAdminRequest({
            companyHandlerId,
            accessLevel,
            status: 1,
          })
        );
      }
    } else {
      dispatch(
        createCompanyAdminRequest({
          status: 0,
          accessLevel,
          companyHandlerId: 0,
          email: _.get(formDataPayload, "email"),
          first_name: _.get(formDataPayload, "first_name"),
          last_name: _.get(formDataPayload, "last_name"),
        })
      );
    }
  };

  useEffect(() => {
    handleErrors(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleErrors = (response: any) => {
    _.forEach(response, (value, key: any) => {
      setError(key, { type: "manual", message: value });
    });
  };

  return (
    <Modal
      visible={showModal}
      size="large"
      title={`Add new Role`}
      closeOnBackDrop={false}
      className="admin-profile-add-modal"
      closeButton={showModal}
      onClose={() => {
        handleClose();
        setStatus("internal");
      }}
    >
      <form className={"invite-admin-form"} onSubmit={handleSubmit(onSubmit)}>
        <div className="add-new-admin-form">
          <div className="search-by-name-section">
            <Grid>
              <Radio
                control={control}
                labelPlacement="end"
                defaultValue="internal"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setStatus(_.get(e, "target.value", ""));
                  reset({});
                }}
                options={typeOptions}
                className="ml-20"
                name={""}
              />
            </Grid>
            {status === "internal" ? (
              <div>
                <Grid
                  container
                  spacing={2}
                  text-align="center"
                  alignItems="center"
                  className="search-by-name-wrapper"
                >
                  <Grid item xs={12} ref={wrapperRef}>
                    <FormLabel id="choose-admin-role-label">
                      Search By Name
                    </FormLabel>

                    <Autocomplete
                      id="search-employee"
                      noOptionsText={createMessage}
                      loading={searchLoading}
                      options={searchAdminData}
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      className="admin-form-autocomplete"
                      onChange={(event, user) => setCompanyHandlerId(user?.id)}
                      renderOption={(option) => (
                        <div style={{ display: "flex" }}>
                          <Avatar
                            src={option.profile_image}
                            size="xsm"
                          />
                          <ul
                            className="user-name-description"
                            style={{
                              display: "flex",
                              padding: "0 10px",
                              alignItems: "center",
                            }}
                          >
                            <li>{option.name}</li>
                          </ul>
                        </div>
                      )}
                      renderInput={(params) => {
                        return (
                          <Input
                            {...params}
                            validationObj={errors}
                            name="search"
                            placeholder="Search User"
                            onKeyUp={(e) => handleAdminSearch(e.target.value)}
                            variant="outlined"
                            inputRef={register({
                              required: {
                                value: true,
                                message: "Please select user",
                              },
                            })}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div>
                <Grid
                  container
                  spacing={2}
                  text-align="center"
                  alignItems="center"
                  className="search-by-name-wrapper"
                >
                  <Grid item xs={12} ref={wrapperRef}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <FormLabel id=""> First Name </FormLabel>
                        <Input
                          name={"first_name"}
                          validationObj={errors}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "Please enter first name",
                            },
                          })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormLabel id=""> Last Name </FormLabel>
                        <Input
                          name={"last_name"}
                          validationObj={errors}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "Please enter last name",
                            },
                          })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormLabel id=""> Email </FormLabel>
                        <Input
                          name={"email"}
                          validationObj={errors}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "Please enter email",
                            },
                          })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormLabel id="">Confirm Email </FormLabel>
                        <Input
                          name={"confirm_email"}
                          validationObj={errors}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "Please enter confirm email",
                            },
                            validate: (value) =>
                              value === confirm_email.current ||
                              "The re-entered email do not match",
                          })}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
            <Grid container spacing={2} className="invite-by-email">
              <Grid item xs={12} sm={12}>
                <FormControl>
                  <div className="radio-wrapper">
                    <FormLabel id="choose-admin-role-label">
                      Choose role
                    </FormLabel>
                    <Radio
                      name="Choose Role"
                      control={control}
                      validationObj={errors}
                      labelPlacement="end"
                      defaultValue="admin"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setAccessLevel(_.get(e, "target.value", ""));
                      }}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Please select a role",
                        },
                      })}
                      options={companyUserAccessLevel}
                    />
                  </div>
                  <div className="text-center btns-wrapper">
                    <Button
                      type="submit"
                      disabled={!companyHandlerId && !accessLevel}
                      className="post-job-btn invite-admin-btn primary-btn"
                      loading={createLoading}
                    >
                      Invite
                    </Button>
                    <Button
                      type="button"
                      className="post-job-btn invite-admin-btn btn-secondary"
                      onClick={() => {
                        handleClose();
                        setStatus("internal");
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddAdminForm;
