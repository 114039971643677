import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, InputLabel, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import Skeleton from "@material-ui/lab/Skeleton";
import _ from "lodash";

import {
  Input,
  SelectNew,
  AsyncSelect,
  FlashMessage,
} from "../../../components/common";
import { industryListRequest } from "../../../reducers/industry/industry.reducer";
import { employeeSizeListRequest } from "../../../reducers/employeeSize/employeeSize.reducer";
import {
  getCompanyDetailRequest,
  updateCompanyProfileRequest,
  updateCompanyProfileResetFlag,
  updateCompanyProfilePartiallyRequest,
} from "../../../reducers/company/companyProfile.reducer";
import appRoutes from "../../../routes/app.routes";
import CompanyBanner from "./components/CompanyBanner";
import CompanyVideos from "./components/CompanyVideo";
import { pluckFromArray } from "../../../utils/helper";
import { navSaveButtonLoading } from "../../../reducers/common/navBar.reducer";
import Actions from "../../CompanyPage/private/components/Actions";
import { tabChangeRequest } from "../../../reducers/company/tabChange.reducer";
import commonServices from "../../../services/common.services";

type FormFields = {
  company_name: string;
  industry_id: object | null;
  city_id: {
    label: string;
    value: string;
  };
  employee_size_id: object | null;
  website: string;
  who_we_are: string;
  mission_and_vision: string;
  diversity_and_inclusion: string;
};

let searchDelay: NodeJS.Timeout;

const CompanyPrivateHome = (props) => {
  const pageSubmitBtnRef = useRef<HTMLButtonElement>(null);
  const [industryOption, setIndustryOption] = useState([]);
  const [employeeSizeOption, setEmployeeSizeOption] = useState([]);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    setError,
    setValue,
  } = useForm<FormFields>();

  const companyReducer = useSelector(({ company }: any) => company);
  const companyUserData = _.get(companyReducer, "companyProfile.data", {}); // Parent object
  const companyProfileData = _.get(
    companyReducer,
    "companyProfile.data.companyProfile",
    {}
  );
  const companyProfileUpdateFlag = _.get(
    companyReducer,
    "companyProfile.updateFlag",
    null
  );
  const companyProfileUpdateErrors = _.get(
    companyReducer,
    "companyProfile.profileUpdateErrors",
    {}
  );

  const industryReducer = useSelector(({ industry }: any) => industry);
  const industryOptionsData = _.get(industryReducer, "industries.data", []);

  const employeeSizeReducer = useSelector(
    ({ employeeSize }: any) => employeeSize
  );
  const employeeSizeOptionsData = _.get(employeeSizeReducer, "list.data", []);

  const {
    companyProfile: { loading },
  } = useSelector(({ company }: any) => company);
  const dispatch = useDispatch();
  const [isPublished, setIsPublished] = useState(0);

  // const sessionReducer = useSelector(
  //   ({ session }: rootReducersState) => session
  // );
  // const userTokenData = _.get(sessionReducer, "currentUser", {});

  useEffect(() => {
    dispatch(industryListRequest());
    dispatch(employeeSizeListRequest());
    dispatch(getCompanyDetailRequest());

    // eslint-disable-next-line
  }, []);

  // Set the form data
  useEffect(() => {
    if (!_.isEmpty(companyUserData)) {
      setValue("company_name", _.get(companyProfileData, "company_name", ""));
      setValue("website", _.get(companyProfileData, "website", ""));
      setValue("who_we_are", _.get(companyProfileData, "who_we_are", ""));
      setValue(
        "mission_and_vision",
        _.get(companyProfileData, "mission_and_vision", "")
      );
      setValue(
        "diversity_and_inclusion",
        _.get(companyProfileData, "diversity_and_inclusion", "")
      );
      setValue("city_id", {
        label: _.get(companyProfileData, "location.location", ""),
        value: _.get(companyProfileData, "location.id", ""),
      });
    }

    // eslint-disable-next-line
  }, [companyUserData]);

  // Set the form data
  useEffect(() => {
    if (
      companyProfileUpdateFlag === true ||
      companyProfileUpdateFlag === false
    ) {
      if (companyProfileUpdateFlag === true) {
        FlashMessage("Profile updated successfully");

        const formData = getValues();
        let updatedCompanyUserData = {
          ...formData,
          industry_id: _.get(getValues("industry_id"), "value", 0),
          employee_size_id: _.get(getValues("employee_size_id"), "value", 0),
          location: {
            location: _.get(getValues("city_id"), "label", 0),
            id: _.get(getValues("city_id"), "value", 0),
          },
        };

        updatedCompanyUserData = _.set(
          _.cloneDeep(companyUserData),
          "companyProfile",
          updatedCompanyUserData
        );

        dispatch(
          updateCompanyProfilePartiallyRequest({ ...updatedCompanyUserData })
        );
      } else if (companyProfileUpdateFlag === false) {
        if (
          typeof companyProfileUpdateErrors === "object" &&
          !_.isEmpty(companyProfileUpdateErrors)
        ) {
          for (const [fieldName, errMsg] of Object.entries(
            companyProfileUpdateErrors
          )) {
            setError(fieldName, { type: "manual", message: errMsg as string });
          }
        }
      }
      dispatch(updateCompanyProfileResetFlag());
      dispatch(navSaveButtonLoading(false));
    }

    // eslint-disable-next-line
  }, [companyProfileUpdateFlag]);

  // Set Industry options
  useEffect(() => {
    if (!_.isEmpty(industryOptionsData) || !_.isEmpty(companyUserData)) {
      const finalOption = pluckFromArray(
        industryOptionsData,
        "id",
        "title",
        "value",
        "label"
      );
      setIndustryOption(finalOption);

      const industryId = _.get(companyProfileData, "industry_id", 0);
      const selectedIndustry =
        _.chain(finalOption).find({ value: industryId }).value() || null;

      setValue("industry_id", selectedIndustry);
    }

    // eslint-disable-next-line
  }, [industryOptionsData, companyUserData]);

  // Set Employee options
  useEffect(() => {
    if (!_.isEmpty(employeeSizeOptionsData) || !_.isEmpty(companyUserData)) {
      const finalOption = pluckFromArray(
        employeeSizeOptionsData,
        "id",
        "title",
        "value",
        "label"
      );
      setEmployeeSizeOption(finalOption);

      const empSizeId = _.get(companyProfileData, "employee_size_id", 0);
      const selectedEmployeeSize =
        _.chain(finalOption).find({ value: empSizeId }).value() || null;

      setValue("employee_size_id", selectedEmployeeSize);
    }

    // eslint-disable-next-line
  }, [employeeSizeOptionsData, companyUserData]);

  useEffect(() => {
    if (isPublished === 1) {
      saveData();
      setIsPublished(0);
    }
  }, [isPublished]);

  const setProfilePreview = () => {
    // TODO not able to get the data from reducer hence have to add token - remove this in the future
    const slugName = _.get(companyProfileData, "slug", "");
    if (slugName) {
      const win = window.open(
        appRoutes.CompanyPublicView.generatePath(slugName),
        "_blank"
      );
      win.focus();
    }
  };

  const saveData = () => {
    pageSubmitBtnRef?.current?.click();
  };

  const onSubmit = (formData, e) => {
    dispatch(
      updateCompanyProfileRequest({
        ...formData,
        employee_size_id: _.get(formData, "employee_size_id.value", 0),
        industry_id: _.get(formData, "industry_id.value", 0),
        city_id: _.get(formData, "city_id.value", 0),
        is_public: isPublished,
      })
    );
    dispatch(navSaveButtonLoading(true));
  };

  const handleTabClick = (type: string) => {
    dispatch(tabChangeRequest({ tab: "page", innerTab: type }));
    switch (type) {
      case "All":
        history.push(appRoutes.companyPage.path);
        break;
      case "People":
        history.push(appRoutes.companyPagePeople.path);
        break;
      // case "Jobs":
      //   history.push(appRoutes.companyPageJobs.path);
      //   break;
      // case "News":
      //   history.push(appRoutes.companyPageNews.path);
      //   break;
      // case "Events":
      //   history.push(appRoutes.companyPageEvents.path);
      //   break;
      default:
        history.push(appRoutes.companyPage.path);
        break;
    }
  };

  const handleButtonClick = (type) => {
    switch (type) {
      case "Save":
        saveData();
        break;
      case "Publish":
        setIsPublished(1);
        break;
      case "view-profile":
        setProfilePreview();
        break;
      case "All":
      case "People":
      case "Jobs":
      case "News":
      case "Events":
        handleTabClick(type);
        break;
      default:
        break;
    }
  };

  // Search location with delay of 1 second
  const searchLocations = (search: string) =>
    new Promise((resolve) => {
      clearTimeout(searchDelay);
      searchDelay = setTimeout(async () => {
        const locationData = await commonServices.fetchLocations(search);
        const locations = _.get(locationData, "data.data", []).map((c) => ({
          value: c.id,
          label: c.location,
        }));

        resolve(locations);
      }, 1000);
    });

  return (
    <div className="company-page-form-container">
      <Actions handleClick={handleButtonClick} />
      <div className="company-home-page-form">
        {/* <Spinner visible={loading} loadingTip={""}> */}
        <div className="company-profile-intro">
          <CompanyBanner />
        </div>
        <form
          className={"page-home-form"}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          {loading ? (
            <>
              <div className="mt-25">
                <Grid
                  container
                  spacing={2}
                  justify="center"
                  text-align="center"
                  alignItems="center"
                  className="basic-info-form-wrap"
                >
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h3">
                      <Skeleton />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h3">
                      <Skeleton />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h3">
                      <Skeleton />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h3">
                      <Skeleton />
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h3">
                      <Skeleton />
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <Grid
                container
                spacing={2}
                justify="center"
                text-align="center"
                alignItems="stretch"
                className="professional-passions"
              >
                <Grid item xs={12} sm={4}>
                  <Skeleton height={235} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Skeleton height={235} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Skeleton height={235} />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <div className="company-profile-form">
                <div className="page-form">
                  <div className="page-form-wrapper">
                    <Grid container spacing={2} xs={12}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        className="page-home-form-field"
                      >
                        <Input
                          name="company_name"
                          externalLabel={{ label: "Name" }}
                          validationObj={errors}
                          placeholder="Enter the company name"
                          inputRef={register({
                            required: {
                              value: true,
                              message: "Please enter company name",
                            },
                          })}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        className="page-home-form-field"
                      >
                        <SelectNew
                          name="industry_id"
                          externalLabel={{ label: "Industry" }}
                          placeholder="Select an industry"
                          className="select-box"
                          options={industryOption}
                          isSearchable={true}
                          validationObj={errors}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Please select an industry",
                            },
                            validate: (value) =>
                              !_.isEmpty(value) || "Please select an industry",
                          }}
                          required={true}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        className={`job-form-field`}
                      >
                        <AsyncSelect
                          name="city_id"
                          placeholder="Search City, State or country..."
                          externalLabel={{
                            label: "Location",
                            className: "required",
                          }}
                          onStartSearching={searchLocations}
                          validationObj={errors}
                          control={control}
                          noOptionsMessage="No matching locations found"
                          rules={{
                            required: {
                              value: true,
                              message: "Please select location from list",
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        className="page-home-form-field"
                      >
                        <SelectNew
                          name="employee_size_id"
                          externalLabel={{ label: "Size" }}
                          placeholder="Select number of employees"
                          className="select-box"
                          options={employeeSizeOption}
                          isSearchable={true}
                          validationObj={errors}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Please select number of employees",
                            },
                            validate: (value) =>
                              !_.isEmpty(value) ||
                              "Please select number of employees",
                          }}
                          required={true}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        className="job-form-field"
                      >
                        <Input
                          name="website"
                          placeholder="Enter a URL"
                          externalLabel={{ label: "Website" }}
                          validationObj={errors}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "Please enter a valid URL",
                            },
                            // pattern: {
                            // 	value: /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                            // 	message: "Enter valid URL",
                            // },
                          })}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        className="job-form-field job-form-text-area"
                      >
                        <Input
                          name="who_we_are"
                          multiline
                          rows={6}
                          externalLabel={{ label: "Who We Are" }}
                          placeholder="Who We Are"
                          inputRef={register}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        className="job-form-field job-form-text-area"
                      >
                        <Input
                          name="mission_and_vision"
                          multiline
                          rows={6}
                          externalLabel={{ label: "Mission and Vision" }}
                          placeholder="Mission and Vision"
                          inputRef={register}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        className="job-form-field job-form-text-area"
                      >
                        <Input
                          name="diversity_and_inclusion"
                          multiline
                          rows={6}
                          externalLabel={{ label: "Diversity and Inclusion" }}
                          placeholder="Diversity and Inclusion"
                          inputRef={register}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                ref={pageSubmitBtnRef}
                className="d-none"
              ></button>
            </>
          )}
        </form>
        <div className="company-profile-videos">
          <div className={`page-form-video-wrapper`}>
            <InputLabel>Company Videos</InputLabel>
            <Grid container spacing={2} xs={12}>
              {[0, 1, 2].map((videoId) => (
                <CompanyVideos key={videoId} videoId={videoId} />
              ))}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompanyPrivateHome;
