import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useParams } from "react-router-dom";
import {
  getCompanyProfileDetailRequest,
  resetCompanyProfileRequest,
} from "../../../../reducers/company/companyPublicProfile.reducer";
import { sanitizeUrl } from "../../../../utils/helper";
import { get, isEmpty, isNull } from "lodash";
import LinkIcon from "@material-ui/icons/Link";
import Image from "../../../../components/common/Image";
import Banner from "../../../../components/common/Banner";

const _ = { get, isEmpty, isNull };

const CompanyBanner = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const {
    companyPublicProfile: { data, profileGetFailed, loading },
  } = useSelector(({ company }: any) => company);
  const bannerImage = _.get(data, "companyProfile.banner_image", "");
  const profileImage = _.get(data, "profile_image", "");
  const industryName = _.get(data, "companyProfile.industry.title", "");
  const companyName = _.get(data, "companyProfile.company_name", "");
  const location = _.get(data, "companyProfile.location_json", "");
  const locationData = !_.isEmpty(location) ? JSON.parse(location) : {};
  const locationCityName = _.get(locationData, "city", "");
  const locationRegion = _.get(locationData, "region", "");
  const employeeSize = _.get(data, "companyProfile.employeeSize.title", "");
  const companyWebsite = _.get(data, "companyProfile.website", "");
  const sanitizeLink = _.isNull(companyWebsite)
    ? ""
    : sanitizeUrl(companyWebsite);

  useEffect(() => {
    if (_.isEmpty(data)) {
      const companySlug = _.get(params, "slug", "");
      dispatch(getCompanyProfileDetailRequest({ slug: companySlug }));
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (profileGetFailed === true) {
      dispatch(resetCompanyProfileRequest());
      history.push(`/`);
    }

    // eslint-disable-next-line
  }, [profileGetFailed]);

  return (
    <div className="company-public-profile-header text-center">
      <div className="cover-img">
        <Grid container justify="center" xs={12}>
          {loading ? (
            <Skeleton variant="rect" width={1500} height={250} />
          ) : !_.isEmpty(bannerImage) ? (
            <Image src={bannerImage} alt="company banner image" banner />
          ) : (
            <Banner />
          )}
        </Grid>
      </div>
      <div className="company-intro">
        <div className="black-logo">
          {loading ? (
            <Skeleton variant="circle" width={135} height={135} />
          ) : (
            <Image src={profileImage} alt="company image" />
          )}
        </div>
        <div className="intro-header">
          <div className="intro-header-inner">
            <div className="intro-logo">{companyName}</div>
            <div className="address">
              {!_.isEmpty(industryName) && (
                <div className="indus-name">
                  {" "}
                  {loading ? <Skeleton width={100} /> : industryName}{" "}
                </div>
              )}
              {!_.isEmpty(locationRegion || locationCityName) && (
                <>
                  <span>|</span>
                  <div className="location-name">
                    {loading ? (
                      <Skeleton width={50} />
                    ) : (
                      `${locationCityName}, ${locationRegion}`
                    )}{" "}
                  </div>
                </>
              )}
              <span>|</span>
              <div className="emp-size">
                {" "}
                {loading ? <Skeleton width={80} /> : employeeSize}
              </div>
            </div>
          </div>
          <div className="external-link">
            <LinkIcon />{" "}
            {loading ? (
              <Skeleton width={300} className="external-link-skeleton" />
            ) : (
              <a target="_blank" rel="noreferrer" href={sanitizeLink}>
                {companyWebsite}
              </a>
            )}
          </div>
        </div>
      </div>
      {/* </Spinner> */}
    </div>
  );
};

export default CompanyBanner;
