import React from "react";
import moment from "moment";
import _ from "lodash";
import unknownProfile from "../../../../assets/images/unknownProfile.jpeg";
import { ReactComponent as MoreVert } from "../../../../assets/svg/more-vertical.svg";
import { ReactComponent as SmallCirlce } from "../../../../assets/svg/small-circle.svg";
import {
  Star as StarFillIcon,
  StarOutline as StarEmptyIcon,
  GetApp as GetAppIcon,
  Launch as LaunchIcon,
  Message as MessageIcon,
} from "@material-ui/icons";
import { JSONParse } from "../../../../utils/helper";
import appRoutes from "../../../../routes/app.routes";
import Avatar from "../../../../components/common/AvtarImage";
import PlayIcon from "../../../../components/ProfileView/icons/play_icon.svg";
import Image from "../../../../components/common/Image";
interface ICandidateListBox {
  details: object;
  handleFavorite?: (data) => void;
  handleOpenVideoModal?: (data) => void;
  handleOpenProfileModal?: (data) => void;
  handleOpenStatusMenu?: (e, data) => void;
  handleRedirectToMessages?: (data) => void;
  handleDownloadResume?: (data) => void;
  handleShowCoverLetter?: (data) => void;
  [key: string]: any;
}

const CandidateListBox = (props: ICandidateListBox) => {
  const candidateDetails: any = _.get(props, "details", {});

  const _handleFavorite = (): void => {
    const { handleFavorite } = props;

    if (typeof handleFavorite === "function") {
      handleFavorite(candidateDetails);
    }
  };

  const _handleOpenStatusMenu = (e): void => {
    const { handleOpenStatusMenu } = props;

    if (typeof handleOpenStatusMenu === "function") {
      handleOpenStatusMenu(e, candidateDetails);
    }
  };

  const _handleOpenProfileModal = (): void => {
    const path = appRoutes.candidatePublicProfile.generateFullPath(
      candidateDetails.slug
    );
    window.open(path, "_blank");
  };

  const _handleOpenVideoModal = (): void => {
    const { handleOpenVideoModal } = props;

    if (typeof handleOpenVideoModal === "function") {
      handleOpenVideoModal(candidateDetails);
    }
  };

  const _renderUserLocation = (): string => {
    const jobTitle = candidateDetails.candidate_job_title || "";
    const workStatus = candidateDetails.work_status || "";
    const jobLocationJson: any = JSONParse(
      candidateDetails.job_location_json || ""
    );
    const city = jobLocationJson.city || "";

    return [jobTitle, city, workStatus].filter((a) => a).join(" | ");
  };

  const _handleRedirectToMessages = () => {
    const { handleRedirectToMessages } = props;
    if (typeof handleRedirectToMessages === "function") {
      handleRedirectToMessages(candidateDetails);
    }
  };

  const _handleDownloadResume = () => {
    const { handleDownloadResume } = props;

    if (typeof handleDownloadResume === "function") {
      handleDownloadResume(candidateDetails);
    }
  };

  const _handleShowCoverLetter = () => {
    const { handleShowCoverLetter } = props;

    if (typeof handleShowCoverLetter === "function") {
      handleShowCoverLetter(candidateDetails);
    }
  };


  return (
    <div className="candidate-preview-wrapper">
      <div className="candidate-wrapper">
        <div className="user-info">
          <div className="user-avatar">

            <Avatar
              src={_.get(candidateDetails, "profile_image", { unknownProfile })}
              size="md"
              onClick={() => _handleOpenProfileModal()}
            />
            {!_.isEmpty(candidateDetails.profile_video) && (
              <span className="play-button"
                onClick={() => _handleOpenVideoModal()}
              >
                <Image src={PlayIcon} alt="Play icon" height="15" width="13" />
              </span>
            )}
          </div>
          <div className="user-content">
            <div className="name-info">
              <h2
                className="user-name cursor-pointer text-capitalize"
                onClick={() => _handleOpenProfileModal()}
              >
                {candidateDetails.first_name} {candidateDetails.last_name}
              </h2>
              {candidateDetails.favorite === "favorite" ? (
                <StarFillIcon
                  color="secondary"
                  className="cursor-pointer fav-star-icon"
                  onClick={() => _handleFavorite()}
                />
              ) : (
                <StarEmptyIcon
                  color="secondary"
                  className="cursor-pointer fav-star-icon"
                  onClick={() => _handleFavorite()}
                />
              )}
              {!_.isEmpty(candidateDetails.status) && (
                <label
                  className={`candidate-${(
                    candidateDetails.status || ""
                  ).toLowerCase()} text-capitalize`}
                >
                  {candidateDetails.status}
                </label>
              )}
            </div>

            <div className="applied-info-wrapper">
              <div className="applied-info">
                <SmallCirlce className="user-values-dot" />
                <span className="d-block">
                  Applied on{" "}
                  <span className="font-bold-500">
                    {moment(candidateDetails.applied_at).format("Do MMM YYYY")}
                  </span>
                </span>
              </div>
              <div className="applied-info">
                <SmallCirlce className="user-values-dot" />
                <span className="d-block">
                  Applied for{" "}
                  <span className="font-bold-500">
                    {candidateDetails.job_title}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="user-location">
          <span>{_renderUserLocation()}</span>
          <span
            className="span-link d-flex"
            onClick={() => _handleRedirectToMessages()}
          >
            <MessageIcon />
            &nbsp; Send Message
          </span>
          {candidateDetails.resume && (
            <span
              className="span-link d-flex"
              onClick={() => _handleDownloadResume()}
            >
              <GetAppIcon />
              &nbsp;
              <span>Download Resume</span>
            </span>
          )}
          {candidateDetails.cover_letter && (
            <span
              className="span-link d-flex"
              onClick={() => _handleShowCoverLetter()}
            >
              <LaunchIcon />
              &nbsp; Show Cover letter
            </span>
          )}
        </div>
      </div>
      {candidateDetails.status !== "rejected" && (
        <div
          className="more-icon-wrapper cursor-pointer"
          onClick={(e) => _handleOpenStatusMenu(e)}
        >
          <MoreVert className="more-icon " />
        </div>
      )}
    </div>
  );
};

export default CandidateListBox;
