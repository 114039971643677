import _ from "lodash";
import React from "react";
import ReactPlayer from "react-player";
import ImageIcon from "@material-ui/icons/ImageOutlined";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import { Button, Modal } from "../../common";
import EditBasicInformationModal from "./EditBasicInformationModal";
import { useSelector } from "react-redux";
import { rootReducersState } from "../../../reducers";
import { useHistory } from "react-router-dom";

interface IProfileHeader {
  candidateProfileData: any;
  showEditButton?: boolean;
  showShareButton?: boolean;
  setSureAboutVideoDelete: () => void;
  publicPage?: boolean;
  videoModal: boolean;
  basicProfile?: boolean;
  setBasicProfile?: any;
  setVideoModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function ProfileHeader({
  candidateProfileData,
  showEditButton,
  setBasicProfile,
  basicProfile,
  setSureAboutVideoDelete,
  publicPage,
  videoModal,
  setVideoModal,
}: IProfileHeader) {
  const history = useHistory();
  const candidate = useSelector(
    ({ candidate }: rootReducersState) => candidate
  );
  const session = useSelector(({ session }: rootReducersState) => session);

  return (
    <>
      <div className="profile-view-header mt-12">
        <div
          className={`video-wrapper ${
            _.isEmpty(
              _.get(candidateProfileData, "profile.personal_values", "")
            )
              ? "video-wrapper-changed"
              : ""
          } ${
            publicPage
              ? "video-wrapper-public"
              : !showEditButton
              ? "video-wrapper-public-modal"
              : null
          }`}
        >
          {!_.isEmpty(
            _.get(candidateProfileData, "profile.personal_values", "")
          ) && (
            <div className={`about ${publicPage && "mt-30"}`}>
              <p>
                {_.get(candidateProfileData, "profile.personal_values", "")}
              </p>
            </div>
          )}
        </div>
      </div>
      <Modal
        title="Profile Video"
        visible={videoModal}
        className="profile-modal share-profile-modal editable-modal no-scroll-on-edit-video-modal"
        onClose={() => setVideoModal(false)}
        size="x-large"
      >
        <div className="edit-modal-wrapper">
          <>
            <div className="video-player-wrapper">
              {_.isEmpty(
                _.get(candidateProfileData, "profile.profile_video", "")
              ) && _.isEmpty(_.get(candidate, "candidates.videoUrl", "")) ? (
                <div className="video-placeholder-wrapper">
                  <ImageIcon />
                </div>
              ) : (
                <ReactPlayer
                  playing={true}
                  controls
                  playIcon={
                    <div className="play-icon">
                      <PlayArrowOutlinedIcon />
                    </div>
                  }
                  className="video-player-inner-wrapper"
                  url={
                    candidate?.candidates?.videoUrl === ""
                      ? candidateProfileData?.profile?.profile_video
                      : candidate?.candidates?.videoUrl
                  }
                />
              )}
            </div>
            {!publicPage && (
              <div className="d-flex justify-content-center m-10">
                <Button
                  color="secondary"
                  className="submit-button text-uppercase mr-10"
                  onClick={setSureAboutVideoDelete}
                >
                  Delete
                </Button>
                <Button
                  color="primary"
                  className="submit-button text-uppercase ml-10"
                  onClick={() => {
                    history.push(
                      `/video-studio/edit/${session?.currentUser?.primary_video_id}`
                    );
                  }}
                >
                  Edit
                </Button>
              </div>
            )}
          </>
        </div>
      </Modal>
      <EditBasicInformationModal
        profile={candidateProfileData}
        basicProfile={basicProfile}
        setBasicProfile={setBasicProfile}
      />
    </>
  );
}

export default ProfileHeader;
