import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import companyPublicView from "../../services/companyPublicView.services";
import Image from "../../components/common/Image";
import Actions from "./components/Actions";
import Home from "./components/Home";
import Jobs from "./components/Jobs";
import appRoutes from "../../routes/app.routes";
import { getCurrentTab } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  companyPublicProfile,
  companyJobsList,
} from "../../reducers/companyPublicView/companyPublicView.reducer";
import { Skeleton } from "@material-ui/lab";
import LinkSVG from "../../assets/svg/LinkSVG";
import Feed from "./components/Feed";
import Banner from "../../components/common/Banner";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import SettingsIcon from "@material-ui/icons/MoreHorizOutlined";
import { Button, FlashMessage, Input, Modal } from "../../components/common";
import { Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { rootReducersState } from "../../reducers";
import Avatar from "../../components/common/AvtarImage";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Report from "../../components/common/Report";

type FormInputs = {
  claim_text: string;
};

const CompanyPublicView = (props) => {
  const dispatch = useDispatch();
  const { companyPublicProfile: compPublicProfile } = useSelector(
    ({ companyPublicView }: any) => companyPublicView.companyPublicView
  );
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );


  const token = useSelector(({ session }: rootReducersState) => session.token);

  const history = useHistory();
  const [companyProfile, setCompanyProfile] = useState(compPublicProfile);
  const [companySlug, setCompanySlug] = useState(
    window.location.pathname.split("/")[2]
  );
  const [currentTab, setCurrentTab] = useState("");
  const [loading, setLoading] = useState(false);
  const [showHomeTab, setShowHomeTab] = useState<boolean>(true);
  const [claimModal, setClaimModal] = useState<boolean>(false);
  const [isClaim, setIsClaim] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { register, handleSubmit, errors } = useForm<FormInputs>();
  const currentCompanyId = _.get(companyProfile, "companyProfile.id", "");
  const [companyId, setCompanyId] = useState(null);

  useEffect(() => {
    const companies = _.get(sessionReducer, "currentUser.companies", []);
    if (companies.length > 0) {
      setCompanyId(companies[0].id);
    }
  }, [sessionReducer]);

  useEffect(() => {
    setCurrentTab(getCurrentTab(props, true));
    async function fetchData() {
      const company: any = await companyPublicView.getCompanyProfile({
        companySlug: companySlug,
      });
      dispatch(companyPublicProfile(company.data));
      setCompanyProfile(company?.data);
      setLoading(false);
      // setIsClaim(_.get(company, 'data.companyProfile.is_claimed') === 1 ? true : false)
      setIsClaim(true); // Remove this
    }
    // setLoading(true);
    if (companyProfile && Object.entries(companyProfile).length === 0) {
      fetchData();
      setLoading(true);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (compPublicProfile?.first_name === "COMPANY_NAME") {
      handleTabClick("Jobs");
      setShowHomeTab(false);
    }
    setCompanyProfile(compPublicProfile);
    // eslint-disable-next-line
  }, [compPublicProfile]);

  const handleTabClick = (type) => {
    dispatch(companyJobsList([]));
    setCompanySlug(window.location.pathname.split("/")[2]);
    if (type === "Jobs") {
      history.push(appRoutes.CompanyPublicViewJobs.generatePath(companySlug));
    } else if (type === "feed") {
      history.push(appRoutes.CompanyPublicViewFeed.generatePath(companySlug));
    } else {
      history.push(appRoutes.CompanyPublicView.generatePath(companySlug));
    }
  };

  const handleButtonClick = (type: string, text: any) => {
    switch (type) {
      case "Home":
        handleTabClick(type);
        break;
      case "Jobs":
        handleTabClick(type);
        break;
      case "feed":
        handleTabClick(type);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.scroll(0, 400);
  }, []);

  const handleClaim = () => {
    setClaimModal(true);
  };
  const onSubmit = async (formData: any) => {
    const payload = {
      claim_description: formData.claim_text,
      company_profile_id: _.get(companyProfile, "companyProfile.id", ""),
    };

    try {
      const resp = await companyPublicView.claimPage(payload);

      if (resp.flag) {
        setClaimModal(false);
        setIsClaim(true);
        FlashMessage(resp.message, "success");
      } else {
        FlashMessage(resp.message, "error");
      }
    } catch (error) {
      FlashMessage(error.message, "error");
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className="company-veiw-wrapper">
      <div className="veiw-container">
        <div className="profile-banner">
          {loading ? (
            <Skeleton variant="rect" width={"100%"} height={365} />
          ) : !_.isEmpty(
            _.get(companyProfile, "companyProfile.banner_image", "")
          ) ? (
            <Image
              src={_.get(companyProfile, "companyProfile.banner_image", "")}
              width="100%"
              height="365"
              banner
            />
          ) : (
            <Banner />
          )}
        </div>
        <div className="profile-veiw">
          {token && !isClaim && (
            <div className="claim-container">
              <Menu
                menuButton={
                  <MenuButton className="icon-button">
                    <SettingsIcon />
                  </MenuButton>
                }
                transition
              >
                <MenuItem
                  onClick={() => {
                    handleClaim();
                  }}
                >
                  Claim this Page
                </MenuItem>
              </Menu>
            </div>
          )}
          {loading ? (
            <Skeleton variant="rect" width={"100%"} height={50} />
          ) : (
            <div className="user-info-wrapper">
              <div className="user-wrapper">

                <Avatar
                  src={_.get(companyProfile, "companyProfile.company_logo", "")}
                  size="md"
                  type="company"
                />
                <div className="user-info">
                  <h4>
                    {_.get(companyProfile, "companyProfile.company_name", "")}
                  </h4>
                  <div className="company-title">
                    <span>
                      {[companyProfile?.companyProfile?.industry?.title, companyProfile?.companyProfile?.employeeSize?.title].filter(row => row).join(" - ")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="company-link-report">
                <div className="company-link">
                  {!_.isEmpty(
                    _.get(companyProfile, "companyProfile.website", "")
                  ) && (
                      <a
                        href={`${_.get(
                          companyProfile,
                          "companyProfile.website",
                          ""
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkSVG />
                        {_.get(companyProfile, "companyProfile.website", "")}
                      </a>
                    )}
                </div>
                {currentCompanyId !== companyId && (<MoreHorizIcon
                  className="more-horizontal"
                  onClick={(e) => handleMenu(e)} />)}

                {anchorEl && (
                  <>
                    <Report
                      anchorValue={anchorEl}
                      referenceId={currentCompanyId}
                      type="company"
                      onClose={() => setAnchorEl(null)}
                    />
                  </>)}
              </div>
            </div>
          )}
        </div>
        <div className="main-content">
          <Actions
            handleClick={handleButtonClick}
            {...props}
            showHomeTab={showHomeTab}
          />
          <div className="content-wrapper">
            {currentTab === undefined && <Home loading={loading} />}
            {currentTab === "jobs" && <Jobs />}
            {currentTab === "feed" && <Feed />}
          </div>
        </div>
      </div>
      <Modal
        visible={claimModal}
        onClose={() => setClaimModal(false)}
        title={
          "Claim " + _.get(companyProfile, "companyProfile.company_name", "")
        }
        className="change-pw-modal"
      >
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <div className="user-pv-row">
                <Input
                  name="claim_text"
                  placeholder="Please provide information about this page and our support team will get back to you"
                  multiline={true}
                  validationObj={errors}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter about your claim text",
                    },
                  })}
                  externalLabel={{
                    label:
                      "Please provide information about this page and our support team will get back to you",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} className="text-right form-actions">
              <Button
                onClick={() => setClaimModal(false)}
                color="secondary"
                className="text-uppercase"
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" className="text-uppercase">
                Claim
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </div>
  );
};
export default CompanyPublicView;
