import React from "react";
import CompanySidebar from "./CompanySidebar";
import ProfileAndVisibility from "./ProfileAndVisibility";
import { useLocation } from "react-router-dom";
import AccountPreferences from "./AccountPreferences";
import Notifications from "./Notifications";
import SecurityLogin from "./SecurityLogin";

const Settings = () => {
  const { pathname } = useLocation();
  const currentSubTab = pathname.split("/")[2];
  return (
    <div className="main-setting max-container">
      <div className="company-container main-setting">
        <CompanySidebar />
        {currentSubTab === "profile-visibility" && <ProfileAndVisibility />}
        {currentSubTab === "account-preferences" && <AccountPreferences />}
        {currentSubTab === "notifications" && <Notifications />}
        {currentSubTab === "security-login" && <SecurityLogin />}
      </div>
    </div>
  );
};

export default Settings;
