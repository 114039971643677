import React from "react";
import { Avatar as AV } from "@material-ui/core";
import CompanyIcon from "../svgIcons/CompanyIcon";
import CandidateIcon from "../svgIcons/CandidateIcon";
import NoImage from "../svgIcons/NoImage";
import JobIcon from "../svgIcons/JobIcon";
interface AvatarProps {
  size?: "xsm" | "sm" | "md" | "lg" | "cmd" | "comd";
  src?: string,
  type?: "candidate" | "company" | "post" | "job",
  [key: string]: any;
  className?: string;
}

const Avatar = ({
  size,
  src,
  type,
  className,
  ...props
}: AvatarProps) => {

  const renderComponent = () => {
    if (type === 'company') {
      return (
        <AV
          className={`${className} user-avatar user-avatar-${size}`}
          src={src}
          variant="circle"
          {...props}
        >
          <CompanyIcon />
        </AV>
      )
    } else if (type === 'post') {
      return (<AV
        className="post-image"
        src={src}
        variant="square"
        {...props}
      >
        <NoImage />
      </AV>)
    }
    else if (type === 'job') {
      return (
        <AV
          src={src}
          {...props}
          variant="circle"
        >
          <JobIcon />
        </AV>
      )
    } else {
      return (
        <AV
          className={` ${className} user-avatar user-avatar-${size}`}
          src={src}
          variant="circle"
          {...props}
        >
          <CandidateIcon />
        </AV>
      )
    }
  }
  return renderComponent()
}

export default Avatar;