import CloudUploadRounded from "@material-ui/icons/CloudUploadRounded";
import React, { useRef, useState } from "react";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Paper, makeStyles, Typography, Button, Divider} from "@material-ui/core";
import {  FlashMessage } from "../../../components/common";
import marketingSolutionServices from "../../../services/marketingSolution.services";
import { SAMPLE_CSV_DOWNLOAD, EMAIL_SUBSCRIPTION_OPTIONS, SubscriptionStatus  } from "../../../utils/appConstants";
import { useHistory } from "react-router-dom";
import appRoutes from "../../../routes/app.routes";
import { useSelector, useDispatch } from "react-redux";
import { rootReducersState } from "../../../reducers";
import { setParsedContacts, setSubscriptionStatus } from "../../../reducers/company/companyMarketingSolution.reducer";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { SelectNew } from "../../../components/common";

const useStyles = makeStyles({
  contentwrapper: {
    maxWidth: "1516px",
    width: "100%",
    margin: "0 auto",
    backgroundColor: "#fff",
    maxHeight: "407px",
    height: "100%",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 30px",
  },
  backButn: {
    fontSize: "20px",
    color: "#31222A",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  sectionHeading: {
    color: "#31222A",
    fontSize: "20px",
    lineHeight: "normal",
  },
  divider: {
    height: "1px",
  },
  continueButton: {
      borderRadius: "4px !important",
      border: "1px solid #FF0990 !important",
      background: "#FF0990",
      "&:hover": {
        backgroundColor: "#FF0990",
      },
      padding: "12px 20px",
      color: "#fff",
      fontSize: "16px"
  },
  continueButtonWrapper: {
    // marginLeft: "10px",
    // padding: "30px 15px",
    marginTop: "20px",
    "& .MuiButtonBase-root":{
      borderRadius: "4px !important",
    }
  },
  dropZoneWrapper:{
    maxWidth: "1126px",
    width: "100%",
    margin: "0 auto",
    border: "2px dashed rgba(24, 24, 24, 0.45)",
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "45px 0px",
    cursor: "pointer" 
  },
  dropZoneContent:{
    textAlign: "center",
    
  },
  dropeZoneText:{
    color: "#31222A",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px", 
    letterSpacing: "0.15px",
    marginBottom: "5px"
  },
  subscriptionWrapper:{
    display: "flex",
    "& .custom-select-box":{
      maxWidth: "200px",
      width: "100%",
      padding: "0px 45px",
      "& input": {
        width: "232px !important",
        height: "26px !important",
      },
      "& .eh-custom-dropdown-inner__control":{
        border: "none !important",
        outline: "none !important",
        "& .eh-custom-dropdown-inner":{
          "& .eh-custom-dropdown-inner__indicator-separator":{
            width: "0px !important"
          }
        }
      }
    }
  }
});

const UploadContact = () => {
  const [contactFile, setContactFile] = useState<File>();
  const [selectedVal, setSelectedVal] = useState("")
  const inputRef: any = useRef();
  const history = useHistory();
  const classes = useStyles();
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const companySlug = sessionReducer.currentCompany.slug;
  const dispatch = useDispatch();


  const handleContinue = async () => {
    console.log("contactFile", contactFile);

    if (contactFile) {
      try {
        const formData = new FormData();
        formData.append("file", contactFile);
        formData.append("tags", JSON.stringify([1, 2, 3]));

        // const xlsData = await contactFile.arrayBuffer();
        // const workbook = XLSX.read(xlsData);
        // const workSheet = workbook.Sheets[workbook.SheetNames[0]];
        // const jsonData = XLSX.utils.sheet_to_json(workSheet, {
        //   raw: true,
        // });

        // Check if any record exists
        // if (jsonData.length === 0) {
        //   FlashMessage("At least one record is required", "error");
        //   return;
        // }

        try {
          const result = await marketingSolutionServices.parseContactsFile(
            formData
          );

          if (result.flag) {
            // FlashMessage(result.message, "info");
            dispatch(setParsedContacts(result?.data));
            history.push(appRoutes.createTags.generatePath(companySlug));

          } else {
            // handleErrors(result);
            FlashMessage(result.message, "error");
          }
        } catch (error) {
          FlashMessage(error?.message, "error");
        } 
      } catch (error) {
        FlashMessage("Please upload valid File", "error");
        console.log("error", error);
      }
    }
  };

  const handleSubscriptionStatusChange = (value) => {
    setSelectedVal(value);
    const { label } = value;
    if (label in SubscriptionStatus) {
      const subscriptionStatus = SubscriptionStatus[label];
      dispatch(setSubscriptionStatus(subscriptionStatus))
    }
  }
  
  const handleDownload = () => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.href = SAMPLE_CSV_DOWNLOAD;
    a.download = "Sample File";
    a.click();
    window.URL.revokeObjectURL(SAMPLE_CSV_DOWNLOAD);
    a.remove();
  };

  const _handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setContactFile(file);
    } else {
      FlashMessage("Please upload valid File", "error");
    }
  };

  const handleBack = () => {
    history.goBack(); 
  };
  return (
    <>
      <Paper className={classes.contentwrapper}>
        <div className={classes.titleWrapper}>
          <Typography
            style={{ fontWeight: "500" }}
            className={classes.sectionHeading}
            component="p"
          >
            Upload your contacts file
          </Typography>
          <div className={classes.subscriptionWrapper}>
            <SelectNew
              name="email_subscription"
              placeholder="Select Status"
              options={EMAIL_SUBSCRIPTION_OPTIONS}
              isSearchable={false}
              className="select-box"
              value={selectedVal}
              onChange={handleSubscriptionStatusChange}
            />
            <Button className={classes.backButn} onClick={handleBack}>
              <ArrowBackIosIcon
                width="10"
                height="18"
                style={{ color: "#FF0990A6" }}
              />{" "}
              Back
            </Button>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className="pt-15">
          <input
            ref={inputRef}
            hidden
            accept=".csv"
            type="file"
            onChange={_handleUploadFile}
            placeholder="Banner"
          />
          <Paper
            className={classes.dropZoneWrapper}
            onClick={() => inputRef.current.click()}
          >
            <label className={classes.dropZoneContent}>
              {contactFile ? (
                <div>
                  <Typography className={classes.dropeZoneText}>
                    {contactFile.name}
                  </Typography>
                  <InsertDriveFileIcon fontSize="large" color="primary" />
                </div>
              ) : (
                <div>
                  <Typography className={classes.dropeZoneText}>
                    Browse Contact
                  </Typography>
                  <CloudUploadRounded fontSize="large" color="primary" />
                </div>
              )}
            </label>
          </Paper>
        </div>
        <div className={classes.titleWrapper}>
          <div className={classes.continueButtonWrapper}>
            <Button onClick={handleContinue} className={classes.continueButton}>
              Continue
            </Button>
          </div>
          <div
            style={{
              marginTop: "45px",
              cursor: "pointer",
            }}
            onClick={handleDownload}
          >
            Download Sample File
          </div>
        </div>
      </Paper>
    </>
  );
};
export default UploadContact;
