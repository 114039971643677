import React from "react";
import noBannerImg from "../../assets/images/no-banner.png";

interface BannerProps {
  height?: string;
  className?: string;
  width?: string;
  alt?: string;
}
const Banner = (props: BannerProps) => {
  return (
    <div className={`${props?.className} banner-img`} style={{ width: "100%" }}>
      <img
        src={noBannerImg}
        width={props?.width ? props?.width : "100%"}
        height={props?.height ? props?.height : "365"}
        alt={props?.alt ? props?.alt : "banner-img"}
      />
    </div>
  );
};

export default Banner;
