import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { ReducerFlag } from "../../types/reducer.types";
const _ = { get };
interface IState {
  [key: string]: any;
}
// Initial state for reducer
const initialState: IState = {
  loading: false,
  detailLoading: false,
  flag: false,

  // Job Create initial states
  jobCreateLoading: false,
  jobCreateFlag: ReducerFlag.INIT,
  jobCreateMessage: "",
  jobCreateErrors: {},

  // Job Update initial states
  jobUpdateLoading: false,
  jobUpdateFlag: ReducerFlag.INIT,
  jobUpdateMessage: "",
  jobUpdateErrors: {},

  deleteflag: false,
  archiveflag: false,
  pagination: {},
  data: [],
  detail: {},
  jobTypes: [],
  jobLevels: [],
  jobStatuses: [],
  jobDepartments: {
    loading: false,
    errors: null,
    message: "",
    data: [],
  },
  salaryRanges: [],
  jobFilter: {},
  message: "",
  errors: {},
};

const slice = createSlice({
  name: "jobs",
  initialState: initialState,
  reducers: {
    jobListRequest: (state, action) => {
      return {
        ...state,
        loading: true,
        flag: false,
        archiveflag: false,
      };
    },
    publicJobListRequest: (state, action) => {
      return { ...state, loading: true };
    },
    jobListSuccess: (state, { payload }) => {
      const currentData = _.get(payload, "data.data", []);
      let data = [...currentData];
      const lazyLoad = payload?.lazyLoad ? true : false;
      const currentPage = _.get(payload, "data.pagination.page", 1) || 1;

      // TODO - separate reducer for private and public jobs
      if (lazyLoad && currentPage > 1) {
        const oldData = _.get(state, "data", []);
        data = [...oldData, ...currentData];
      }

      return {
        ...state,
        loading: false,
        flag: false,
        data: data,
        pagination: {
          current: currentPage,
          total: _.get(payload, "data.pagination.totalPages", 0),
          pageSize: _.get(payload, "data.pagination.pageSize", 10),
          totalRecords: _.get(payload, "data.pagination.pageRecords", 0),
          totalResult: _.get(payload, "data.pagination.totalResult", 0),
        },
      };
    },
    jobListReset: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        flag: false,
        data: [],
        pagination: {
          current:
            _.get(payload, "data.pagination.page", 0) === 0
              ? 1
              : _.get(payload, "data.pagination.page", 0),
          total: _.get(payload, "data.pagination.totalPages", 0),
          pageSize: _.get(payload, "data.pagination.pageSize", 10),
          totalRecords: _.get(payload, "data.pagination.pageRecords", 0),
        },
      };
    },
    jobListFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        flag: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    getJobRequest: (state, payload) => {
      return { ...state, detailLoading: true };
    },
    getJobSuccess: (state, { payload }) => {
      return {
        ...state,
        detailLoading: false,
        flag: false,
        detail: payload.data,
      };
    },
    getJobFailed: (state, { payload }) => {
      return {
        ...state,
        detailLoading: false,
        flag: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    createJobRequest: (state, payload) => {
      return {
        ...state,
        jobCreateLoading: true,
        jobCreateFlag: ReducerFlag.INIT,
        jobCreateMessage: "",
        jobCreateErrors: {},
      };
    },
    createJobSuccess: (state, { payload }) => {
      return {
        ...state,
        jobCreateLoading: false,
        jobCreateFlag: ReducerFlag.SUCCESS,
        jobCreateMessage: payload.message,
      };
    },
    createJobFailed: (state, { payload }) => {
      return {
        ...state,
        jobCreateLoading: false,
        jobCreateFlag: ReducerFlag.FAILED,
        jobCreateMessage: _.get(payload, "message", ""),
        jobCreateErrors: _.get(payload, "errors", {}),
      };
    },
    createUpdateJobReset: (state) => {
      return {
        ...state,
        jobCreateLoading: false,
        jobCreateFlag: ReducerFlag.INIT,
        jobCreateMessage: "",
        jobCreateErrors: {},
        jobUpdateLoading: false,
        jobUpdateFlag: ReducerFlag.INIT,
        jobUpdateMessage: "",
        jobUpdateErrors: {},
      };
    },

    deleteJobRequest: (state, { payload }) => {
      return { ...state, deleteflag: false, loading: true };
    },
    deleteJobSuccess: (state, { payload }) => {
      const oldData = _.get(state, "data", []);
      const id = _.get(payload, "id");
      const currentData: object[] = [];
      if (id) {
        oldData.forEach((element: any) => {
          if (element?.id !== id) {
            currentData.push(element);
          }
        });
      }

      return {
        ...state,
        loading: false,
        deleteflag: true,
        data: currentData,
        message: payload.message,
      };
    },
    deleteJobFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        deleteflag: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    updateJobRequest: (state, payload) => {
      return {
        ...state,
        jobUpdateLoading: true,
        jobUpdateFlag: ReducerFlag.INIT,
        jobUpdateMessage: "",
        jobUpdateErrors: {},
      };
    },
    updateJobSuccess: (state, { payload }) => {
      return {
        ...state,
        jobUpdateLoading: false,
        jobUpdateFlag: ReducerFlag.SUCCESS,
        jobUpdateMessage: payload.message,
      };
    },
    updateJobFailed: (state, { payload }) => {
      return {
        ...state,
        jobUpdateLoading: false,
        jobUpdateFlag: ReducerFlag.FAILED,
        jobUpdateMessage: _.get(payload, "message", ""),
        jobUpdateErrors: _.get(payload, "errors", {}),
      };
    },

    archiveJobRequest: (state, payload) => {
      return { ...state, loading: true };
    },
    archiveJobSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        archiveflag: true,
        message: payload.message,
      };
    },
    archiveJobFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        archiveflag: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    jobTypesRequest: (state) => {
      return { ...state, loading: true };
    },
    jobTypesSuccess: (state, { payload }) => {
      return { ...state, loading: false, jobTypes: _.get(payload, "data", []) };
    },
    jobTypesFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    jobLevelsRequest: (state) => {
      return { ...state, loading: true };
    },
    jobLevelsSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        jobLevels: _.get(payload, "data", []),
      };
    },
    jobLevelsFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    salaryRangesRequest: (state) => {
      return { ...state, loading: true };
    },
    salaryRangesSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        salaryRanges: _.get(payload, "data", []),
      };
    },
    salaryRangesFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    jobStatusRequest: (state) => {
      return { ...state, loading: true };
    },
    jobStatusSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        jobStatuses: _.get(payload, "data", []),
      };
    },
    jobStatusFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    jobReset: (state) => {
      return { ...state, ...initialState };
    },

    jobDetailReset: (state) => {
      return { ...state, detail: {} };
    },
    jobDetailUpdate: (state, { payload }) => {
      return { ...state, detail: payload };
    },

    jobAddFilter: (state, { payload }) => {
      return { ...state, jobFilter: { ...payload } };
    },

    jobResetFilter: (state) => {
      return { ...state, jobFilter: {} };
    },

    jobDepartmentListRequest: (state) => {
      return {
        ...state,
        jobDepartments: {
          ...state.jobDepartments,
          loading: true,
        },
      };
    },

    jobDepartmentListSuccess: (state, { payload }) => {
      return {
        ...state,
        jobDepartments: {
          ...state.jobDepartments,
          data: _.get(payload, "data", []),
          loading: false,
        },
      };
    },

    jobDepartmentListFailed: (state, { payload }) => {
      return {
        ...state,
        jobDepartments: {
          ...state.jobDepartments,
          loading: false,
          message: _.get(payload, "message", ""),
          errors: _.get(payload, "errors", {}),
        },
      };
    },
  },
});

// Actions
export const {
  getJobRequest,
  getJobSuccess,
  jobListReset,
  getJobFailed,
  jobListRequest,
  jobListSuccess,
  jobListFailed,
  jobTypesRequest,
  jobTypesSuccess,
  jobTypesFailed,
  jobStatusRequest,
  jobStatusSuccess,
  jobStatusFailed,
  jobLevelsRequest,
  jobLevelsSuccess,
  jobLevelsFailed,
  createJobRequest,
  createJobSuccess,
  createJobFailed,
  createUpdateJobReset,
  deleteJobRequest,
  deleteJobSuccess,
  deleteJobFailed,
  updateJobRequest,
  updateJobSuccess,
  updateJobFailed,
  archiveJobRequest,
  archiveJobSuccess,
  archiveJobFailed,
  salaryRangesRequest,
  salaryRangesSuccess,
  salaryRangesFailed,
  jobDepartmentListRequest,
  jobDepartmentListSuccess,
  jobDepartmentListFailed,
  jobReset,
  jobDetailReset,
  jobDetailUpdate,
  jobAddFilter,
  jobResetFilter,
  publicJobListRequest,
} = slice.actions;

// Reducers
export default slice.reducer;
