/* eslint-disable react-hooks/exhaustive-deps */
import { Menu, MenuItem, Tooltip, Typography } from "@material-ui/core";
import copyToClipboard from "copy-to-clipboard";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { getMetadata as getVideoMetadata } from "video-metadata-thumbnails";
import LinkSVG from "../../assets/svg/LinkSVG";
import PenSVG from "../../assets/svg/PenSVG";
import { Button, ConfirmDialog, Modal } from "../../components/common";
import useSessionUser from "../../hooks/useSessionUser";
import Feed from "../../pages/CandidateProfile/Feed";
import VideoUploader from "../../pages/CandidateProfile/VideoUploader";
import InvitationModal from "../../pages/SearchAndConnect/components/InvitationModal";
import { rootReducersState } from "../../reducers";
import { fetchSessionDataRequest } from "../../reducers/auth/session.reducer";
import {
  candidateViewPageUpdateFlagReset,
  deleteVideoRequest,
  uploadCandidateFileRequest,
} from "../../reducers/candidate/candidate.reducer";
import { tabChangeRequest } from "../../reducers/company/tabChange.reducer";
import appRoutes from "../../routes/app.routes";
import { fetchCandidatePublicProfile } from "../../services/candidate.services";
import searchAndConnectServices from "../../services/searchAndConnect.services";
import {
  DARK_PINK_COLOR,
  DATE_TIME_FORMAT,
  LIGHT_PINK_COLOR,
  RESEND_CONNECTION_REQUEST_TIME_LIMIT,
  VideoMimeTypes,
  connectionRequestStatus,
} from "../../utils/appConstants";
import { storeNewMessageInfo } from "../../utils/helper";
import httpRequest from "../../utils/httpRequest";
import { Accordion, FlashMessage } from "../common";
import Image from "../common/Image";
import DeleteVideoModal from "./components/DeleteVideoModal";
import Education from "./components/Education";
import ProfessionalPassions from "./components/ProfessionalPassions";
import ProfileHeader from "./components/ProfileHeader";
import ProfileShareModal from "./components/ProfileShareModal";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import WorkExperience from "./components/WorkExperience";
import PlayIcon from "./icons/play_icon.svg";
import Avatar from "../common/AvtarImage";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Report from "../common/Report";

interface IProps {
  slug?: string;
  showEditButton?: boolean;
  showShareButton?: boolean;
  showDeleteButton?: boolean;
  picturePath?: string;
  isUploading?: boolean;
  loading?: boolean;
}

let connectAccepted = false;

const ProfileView = (props: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { loggedIn, currentUser, IS_COMPANY } = useSessionUser();
  const {
    candidates: { uploadResumeLoading, candidateUpdatedFlag },
  } = useSelector(({ candidate }: any) => candidate);
  const [candidateProfileData, setCandidateProfileData] = useState({});
  const showProfessionalPassion =
    !_.isEmpty(_.get(candidateProfileData, "profile.early_career", "")) ||
      !_.isEmpty(_.get(candidateProfileData, "profile.mid_career", "")) ||
      !_.isEmpty(_.get(candidateProfileData, "profile.recent_life", ""))
      ? true
      : false;

  const [msgAnchorEl, setMsgAnchorEl] = useState(null);
  const [showResumeDownload, setShowResumeDownload] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [publicProfileLink, setPublicProfileLink] = useState("");
  const [profileShareModal, setProfileShareModal] = useState(false);
  const [sureAboutVideoDelete, setSureAboutVideoDelete] = useState(false);
  const [cancelSentRequestShow, setCancelSentRequestShow] = useState(false);
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const candidateReducer = useSelector(
    ({ candidate }: rootReducersState) => candidate.candidates
  );
  const [workModal, setWorkModal] = useState<boolean>(false);
  const [projectModal, setProjectModal] = useState<boolean>(false);
  const [skillModal, setSkillModal] = useState<boolean>(false);
  const [educationModal, setEducationModal] = useState<boolean>(false);
  const [passionModal, setPassionModal] = useState<boolean>(false);
  const [invitationModalStatus, setInvitationOpen] = useState<boolean>(false);
  const [currentCandidate, setCurrentCandidate] = useState<any>({});
  const [basicProfile, setBasicProfile] = useState<boolean>(false);
  const [resumeViewModal, setResumeViewModal] = useState<boolean>(false);
  const [resumePreviewPath, setResumePreviewPath] = useState<string>("");
  const [loadingResumePreview, setLoadingResumePreview] =
    useState<boolean>(false);
  const referenceId = candidateProfileData?.id
  const [anchorEl, setAnchorEl] = useState(null);
  const publicPage = useRouteMatch({
    path: appRoutes.candidatePublicProfile.path,
    strict: true,
  })
    ? true
    : false;
  let slug = _.get(params, "slug", "");

  // Only set token data if you are not on pubic page
  const tokenData = publicPage ? {} : _.get(sessionReducer, "currentUser", {});
  const currentUserId = _.get(sessionReducer, "currentUser.id", "");
  useEffect(() => {
    // Set the updated data only if user is in the private page and after modified any data
    if (!_.isEmpty(tokenData) && !publicPage) {
      const experiences = (tokenData?.experiences || []).map((row) => {
        if (row.job_title_custom) {
          return {
            ...row,
            job_title_custom: "",
            jobTitle: {
              label: row.job_title_custom,
              value: row.job_title_custom,
            },
          };
        }

        return row;
      });

      const newTokenData = { ...tokenData, experiences: experiences };

      setCandidateProfileData(newTokenData);
    }

    // eslint-disable-next-line
  }, [tokenData]);

  // Fetch the data one candidate view profile page data is updated
  // TODO - handle this via state in the future
  useEffect(() => {
    if (
      candidateReducer.candidateViewPageUpdateFlag === "success" &&
      !publicPage
    ) {
      dispatch(candidateViewPageUpdateFlagReset());
      dispatch(fetchSessionDataRequest());
    }
    // eslint-disable-next-line
  }, [candidateReducer.candidateViewPageUpdateFlag]);

  useEffect(() => {
    if (!uploadResumeLoading && candidateUpdatedFlag) {
      dispatch(fetchSessionDataRequest());
    }

    // eslint-disable-next-line
  }, [candidateUpdatedFlag]);

  useEffect(() => {
    (async function () {
      if (!publicPage) {
        // Candidate view with Auth
        dispatch(fetchSessionDataRequest());
      } else {
        // for the case of fetching profile through slug
        let profileData = {};
        let slug = _.get(params, "slug", "");
        try {
          const result = await fetchCandidatePublicProfile(slug);
          setCurrentCandidate(_.get(result, "data", {}));
          const checkData = _.get(result, "data.email", "");
          if (!_.isEmpty(checkData)) {
            profileData = _.get(result, "data", {});
          }
        } catch (error) {
          profileData = {};
        }
        // Set token profile found successfully
        if (Object.keys(profileData).length > 0) {
          setCandidateProfileData(profileData);
        } else {
          // No profile found
          if (publicPage) history.push(appRoutes.notFoundError.path);
        }
      }
    })();

    // eslint-disable-next-line
  }, []);

  // set candidate profile data Hook
  useEffect(() => {
    if (!_.isEmpty(tokenData)) {
      setCandidateProfileData(tokenData);
      setCurrentCandidate(tokenData);
      const profileLink = appRoutes.candidatePublicProfile.generatePath(
        _.get(tokenData, "slug", "")
      );
      setPublicProfileLink(profileLink);
    } else if (publicPage) {
      const profileLink = appRoutes.candidatePage.generateFullPath(slug);
      setPublicProfileLink(profileLink);
    }

    // eslint-disable-next-line
  }, []);
  const invitationShowModal = () => {
    setInvitationOpen(true);
  };

  const _handleWithdrawModal = () => {
    setCancelSentRequestShow(true);
  };

  const handleCancelSentRequest = async () => {
    try {
      const followId = _.get(currentCandidate, "follow.id");
      const cancelledStatus =
        connectionRequestStatus.find(
          (row) => row.status_text === "cancelled_24_hours"
        )?.status_id || 0;

      await searchAndConnectServices.updateCandidateConnection({
        id: followId,
        type: "cancelled",
        reject_reason: "",
      });

      setCurrentCandidate((prevState) => ({
        ...prevState,
        follow: {
          id: followId,
          status: cancelledStatus,
          cancelled_at: moment().format(DATE_TIME_FORMAT),
        },
      }));
    } catch (error) { }

    setCancelSentRequestShow(false);
  };

  const handleMessageBtnClick = (event) => {
    setMsgAnchorEl(event.currentTarget);
  };

  const handleMessageBtnClose = () => {
    setMsgAnchorEl(null);
  };

  const _handleRedirectToMessages = (isCompany = false, data?: any) => {
    storeNewMessageInfo({
      slug: currentCandidate.slug,
      uuid: currentCandidate.id,
      first_name: currentCandidate.first_name,
      isCompany: isCompany ? 1 : null,
      last_name: currentCandidate.last_name,
      profile_image: currentCandidate.profile_image,
    });

    if (isCompany) {
      dispatch(tabChangeRequest({ tab: "messages" }));
      history.push(appRoutes.companyMessages.generatePath(data.slug));
    } else {
      history.push(appRoutes.candidateMessages.altPath);
    }

    setMsgAnchorEl(null);
  };

  const handleOnInviteSent = async ({ note }) => {
    try {
      const pendingStatus =
        connectionRequestStatus.find((row) => row.status_text === "pending")
          ?.status_id || 0;

      const result = await searchAndConnectServices.sendInvitation({
        note: note || "",
        id: currentCandidate.id,
      });

      setCurrentCandidate((prevState) => ({
        ...prevState,
        follow: {
          id: _.get(result, "data.data.id", 0),
          status: pendingStatus,
        },
      }));
    } catch (error) { }
  };

  const invitationModalHandleClose = () => {
    setInvitationOpen(false);
  };

  const _copyProfileLink = () => {
    const profileLink = publicPage
      ? appRoutes.candidatePage.generateFullPath(slug)
      : appRoutes.candidatePublicProfile.generateFullPath(
        _.get(tokenData, "slug", "")
      );
    copyToClipboard(profileLink);
    FlashMessage("Copied profile link");
  };

  const _handleDeleteVideo = () => {
    dispatch(deleteVideoRequest());
    setTimeout(() => {
      setSureAboutVideoDelete(false);
      setVideoModal(false);
      dispatch(fetchSessionDataRequest());
    }, 1000);
  };

  const renderCustomGenderValue = () => {
    const genderPronoun = parseInt(
      _.get(candidateProfileData, "gender_pronoun", 0)
    );
    const customGender = _.get(candidateProfileData, "gender_optional")
      ? `- ${_.get(candidateProfileData, "gender_optional")}`
      : "";
    switch (genderPronoun) {
      case 1:
        return `She/Her ${customGender}`;
      case 2:
        return `He/Him ${customGender}`;
      case 3:
        return `They/Them ${customGender}`;
    }

    return "";
  };

  const handleGender = (gender) => {
    let genderText = "";
    switch (gender) {
      case "female":
        genderText = "She/Her";
        break;
      case "male":
        genderText = "He/Him";
        break;
      case "they":
        genderText = "They/Them";
        break;
      case "custom":
        genderText = renderCustomGenderValue();
        break;
      default:
        break;
    }

    return genderText ? ` - ${genderText}` : genderText;
  };

  const uploadFile = async (file, type) => {
    if (file) {
      const fileType = file.type;
      if (type === "video") {
        if (VideoMimeTypes.includes(fileType)) {
          const videoMetaData = await getVideoMetadata(file);
          const payload = new FormData();
          payload.append("files", file);
          payload.append("meta", JSON.stringify(videoMetaData));
          dispatch(uploadCandidateFileRequest({ payload, type }));
        } else {
          FlashMessage("Please select valid mp4 or mov file", "error");
        }
      }
    } else {
      FlashMessage("Please select file", "error");
    }
  };

  const AccordionItems = [
    {
      id: 2,
      type: "professional_passions",
      addLink: (
        <>
          {!showProfessionalPassion && (
            <h2
              className="bold link-text mt-0"
              onClick={() => setPassionModal(true)}
            >
              + Add Professional Passions
            </h2>
          )}
        </>
      ),
      addLinkActive: showProfessionalPassion ? true : false,
      title: <h1>Professional Passions</h1>,
      sectionContent: (
        <ProfessionalPassions
          publicPage={publicPage}
          candidateProfileData={candidateProfileData}
          showProfessionalPassion={showProfessionalPassion}
          passionModal={passionModal}
          setPassionModal={setPassionModal}
          showEditButton={props.showEditButton}
        />
      ),
    },
    {
      id: 3,
      type: "work_experience",
      addLink: (
        <>
          {_.isEmpty(_.get(candidateProfileData, "experiences", [])) && (
            <h2
              className="bold link-text mt-0"
              onClick={() => setWorkModal(true)}
            >
              + Add Work Experience
            </h2>
          )}
        </>
      ),
      addLinkActive: !_.isEmpty(_.get(candidateProfileData, "experiences", []))
        ? true
        : false,
      title: <h1>Work Experience</h1>,
      sectionContent: (
        <WorkExperience
          experiences={_.get(candidateProfileData, "experiences", [])}
          publicPage={publicPage}
          workModal={workModal}
          setWorkModal={setWorkModal}
          showEditButton={props.showEditButton}
        />
      ),
    },
    {
      id: 4,
      type: "projects",
      addLink: (
        <>
          {_.isEmpty(_.get(candidateProfileData, "projects", [])) && (
            <h2
              className="bold link-text mt-0"
              onClick={() => setProjectModal(true)}
            >
              + Add Projects
            </h2>
          )}
        </>
      ),
      addLinkActive: !_.isEmpty(_.get(candidateProfileData, "projects", []))
        ? true
        : false,
      title: <h1>Projects</h1>,
      sectionContent: (
        <Projects
          projects={_.get(candidateProfileData, "projects", [])}
          publicPage={publicPage}
          projectModal={projectModal}
          setProjectModal={setProjectModal}
          showEditButton={props.showEditButton}
        />
      ),
    },
    {
      id: 5,
      type: "skills",
      addLink: (
        <>
          {_.isEmpty(_.get(candidateProfileData, "skills", [])) && (
            <h2
              className="bold link-text mt-0"
              onClick={() => setSkillModal(true)}
            >
              + Add Skills & Tools
            </h2>
          )}
        </>
      ),
      addLinkActive: !_.isEmpty(_.get(candidateProfileData, "skills", []))
        ? true
        : false,
      title: <h1>Skills &amp; Tools</h1>,
      sectionContent: (
        <Skills
          skills={_.get(candidateProfileData, "skills", [])}
          publicPage={publicPage}
          skillModal={skillModal}
          setSkillModal={setSkillModal}
          showEditButton={props.showEditButton}
        />
      ),
    },
    {
      id: 6,
      type: "education",
      addLink: (
        <>
          {_.isEmpty(_.get(candidateProfileData, "education", [])) && (
            <h2
              className="bold link-text mt-0"
              onClick={() => setEducationModal(true)}
            >
              + Add Education
            </h2>
          )}
        </>
      ),
      addLinkActive: !_.isEmpty(_.get(candidateProfileData, "education", []))
        ? true
        : false,
      title: <h1>Education</h1>,
      sectionContent: (
        <Education
          education={_.get(candidateProfileData, "education", [])}
          publicPage={publicPage}
          educationModal={educationModal}
          setEducationModal={setEducationModal}
          showEditButton={props.showEditButton}
        />
      ),
    },
  ];

  useEffect(() => {
    if (currentCandidate?.follow?.status === 2 && publicPage) {
      const url = _.get(currentCandidate, "profile.resume_file", "");
      if (url) {
        setShowResumeDownload(true);
      }
    } else {
      setShowResumeDownload(false);
    }
  }, [currentCandidate?.follow?.status]);

  // Render connect button
  const renderConnectButton = () => {
    // Check valid condition
    const sessionUserEmail = sessionReducer?.currentUser?.email;
    const profileUserEmail = currentCandidate?.email;

    // If seeing own profile
    if (sessionUserEmail === profileUserEmail || !sessionUserEmail) {
      return <></>;
    }

    // If seeing other candidate profile
    if (
      sessionUserEmail &&
      sessionUserEmail !== profileUserEmail &&
      profileUserEmail
    ) {
      const connectStatusId = currentCandidate?.follow?.status;

      const connectStatus = connectionRequestStatus.find(
        (row) => row.status_id === connectStatusId
      )?.status_text;

      switch (connectStatus) {
        case "accepted":
          connectAccepted = true;
          return <></>;
        case "rejected":
          return (
            <Button color="secondary" className="text-uppercase">
              Rejected
            </Button>
          );
        case "pending":
          const flag =
            currentUserId === _.get(candidateProfileData, "follow.follower_id");
          return (
            <Button
              color="secondary"
              className={`${flag ? "btn-cancel-request" : ""}`}
              onClick={() => _handleWithdrawModal()}
            >
              {flag ? "Cancel Request" : "Withdraw"}
            </Button>
          );
        case "cancelled_24_hours":
          const cancelledAt =
            currentCandidate?.follow?.cancelled_at || new Date();
          const cancelledTime = moment(cancelledAt).add(
            RESEND_CONNECTION_REQUEST_TIME_LIMIT,
            "hours"
          );
          const allowResentRequest = moment().diff(cancelledTime, "hours");
          // 24 hours is not passed yet, user still not able to resent the request
          const finalHours = Math.abs(allowResentRequest) + 1;
          return (
            <Button disabled className="w-mx-content">
              Wait {finalHours} {finalHours === 1 ? "hour" : "hours"}
            </Button>
          );
        default:
          if (IS_COMPANY) {
            return <></>;
          } else {
            return (
              <Button onClick={() => invitationShowModal()} color="primary">
                Connect
              </Button>
            );
          }
      }
    }

    return <></>;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleShowResume = async () => {
    setLoadingResumePreview(true);
    try {
      const { data } = await httpRequest().post(
        `candidates/profile/public/resume/${slug}/preview`
      );
      const path = data.path;
      if (path) {
        setResumePreviewPath(path);
        setResumeViewModal(true);
      } else {
        FlashMessage("Resume not found", "error");
      }
    } catch (error) {
      FlashMessage("Resume not found", "error");
    } finally {
      setLoadingResumePreview(false);
    }
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className="max-container">
      <div
        className={`profile-view-wrapper  ${publicPage && "search-profile-card-wrapper"
          }`}
      >
        <div
          className={`profile-view-card profile-view-info-card mb-30 ${publicPage ||
            _.isEmpty(
              _.get(candidateProfileData, "profile.personal_values", "")
            )
            ? "pb-10"
            : null
            }`}
        >
          <div className="profile-view-header mb-15 d-flex justify-content-between">
            <div className="user-avatar-wrapper">
              <CircularProgressbarWithChildren
                value={13}
                strokeWidth={0}
                maxValue={100}
                styles={buildStyles({
                  pathColor: DARK_PINK_COLOR,
                  trailColor: LIGHT_PINK_COLOR,
                })}
              >
                <Avatar
                  src={_.get(candidateProfileData, "profile_image", "")}
                  size="sm"
                ></Avatar>

                {!_.isEmpty(
                  _.get(candidateProfileData, "profile.profile_video", "") ||
                  _.get(candidateReducer, "videoUrl", "")
                ) ? (
                  <span
                    className={`play-button ${_.get(candidateProfileData, "profile_image")
                      ? "image-pf"
                      : null
                      }`}
                    onClick={() => setVideoModal(true)}
                  >
                    <Image src={PlayIcon} alt="Play icon" />
                  </span>
                ) : null}
              </CircularProgressbarWithChildren>
            </div>
            <div className="profile-view-header-info">
              <div className="header-main-info">
                <h1>
                  {_.get(candidateProfileData, "first_name", "")}{" "}
                  {_.get(candidateProfileData, "last_name", "")}
                  {handleGender(_.get(candidateProfileData, "gender", ""))}
                </h1>
                <div className="action-link">
                  {props.showShareButton && (
                    <Tooltip title="Share profile" placement="top" arrow>
                      <div
                        onClick={() => setProfileShareModal(true)}
                        className="action-item-icon"
                      >
                        <LinkSVG />
                      </div>
                    </Tooltip>
                  )}
                  {(showResumeDownload || IS_COMPANY) && (
                    <div>
                      <Button
                        color="secondary"
                        onClick={() => handleShowResume()}
                        loading={loadingResumePreview}
                      >
                        View Resume
                      </Button>
                    </div>
                  )}
                  {!publicPage && props.showEditButton && (
                    <Tooltip title="Edit profile" placement="top" arrow>
                      <div
                        onClick={() => setBasicProfile(true)}
                        className="action-item-icon"
                      >
                        <PenSVG />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
              <h2 className="mobile-only">
                {_.get(candidateProfileData, "profile.job_title_custom", "") ===
                  "" ||
                  _.get(candidateProfileData, "profile.job_title_custom", "") ===
                  null
                  ? _.get(candidateProfileData, "profile.jobTitle.title", "")
                  : _.get(candidateProfileData, "profile.job_title_custom", "")}
              </h2>
              {!publicPage && props.showEditButton && (
                <h2 className="desktop-only">
                  {_.get(candidateProfileData, "email", "")}
                </h2>
              )}
              {!publicPage && (
                <h2 className="mobile-only">
                  {_.get(candidateProfileData, "email", "")}
                </h2>
              )}
              <h2 className="desktop-only">
                {_.get(candidateProfileData, "profile.job_title_custom", "") ===
                  "" ||
                  _.get(candidateProfileData, "profile.job_title_custom", "") ===
                  null
                  ? _.get(candidateProfileData, "profile.jobTitle.title", "")
                  : _.get(candidateProfileData, "profile.job_title_custom", "")}
              </h2>
              <h2 className="bold desktop-only">
                {_.get(candidateProfileData, "profile.work_status", "")}
              </h2>
              <h2 className="bold mobile-only">
                {_.get(candidateProfileData, "profile.work_status", "")}
              </h2>
              <h2>
                {_.get(candidateProfileData, "profile.location.location", "")}
              </h2>
              <div className="mt-5">
                <ProfileHeader
                  candidateProfileData={candidateProfileData}
                  showEditButton={props.showEditButton}
                  showShareButton={props.showShareButton}
                  setSureAboutVideoDelete={() => setSureAboutVideoDelete(true)}
                  publicPage={publicPage}
                  videoModal={videoModal}
                  setVideoModal={setVideoModal}
                  basicProfile={basicProfile}
                  setBasicProfile={setBasicProfile}
                />
              </div>
            </div>
            {publicPage && loggedIn && (
              <div className="ml-15 flex-row gap-2">
                {renderConnectButton()}

                {(currentUser?.companies?.length > 0 || connectAccepted) && (
                  <Button
                    color="primary"
                    onClick={(e) => handleMessageBtnClick(e)}
                  >
                    Message
                  </Button>
                )}
                <MoreHorizIcon
                  className="more-horizontal"
                  onClick={(e) => handleMenu(e)} />

                {anchorEl && currentCandidate?.id !== currentUserId && (
                  <Report
                    anchorValue={anchorEl}
                    referenceId={referenceId}
                    type="candidate_profile"
                    onClose={() => setAnchorEl(null)}
                  />
                )}

              </div>
            )}
          </div>
        </div>
        {!publicPage ? (
          <>
            <div className="main-profile-wrapper">
              {props.showEditButton && (
                <VideoUploader
                  profileVideoUrl={_.get(
                    props,
                    "profile.profile.profile_video",
                    ""
                  )}
                  isUploading={props.isUploading}
                  uploadVideo={(file, type) => uploadFile(file, type)}
                  totalVideos={_.get(props, "profile.profileVideoCount", 0)}
                  showEditButton={props.showEditButton}
                  candidateProfileData={candidateProfileData}
                />
              )}
            </div>
            <Accordion
              data={AccordionItems}
              showId={false}
              className={"profile-accordion-wrapper"}
              basedOnTypeSection={true}
            />
          </>
        ) : (
          <>
            {!publicPage && (
              <>
                <div className="main-profile-wrapper">
                  <div className="her-profile position-collapsible-label">
                    <Typography gutterBottom>
                      employ<span>HER</span> Video
                    </Typography>
                  </div>
                  <VideoUploader
                    profileVideoUrl={_.get(
                      props,
                      "profile.profile.profile_video",
                      ""
                    )}
                    isUploading={props.isUploading}
                    uploadVideo={(file, type) => uploadFile(file, type)}
                    totalVideos={_.get(
                      candidateProfileData,
                      "profile_video_count",
                      0
                    )}
                  />
                </div>
              </>
            )}
            <ProfessionalPassions
              publicPage={publicPage}
              candidateProfileData={candidateProfileData}
              showProfessionalPassion={showProfessionalPassion}
              passionModal={passionModal}
              setPassionModal={setPassionModal}
            />
            <WorkExperience
              experiences={_.get(candidateProfileData, "experiences", [])}
              publicPage={publicPage}
              workModal={workModal}
              setWorkModal={setWorkModal}
            />
            <Projects
              projects={_.get(candidateProfileData, "projects", [])}
              publicPage={publicPage}
              projectModal={projectModal}
              setProjectModal={setProjectModal}
            />
            <Skills
              skills={_.get(candidateProfileData, "skills", [])}
              publicPage={publicPage}
              skillModal={skillModal}
              setSkillModal={setSkillModal}
            />
            <Education
              education={_.get(candidateProfileData, "education", [])}
              publicPage={publicPage}
              educationModal={educationModal}
              setEducationModal={setEducationModal}
            />
            {publicPage &&
              !_.isEmpty(candidateProfileData) &&
              !_.isEmpty(_.get(candidateProfileData, "feed", [])) && (
                <Feed
                  feed={_.get(candidateProfileData, "feed", [])}
                  publicPage={publicPage}
                  type={0}
                />
              )}
            {publicPage &&
              !_.isEmpty(candidateProfileData) &&
              !_.isEmpty(_.get(candidateProfileData, "article", [])) && (
                <Feed
                  feed={_.get(candidateProfileData, "article", [])}
                  publicPage={publicPage}
                  type={1}
                />
              )}
          </>
        )}
        <ProfileShareModal
          publicProfileLink={publicProfileLink}
          open={profileShareModal}
          onClose={() => setProfileShareModal(false)}
          copyProfileLink={_copyProfileLink}
        />

        <InvitationModal
          open={invitationModalStatus}
          payload={currentCandidate}
          onInviteSent={handleOnInviteSent}
          handleOnClose={invitationModalHandleClose}
        />

        <DeleteVideoModal
          open={sureAboutVideoDelete}
          onClose={() => setSureAboutVideoDelete(false)}
          handleDelete={_handleDeleteVideo}
        />

        <ConfirmDialog
          visible={cancelSentRequestShow}
          title={`Cancel sent request`}
          onCancel={() => setCancelSentRequestShow(false)}
          bodyText={`Request to cancel will not allow to reconnect with this person for up to ${RESEND_CONNECTION_REQUEST_TIME_LIMIT} hours.`}
          onConfirm={() => handleCancelSentRequest()}
          confirmText="Okay"
        />

        <Modal
          visible={resumeViewModal}
          onClose={() => {
            setResumeViewModal(false);
          }}
          title="View Resume"
          size="x-large"
          className="resume-upload-modal"
        >
          <div className="flex-column align-items-center mb-20">
            <DocViewer
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: true,
                },
              }}
              style={{ width: "100%", height: "auto" }}
              documents={[
                {
                  uri: resumePreviewPath,
                },
              ]}
              pluginRenderers={DocViewerRenderers}
            />
          </div>
        </Modal>
      </div>
      <Menu
        id="message-menu"
        anchorEl={msgAnchorEl}
        keepMounted
        open={Boolean(msgAnchorEl)}
        onClose={handleMessageBtnClose}
        classes={{ paper: "border-radius-md" }}
      >
        {currentUser?.companies?.map((row: any, key: any) => (
          <MenuItem
            key={key}
            onClick={() => _handleRedirectToMessages(true, row)}
          >
            as {row?.company_name}
          </MenuItem>
        ))}
        {connectAccepted && (
          <MenuItem onClick={() => _handleRedirectToMessages(false)}>
            as {`${currentUser.first_name} ${currentUser.last_name}`}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default ProfileView;
