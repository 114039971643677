import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getContactsListRequest } from '../../../reducers/company/companyMarketingSolution.reducer';
import { rootReducersState } from '../../../reducers';
import { Tabs, Tab, makeStyles} from "@material-ui/core";
import _ from 'lodash';
import Contacts from './contacts';

const useStyles = makeStyles((theme) => ({
  contentwrapper: {
    maxWidth: "1516px",
    width: "100%",
    margin: "0 auto",
    backgroundColor: "#fff",
    maxHeight: "407px",
    height: "100%",
  },
  tabsContainer: {
    maxWidth: "483px",
    width: "100%",
    borderBottom: "1px solid #D4D4D4",
    '& .PrivateTabIndicator-colorSecondary-10':{
      backgroundColor: '#FF0990',
    }
  },
  activeTab: {
    color: '#FF0990',
  },
  
}));
const pageSize = 50;

const ContactList = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(1);
  const marketingSolutionData = useSelector(({company}: rootReducersState) => company.companyMarketingSolution);
  const contactList = _.get(marketingSolutionData, "contactList.data", []);
  const contactListCount = _.get(marketingSolutionData, "contactList.count", 0);

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getContactsListRequest({page, pageSize}));
  },[page, dispatch])

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };
  console.log(contactListCount, "::::count")
  const fetchContactsForPage = (currentPage) => {
    dispatch(getContactsListRequest({currentPage, pageSize}));
  };

  return (
    <>
     <div className={classes.contentwrapper}>
      <Tabs
        className={classes.tabsContainer}
        value={activeTab}
        onChange={handleTabChange}
        variant="fullWidth"
      >
        <Tab label="View Contacts" className={activeTab === 0 ? classes.activeTab : ''} />
        <Tab
          label="Filter Contacts"
          className={activeTab === 1 ? classes.activeTab : ''}
        />
      </Tabs>
    </div>
    <div className="tab-content">
    {activeTab === 0 && <Contacts contactList={contactList} page={page} setPage={setPage} count={contactListCount} fetchContactsForPage={fetchContactsForPage}/>}
    {activeTab === 1 && <div>Filter Contacts Content</div>}
  </div>
  </>
  )
}

export default ContactList;
