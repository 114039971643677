import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import history from "../../../utils/history";
import FeedList from "../FeedList";
import { handleIsHashtagPage } from "../../../reducers/feeds/feedPost.reducer";

const HashTagLayout = () => {
  const dispatch = useDispatch();
  const searchURL = history.location.pathname.split("/");
  const searchParams = searchURL?.[searchURL.length - 1];

  useEffect(() => {
    dispatch(handleIsHashtagPage());

    // eslint-disable-next-line
  }, []);

  return (
    <div className="max-container">
      <div className="feed-container">
        <FeedList isHashTagPage={true} searchParams={searchParams} />
      </div>
    </div>
  );
};

export default HashTagLayout;
