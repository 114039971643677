import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlashMessage, Radio } from "../../../components/common";
import { editAdminRequest } from "../../../reducers/company/companyAdmins.reducer";
import { FormControl, FormLabel, Grid, Typography } from "@material-ui/core";
import { Button, Modal } from "../../../components/common";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { companyUserAccessLevel } from "../../../utils/appConstants";
import Avatar from "../../../components/common/AvtarImage";
interface Props {
  data: any;
  loading: boolean;
  visible: boolean;
  handleClose: () => void;
}
type Inputs = {
  companyHandlerId: number;
  accessLevel: string;
};

const EditAdminModal = ({ visible, handleClose, data }: Props) => {
  const dispatch = useDispatch();

  const defaultValues = { accessLevel: data.role };

  const { register, errors, control } = useForm<Inputs>({
    defaultValues,
  });
  const [accessLevel, setAccessLevel] = useState<string>("");
  const {
    companyAdmins: { updateFlag, editLoading, updateMessage },
  } = useSelector(({ company }: any) => company);

  //  Delete admin success Hook
  useEffect(() => {
    setAccessLevel(data?.role);

    // eslint-disable-next-line
  }, [data?.role]);

  //  Delete admin success Hook
  useEffect(() => {
    if (updateFlag === "success") {
      handleClose();
      FlashMessage(updateMessage);
    } else {
      FlashMessage(updateMessage, "error");
    }

    // eslint-disable-next-line
  }, [updateFlag, updateMessage]);

  const accessRole =
    companyUserAccessLevel.find((row) => row.value === data.role)?.label || "";
  return (
    <Modal
      visible={visible}
      size="large"
      title="Edit Access"
      className="admin-main-modal admin-profile-add-modal"
      closeButton={visible}
      onClose={handleClose}
      closeOnBackDrop={false}
    >
      <div className="admin-info">
        <div className="admin-avatar">
          <Avatar
            src={data.profile_image}
            size="sm"
            onClick={() => _handleOpenProfileModal()}
          />
        </div>
        <div className="admin-name">
          <Typography className="heading-title">{data.name}</Typography>
          <Typography className="heading-role">{accessRole}</Typography>
        </div>
      </div>
      <Grid container xs={12} className="edit-admin-modal-wrapper">
        <FormControl>
          <div className="radio-wrapper">
            <FormLabel id="choose-admin-role-label">Choose role</FormLabel>
            <Radio
              name="Choose Admin Role"
              control={control}
              validationObj={errors}
              labelPlacement="end"
              defaultValue={defaultValues.accessLevel}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAccessLevel(_.get(e, "target.value", ""));
              }}
              inputRef={register({
                required: {
                  value: true,
                  message: "Please select a role",
                },
              })}
              options={companyUserAccessLevel}
            />
          </div>
          <Grid
            item
            spacing={4}
            xs={12}
            className="text-center action-button-wrapper"
          >
            <Button
              loading={editLoading}
              className="primary-btn dlt-btn delete-video-yes text-uppercase"
              onClick={() =>
                dispatch(
                  editAdminRequest({
                    id: data.id,
                    name: data.name,
                    status: data.status,
                    profile_image: data.profile_image,
                    role: accessLevel,
                  })
                )
              }
            >
              Save
            </Button>
            <Button
              disabled={editLoading}
              className="primary-btn btn-transparent dlt-btn delete-video-no"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Grid>
        </FormControl>
      </Grid>
    </Modal>
  );
};

export default EditAdminModal;
