import { put, takeLatest } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import {
  applyJobRequest,
  applyJobSuccess,
  applyJobFailed,
  deleteVideoRequest,
  deleteVideoSuccess,
  deleteVideoFailed,
  updateCandidateRequest,
  updateCandidateSuccess,
  updateCandidateFailed,
  companyCandidatesRequest,
  companyCandidatesSuccess,
  companyCandidatesFail,
  uploadCandidateFileRequest,
  uploadCandidateFileSuccess,
  setCandidateProfileVideoURL,
  uploadCandidateFileFailed,
  getCandidateProfileDetailRequest,
  getCandidateProfileDetailSuccess,
  getCandidateProfileDetailFail,
  getCandidateJobApplicationsRequest,
  getCandidateJobApplicationsAppliedSuccess,
  getCandidateJobApplicationsSavedSuccess,
  getCandidateJobApplicationsFailed,
  getCandidateJobApplicationsFulfilled,
  saveJobSuccess,
  saveJobFailed,
  saveJobRequest,
  updateCandidateProfessionalPassionRequest,
  updateCandidateProfessionalPassionSuccess,
  updateCandidateProfessionalPassionFailed,
  updateCandidatePersonalValuesRequest,
  updateCandidatePersonalValuesSuccess,
  updateCandidatePersonalValuesFailed,
  updateCandidateWorkExperienceRequest,
  updateCandidateWorkExperienceSuccess,
  updateCandidateWorkExperienceFailed,
  updateCandidateProjectsRequest,
  updateCandidateProjectsSuccess,
  updateCandidateProjectsFailed,
  updateCandidateSkillsRequest,
  updateCandidateSkillsSuccess,
  updateCandidateSkillsFailed,
  updateCandidateEducationRequest,
  updateCandidateEducationSuccess,
  updateCandidateEducationFailed,
  uploadCandidateResumeSuccess,
  uploadCandidateResumeFailed,
  uploadCandidateResumeRequest,
  deleteCandidateResumeRequest,
  deleteCandidateResumeSuccess,
  deleteCandidateResumeFailed,
  parseCandidateResumeSuccess,
  parseCandidateResumeFailed,
  parseCandidateResumeRequest,
} from "../reducers/candidate/candidate.reducer";
import candidateServices from "../services/candidate.services";
import { get } from "lodash";
const _ = { get };

interface payloadInterface {
  type: String;
  payload: any;
}

// Get candidate profile detail.
function* getCandidateProfileDetailWorker({ payload }: payloadInterface) {
  // calling the API
  const slug = _.get(payload, "slug", "");
  const response = yield* call(
    candidateServices.fetchCandidatePublicProfile,
    slug
  );

  try {
    if (response.flag === true) {
      const data = _.get(response, "data", []);
      if (data) {
        yield put(
          getCandidateProfileDetailSuccess({
            message: _.get(response, "message", ""),
            data: _.get(response, "data", []),
          })
        );
      } else {
        yield put(
          getCandidateProfileDetailFail({
            message: _.get(response, "message", ""),
            errors: _.get(response, "errors", {}),
          })
        );
      }
    } else {
      yield put(
        getCandidateProfileDetailFail({
          message: _.get(response, "message", ""),
        })
      );
    }
  } catch (error) {
    yield put(
      getCandidateProfileDetailFail({ message: _.get(error, "message", "") })
    );
  }
}

export function* getCandidateProfileDetailWatcher() {
  yield takeLatest(
    getCandidateProfileDetailRequest.type,
    getCandidateProfileDetailWorker
  );
}

// Candidate Update
function* candidateUpdateWorker({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(candidateServices.updateCandidate, payload);

  try {
    if (response.flag === true) {
      yield put(
        updateCandidateSuccess({ message: _.get(response, "message", "") })
      );
    } else {
      yield put(
        updateCandidateFailed({
          message: _.get(response, "message", ""),
          errors: _.get(payload, "errors", {}),
        })
      );
    }
  } catch (error) {
    yield put(updateCandidateFailed({ message: _.get(error, "message", "") }));
  }
}

export function* candidateUpdateWatcher() {
  yield takeLatest(updateCandidateRequest.type, candidateUpdateWorker);
}

// Candidate Apply Job
function* candidateApplyJobWorker({ payload }: payloadInterface) {
  const response = yield* call(candidateServices.candidateApplyJob, payload);

  try {
    if (response.flag === true) {
      yield put(applyJobSuccess({ message: response.message }));
    } else {
      yield put(
        applyJobFailed({
          message: _.get(response, "message", ""),
          errors: _.get(response, "errors", {}),
        })
      );
    }
  } catch (error) {
    yield put(applyJobFailed({ message: error.message }));
  }
}

export function* candidateApplyJobWatcher() {
  yield takeLatest(applyJobRequest.type, candidateApplyJobWorker);
}

// Candidate save Job
function* candidateSaveJobWorker({ payload }: payloadInterface) {
  const response = yield* call(candidateServices.candidateSaveJob, payload);

  try {
    if (response.flag === true) {
      yield put(
        saveJobSuccess({
          message: response.message,
          data: _.get(response, "data", {}),
        })
      );
    } else {
      yield put(
        saveJobFailed({
          message: _.get(response, "message", ""),
          errors: _.get(response, "errors", {}),
        })
      );
    }
  } catch (error: any) {
    yield put(saveJobFailed({ message: error.message }));
  }
}

export function* candidateSaveJobWatcher() {
  yield takeLatest(saveJobRequest.type, candidateSaveJobWorker);
}

function* getCompanyCandidatesListWorker({ payload }: payloadInterface) {
  const response = yield* call(
    candidateServices.companyCandidatesList,
    payload
  );

  try {
    if (response.flag) {
      const data = _.get(response, "data", {});
      if (data) {
        yield put(
          companyCandidatesSuccess({
            message: _.get(response, "message", ""),
            data: data,
          })
        );
      } else {
        yield put(
          companyCandidatesFail({
            message: _.get(response, "message", ""),
            errors: _.get(payload, "errors", {}),
          })
        );
      }
    } else {
      yield put(
        companyCandidatesFail({
          message: _.get(response, "message", ""),
        })
      );
    }
  } catch (error) {
    yield put(
      companyCandidatesFail({
        message: _.get(error, "message", ""),
      })
    );
  }
}

export function* getCompanyCandidatesListWatcher() {
  yield takeLatest(
    companyCandidatesRequest.type,
    getCompanyCandidatesListWorker
  );
}

// Candidate Profile Upload
function* candidateFileUploadWorker({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(candidateServices.uploadCandidateFile, payload);

  try {
    if (response.flag === true) {
      yield put(
        uploadCandidateFileSuccess({
          data: _.get(response, "data", {}),
          message: _.get(response, "message", ""),
        })
      );
      yield put(
        setCandidateProfileVideoURL({
          data: _.get(response, "data", {}),
          message: _.get(response, "message", ""),
        })
      );
    } else {
      yield put(
        uploadCandidateFileFailed({
          message: _.get(response, "message", ""),
          errors: _.get(payload, "errors", {}),
        })
      );
    }
  } catch (error) {
    yield put(
      uploadCandidateFileFailed({ message: _.get(error, "message", "") })
    );
  }
}

export function* candidateFileUploadWatcher() {
  yield takeLatest(uploadCandidateFileRequest.type, candidateFileUploadWorker);
}

// Delete Video
function* videoDeleteWorker({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(candidateServices.deleteVideo);

  try {
    if (response.flag === true) {
      yield put(
        deleteVideoSuccess({ message: _.get(response, "message", "") })
      );
      yield put(
        setCandidateProfileVideoURL({
          data: _.get(response, "data", {}),
          message: _.get(response, "message", ""),
        })
      );
    } else {
      yield put(
        deleteVideoFailed({
          message: _.get(response, "message", ""),
          errors: _.get(payload, "errors", {}),
        })
      );
    }
  } catch (error) {
    yield put(deleteVideoFailed({ message: _.get(error, "message", "") }));
  }
}

export function* videoDeleteWatcher() {
  yield takeLatest(deleteVideoRequest.type, videoDeleteWorker);
}

// Job Applications
function* candidateJobApplicationsWorker({ payload }: payloadInterface) {
  try {
    const responseApplied = yield* call(
      candidateServices.candidateJobApplicationList,
      { type: "applied", ...payload }
    );
    const responseSaved = yield* call(
      candidateServices.candidateJobApplicationList,
      { type: "saved", ...payload }
    );
    yield put(
      getCandidateJobApplicationsAppliedSuccess({ data: responseApplied })
    );
    yield put(getCandidateJobApplicationsSavedSuccess({ data: responseSaved }));
    yield put(getCandidateJobApplicationsFulfilled());
  } catch (error: any) {
    yield put(getCandidateJobApplicationsFailed({ message: error.message }));
  }
}

export function* candidateJobApplicationsWatcher() {
  yield takeLatest(
    getCandidateJobApplicationsRequest.type,
    candidateJobApplicationsWorker
  );
}

//Candidate personal values
function* updateCandidatePersonalValuesSaga({ payload }: payloadInterface) {
  try {
    const response = yield* call(
      candidateServices.updateCandidatePersonalValue,
      { ...payload }
    );
    yield put(updateCandidatePersonalValuesSuccess({ data: response }));
  } catch (error: any) {
    yield put(updateCandidatePersonalValuesFailed({ message: error.message }));
  }
}

export function* candidateUpdatePersonalValuesWatcher() {
  yield takeLatest(
    updateCandidatePersonalValuesRequest.type,
    updateCandidatePersonalValuesSaga
  );
}

//Candidate professionalPassion

function* updateCandidateProfessionalPassionSaga({
  payload,
}: payloadInterface) {
  try {
    const response = yield* call(
      candidateServices.updateCandidateProfessionalPassion,
      { ...payload }
    );
    yield put(updateCandidateProfessionalPassionSuccess({ data: response }));
  } catch (error: any) {
    yield put(
      updateCandidateProfessionalPassionFailed({ message: error.message })
    );
  }
}

export function* candidateUpdateProfessionalPassionWatcher() {
  yield takeLatest(
    updateCandidateProfessionalPassionRequest.type,
    updateCandidateProfessionalPassionSaga
  );
}

//Candidate projects

function* updateCandidateProjectsSaga({ payload }: payloadInterface) {
  try {
    const response = yield* call(
      candidateServices.updateCandidateProjects,
      payload
    );
    yield put(updateCandidateProjectsSuccess({ data: response }));
  } catch (error: any) {
    yield put(updateCandidateProjectsFailed({ message: error.message }));
  }
}

export function* candidateUpdateProjectsWatcher() {
  yield takeLatest(
    updateCandidateProjectsRequest.type,
    updateCandidateProjectsSaga
  );
}

//Candidate work experience

function* updateCandidateWorkExperienceSaga({ payload }: payloadInterface) {
  try {
    const response = yield* call(
      candidateServices.updateCandidateWorkingExperience,
      payload
    );
    yield put(updateCandidateWorkExperienceSuccess({ data: response }));
  } catch (error: any) {
    yield put(updateCandidateWorkExperienceFailed({ message: error.message }));
  }
}

export function* candidateUpdateWorkExperienceWatcher() {
  yield takeLatest(
    updateCandidateWorkExperienceRequest.type,
    updateCandidateWorkExperienceSaga
  );
}

//Candidate Education

function* updateCandidateEducationSaga({ payload }: payloadInterface) {
  try {
    const response = yield* call(
      candidateServices.updateCandidateEducation,
      payload
    );
    yield put(updateCandidateEducationSuccess({ data: response }));
  } catch (error: any) {
    yield put(updateCandidateEducationFailed({ message: error.message }));
  }
}

export function* candidateUpdateEducationWatcher() {
  yield takeLatest(
    updateCandidateEducationRequest.type,
    updateCandidateEducationSaga
  );
}

//Candidate Skills

function* updateCandidateSkillsSaga({ payload }: payloadInterface) {
  try {
    const response = yield* call(
      candidateServices.updateCandidateSkills,
      payload
    );
    yield put(updateCandidateSkillsSuccess({ data: response }));
  } catch (error: any) {
    yield put(updateCandidateSkillsFailed({ message: error.message }));
  }
}

export function* candidateUpdateSkillsWatcher() {
  yield takeLatest(
    updateCandidateSkillsRequest.type,
    updateCandidateSkillsSaga
  );
}

// Candidate resume Upload
function* candidateResumeUploadWorker({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(candidateServices.uploadCandidateResume, payload);

  try {
    if (response.flag === true) {
      yield put(
        uploadCandidateResumeSuccess({
          data: _.get(response, "data", {}),
          message: _.get(response, "message", ""),
          resumeParsedMessage: _.get(response, "resumeParsedMessage", ""),
        })
      );
    } else {
      yield put(
        uploadCandidateResumeFailed({
          message: _.get(response, "message", ""),
          errors: _.get(payload, "errors", {}),
        })
      );
    }
  } catch (error) {
    yield put(
      uploadCandidateResumeFailed({ message: _.get(error, "message", "") })
    );
  }
}

export function* candidateResumeUploadWatcher() {
  yield takeLatest(uploadCandidateResumeRequest.type, candidateResumeUploadWorker);
}

// Candidate resume parsing
function* candidateResumeParseWorker({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(candidateServices.parseCandidateResume, payload);

  try {
    if (response.flag === true) {
      yield put(
        parseCandidateResumeSuccess({
          data: _.get(response, "data", {}),
          message: _.get(response, "message", ""),
        })
      );
    } else {
      yield put(
        parseCandidateResumeFailed({
          message: _.get(response, "message", ""),
          errors: _.get(payload, "errors", {}),
        })
      );
    }
  } catch (error) {
    yield put(
      parseCandidateResumeFailed({ message: _.get(error, "message", "") })
    );
  }
}

export function* candidateResumeParseWatcher() {
  yield takeLatest(parseCandidateResumeRequest.type, candidateResumeParseWorker);
}

// Candidate delete resume 
function* candidateDeleteResumeWorker() {
  // calling the API
  const response = yield* call(candidateServices.deleteCandidateResume);

  try {
    if (response.flag === true) {
      yield put(
        deleteCandidateResumeSuccess({
          data: _.get(response, "data", {}),
          message: _.get(response, "message", ""),
        })
      );
    } else {
      yield put(
        deleteCandidateResumeFailed({
          message: _.get(response, "message", ""),
          errors: _.get(response, "errors", {}),
        })
      );
    }
  } catch (error) {
    yield put(
      deleteCandidateResumeFailed({ message: _.get(error, "message", "") })
    );
  }
}

export function* candidateDeleteResumeWatcher() {
  yield takeLatest(deleteCandidateResumeRequest.type, candidateDeleteResumeWorker);
}
