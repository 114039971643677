import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Input,
  Modal,
  SelectNew,
  ConfirmDialog,
} from "../../../components/common";
import { useForm } from "react-hook-form";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import API_URLS from "../../../utils/apiUrls";
import httpRequest from "../../../utils/httpRequest";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { deleteFeedRequest } from "../../../reducers/feeds/feedPost.reducer";
import { rootReducersState } from "../../../reducers";
import { useDispatch, useSelector } from "react-redux";

interface TimeOption {
  label: string;
  value: string;
}

interface MyDataType {
  id: number;
  content?: string;
  schedule_at?: string;
}

interface ShareModelProps {
  open: boolean;
  onClose: () => void;
  profile?: any;
  setSchedule: (data: { date: Date; selectedTime: TimeOption } | null) => void;
}

function ScheduleModel({
  open,
  onClose,
  profile,
  setSchedule,
}: ShareModelProps) {
  const [time, setTime] = useState<TimeOption[]>([]);
  const [selectedTime, setSelectedTime] = useState<TimeOption>({
    label: "",
    value: "",
  });
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [showRewardModal, setShowRewardModal] = useState<boolean>(false);
  const [dataId, setDataId] = useState<number | undefined>(undefined);
  const [fetchedData, setFetchedData] = useState<MyDataType[] | null>(null);
  const [openDeleteFeedModal, setOpenDeleteFeedModal] =
    useState<boolean>(false);

  const currentDate = new Date();
  const maxDate = new Date();
  maxDate.setDate(currentDate.getDate() + 7);

  useEffect(() => {
    let currentTime = moment();
    const startTime = moment(currentTime).startOf("hour").add(30, "minutes");
    let endTime = moment().set({ hour: 23, minute: 30 });

    const timeOptions: TimeOption[] = [];

    while (startTime.isSameOrBefore(endTime)) {
      timeOptions.push({
        label: startTime.format("hh:mm A"),
        value: startTime.format("YYYY-MM-DD hh:mm"),
      });
      startTime.add(30, "minutes");
    }
    setTime(timeOptions);
  }, []);

  const { errors } = useForm({});

  const handleTimeChange = (selectedOption) => {
    setSelectedTime(selectedOption);
    setSchedule(selectedOption);
  };

  const handleClose = () => {
    onClose();
  };

  const _handleCloseModal = () => {
    setSelectedTime((prevTime) => ({ ...prevTime, label: "", value: "" }));
    onClose();
  };

  const viewAllPosts = async () => {
    try {
      const response = await httpRequest().get(API_URLS.fetchScheduledPost);
      const data: MyDataType[] = response.data.data;
      setFetchedData(data);
      console.log("Fetched Post Data:");
      setShowRewardModal(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const dispatch = useDispatch();
  const { deleteLoading } = useSelector(
    ({ postFeed }: rootReducersState) => postFeed.postFeed
  );

  const handleDeletePost = async () => {
    try {
      await dispatch(deleteFeedRequest(dataId));

      setFetchedData((prevData) => {
        if (!prevData) {
          return null;
        }
        return prevData.filter((post) => post.id !== dataId);
      });
    } catch (error) {
      console.error("Error deleting post:", error);
    } finally {
      setOpenDeleteFeedModal(false);
    }
  };

  return (
    <>
      <Modal
        title="Schedule post"
        visible={open}
        className="share-modal"
        size="large"
        onClose={() => onClose()}
      >
        <div className="date-time">
          <p className="mb3">
            {" "}
            Tue, Apr 16, 5:00 PM Eastern Daylight Time, based on your location{" "}
          </p>

          <div className="Schedule-Wrraper">
            <div className="Date-wraper">
              <p className="scheduled-date">Date</p>
              <Input
                name="date"
                type="date"
                value={
                  selectedDate ? selectedDate.toISOString().slice(0, 10) : ""
                }
                onChange={(e) => {
                  const selected = new Date(e.target.value);
                  if (selected >= currentDate && selected <= maxDate) {
                    setSelectedDate(selected);
                  } else {
                    alert("Please select a date within the valid range");
                  }
                }}
                min={currentDate.toISOString().slice(0, 10)}
                max={maxDate.toISOString().slice(0, 10)}
              />
            </div>

            <div className="Time-wrraper">
              <p className="scheduled-time">Time</p>
              {
                <SelectNew
                  name="Time_status"
                  value={{
                    value: selectedTime?.value,
                    label: selectedTime?.label,
                  }}
                  validationObj={errors}
                  onChange={handleTimeChange}
                  options={time.map((e) => ({
                    value: e.value,
                    label: e.label,
                  }))}
                  required={true}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select work status",
                    },
                  }}
                />
              }
            </div>
          </div>
          <br />
          <div>
            <p className="span-link" onClick={viewAllPosts}>
              View all scheduled posts <span /> <ArrowForwardIcon />
            </p>
          </div>

          <div className="main-btn">
            <Button
              color="secondary"
              className="Back-button"
              onClick={() => _handleCloseModal()}
            >
              Back
            </Button>

            <Button
              color="primary"
              className="Set-button"
              onClick={handleClose}
            >
              Set
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        visible={showRewardModal}
        size="large"
        onClose={() => setShowRewardModal(false)}
        className="refer-how-works"
      >
        <div className="main_body">
          <div className="modal_title">
            <h5>All Scheduled Posts</h5>
          </div>
          <div className="modal_body">
            {fetchedData && fetchedData.length > 0 ? (
              <table className="main_table">
                <thead>
                  <tr>
                    <th className="title_hending">Title</th>
                    <br />
                    <th className="title_hending">Time</th>

                    <th className="title_hending">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {fetchedData.map((post) => (
                    <tr key={post.id}>
                      {" "}
                      <td className="point">
                        {post.content
                          ? post.content.replace(/<[^>]+>/g, "")
                          : ""}
                      </td>
                      <br />
                      <td className="point">{post.schedule_at || "-"}</td>
                      <td className="point">
                        <p
                          onClick={() => {
                            setOpenDeleteFeedModal(true);
                            setDataId(post.id);
                          }}
                        >
                          <DeleteIcon className="delete-icon" />
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No scheduled posts found.</p>
            )}
            {!fetchedData && <p>Loading scheduled posts...</p>}{" "}
            <div className="main-btn">
              <Button
                color="secondary"
                className="close-button"
                onClick={() => setShowRewardModal(false)}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <ConfirmDialog
        visible={openDeleteFeedModal}
        title="Delete post"
        onCancel={() => setOpenDeleteFeedModal(false)}
        bodyText="Do you really want to delete post?"
        onConfirm={() => {
          dispatch(handleDeletePost);
          setOpenDeleteFeedModal(false);
        }}
        loading={deleteLoading}
        confirmText="Confirm"
      />
    </>
  );
}

export default ScheduleModel;
