import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useForm } from "react-hook-form";
import ReactPlayer from "react-player";
import FeedLogo from "./component/FeedLogo";
import FeedButton from "./component/FeedButton";
import { rootReducersState } from "../../../reducers";
import { ReducerFlag } from "../../../types/reducer.types";
import {
  FlashMessage,
  Button,
  ErrorTextHelper,
  RichTextEditor,
} from "../../../components/common";
import {
  feedPostRequest,
  resetFeedPostFlagRequest,
} from "../../../reducers/feeds/feedPost.reducer";
import cameraIcon from "../../../assets/svg/camera.svg";
import Image from "../../../components/common/Image";
import { stripHTMLTags } from "../../../utils/helper";
import useSessionUser from "../../../hooks/useSessionUser";
import {
  POST_MEDIA_UPLOAD_LIMIT,
} from "../../../utils/appConstants";
import Modal from "../../../components/common/Modal";
import httpRequest from "../../../utils/httpRequest";
import API_URLS from "../../../utils/apiUrls";
import Checkbox from "../../../components/common/Checkbox";
import Slider from "@material-ui/core/Slider";
import {
  CreateOutlined as CreateOutlinedIcon,
  DeleteOutlined as DeleteIcon,
  OutlinedFlagOutlined as OutlinedFlagOutlinedIcon,
  Flare as FlareIcon,
  WorkOutlineOutlined as WorkOutlineOutlinedIcon,
  SentimentSatisfiedOutlined as SentimentSatisfiedOutlinedIcon,
  TouchAppOutlined as TouchAppOutlinedIcon,
  AdjustOutlined as AdjustOutlinedIcon,
  PanToolOutlined as PanToolOutlinedIcon,
  InsertEmoticonOutlined as InsertEmoticonOutlinedIcon,
  SentimentDissatisfiedOutlined as SentimentDissatisfiedOutlinedIcon,
  PhotoFilterOutlined as PhotoFilterOutlinedIcon,
  PeopleOutlineOutlined as PeopleOutlineOutlinedIcon,
  LocalActivityOutlined as LocalActivityOutlinedIcon,
  Cancel as CancelOut
} from '@material-ui/icons';
import output from "../../../../src/assets/images/output-onlinegiftools.gif"
import ScheduleModel from "../components/ScheduleModel";
import { Tooltip } from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import appRoutes from "../../../routes/app.routes";
import { useHistory } from "react-router-dom";

type FormFields = {
  post_media: any;
  post_text: string;
  video_cover: any;
};
interface UploadAsset extends Blob {
  url: string;
}

interface IProps {
  slug?: string;
  showEditButton?: boolean;
  showShareButton?: boolean;
  showDeleteButton?: boolean;
  picturePath?: string;
  isUploading?: boolean;
  loading?: boolean;
}
function FeedPost(props: IProps) {
  const dispatch = useDispatch();
  const {
    currentUser,
    IS_COMPANY,
    userProfile,
    userFullName,
    currentCompanyName,
    currentCompanyLogo,
    currentCompanySlug,
  } = useSessionUser();
  const postForm = useForm<FormFields>();
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = postForm;
  const [uploadImage, setUploadImage] = useState<any>([]);
  const [uploadVideo, setUploadVideo] = useState<any>([]);
  const [uploadAssets, setUploadAssets] = useState<UploadAsset[]>([]);
  const [postTextLength, setPostTextLength] = useState<number>(0);
  const [overflow, setOverflow] = useState(false);
  const [showAIModal, setShowAIModal] = useState<boolean>(false);
  const [specify, setSpecify] = useState<any>("SoundCausal");
  const [questions, setQuestion] = useState<any>([]);
  const [responseAiData, setResponseAiData] = useState<any>("");
  const [hash, setHash] = useState<boolean>(false);
  const [wordsLimit, setWordLimit] = useState<number>(50);
  const [loading, setLoading] = useState<boolean>(false);
  const [AIflag, setAIflag] = useState<boolean>(false);
  // const [cover, setCover] = useState<Blob>();
  const [profileShareModal, setProfileShareModal] = useState(false);
  const [schedule, setSchedule] = useState<string | null>();

  const handleTimeChange = (selectedTime) => {
    setSchedule(selectedTime.value);
  };

  const { postLoading, message, postCreateFlag } = useSelector(
    ({ postFeed }: rootReducersState) => postFeed.postFeed
  );

  const postProfile = IS_COMPANY ? currentCompanyLogo : userProfile;
  const postProfileName = IS_COMPANY
    ? (currentCompanyName as string)
    : (userFullName as string);

  const postText = watch("post_text", "");

  // Check the content length
  useEffect(() => {
    const tmpText = stripHTMLTags(postText);
    setPostTextLength(tmpText.length);
  }, [postText]);

  // Create flag
  useEffect(() => {
    if ([ReducerFlag.SUCCESS, ReducerFlag.FAILED].includes(postCreateFlag)) {
      if (postCreateFlag === ReducerFlag.SUCCESS) {
        window.location.reload();
      } else if (postCreateFlag === ReducerFlag.FAILED) {
        FlashMessage(message, "error");
      }

      dispatch(resetFeedPostFlagRequest());
    }

    // eslint-disable-next-line
  }, [postCreateFlag]);

  const onSubmit = (formData: FormFields) => {
    let payload = new FormData();
    if (Array.isArray(uploadAssets) && uploadAssets.length > 0) {
      if (uploadAssets.length > 8) {
        FlashMessage(
          `Max ${POST_MEDIA_UPLOAD_LIMIT} files can be uploaded`,
          "error"
        );
        return;
      }
      let i = 1;
      // console.log('UploadAsset', uploadAssets);
      // let firstFileObject: UploadAsset[] = [];
      // firstFileObject.push(uploadAssets[1]);
      // firstFileObject.push(uploadAssets[3]);
      // console.log('firstFileObject', firstFileObject);
      for (const fileObject of uploadAssets) {
        // console.log('fileObject', fileObject);
        // let a = 1;
        // console.log('a', a);
        // let firstFileObject = uploadAssets[a]

        payload.append(`post_media_${i}`, fileObject);
        // if (fileObject.type === 'video/mp4') {
        //   // firstFileObject.forEach((fileData) => {
        //   //   console.log('fileData.File', fileData);
        //   payload.append(`cover_media_${i}`, firstFileObject); // Assuming `file` is the property containing the actual file data
        //   // });
        // }
        // a = a + 2
        i++;
      }
    }

    const postBy = IS_COMPANY ? (currentCompanySlug as string) : "user";
    payload.append("schedule_at", schedule || "");
    payload.append("post_text", formData.post_text || "");
    payload.append("post_by", postBy);
    // if (cover) {
    //   payload.append("video_cover", cover)
    // }

    dispatch(feedPostRequest(payload));
  };
  // const onUploadCover = async (e: any) => {
  //   const uploadValue = e.target.files;
  //   // setCover(uploadValue);
  //   // Object.keys(uploadValue).forEach((key) => {
  //   //   if (uploadValue[key].type.includes("video") || uploadValue[key].type.includes("image")) {
  //   //     const url = URL.createObjectURL(uploadValue[key]);
  //   //     uploadValue[key]['url'] = url;
  //   //     console.log('uploadValue', uploadValue);
  //   //     setCover(uploadValue);
  //   //   }
  //   // });
  // };
  const onUploadImage = async (e: any) => {
    const uploadValue = e.target.files;
    console.log("uploadValue", uploadValue);
    const fileType = uploadValue[0].type;
    const fileSize = uploadValue[0].size;
    let allowFileSize;
    if (fileType === "video/mp4") {
      allowFileSize = 10485760;
      if (fileSize > (allowFileSize || 0)) {
        const mb = Math.floor((allowFileSize || 0) / 1024 / 1024);
        FlashMessage(`Max ${mb} mb file size allowed`, "error");
      }
    } else {
      allowFileSize = 5242880;
      if (fileSize > (allowFileSize || 0)) {
        const mb = Math.floor((allowFileSize || 0) / 1024 / 1024);
        FlashMessage(`Max ${mb} mb file size allowed`, "error");
      }
    }
    if (uploadValue.length > 9 || uploadAssets.length > 9) {
      e.target.value = null;
      FlashMessage(
        `Max ${POST_MEDIA_UPLOAD_LIMIT} files can be uploaded`,
        "error"
      );
      return;
    }

    Object.keys(uploadValue).forEach((key) => {
      if (
        uploadValue[key].type.includes("video") ||
        uploadValue[key].type.includes("image")
      ) {
        const url = URL.createObjectURL(uploadValue[key]);
        uploadValue[key]["url"] = url;
        console.log("uploadValue", uploadValue);
        setUploadAssets([...uploadValue, ...uploadAssets]);
      }
    });
  };
  const removeMedia = (i) => {
    if (uploadImage) {
      const images = [...uploadImage];
      const imageObj = [...uploadAssets];
      imageObj.splice(i, 1);
      images.splice(i, 1);
      setUploadImage(images);
      setUploadAssets(imageObj);
    } else if (uploadVideo) {
      const videos = [...uploadVideo];
      videos.splice(i, 1);
      setUploadVideo(videos);
    }
    const removeObj = [...uploadAssets];
    removeObj.splice(i, 1);
    setUploadAssets(removeObj);
  };

  const checkKeyDown = (e) => {
    const keyCode = e.keyCode ? e.keyCode : e.which;
    if (keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
    }
  };

  const invokeBedRock = async () => {
    setLoading(true);
    try {
      if (questions.length > 0) {
        const specification = specify;
        const question = questions;
        const payload = {
          question: question,
          specification: specification,
          hash: hash,
          words: wordsLimit,
        };
        const response = await httpRequest().post(
          API_URLS.invokeAwsBedRock,
          payload
        );
        setResponseAiData(response.data.data);
      } else {
        FlashMessage("Please Enter Question", "error");
      }
    } catch (error) {
      FlashMessage(error, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = () => {
    setHash(!!!hash);
  };

  const handleClear = () => {
    setQuestion("");
    setResponseAiData("");
    setSpecify("SoundCausal");
    setHash(false);
    setWordLimit(50);
  };

  const postIncidenceReports = async () => {
    try {
      if (responseAiData) {
        const specification = specify;
        const question = questions;
        const payload = {
          question: question,
          outputText: responseAiData,
          specification: specification,
          hash: hash,
          words: wordsLimit,
        };
        await httpRequest().post(API_URLS.postIncidenceReports, payload);
        FlashMessage("Incidence Report Generate Successfully", "success");
      }
    } catch (error) {
      FlashMessage(error, "error");
    }
  };
  const history = useHistory();
  const handleArticle = () => {
    history.push(appRoutes.article.path);
  };

  return (
    <>
      {currentUser && (
        <div className="feed-input-wrapper">
          <FeedLogo profileLogo={postProfile} name={postProfileName} />
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <div
              className={`post-wrapper wysiwyg-data ${overflow ? "no-overflow" : ""
                }`}
            >
              <RichTextEditor
                name="post_text"
                validationObj={errors}
                control={control}
                showError={false}
                setOverflow={setOverflow}
                value={postText}
                rules={{
                  validate: {
                    validLength: (value) => {
                      const tmpText = stripHTMLTags(value || "");
                      return (
                        tmpText.length <= 3000 ||
                        "Can not exceed more then 3000 characters"
                      );
                    },
                  },
                }}
                useMention={true}
                AIflag={AIflag}
              />
            </div>
            {_.get(errors, "post_text.message") && (
              <ErrorTextHelper text={_.get(errors, "post_text.message", "")} />
            )}
            <p>{postTextLength}/3000</p>
            <div className="media-uploaded">
              {uploadAssets.map((asset, i) => {
                return (
                  <div className="media-src" key={i}>
                    <span className="media-close"
                      onClick={() => removeMedia(i)}>
                      <CancelOut />
                    </span>
                    {asset.type === "video/mp4" ? (
                      <ReactPlayer
                        width="100%"
                        height="100%"
                        url={asset.url}
                        playing={false}
                        controls={true}
                      />
                    ) : (
                      <Image src={asset.url} alt="media image" />
                    )}
                  </div>
                );
              })}
            </div >
            <div className="feed-button-wrapper">
              <FeedButton
                svg={cameraIcon}
                name="post_media"
                id="create_post"
                type="file"
                form={postForm}
                onUploadImage={onUploadImage}
                value="post_media"
                accept="image/png, image/gif, image/bmp, image/jpeg, video/mp4"
              />


              <ScheduleModel
                open={profileShareModal}
                onClose={() => setProfileShareModal(false)}
                setSchedule={handleTimeChange}
              />
              {/* <FeedButton
                svg={cameraIcon}
                name="video_cover"
                id="video_cover"
                type="file"
                form={postForm}
                onUploadImage={onUploadCover}
                value="video_cover"
                accept="image/png, image/gif, image/bmp, image/jpeg"
              /> */}
              <Tooltip title="Schedule for later" placement="top" arrow className="schedule-post">
                <div
                  onClick={() => setProfileShareModal(true)}
                  className="action-item-icon"
                >
                  <ScheduleIcon color="primary" />
                </div>
              </Tooltip>
              <Button
                className="write-ai"
                color="secondary"
                onClick={() => {
                  setAIflag(false);
                  setShowAIModal(true);
                }}
              >
                <CreateOutlinedIcon />
                &nbsp;Write with AI
              </Button>

              {showAIModal && (
                <Modal
                  title={
                    <>
                      {" "}
                      Generate with employ{" "}
                      <span style={{ color: "#F33066" }}>Her</span>&nbsp;AI{" "}
                    </>
                  }
                  className="ai-model"
                  visible={true}
                  onClose={() => {
                    loading ? setShowAIModal(true) : setShowAIModal(false);
                    handleClear();
                  }}
                >
                  <div className="ai-output">
                    {loading ? (
                      <div className="load-image">
                        <Image src={output} />
                      </div>
                    ) : responseAiData ? (
                      <textarea
                        className="response"
                        onChange={(e) => {
                          setResponseAiData(e?.currentTarget?.value || "");
                        }}
                      >
                        {responseAiData}
                      </textarea>
                    ) : (
                      <>
                        <textarea
                          className="ai-question"
                          id="title"
                          maxLength={150}
                          placeholder="Enter a Prompt here"
                          rows={30}
                          cols={30}
                          value={questions}
                          onKeyDown={(e) => checkKeyDown(e)}
                          onChange={(e) => {
                            setQuestion(e?.currentTarget?.value || "");
                          }}
                        />
                      </>
                    )}
                    {!loading && (
                      <div>
                        <DeleteIcon
                          className="delete-icon"
                          onClick={() => setResponseAiData("")}
                        />
                        <OutlinedFlagOutlinedIcon
                          className="flag"
                          onClick={() => postIncidenceReports()}
                        />
                        <Button
                          className="rephrase"
                          color="secondary"
                          onClick={() => invokeBedRock()}
                        >
                          <FlareIcon />
                          &nbsp;GENERATE MAGIC
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="specification-area">
                    <div className="adjust-tone">Adjust Tone</div>
                    <div className="btn-specification">
                      <Button
                        color={
                          specify === "SoundCausal" ? "primary" : "secondary"
                        }
                        className="specification"
                        type="submit"
                        disabled={loading}
                        onClick={() => setSpecify("SoundCausal")}
                      >
                        <WorkOutlineOutlinedIcon />
                        &nbsp;Sound Causal
                      </Button>
                      <Button
                        color={
                          specify === "SoundProfessional"
                            ? "primary"
                            : "secondary"
                        }
                        className="specification"
                        type="submit"
                        disabled={loading}
                        onClick={() => setSpecify("SoundProfessional")}
                      >
                        <LocalActivityOutlinedIcon />
                        &nbsp;Sound Professional
                      </Button>
                      <Button
                        color={
                          specify === "MakeItFriendly" ? "primary" : "secondary"
                        }
                        className="specification"
                        type="submit"
                        disabled={loading}
                        onClick={() => setSpecify("MakeItFriendly")}
                      >
                        <SentimentSatisfiedOutlinedIcon />
                        &nbsp;Make It Friendly
                      </Button>
                      <Button
                        color={
                          specify === "MakeItDirect" ? "primary" : "secondary"
                        }
                        className="specification"
                        type="submit"
                        disabled={loading}
                        onClick={() => setSpecify("MakeItDirect")}
                      >
                        <AdjustOutlinedIcon /> &nbsp;Make It Direct
                      </Button>
                      <Button
                        color={
                          specify === "MakeItAssertive"
                            ? "primary"
                            : "secondary"
                        }
                        className="specification"
                        type="submit"
                        disabled={loading}
                        onClick={() => setSpecify("MakeItAssertive")}
                      >
                        <TouchAppOutlinedIcon />
                        &nbsp;Make It Assertive
                      </Button>

                      <Button
                        color={
                          specify === "MakeItPersuasive"
                            ? "primary"
                            : "secondary"
                        }
                        className="specification"
                        type="submit"
                        disabled={loading}
                        onClick={() => setSpecify("MakeItPersuasive")}
                      >
                        <AdjustOutlinedIcon /> &nbsp;Make It Persuasive
                      </Button>
                      <Button
                        color={
                          specify === "SoundConfident" ? "primary" : "secondary"
                        }
                        className="specification"
                        type="submit"
                        disabled={loading}
                        onClick={() => setSpecify("SoundConfident")}
                      >
                        <PeopleOutlineOutlinedIcon />
                        &nbsp;Sound Confident
                      </Button>
                      <Button
                        color={
                          specify === "MakeItLuxury" ? "primary" : "secondary"
                        }
                        className="specification"
                        type="submit"
                        disabled={loading}
                        onClick={() => setSpecify("MakeItLuxury")}
                      >
                        <SentimentSatisfiedOutlinedIcon />
                        &nbsp;Make It Luxury
                      </Button>
                      <Button
                        color={
                          specify === "MakeItEmpathetic"
                            ? "primary"
                            : "secondary"
                        }
                        className="specification"
                        type="submit"
                        disabled={loading}
                        onClick={() => setSpecify("MakeItEmpathetic")}
                      >
                        <InsertEmoticonOutlinedIcon />
                        &nbsp;Make It Empathetic
                      </Button>
                      <Button
                        color={
                          specify === "MakeItConstructive"
                            ? "primary"
                            : "secondary"
                        }
                        className="specification"
                        type="submit"
                        disabled={loading}
                        onClick={() => setSpecify("MakeItConstructive")}
                      >
                        <PanToolOutlinedIcon />
                        &nbsp;Make It Constructive
                      </Button>
                      <Button
                        color={
                          specify === "MakeItDiplomatic"
                            ? "primary"
                            : "secondary"
                        }
                        className="specification"
                        type="submit"
                        disabled={loading}
                        onClick={() => setSpecify("MakeItDiplomatic")}
                      >
                        <SentimentDissatisfiedOutlinedIcon />
                        &nbsp;Make It Diplomatic
                      </Button>

                      <Button
                        color={
                          specify === "MakeItInspirational"
                            ? "primary"
                            : "secondary"
                        }
                        className="specification"
                        type="submit"
                        disabled={loading}
                        onClick={() => setSpecify("MakeItInspirational")}
                      >
                        <PhotoFilterOutlinedIcon />
                        &nbsp;Make It Inspirational
                      </Button>
                    </div>
                  </div>
                  <div className="main-hash-range">
                    <div className="hash">
                      <Checkbox
                        name="hash"
                        externalLabel={{ label: "Add Hashtags" }}
                        checked={hash}
                        disabled={loading}
                        onClick={() => {
                          handleChange();
                        }}
                      />
                    </div>
                    <div className="slider">
                      <div className="count-title">Words Count</div>
                      <Slider
                        disabled={loading}
                        defaultValue={50}
                        value={wordsLimit}
                        min={50}
                        max={300}
                        step={10}
                        onChange={(e, words) => setWordLimit(words as number)}
                      />
                      <div className="word-count">({wordsLimit}words)</div>
                    </div>
                  </div>
                  <Button
                    color="primary"
                    className="insert-post"
                    type="submit"
                    disabled={!responseAiData || loading}
                    onClick={() => {
                      setValue("post_text", responseAiData);
                      setAIflag(true);
                      setShowAIModal(false);
                      handleClear();
                    }}
                  >
                    INSERT TO POST
                  </Button>
                </Modal>
              )}
              <Button
                className="submit-btn"
                loading={postLoading}
                disabled={
                  // uploadImage.length > 0 ||
                  uploadAssets.length > 0 || postText.length > 0 ? false : true
                }
                type="submit"
              >
                Post
              </Button>

              <Button
                onClick={() => {
                  handleArticle()
                }
                }
                color="secondary"
              >
                Write Article ?
              </Button>
            </div>
          </form >
        </div >
      )}
    </>
  );
}

export default FeedPost;
