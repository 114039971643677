import { Badge } from "@material-ui/core";
import { Home as HomeIcon, Notifications } from "@material-ui/icons";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import UserProfileIcon from "../../components/common/UserProfileIcon";
import HeaderSearchBar from "../../pages/SearchAndConnect/components/HeaderSearchBar";
import { rootReducersState } from "../../reducers";
import appRoutes from "../../routes/app.routes";

const CompanyHeader = () => {
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const notificationCount = _.get(
    sessionReducer,
    "currentUser.companyNotificationCount",
    0
  );

  return (
    <>
      <header className="company-header-wrap loggedin mb-0">
        <nav className="navbar-expand-lg company-header">
          <HeaderSearchBar
            key={1}
            className="desktop-search"
            modalClass="company-modal"
          />
          <HeaderSearchBar key={1} searchLabel className="mobile-search" />
          <div className="nav-link desktop-navbar-nav" id="desktop-navbar-nav">
            <div className="justify-content-end w-100 navLinks navbar-nav">
              <div className="nav-item mr-0">
                <NavLink to={appRoutes.Feed.path}>
                  <span className="icon">
                    <HomeIcon />
                  </span>
                  <span>Home</span>
                </NavLink>
              </div>
              <div className="nav-item">
                <NavLink to={appRoutes.companyNotifications.path}>
                  <span className="icon">
                    <Notifications />
                  </span>
                  {notificationCount > 0 ? (
                    <Badge
                      className="badge-blue"
                      badgeContent={notificationCount}
                    >
                      <span>Notifications</span>
                    </Badge>
                  ) : (
                    <span>Notifications</span>
                  )}
                </NavLink>
              </div>
              {/* <div className="nav-item">
              <Link to={appRoutes.home.path}>Home</Link>
            </div>
            <div className="nav-item">
              <Link to={appRoutes.candidateJobSearch.path}>Careers</Link>
            </div> */}
            </div>
          </div>
          <div className="user-detail">
            <UserProfileIcon fromSide="company" key="company-header" />
          </div>
        </nav>
      </header>
    </>
  );
};

export default CompanyHeader;
