import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import PublicRoutes from "./routes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";
import history from "./utils/history";
import ScrollToTop from "./components/common/ScrollToTop";
import SnakeBar from "./components/common/SnakeBar";
import LogRocket from "logrocket";
import SmartLook from "smartlook-client";
// import UserMessage from '../src/pages/MessagesData/index';

const App = () => {

  useEffect(() => {
    // LogRocket integration
    const logRocketAppId = process.env.REACT_APP_LOG_ROCKET;
    if (logRocketAppId) {
      LogRocket.init(logRocketAppId);
    }

    // Smartlook integration
    const smartLookAppId = process.env.REACT_APP_SMART_LOOK;
    if (smartLookAppId) {
      SmartLook.init(smartLookAppId);
    }
  }, []);

  return (
    <>
      <ScrollToTop />
      <PublicRoutes />
      <PrivateRoutes />
      {/* <UserMessage /> */}
      <SnakeBar />
    </>
  );
};

const AppWrapper = () => (
  <Router history={history}>
    <App />
  </Router>
);

export default AppWrapper;

