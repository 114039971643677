import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Button, Modal, FlashMessage, Input } from "../../components/common";
import messagesServices from "../../services/messages.services";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import ConfirmDialog from "../../components/common/ConfirmDialog";

const MyMeetings = () => {
  const [allInvitations, setAllInvitations] = useState<Array<object>>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [removeId, setRemoveId] = useState<string>("");
  const [data, setData] = useState<object>({});
  const [reason, setReason] = useState<string>("");

  useEffect(() => {
    getMeetings();
    // eslint-disable-next-line
  }, []);

  const getMeetings = async () => {
    const allInvitations = await messagesServices.fetchInvitationList();
    setAllInvitations(allInvitations?.data);
  };

  const slotEx = (hour: string) => {
    if (parseInt(hour.split(":")[0]) > 12) return "p";
    else return "a";
  };

  const slotNumber = (hour: string) => {
    if (hour) {
      if (parseInt(hour.split(":")[0]) < 12) return hour.slice(0, -3);
      else return parseInt(hour.split(":")[0]) - 12 + ":" + hour.split(":")[1];
    }
  };

  const removeEvent = async (id) => {
    setShowDetailsModal(false);
    setShowConfirmModal(true);
    setRemoveId(id);
  };

  const _handleRemove = async () => {
    await messagesServices
      .removeInvitation(removeId, reason)
      .then((result) => {
        FlashMessage(result.message);
        getMeetings();
        setRemoveId("");
        setReason("");
      })
      .catch((error) => {
        console.log(error);
        FlashMessage(error.message, "error");
      });
    setShowDetailsModal(false);
    setShowConfirmModal(false);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <Grid container spacing={3}>
        <Grid
          item
          xs={9}
          className={
            eventInfo.event._def.extendedProps.is_deleted ? "text-display" : ""
          }
        >
          <span>
            &#x2022;
            {slotNumber(eventInfo.event._def.extendedProps.from_time)}
            {slotEx(eventInfo.event._def.extendedProps.from_time)}
          </span>
          <span>
            <b>&nbsp;{eventInfo.event._def.extendedProps.agenda_of_call}</b>
          </span>
        </Grid>
        <Grid item xs={3}>
          <span>
            {!eventInfo.event._def.extendedProps.is_deleted && (
              <button className="close-btn">
                <i
                  className="fa fa-close"
                  onClick={() => {
                    removeEvent(eventInfo.event.id);
                  }}
                ></i>
              </button>
            )}
          </span>
        </Grid>
      </Grid>
    );
  };

  const _handleCloseNewMessageModal = () => {
    setShowConfirmModal(false);
  };

  const _handleCloseDetailsModal = () => {
    setShowDetailsModal(false);
  };

  const _handleClick = (e) => {
    setShowDetailsModal(true);
    setData(e.event._def.extendedProps);
  };

  const handleChange = (value) => {
    setReason(value);
  };

  return (
    <>
      <div className="cal-container">
        <div>
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            eventContent={renderEventContent}
            events={allInvitations}
            dayMaxEventRows={3}
            eventClick={(e) => {
              _handleClick(e);
            }}
          />
        </div>
        <ConfirmDialog
          visible={showConfirmModal}
          title="Confirm"
          onCancel={() => _handleCloseNewMessageModal()}
          onConfirm={() => _handleRemove()}
          bodyText={
            <>
              <p className="pb-12">Are sure want to remove ?</p>
              <div>
                <Input
                  name="reason"
                  className="reason-text"
                  placeholder="Enter reason"
                  onChange={(e) => {
                    handleChange(e.target.value);
                  }}
                />
              </div>
            </>
          }
          confirmText="Ok"
        />
        <Modal
          className="new-msg-model"
          visible={showDetailsModal}
          closeButton={true}
          title="Details"
          onClose={() => _handleCloseDetailsModal()}
        >
          <table className="my-table">
            <tr className="tr-class">
              <td className="td-class">Agenda Of Call</td>
              <td className="td-class">{data?.agenda_of_call}</td>
            </tr>
            <tr className="tr-class">
              <td className="td-class">Email</td>
              <td className="td-class">{data?.email}</td>
            </tr>
            <tr className="tr-class">
              <td className="td-class">From</td>
              <td className="td-class">{slotNumber(data?.from_time)}</td>
            </tr>
            <tr className="tr-class">
              <td className="td-class">To</td>
              <td className="td-class">{slotNumber(data?.to_time)}</td>
            </tr>
            <tr className="tr-class">
              <td className="td-class">Location</td>
              <td className="td-class">{data?.location_id}</td>
            </tr>
            <tr className="tr-class">
              <td className="td-class">Additional Notes</td>
              <td className="td-class">{data?.additional_notes}</td>
            </tr>
            <tr className="tr-class">
              <td className="td-class">Meeting Type</td>
              <td className="td-class">
                {data?.meeting_type === "video_meeting"
                  ? "Video Meeting"
                  : "Phone Call"}
              </td>
            </tr>
            {data?.meeting_type === "video_meeting" ? (
              <tr className="tr-class">
                <td className="td-class">video_link</td>
                <td className="td-class">{data?.video_link}</td>
              </tr>
            ) : (
              <tr className="tr-class">
                <td className="td-class">phone_Number</td>
                <td className="td-class">{data?.phone_Number}</td>
              </tr>
            )}
          </table>
          <br></br>
          <Button
            variant="text"
            className="btn-secondary-black float-right text-uppercase"
            onClick={() => _handleCloseDetailsModal()}
          >
            Ok
          </Button>
        </Modal>
      </div>
    </>
  );
};

export default MyMeetings;
