import React, { useEffect, useState } from "react";
import { Control, useFieldArray } from "react-hook-form";
import ChipInput from "material-ui-chip-input";
import { Grid } from "@material-ui/core";

const CareerDifferentiatorsSkills = (props) => {
	const control: Control<Record<string, any>> = props.control;
	const { fields: skillFields, append: skillAppend, remove: skillRemove } = useFieldArray({ control, name: "skills" });

	const [skills, setSubmitChip] = useState([]);

	// set candidate skills hook
	useEffect(() => {
		if (props.skills) {
			const skillsArray = skillFields.map((item) => item.title);
			setSubmitChip(skillsArray);
			props.handleSkills(skillsArray);
		}

		// eslint-disable-next-line
	}, [props.skills]);

	const _handleAddChip = (chip) => {
		const skillsArray = [...skills];
		skillsArray.push(chip);
		skillAppend({ title: chip });
		setSubmitChip(skillsArray);
		props.handleSkills(skillsArray);
	};

	const _handleDeleteChip = (chip, index) => {
		const skillsArray = [...skills];
		if (index > -1) {
			skillsArray.splice(index, 1);
			setSubmitChip(skillsArray);
			skillRemove(index);
			props.handleSkills(skillsArray);
		}
	};
	return (
		<div className="add-skill-wrapper">
			<Grid container spacing={3}>
				<Grid item xs={12} md={12}>
					<label>Add Skills</label>
					<div>
						<ChipInput
							variant="outlined"
							placeholder="Add skills"
							value={skills}
							onAdd={(chip) => _handleAddChip(chip)}
							onDelete={(chip, index) => _handleDeleteChip(chip, index)}
							inputRef={props.register}
							className="w-100"
						/>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default CareerDifferentiatorsSkills;
