import React from 'react';
import _ from "lodash";
import DesktopWindowsOutlinedIcon from "@material-ui/icons/DesktopWindowsOutlined";
import { Grid, Paper } from "@material-ui/core";
import moment from "moment";
import useSessionUser from "../../../hooks/useSessionUser";

const List = ({ data, size, handleLogout }) => {

  const { currentSession } = useSessionUser();

  return (
    <div>
      <Grid container spacing={3} className="search-result-grid">
        {data &&
          data.slice(0, size).map((row, index) => {
            return (
              <Grid
                item
                className="search-result-profle-card-container"
                xs={12}
                sm={6}
                md={6}
                lg={6}
              >
                <Paper
                  className="search-result-profle-card session-card"
                  key={index}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <DesktopWindowsOutlinedIcon fontSize="large" />
                    </Grid>
                    <Grid item xs={10}>
                      <div>
                        <b>{_.get(row, "session_os", "")} - </b>
                        {_.get(row, "session_browser", "")}
                      </div>
                      <div>
                        {_.get(row, "active_session", "") === 1 ? (
                          <div>
                            {_.get(row, "id", "") === currentSession && (
                              <p className="title">Current Session</p>
                            )}
                            {_.get(row, "id", "") !== currentSession && (
                              <p className="title">
                                <span>Active Session</span>
                                <span className="log-out" onClick={() => {handleLogout(_.get(row, "id", ""))}}>Logout</span>
                              </p>
                            )}
                          </div>
                        ) : (
                          <p className="mt-50 mt-50"></p>
                        )}
                      </div>
                      <div>
                        {_.get(row, "ip_address", "")} -{" "}
                        {moment(_.get(row, "created_at", "")).format(
                          "MMM D, YYYY h:mma"
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default List;
