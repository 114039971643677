import React, { useEffect, useState } from "react";
import httpRequest from "../../utils/httpRequest";
import API_URLS from "../../utils/apiUrls";
import FlagIcon from '@material-ui/icons/Flag';
import { FormControlLabel, Menu, MenuItem, Radio, RadioGroup } from "@material-ui/core";
import Modal from "./Modal";
import Button from "./Button";
import { FlashMessage } from ".";
import { reportAbuseData } from "../../utils/appConstants";
interface SelectProps {
  referenceId: string;
  type: string;
  anchorValue?: null
  onClose: () => void;
  openReports?: boolean
}


const Report = ({ referenceId, type, anchorValue, onClose, openReports }: SelectProps) => {
  const [openReport, setReportOpen] = useState<boolean>(false);
  const [reportFeedBack, setReportFeedBack] = useState<any>({ report_text: "" });
  const [anchorEl, setAnchorEl] = useState(anchorValue);
  const open = Boolean(anchorEl);
  const reportOptions = reportAbuseData[type as keyof typeof reportAbuseData] || [];

  const handleClose = () => {
    onClose();
    setAnchorEl(null);
    setReportOpen(false)
  };

  useEffect(() => {
    if (openReports) {
      setReportOpen(true)
    }
  }, [openReports]);

  const handleCloseReport = () => {
    onClose();
    setReportOpen(false)
    setAnchorEl(null);
  };

  const submitReport = async () => {
    try {

      if (reportFeedBack.report_text.length > 0) {
        const payload = {
          reference_id: referenceId,
          feedback_id: reportFeedBack.report_text
        }
        await httpRequest().post(API_URLS.report(type), payload);
        FlashMessage("Your report submit successfully", "success")
        onClose();
        setReportFeedBack("")
        setReportOpen(false)
        setAnchorEl(null);
      } else {
        FlashMessage("Enter FeedBack first.", "error")
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  return (
    <div>
      <Menu
        id="report-menu"
        anchorEl={anchorEl}
        className="menu-option-wrapper"
        // keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: "border-radius-md" }}
      >
        <MenuItem
          className="flag-report"
          onClick={() => setReportOpen(true)}
        >
          <FlagIcon
            className="flag-icon"
            htmlColor="#ff0a90"

          /> Report
        </MenuItem>
      </Menu>
      {openReport && (
        <Modal
          title={`Report a ${type}`}
          visible={openReport}
          onClose={() => handleCloseReport()}
          className="report-model"
        >
          <div>
            <RadioGroup
              value={reportFeedBack?.report_text}
              onChange={(e: React.ChangeEvent<any>) => {
                setReportFeedBack({ ...reportFeedBack, report_text: e.target.value });
              }}
            >
              {reportOptions.map((option) => (
                <>
                  <div className="report-title">{option.title}</div>
                  <FormControlLabel
                    className="report-description"
                    key={option.feedback_id}
                    value={option.feedback_id.toString()}
                    control={<Radio />}
                    label={option.description}
                  />
                </>
              ))}

            </RadioGroup>
          </div>
          <div className="report-title">Other</div>
          <textarea
            className="report-feedback"
            // value={reportFeedBack?.report_text}
            onChange={(e) => {
              const data = e?.currentTarget?.value || "";
              setReportFeedBack((prevState: any) => ({
                ...prevState,
                report_text: data
              }))
            }}
          />
          <div className="report-submit">
            <Button
              className="submit-report"
              color="primary"
              onClick={() => submitReport()}
            >
              SUBMIT
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
};

export default Report;
