import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCandidateProfileAccordion } from "../../reducers/common/candidateProfilePersists.reducer";
import { rootReducersState } from "../../reducers";

interface IAccordionItem {
  id: number;
  title: string | React.ReactNode;
  content: string | React.ReactNode;
  sectionContent: string | React.ReactNode;
  num?: number;
  showId?: boolean;
  addLink?: string | React.ReactNode;
  addLinkActive?: boolean;
  type: string;
  basedOnTypeSection?: boolean;
}

interface IAccordion {
  data: IAccordionItem[];
  className?: string;
  showId?: boolean;
  basedOnTypeSection?: boolean;
}

function pad(d: any) {
  return d < 10 ? "0" + d.toString() : d.toString();
}

const AccordionItem = ({
  title,
  content,
  sectionContent,
  id,
  num,
  showId,
  addLink,
  addLinkActive,
  type = "",
  basedOnTypeSection,
}: IAccordionItem) => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState<Boolean>(false);
  const candidateAccordionPersist = useSelector(
    ({ candidateProfilePersists }: rootReducersState) =>
      candidateProfilePersists
  );

  function toggler(type: string) {
    switch (type) {
      case "video":
        dispatch(
          setCandidateProfileAccordion({ video: !getStateBasedOnType("video") })
        );
        break;
      case "professional_passions":
        dispatch(
          setCandidateProfileAccordion({
            professional_passions: !getStateBasedOnType(
              "professional_passions"
            ),
          })
        );
        break;
      case "work_experience":
        dispatch(
          setCandidateProfileAccordion({
            work_experience: !getStateBasedOnType("work_experience"),
          })
        );
        break;
      case "projects":
        dispatch(
          setCandidateProfileAccordion({
            projects: !getStateBasedOnType("projects"),
          })
        );
        break;
      case "skills":
        dispatch(
          setCandidateProfileAccordion({
            skills: !getStateBasedOnType("skills"),
          })
        );
        break;
      case "education":
        dispatch(
          setCandidateProfileAccordion({
            education: !getStateBasedOnType("education"),
          })
        );
        break;
      default:
        return false;
    }
  }

  function getStateBasedOnType(type: string) {
    switch (type) {
      case "video":
        return candidateAccordionPersist.video;
      case "professional_passions":
        return candidateAccordionPersist.professional_passions;
      case "work_experience":
        return candidateAccordionPersist.work_experience;
      case "projects":
        return candidateAccordionPersist.projects;
      case "skills":
        return candidateAccordionPersist.skills;
      case "education":
        return candidateAccordionPersist.education;
      default:
        return false;
    }
  }

  return (
    <div className="accordion-item">
      <div
        className="accordion-header"
        onClick={() => {
          setIsActive(!isActive);
          toggler(type);
        }}
      >
        <div
          className={`title ${
            getStateBasedOnType(type) ? "active" : isActive && "active"
          }`}
        >
          {showId && `${pad(id)}.`}
          {title}
        </div>
        {addLink && !addLinkActive ? (
          <div>{addLink}</div>
        ) : (
          <div className="icon">
            {basedOnTypeSection ? (
              getStateBasedOnType(type) === true ? (
                <div className="triangle-left" />
              ) : (
                <div className="triangle-down" />
              )
            ) : isActive ? (
              <div className="triangle-left" />
            ) : (
              <div className="triangle-down" />
            )}
          </div>
        )}
      </div>
      {getStateBasedOnType(type) === true ? (
        <div
          className={`accordion-content ${
            addLink && !addLinkActive && "d-none"
          }`}
        >
          {sectionContent}
        </div>
      ) : isActive ? (
        <div
          className={`accordion-content ${
            addLink && !addLinkActive && "d-none"
          }`}
        >
          {content}
        </div>
      ) : null}
    </div>
  );
};

const Accordion = ({
  data,
  className,
  showId,
  basedOnTypeSection,
}: IAccordion) => {
  return (
    <div className={`accordion-wrapper ${className}`}>
      {data.map((item, index) => (
        <AccordionItem
          showId={showId}
          key={item.id}
          num={index + 1}
          {...item}
          basedOnTypeSection={basedOnTypeSection}
        />
      ))}
    </div>
  );
};

Accordion.defaultProps = {
  showId: true,
};

export default Accordion;
