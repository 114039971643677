import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, FlashMessage, Input, Modal } from "../../components/common";
import Checkbox from "@material-ui/core/Checkbox";
import { Grid } from "@material-ui/core";
import authServices from "../../services/auth.services";
import List from "./components/List";
import _ from "lodash";
import { rootReducersState } from "../../reducers";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { updateCandidateUpdatedDate } from "../../reducers/auth/session.reducer";
// import httpRequest from "../../utils/httpRequest";
// import API_URLS from "../../utils/apiUrls";
// import AppleIcon from '@material-ui/icons/Apple';
// import AndroidIcon from '@material-ui/icons/Android';
// import CodeEnter from "../../components/common/CodeEnter";

type FormInputs = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  company_logo: string;
  banner_image: string;
};

const SecurityLogin = () => {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    reset: resetForm,
    setError,
  } = useForm<FormInputs>();

  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState<boolean>(false);
  const [viewAllSessions, setViewAllSessions] = useState<boolean>(false);
  const [sessionData, setSessionData] = useState<Array<object>>([]);
  const [currentlySigned, setCurrentlySigned] = useState<number>(0);
  const [sessionId, setSessionId] = useState<string>("");
  const [password, setPassword] = useState<boolean>(false);

  // const [openModel, setOpenModel] = useState<boolean>(false);
  // const [qrCodeURL, setQrCodeURL] = useState('');
  // const [inputValues, setInputValues] = useState(Array(6).fill(''));
  // const [verifyError, setVerifyError] = useState('');
  // const [clearFlag, setClearFlag] = useState(false);
  // const [passError, setPassError] = useState('');
  // const [confirmDisable, setConfirmDisable] = useState(false);
  // const [haveTwoFA, setTwoFA] = useState(false);
  const {
    handleSubmit: handlePasswordSubmit,
    register: registerPassword,
    errors: errorsPassword,
  } = useForm({});

  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session.currentUser
  );

  useEffect(() => {
    fetchUserSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUserSessions = async () => {
    try {
      const sessionsData = await authServices.getUserSessions();

      const sessionData = _.get(sessionsData, "data.rows", []);

      // eslint-disable-next-line array-callback-return
      sessionData.reduce((accumulator: any, curValue: any) => {
        if (
          accumulator?.active_session === 1 ||
          curValue?.active_session === 1
        ) {
          setCurrentlySigned((currentlySigned) => currentlySigned + 1);
        }
      });
      // setTwoFA(sessionReducer.twofa_enable)
      setSessionData(sessionData);
    } catch (error) {
      FlashMessage(_.get(error, "message"), "error");
    }
  };

  const currentMatchingPassword = watch("newPassword", "");
  // const currentPassword1 = watch("currentPassword1")

  const _handleClosePasswordChangeModal = () => {
    resetForm({});
  };

  const handleChange = () => {
    setSelectedValue(!!!selectedValue);
  };

  const onSubmit = async (formData: FormInputs) => {
    try {
      const resp = await authServices.changePassword({
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword,
        confirmPassword: formData.confirmPassword,
        requireAll: selectedValue,
      });

      if (resp.flag) {
        FlashMessage(_.get(resp, "message"), "success");
        setSelectedValue(false);
        const currentDate = moment().format("YYYY-MM-DD hh:mm:ss");
        dispatch(updateCandidateUpdatedDate(currentDate));
        resetForm({});
        if (selectedValue) {
          await filterSessions(_.get(resp, "data"));
        }
      } else {
        if (resp?.errors?.newPassword) {
          setError("newPassword", {
            type: "server",
            message: resp.errors.newPassword,
          });
        }
        if (resp?.errors?.currentPassword) {
          setError("currentPassword", {
            type: "server",
            message: resp.errors.currentPassword,
          });
        }
      }
    } catch (error) {
      FlashMessage(_.get(error, "message"), "error");
    }
  };

  const filterSessions = (id) => {
    sessionData.forEach((item) => {
      if (_.get(item, "id") !== id) {
        item["active_session"] = 0;
      }
    });

    setSessionData(sessionData);
  };

  const handleLogout = (id) => {
    setSessionId(id);
    setPassword(true);
  };

  const passwordSubmit = async (formData) => {

    try {
      const resp = await authServices.updateSession({
        sessionId: sessionId,
        password: _.get(formData, "currentPassword", ""),
      });

      if (resp.flag) {
        FlashMessage(_.get(resp, "message"), "success");
        setPassword(false);

        await filterSessionsData(sessionId);
      } else {
        FlashMessage(_.get(resp, "message"), "error");
      }
    } catch (error) {
      FlashMessage(_.get(error, "message"), "error");
    }
  };

  const filterSessionsData = (id) => {
    sessionData.forEach((item) => {
      if (_.get(item, "id") === id) {
        item["active_session"] = 0;
      }
    });

    setSessionData(sessionData);
  };

  // const handleTwoStepVerification = async () => {
  //   try {
  //     const payload = {
  //       password: currentPassword1
  //     }
  //     const data = await httpRequest().post(API_URLS.verifyPassword, payload);
  //     if (data.data.isValid) {
  //       setOpenModel(true);
  //       twoStepVerification();
  //       setPassError('')
  //       setClearFlag(false)
  //     } else {
  //       setPassError("Incorrect password.");
  //     }
  //   } catch (error) {
  //     FlashMessage(error, "error")
  //   }
  // }

  // const twoStepVerification = async () => {
  //   try {
  //     const data = await httpRequest().post(API_URLS.twoStepVerification);
  //     setQrCodeURL(data.data.data);
  //   } catch (error) {
  //     FlashMessage(error, "error")
  //   }

  // }

  // const verifyToken = async () => {
  //   try {
  //     if (!inputValues.includes('')) {

  //       const verifyCode = [...inputValues].join('')
  //       const payload = {
  //         token: verifyCode,
  //         id: sessionReducer?.id
  //       }
  //       const data = await httpRequest().post(API_URLS.verifyToken, payload);

  //       if (data.data.data.delta === 0) {
  //         handleClose();
  //       } else {
  //         setVerifyError('Enter valid verification code')
  //       }
  //     } else {
  //       setVerifyError("Please enter 6 digit verification code")
  //     }
  //   } catch (error) {
  //     FlashMessage(error, "error")
  //   }
  // }

  // const handleClose = () => {
  //   setOpenModel(false)
  //   setQrCodeURL('')
  //   setClearFlag(true)
  //   setVerifyError('')
  // };

  // const handleInputChange = (index, value) => {
  //   if (value.length === 1) {
  //     setInputValues(prevValues => {
  //       const newValues = [...prevValues];
  //       newValues[index] = value;
  //       return newValues;
  //     });
  //   }
  // };

  // const handleDisableTwoFA = async () => {
  //   const data = await httpRequest().put(API_URLS.disableTwoFA);
  //   if (data.data.message === "Success") {
  //     setConfirmDisable(false)
  //     setTwoFA(false)
  //   } else {
  //     FlashMessage("something wrong", "error")
  //   }
  // }

  return (
    <div className="profile-container">
      <div>
        <h2 className="title">Security And Login</h2>
        <p>
          <div>Let’s make your account more secure.</div>
          <div>
            We’ re always working on ways to increase safety in our employHer.
            That’s why we look at every account to make sure it’s as secure as
            possible.
          </div>
          <p>You account security : Low</p>
        </p>
      </div>
      <div>
        <h2 className="title">Change Password</h2>
        {_.get(sessionReducer, "password_updated_at") &&
          "Last Updated" +
          moment(_.get(sessionReducer, "password_updated_at")).format(
            " D MMM, YYYY"
          )}
        <p>Your new password must be different from previous used password</p>
        <div className="pt-10">
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid item xs={6}>
                  <Input
                    name="currentPassword"
                    type="password"
                    externalLabel={{ label: "Current password" }}
                    placeholder="Current password"
                    validationObj={errors}
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Please enter current password",
                      },
                    })}
                    className="settings-input"
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} className="pb-0">
                <Input
                  name="newPassword"
                  type="password"
                  externalLabel={{ label: "New password" }}
                  placeholder="New password"
                  validationObj={errors}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter new password",
                    },
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?!.* )(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                      message:
                        "Must content at least one upper case, lower case, digit, special character and no white space",
                    },
                  })}
                  className="settings-input"
                />
              </Grid>
              <Grid item xs={6} className="pb-0">
                <Input
                  name="confirmPassword"
                  type="password"
                  externalLabel={{ label: "Confirm password" }}
                  placeholder="Confirm password"
                  validationObj={errors}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter confirm password",
                    },
                    validate: (value) =>
                      value === currentMatchingPassword ||
                      "The re-entered do not match",
                  })}
                  className="settings-input"
                />
              </Grid>

              <Checkbox
                checked={selectedValue}
                onClick={(e) => {
                  handleChange();
                }}
              />

              <p
                onClick={(e) => {
                  handleChange();
                }}
                className="cursor-pointer"
              >
                Require all devices to sign in with new password
              </p>

              <Grid item xs={8} className="form-actions">
                <Grid item xs={8} container justify="flex-end">
                  <Button
                    type="submit"
                    color="primary"
                    className="text-uppercase"
                  >
                    Change Password
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() => _handleClosePasswordChangeModal()}
                    color="secondary"
                    className="text-uppercase"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
      <hr />
      <div className="mt-20 mb-20">
        <h2 className="title">Two-factor authentication</h2>
        (Last Updated
        {moment(_.get(sessionReducer, "updated_at", "")).format(" D MMM, YYYY")}
        )
        <p>
          Ensure the security of your employHER account by enabling two-step
          verification.
        </p>
        <h4>Mange two-step verification</h4>
        <p>
          With 2-Step Verification, you can add an extra layer of security to
          your account in case your password is stolen. After you set up 2-Step
          Verification, you can sign in to your account with: Your password.
          Your phone.
        </p>
        <h4>Set up two-step verification</h4>
        <p>
          To setting up two-step verification you must have to remember your
          profile current password, by entering current password you can guide
          to enable two-step verification.
        </p>
        {/* disable two step verification */}
        {/* {haveTwoFA ? (
          <>
            <div className="disable-twoFA" onClick={() => setConfirmDisable(true)}>Disable</div>
            <ConfirmDialog
              title="Disable Two Step Authentication"
              visible={confirmDisable}
              bodyText="Are you sure want to Disable Two step verification?"
              onConfirm={() => handleDisableTwoFA()}
              onCancel={() => setConfirmDisable(false)}
            />
          </>
        ) : (
          <>
            <div className="pt-10 pb-10">
              <Grid item xs={6} className="pb-0 verification-wrapper">
                <Input
                  name="currentPassword1"
                  type="password"
                  externalLabel={{ label: "Current password" }}
                  placeholder="Current password"
                  validationObj={errors}
                  className="settings-input"
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter Current password",
                    },
                  })}
                />
              </Grid>
            </div>
            {
              passError && (
                <span className="text-sm text-danger error-helper">
                  {passError}
                </span>
              )
            }
            <Grid container spacing={2}>
              <Grid item xs={6} container justify="flex-end">
                <Button type="submit" color="primary" className="text-uppercase" onClick={() => handleTwoStepVerification()}>
                  Next
                </Button>
              </Grid>
              <Grid item xs={1} className="text-right form-actions">
                <Button
                  onClick={() => _handleClosePasswordChangeModal()}
                  color="secondary"
                  className="text-uppercase"
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </>
        )} */}

      </div >
      {/* <hr /> */}
      < div >
        <Grid container justify="space-between" className="mt-20">
          <Grid item md={10} className="notification-card-left">
            <h5 className="mt-10">Device History</h5>
          </Grid>
          <Grid item md={2} className="d-flex justify-content-between">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setViewAllSessions(true);
              }}
            >
              View All
            </Button>
          </Grid>
        </Grid>
        <p>
          The locations listed below are an estimate of where IP addresses are
          located in your country, region, and city. Look-up accuracy varies
          based on the provider and device location
        </p>
        <b>You’re Currently Signed in to {currentlySigned} session.</b>
        <List
          data={sessionData}
          size={2}
          handleLogout={(e) => {
            handleLogout(e);
          }}
        />
      </div >
      <Modal
        title="Sessions"
        visible={viewAllSessions}
        className="profile-modal editable-profile-modal"
        onClose={() => setViewAllSessions(false)}
      >
        <List
          data={sessionData}
          size={sessionData.length}
          handleLogout={(e) => {
            handleLogout(e);
          }}
        />
      </Modal>
      <Modal
        title="Password"
        visible={password}
        className="change-pw-modal"
        onClose={() => setPassword(false)}
      >
        <div>
          <form noValidate onSubmit={handlePasswordSubmit(passwordSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} className="pb-0">
                <Input
                  name="currentPassword"
                  type="password"
                  externalLabel={{ label: "Current password" }}
                  placeholder="Current password"
                  validationObj={errorsPassword}
                  inputRef={registerPassword({
                    required: {
                      value: true,
                      message: "Please enter current password",
                    },
                  })}
                />
              </Grid>

              <Grid item xs={12} className="text-right form-actions">
                <Button
                  onClick={() => setPassword(false)}
                  color="secondary"
                  className="text-uppercase"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  className="text-uppercase"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>

      {/* twoFA scanner modal */}
      {/* {openModel && (
        <Modal
          title="Set Up Two step verification"
          className="two-Step-verification"
          visible={openModel}
          size="large"
          onClose={() => handleClose()}
        >
          <div className="follow-step">Please follow the step below to set up two-step verification </div>
          <div className="steps-auth"><span>Step 1: </span>Download two step verification app</div>
          <div className="follow-step link-url"><AppleIcon color="primary" />iPhone devices:
            <a href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458" target="_blank" rel="noreferrer"
            >Google Authenticator</a> </div>
          <div className="follow-step link-url"><AndroidIcon color="primary" />Android devices:
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_IN" rel="noreferrer">Google Authenticator</a>
          </div>
          <div className="steps-auth"><span>Step 2: </span>Scan this QR code with your verification app</div>

          {qrCodeURL &&
            <div className="image-container">
              <img className="qr-img" alt="no qr found" src={qrCodeURL}></img>
            </div>
          }

          <div className="steps-auth"><span>Step 3: </span>Enter the resulting of verification code</div>
          <div className="follow-step">Enter 6-digit code verification code generated by authentication App. </div>
          <div className="enter-code">
            <CodeEnter
              clear={clearFlag}
              handleOnChange={(index, e) => handleInputChange(index, e.target.value)}
            />
          </div>
          <div className="code-error">
            {verifyError && (
              <span className="text-sm text-danger error-helper">
                {verifyError}
              </span>
            )}
          </div>
          <Button
            className="verify-btn"
            onClick={() => verifyToken()}
          >verifyCode</Button>
        </Modal>
      )} */}
    </div >
  );
};

export default SecurityLogin;
