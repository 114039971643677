import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConnectionItem from "./components/ConnectionItem";
import {
  connectionRequestsListRequest,
  connectionListFlagReset,
  connectionRequestsListReset,
} from "../../reducers/SearchAndConnect/searchAndConnect.reducer";
import _ from "lodash";
import NoNotificationFound from "../../components/Candidates/noNotificationFound";
import { Tabs } from "../../components/common";
import {
  Badge,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import { rootReducersState } from "../../reducers";
import MyNetwork from "../MyNetwork";
import { ReducerFlag } from "../../types/reducer.types";
import { updateConnectionReceivedCount } from "../../reducers/auth/session.reducer";
import FlashMessage from "../../components/common/FlashMessage";
import { useHistory, useLocation } from "react-router-dom";
import appRoutes from "../../routes/app.routes";
import useQuery from "../../hooks/useQuery";
import Skeleton from "@material-ui/lab/Skeleton";

const ConnectionNotification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryPrams = useQuery();
  const { pathname } = useLocation();
  const typeSearch = queryPrams.get("type");
  //   const [page, setPage] = useState(1);
  const [page, setPage] = useState(1);
  const {
    connectionsData,
    pagination,
    requestFlag,
    updateCandidateConnectionRequestFlag,
    requestType,
    updateCandidateConnectionRequestMessage,
    loading,
  } = useSelector(({ search }: rootReducersState) => search.search);
  const [isFetching, setIsFetching] = useState(false);
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const receivedCount =
    _.get(sessionReducer, "currentUser.receivedCount", 0) > 0
      ? _.get(sessionReducer, "currentUser.receivedCount", 0)
      : 0;
  const [networkCount, setNetworkCount] = useState(0);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [searchFilter, setSearchFilter] = useState(typeSearch || "candidate");

  window.onscroll = _.debounce(() => {
    const docBottom =
      document.documentElement.getBoundingClientRect().bottom - 1200;
    const reachedBottom = window.innerHeight > docBottom;
    if (reachedBottom && !isFetching && !loading && page < pagination.total) {
      setPage((prevPage) => prevPage + 1);
      setIsFetching(true);
    }
  }, 500);
  const TABS = [
    {
      id: 0,
      key: "Connection",
      // label: `Connections (${networkCount})`, // TODO - uncomment this for showing total count
      label: `Connections`,
      component: <p> Connection ({networkCount})</p>,
    },
    {
      id: 1,
      key: "Received",
      label: (
        <Badge className="badge-blue" badgeContent={receivedCount}>
          Received
        </Badge>
      ),
      component: <p>Received</p>,
    },
    {
      id: 2,
      key: "Sent",
      label: "Sent",
      component: <p>Sent</p>,
    },
  ];
  const tab =
    pathname.split("/")[2] === "received"
      ? TABS[1]
      : pathname.split("/")[2] === "sent"
        ? TABS[2]
        : TABS[0];
  const [active, setActive] = useState<any>(tab);

  useEffect(() => {
    setInitialLoading(true);
    setTimeout(() => {
      setInitialLoading(false);
    }, 1000);
  }, [active]);

  useEffect(() => {
    dispatch(
      connectionRequestsListRequest({
        type: TABS.find((tab) => tab.id === active.id)?.key?.toLowerCase(),
        page: page,
        pageSize: 20,
      })
    );
    dispatch(connectionListFlagReset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, page]);

  useEffect(() => {
    if (
      updateCandidateConnectionRequestFlag === ReducerFlag.SUCCESS &&
      requestType !== "cancelled"
    ) {
      const updatedCount =
        receivedCount === 0 ? receivedCount : receivedCount - 1;
      dispatch(updateConnectionReceivedCount(updatedCount));
      setIsFetching(false);
    } else if (updateCandidateConnectionRequestFlag === ReducerFlag.FAILED) {
      FlashMessage(updateCandidateConnectionRequestMessage, "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCandidateConnectionRequestFlag]);

  // Reset the flag once post list API is done with success of failed
  useEffect(() => {
    if ([ReducerFlag.SUCCESS, ReducerFlag.FAILED].includes(requestFlag)) {
      setIsFetching(false);
      dispatch(connectionListFlagReset());
    }

    // eslint-disable-next-line
  }, [requestFlag]);

  const handleSearchFilterChange = (event) => {
    dispatch(connectionListFlagReset());
    setSearchFilter(event.target.value);
    const url = `${appRoutes.candidateConnections.path}?type=${event.target.value}`;
    history.push(url);
  };

  return (
    <div className="max-container">
      <div className="connection-notification-wrapper">
        <div className="title">
          <h1>Connections</h1>
        </div>
        <div className="connections-card-wrapper">
          <div className="head">
            <Tabs
              tabs={TABS}
              active={active}
              setActive={(value) => {
                setActive(value);
                dispatch(
                  connectionRequestsListReset({
                    type: TABS.find(
                      (tab) => tab.id === active.id
                    )?.key?.toLowerCase(),
                    page: 1,
                    pageSize: 20,
                  })
                );
                setPage(1);
                history.push(
                  appRoutes.candidateConnectionsType.generatePath(
                    _.get(value, "key", "connection").toLowerCase()
                  )
                );
              }}
            />
          </div>
          {active.id === 0 ? (
            <>
              <div className="m-15 float-right">
                <Grid>
                  <Select
                    className="search-result-filters-button"
                    value={searchFilter}
                    onChange={handleSearchFilterChange}
                  >
                    <MenuItem value={"candidate"}>My Network</MenuItem>
                    <MenuItem value={"company"}>Companies</MenuItem>
                  </Select>
                </Grid>
              </div>
              <MyNetwork
                setNetworkCount={setNetworkCount}
                searchFilter={searchFilter}
              />
            </>
          ) : (
            <>
              <div className="content">
                {initialLoading ? (
                  [1, 2, 3].map((index) => {
                    return (
                      <div className="connection-item-wrapper" key={index}>
                        <div className="avatar">
                          <Skeleton variant="circle" width={50} height={50} />
                        </div>
                        <div className="details">
                          <Skeleton variant="text" width={300} />
                        </div>
                        {(active.id === 1) ? (
                          <>
                            <Skeleton variant="text" width={100} height={40} />
                            &nbsp;&nbsp;&nbsp;
                            <Skeleton variant="text" width={100} height={40} />
                          </>
                        ) : (
                          <Skeleton variant="text" width={100} height={40} />
                        )}

                      </div>
                    )
                  })
                ) : _.isEmpty(connectionsData) ? (
                  <NoNotificationFound label="connections" />
                ) : (
                  <>
                    {connectionsData.map((connection) => (
                      <ConnectionItem
                        isRequester={active.id === 1}
                        connection={connection}
                      />
                    ))}
                  </>
                )}
              </div>
              {isFetching && (
                <div className="see-more circle-center">
                  <CircularProgress color="secondary" size={25} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectionNotification;
