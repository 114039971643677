import React from 'react';
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
import StarHalf from "@material-ui/icons/StarHalf";

const Rating = ({ value }) => {
  const fullStars = Math.floor(value);
  const hasHalfStar = value % 1 !== 0;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

  const renderStars = () => {
    let stars = [];

    for (let i = 0; i < fullStars; i++) {
      stars.push(<Star key={i} color="primary" />);
    }

    if (hasHalfStar) {
      stars.push(<StarHalf key="half" color="primary" />);
    }

    for (let i = 0; i < emptyStars; i++) {
      stars.push(<StarBorder key={`empty${i}`} color="primary" />);
    }

    return stars;
  };

  return (
    <div>
      {renderStars()}
    </div>
  );
};

export default Rating;