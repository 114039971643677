import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";


const initialState: any = {
  loading: false,
  flag: false,
  subscription_status: false,
  parsedContacts: [],
  tags: [],
  numberOfSavedContacts: 0,
  numberOfFailedContacts: 0,
  contactList: [],
};

const slice = createSlice({
  name: "company/marketingSolution",
  initialState,
  reducers: {
    setTags: (state, { payload }) => {
      state.tags.push(payload);
    },
    handleDeleteTag:(state, {payload}) => {
        state.tags = state.tags.filter((tag: string) => tag !== payload);
    },
    setSubscriptionStatus:(state, {payload}) => {
      state.subscription_status = payload;
    },
    setParsedContacts: (state, {payload}) => {
      state.parsedContacts = [...payload];
      state.numberOfParsedContacts = payload.length;
    },
    saveImportedContactsRequest: (state) => {
      return {...state, loading: true}
    },
    saveImportedContactsSuccess: (state, {payload}) => {
      return { ...state, loading: false, flag: true, numberOfFailedContacts: _.get(payload, 'numberOfFailedContacts', 0), numberOfSavedContacts: _.get(payload, 'numberOfSavedContacts', 0)};
    },
    saveImportedContactsFail: (state, {payload}) => {
      return { ...state, loading: false, flag: false, message: _.get(payload, "message", ""), errors: _.get(payload, "errors", {})};
    },
    getContactsListRequest(state, {payload}){
      return { ...initialState, loading: true};
    },
    getContactsListSuccess(state, {payload}){
      return { ...initialState, loading: false, flag: true, contactList: _.get(payload, 'data', [])};
    },
    getContactsListFail(state, {payload}){
      return { ...state, loading: false, flag: false, message: _.get(payload, "message", ""), errors: _.get(payload, "errors", {})};
    }

  },
});

export const {
  setTags,
  handleDeleteTag,
  setSubscriptionStatus,
  setParsedContacts,
  saveImportedContactsRequest,
  saveImportedContactsSuccess,
  saveImportedContactsFail,
  getContactsListRequest,
  getContactsListSuccess,
  getContactsListFail
} = slice.actions;

export default slice.reducer;
