import _ from "lodash";
import React, { useEffect, useState } from "react";
import iconImage from "../icons/pen_icon.svg";
import { Modal, Button } from "../../common";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { updateCandidateSkillsRequest } from "../../../reducers/candidate/candidate.reducer";
import CareerDifferentiatorsSkills from "./SkillsField";
import Image from "../../common/Image";

interface ISkills {
  skills: [];
  publicPage: boolean;
  skillModal: boolean;
  showEditButton?: any;
  setSkillModal: any;
}

function Skills({
  skills,
  publicPage,
  skillModal,
  setSkillModal,
  showEditButton,
}: ISkills) {
  const [submitChip, setSubmitChip] = useState([]);
  const dispatch = useDispatch();
  const { handleSubmit, control, register, errors, reset } = useForm({
    defaultValues: { skills },
  });

  useEffect(() => {
    reset({ skills });

    // eslint-disable-next-line
  }, [skills]);

  const getSkills = (formData) => {
    const skillsObject = submitChip.map((s) => {
      return { title: s };
    });

    const payload = {
      ...formData,
      skills: [...skillsObject],
    };
    setSkillModal(false);
    dispatch(updateCandidateSkillsRequest(payload));
  };

  return (
    <>
      <div className="edit-wrapper">
        {publicPage && <h1>Skills</h1>}
        {!_.isEmpty(skills) && !publicPage && showEditButton && (
          <span onClick={() => setSkillModal(true)}>
            <Image src={iconImage} alt="pen icon" />
          </span>
        )}
      </div>
      <div
        className={`profile-view-card user-description skills-wrapper ${
          publicPage && "profile-public-card"
        }`}
      >
        {publicPage && _.isEmpty(skills) ? (
          <h2 className="bold text-center w-100">No data available</h2>
        ) : (
          _.isEmpty(skills) && (
            <h2 className="bold link-text" onClick={() => setSkillModal(true)}>
              Add Your Skills
            </h2>
          )
        )}
        {skills.map((skill, index) => (
          <span key={index}>{_.get(skill, "title", "")}</span>
        ))}
      </div>
      <Modal
        title="Edit Skills & Tools"
        visible={skillModal}
        className="profile-modal share-profile-modal editable-modal"
        onClose={() => setSkillModal(false)}
      >
        <div className="edit-modal-wrapper">
          <form onSubmit={handleSubmit(getSkills)}>
            <CareerDifferentiatorsSkills
              register={register}
              errors={errors}
              control={control}
              handleSkills={(skills) => setSubmitChip(skills)}
              skills={skills}
            />
            <div className="d-flex justify-content-center mt-30">
              <Button
                type="submit"
                color="primary"
                className="submit-button text-uppercase"
              >
                Save
              </Button>
              <Button
                variant="text"
                color="secondary"
                className="cancel-button text-uppercase"
                onClick={() => setSkillModal(false)}
              >
                cancel
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default Skills;
