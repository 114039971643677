import React from "react";

import CompanyQuote from "./components/CompanyAbout";
import CompanyVideos from "./components/CompanyVideo";
// import Recommendations from "./components/Recommendations";
import Intro from "./components/CompanyIntro";
import CompanyBanner from "./components/CompanyMedia";

const PublicHome = () => {
  return (
    <div className="company-page-profile-container">
      <>
        <CompanyBanner />
        <Intro />
      </>
      <div className="company-public-profile-tab-wrap">
        <div className="company-page-profile-home-tab">
          <CompanyQuote />
          <CompanyVideos />
        </div>
      </div>
      {/* <Recommendations /> */}
    </div>
  );
};

export default PublicHome;
