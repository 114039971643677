import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  commentPostRequest,
  commentEditRequest,
} from "../../../reducers/feeds/feedPost.reducer";
import { ErrorTextHelper } from "../../../components/common";
import EmojiPicker from "emoji-picker-react";
import Emoji from "../../Messages/components/emoji";
import _ from "lodash";
import { Grid, IconButton } from "@material-ui/core";
import { rootReducersState } from "../../../reducers";
import Avatar from "../../../components/common/AvtarImage";
type Inputs = {
  comment_text: string;
};

const ReplyPost = ({ data, item, setComment, reason }) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();
  const dispatch = useDispatch();
  const [togglePicker, setTogglePicker] = useState<boolean>(false);

  // close popup when clicked outside container
  const emojiRef = useRef<any>(null);
  const hideEmojis = (event) => {
    if (emojiRef.current && !emojiRef.current.contains(event.target)) {
      setTogglePicker(false);
    }
  };

  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const currentUserImage = _.get(
    sessionReducer,
    "currentUser.profile_image",
    ""
  );

  useEffect(() => {
    if (reason === "edit-comment") setValue("comment_text", item?.comment_text);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("click", hideEmojis, true);
    return () => {
      document.removeEventListener("click", hideEmojis, true);
    };
  }, []);

  // on submit comment
  const onSubmit = (formData: any) => {
    const payload = {
      id: "",
      post_id: data?.id ? data.id : item.post_id,
      comment_text: formData.comment_text.trim(),
      parent_id: null,
    };

    if (reason === "edit-comment") {
      payload.id = _.get(item, "id", "");
      dispatch(commentEditRequest(payload));
      setComment();
    } else {
      if (reason === "reply-comment") {
        payload.parent_id = _.get(item, "id", "");
      }
      dispatch(commentPostRequest(payload));
    }
    reset();
  };

  // on key down post comment
  const checkKeyDown = (e) => {
    const keyCode = e.keyCode ? e.keyCode : e.which;
    if (keyCode === 13 && !e.shiftKey) {
      handleSubmit(onSubmit)();
      e.preventDefault();
    }
  };

  return (
    <div className={`${reason === "main-comment" ? "" : "edit-content"}`}>
      <div className="comments-input">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <div className="comment-inner-wrapper">
                <div className="user-img">
                  {_.isEmpty(currentUserImage) ? (
                    <IconButton
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      color="inherit"
                    >
                      <Avatar />
                    </IconButton>
                  ) : (
                    <Avatar
                      src={currentUserImage}
                    />
                  )}
                </div>
                <div className="comment-input-wrapper">
                  <textarea
                    name="comment_text"
                    placeholder={
                      reason === "main-comment"
                        ? "Add a comment..."
                        : "Add a reply..."
                    }
                    onKeyDown={(e) => checkKeyDown(e)}
                    ref={register({
                      maxLength: {
                        value: 1000,
                        message: "Can not exceed more then 1000 characters",
                      },
                      required: {
                        value: true,
                        message: "Comment can't be empty",
                      },
                    })}
                  />
                  <div className="submit-emoji-wrapper">
                    <div className="emoji-icon-wrapper" ref={emojiRef}>
                      <Emoji onClick={() => setTogglePicker(!togglePicker)} />
                      {togglePicker && (
                        <EmojiPicker
                          onEmojiClick={(e) => {
                            setValue(
                              "comment_text",
                              getValues("comment_text") + e.emoji
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {_.get(errors, "add_comment.message") && (
                    <ErrorTextHelper
                      text={_.get(errors, "add_comment.message", '')}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default ReplyPost;
