import React from "react";
import { ReactComponent as CopyIcon } from "../../../assets/svg/copy-icon.svg";
import { IconButton } from "@material-ui/core";
import { Modal } from "../../common";

function JobShareModal({ open, onClose, copyProfileLink, publicProfileLink }) {
  return (
    <Modal
      title="Share Job"
      visible={open}
      className="profile-modal share-profile-modal"
      onClose={() => onClose()}
    >
      <div className="shareable-link-wrapper">
        <p>Copy the link to share this Job</p>
        <div className="shareable-link">
          <span className="generate-link"> {publicProfileLink} </span>
          <IconButton
            className="copy-button"
            aria-label="fileCopy"
            onClick={(e) => copyProfileLink()}
          >
            <CopyIcon />
          </IconButton>
        </div>
      </div>
    </Modal>
  );
}

export default JobShareModal;
