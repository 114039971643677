import React from "react";

const EmployHERName = () => {
  return (
    <>
      {"employ"}
      <span className="text-primary">HER</span>
    </>
  );
};

export default EmployHERName;
