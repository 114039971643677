import React from "react";
import FeedPost from "./FeedPost";
import FeedList from "./FeedList";

function FeedLayout() {
  return (
    <div className="max-container">
      <div className="feed-container">
        <FeedPost />
        <FeedList />
      </div>
    </div>
  );
}

export default FeedLayout;
