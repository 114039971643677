import { createSlice, current } from "@reduxjs/toolkit";
import { get, isArray, isEmpty, isNumber } from "lodash";
import { ReducerFlag } from "../../types/reducer.types";
const _ = { get, isEmpty, isNumber };
interface IState {
  [key: string]: any;
}

// Initial state for reducer
const initialState: IState = {
  loading: false,
  loadingList: false,
  postLoading: false,
  postCreateFlag: ReducerFlag.INIT,
  flag: false,
  deleteFlag: false,
  uploadLoading: false,
  deleteLoading: false,
  data: [],
  replyData: [],
  postLikeLoading: false,
  commentLikeLoading: false,
  postLikeFlag: ReducerFlag.INIT,
  commentLikeFlag: ReducerFlag.INIT,
  feedListFlag: ReducerFlag.INIT,
  updateFlag: ReducerFlag.INIT,
  feedDetailsLoading: false,
  postDetailFlag: ReducerFlag.INIT,
  postErrorMessage: "",
  message: "",
  commentCreateFlag: ReducerFlag.INIT,
  replyCreateFlag: ReducerFlag.INIT,
  commentListFlag: ReducerFlag.INIT,
  loadingCommentList: false,
  deleteCommentFlag: ReducerFlag.INIT,
  deleteCommentLoading: false,
  commentLoading: false,
  commentReplyFlag: false,
  postReplyLoading: false,
  replyListFlag: ReducerFlag.INIT,
  replyList: [],
  replyEditFlag: ReducerFlag.INIT,
  replyDeleteFlag: ReducerFlag.INIT,
  commentErrorMessage: "",
  likeErrorMessage: "",
  hashtagFlag: ReducerFlag.INIT,
  hashtagData: [],
  isHashtagPage: false,
  pagination: {
    page: 1,
  },
};

const slice = createSlice({
  name: "postFeed",
  initialState: initialState,
  reducers: {
    feedPostRequest: (state, payload) => {
      return {
        ...state,
        loading: true,
        postLoading: true,
        postCreateFlag: ReducerFlag.INIT,
      };
    },
    feedPostSuccess: (state, { payload }) => {
      const postData = payload?.data?.data || {};
      return {
        ...state,
        loading: false,
        postLoading: false,
        flag: false,
        postCreateFlag: ReducerFlag.SUCCESS,
        data: [{ ...postData }, ...state.data],
      };
    },
    feedPostFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        postLoading: false,
        flag: false,
        postErrorMessage: _.get(payload, "message", ""),
        postCreateFlag: ReducerFlag.FAILED,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    resetFeedPostFlagRequest: (state) => {
      return {
        ...state,
        postCreateFlag: ReducerFlag.INIT,
        message: "",
      };
    },

    getFeedListRequest: (state, payload) => {
      return {
        ...state,
        feedListFlag: ReducerFlag.INIT,
        loadingList: true,
      };
    },
    getFeedListSuccess: (state, { payload }) => {
      const oldData = state.data || [];
      const currentData = _.get(payload, "data.data", []);
      return {
        ...state,
        loadingList: false,
        feedListFlag: ReducerFlag.SUCCESS,
        data: [...oldData, ...currentData] || [],
        detail: _.get(payload, "data", {}),
      };
    },
    getFeedListFailed: (state, { payload }) => {
      return {
        ...state,
        loadingList: false,
        feedListFlag: ReducerFlag.FAILED,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    resetFeedList: (state) => {
      return {
        ...state,
        loadingList: false,
        feedListFlag: ReducerFlag.INIT,
        data: [],
        detail: {},
        message: "",
        errors: "",
      };
    },
    feedListFlagReset: (state) => {
      return {
        ...state,
        feedListFlag: ReducerFlag.INIT,
      };
    },

    updateFeedListData: (state, { payload }) => {
      return {
        ...state,
        data: payload,
        hashtagData: payload
      };
    },

    postLikeRequest: (state, payload) => {
      return {
        ...state,
        postLikeFlag: ReducerFlag.INIT,
        postLikeLoading: true,
      };
    },
    postLikeSuccess: (state, { payload }) => {
      return {
        ...state,
        postLikeLoading: false,
        postLikeFlag: ReducerFlag.SUCCESS,
        likedData: _.get(payload, "data", {}),
      };
    },
    postLikeFailed: (state, { payload }) => {
      return {
        ...state,
        postLikeLoading: false,
        postLikeFlag: ReducerFlag.FAILED,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    postLikeReset: (state) => {
      return {
        ...state,
        postLikeFlag: ReducerFlag.INIT,
      };
    },

    getFeedDetailsRequest: (state, payload) => {
      return {
        ...state,
        feedDetailsLoading: true,
        postDetailFlag: ReducerFlag.INIT,
      };
    },
    getFeedDetailsSuccess: (state, { payload }) => {
      return {
        ...state,
        feedDetailsLoading: false,
        postDetailFlag: ReducerFlag.SUCCESS,
        flag: false,
        detail: _.get(payload, "data.data", {}),
      };
    },
    getFeedDetailsFailed: (state, { payload }) => {
      return {
        ...state,
        feedDetailsLoading: false,
        postDetailFlag: ReducerFlag.FAILED,
        flag: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    updateFeedDetailsRequest: (state, { payload }) => {
      return {
        ...state,
        detail: payload,
      };
    },

    // edit feed post

    editFeedRequest: (state, payload) => {
      return {
        ...state,
        editLoading: true,
        updateFlag: ReducerFlag.INIT,
      };
    },
    editFeedSuccess: (state, { payload }) => {
      const { data } = payload;
      let postData = { ...current(state) };
      const isHashTagPage = _.get(postData, "isHashtagPage", false);
      let targetData = isHashTagPage ? "hashtagData" : "data";
      const updatedData = postData[targetData].map((item: any) => {
        if (item.id === data?.id) {
          item = data;
        }
        return item;
      });
      return {
        ...state,
        editLoading: false,
        flag: false,
        updateFlag: ReducerFlag.SUCCESS,
        data: updatedData,
        hashtagData: updatedData,
      };
    },
    editFeedFailed: (state, { payload }) => {
      return {
        ...state,
        editLoading: false,
        updateFlag: ReducerFlag.FAILED,
        updateMessage: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    updatePostFlagReset: (state) => {
      return {
        ...state,
        updateFlag: ReducerFlag.INIT,
      };
    },

    deleteFeedRequest: (state, { payload }) => {
      return {
        ...state,
        deleteflag: ReducerFlag.INIT,
        deleteLoading: true,
      };
    },
    deleteFeedSuccess: (state, { payload }) => {
      const _data = state.data.filter(
        (item: any) => item.id !== payload?.response?.data?.post_id
      );
      return {
        ...state,
        deleteLoading: false,
        deleteflag: ReducerFlag.SUCCESS,
        data: [..._data],
        deleteMessage: _.get(payload, "message", ""),
      };
    },
    deleteFeedFailed: (state, { payload }) => {
      return {
        ...state,
        deleteLoading: false,
        deleteflag: ReducerFlag.FAILED,
        deleteMessage: _.get(payload, "message", ""),
        deleteErrors: _.get(payload, "errors", {}),
      };
    },
    deletePostFlagReset: (state) => {
      return {
        ...state,
        deleteflag: ReducerFlag.INIT,
      };
    },

    // Add comments
    commentPostRequest: (state, payload) => {
      return {
        ...state,
        loading: true,
        commentLoading: true,
        commentCreateFlag: ReducerFlag.INIT,
        replyCreateFlag: ReducerFlag.INIT,
      };
    },

    // edit comments
    commentEditRequest: (state, payload) => {
      return {
        ...state,
        loading: true,
        commentLoading: true,
        commentCreateFlag: ReducerFlag.INIT,
        replyEditFlag: ReducerFlag.INIT,
      };
    },
    commentPostSuccess: (state, { payload }) => {
      let newComment = _.get(payload, "data.data", {});
      let postData = { ...current(state) };
      const isHashTagPage = _.get(postData, "isHashtagPage", false);
      let targetData = isHashTagPage ? "hashtagData" : "data";
      if (newComment?.parent_id) {
        postData.replyCreateFlag = ReducerFlag.SUCCESS;
        postData.commentLoading = false;
      } else {
        // eslint-disable-next-line array-callback-return
        let _posts = postData[targetData].map((item: any) => {
          // comparing comment id and post id
          let _post = Object.assign({}, item);
          try {
            if (_post.id === newComment?.post_id) {
              _post.post_comments = [newComment, ...item?.post_comments];
            }
          } catch (error) {
            // code
          }
          return _post;
        });

        let replyList = postData.replyList;
        let emptyReply = {
          count: 1,
          rows: [],
          comment_id: newComment.id,
          post_id: newComment.post_id,
        };

        postData.replyList = [...replyList, ...[emptyReply]];
        postData.commentCreateFlag = ReducerFlag.SUCCESS;
        postData.commentLoading = false;
        postData.data = _posts;
        postData.hashtagData = _posts
      }
      return {
        ...state,
        ...postData,
        replyData: newComment?.parent_id ? newComment : {},
      };
    },
    commentEditSuccess: (state, { payload }) => {
      const newComment = _.get(payload, "data.data", {});
      const postData = { ...current(state) };
      const isHashTagPage = _.get(postData, "isHashtagPage", false);
      let targetData = isHashTagPage ? "hashtagData" : "data";
      if (newComment?.parent_id) {
        postData.replyEditFlag = ReducerFlag.SUCCESS;
      } else {
        // eslint-disable-next-line array-callback-return
        let _posts = postData[targetData].map((item: any) => {
          // comparing comment id and post id
          let _post = Object.assign({}, item);
          try {
            if (_post.id === newComment?.post_id) {
              let comments = _post.post_comments;
              _post.post_comments = comments.map((itm: any) => {
                if (itm.id === newComment?.id) {
                  return {
                    ...itm,
                    updated_at: newComment.updated_at,
                    comment_text: newComment.comment_text,
                  };
                } else {
                  return itm;
                }
              });
            }
          } catch (error) {
            // code
          }
          return _post;
        });
        postData.data = _posts;
        postData.hashtagData = _posts
      }
      postData.commentLoading = false;
      return {
        ...state,
        ...postData,
        replyData: newComment?.parent_id ? newComment : {},
      };
    },
    commentPostFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        commentLoading: false,
        commentErrorMessage: _.get(payload, "message", ""),
        commentCreateFlag: ReducerFlag.FAILED,
        errors: _.get(payload, "errors", {}),
      };
    },

    commentsCreateFlagReset: (state) => {
      return {
        commentCreateFlag: ReducerFlag.INIT,
        ...state,
      };
    },

    // get more comments

    getMoreCommentsRequest: (state, payload) => {
      return {
        ...state,
        commentListFlag: ReducerFlag.INIT,
        loadingCommentList: true,
      };
    },
    getMoreCommentsSuccess: (state, { payload }) => {
      const moreComments = _.get(payload, "data.data.rows", []);
      let postData = { ...current(state) };
      let oldReplyList = _.get(postData, "replyList", []);
      const isHashTagPage = _.get(postData, "isHashtagPage", false);
      let targetData = isHashTagPage ? "hashtagData" : "data";
      let newReplyList: {
        count: any;
        rows: any;
        comment_id: any;
        post_id: any;
      }[] = [];
      // eslint-disable-next-line array-callback-return
      let _posts = postData[targetData].map((item: any) => {
        let _post = Object.assign({}, item);
        try {
          if (_post.id === moreComments?.[0].post_id) {
            _post.post_comments = [...item.post_comments, ...moreComments];
          }
        } catch (error) {
          // code
        }
        return _post;
      });
      postData.loadingCommentList = false;
      postData.commentListFlag = ReducerFlag.SUCCESS;
      postData.data = _posts;
      postData.hashtagData = _posts;
      moreComments.forEach((comment: any) => {
        let replies = _.get(comment, "reply_comments", []);
        newReplyList.push({
          count: replies.length,
          rows: replies,
          comment_id: _.get(comment, "id", "").toString(),
          post_id: _.get(comment, "post_id", ""),
        });
      });
      const finalList = [...oldReplyList, ...newReplyList];
      return {
        ...state,
        ...postData,
        replyList: finalList,
      };
    },
    getMoreCommentsFailed: (state, { payload }) => {
      return {
        ...state,
        loadingCommentList: false,
        commentListFlag: ReducerFlag.FAILED,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    commentsFlagReset: (state) => {
      return {
        commentListFlag: ReducerFlag.INIT,
        ...state,
      };
    },

    // delete comment

    deleteCommentRequest: (state, { payload }) => {
      return {
        ...state,
        deleteCommentFlag: ReducerFlag.INIT,
        replyDeleteFlag: ReducerFlag.INIT,
        deleteCommentLoading: true,
      };
    },
    deleteCommentSuccess: (state, { payload }) => {
      const response = _.get(payload, "response", {});
      const loadingData = _.get(payload, "response.data", []);
      const PayloadData = _.get(payload, "payload", {});
      const postData = { ...current(state) };
      const isHashTagPage = _.get(postData, "isHashtagPage", false);
      let targetData = isHashTagPage ? "hashtagData" : "data";
      if (PayloadData?.parentId) {
        postData.replyDeleteFlag = ReducerFlag.SUCCESS;
        postData.deleteCommentLoading = false;
      } else {
        // eslint-disable-next-line array-callback-return
        let _posts = postData[targetData].map((item: any) => {
          let currentComments = Object.assign({}, item);
          try {
            if (item.id === PayloadData.postId) {
              currentComments.post_comments_count =
                item.post_comments_count - 1;
              const _loadingData = isArray(loadingData) ? loadingData : [];
              currentComments.post_comments = [
                ..._loadingData,
                ...item.post_comments.filter((comment: any) => {
                  return comment.id !== PayloadData.id;
                }),
              ];
            }
          } catch (error) {
            // code
          }
          return currentComments;
        });
        postData.data = _posts;
        postData.hashtagData = _posts;
        postData.deleteCommentLoading = false;
        postData.deleteCommentFlag = ReducerFlag.SUCCESS;
      }
      return {
        ...state,
        ...postData,
        replyData: PayloadData?.parentId ? PayloadData : {},
        deleteCommentMessage: _.get(response, "message", ""),
      };
    },
    deleteCommentFailed: (state, { payload }) => {
      return {
        ...state,
        deleteCommentLoading: false,
        deleteCommentFlag: ReducerFlag.FAILED,
        deleteCommentMessage: _.get(payload, "message", ""),
        deleteErrors: _.get(payload, "errors", {}),
      };
    },
    deleteCommentFlagReset: (state) => {
      return {
        ...state,
        deleteCommentFlag: ReducerFlag.INIT,
      };
    },

    // get replies

    getRepliesRequest: (state, payload) => {
      return {
        ...state,
        replyListFlag: ReducerFlag.INIT,
      };
    },
    getRepliesSuccess: (state, { payload }) => {
      const replies = _.get(payload, "data.data", []);
      return {
        ...state,
        replyData: replies,
        replyListFlag: ReducerFlag.SUCCESS,
      };
    },
    getRepliesFailed: (state, { payload }) => {
      return {
        ...state,
        loadingCommentList: false,
        commentListFlag: ReducerFlag.FAILED,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    getRepliesReset: (state) => {
      return {
        commentListFlag: ReducerFlag.INIT,
        ...state,
      };
    },
    changeReplyData: (state, { payload }) => {
      return {
        ...state,
        replyList: payload,
      };
    },
    commentLikeRequest: (state, payload) => {
      return {
        ...state,
        commentLikeFlag: ReducerFlag.INIT,
        commentLikeLoading: true,
      };
    },
    commentLikeSuccess: (state, { payload }) => {
      return {
        ...state,
        commentLikeLoading: false,
        commentLikeFlag: ReducerFlag.SUCCESS,
        commentLikedData: _.get(payload, "data", {}),
      };
    },
    commentLikeFailed: (state, { payload }) => {
      return {
        ...state,
        commentLikeLoading: false,
        commentLikeFlag: ReducerFlag.FAILED,
        likeErrorMessage: _.get(payload, "message", ""),
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    commentLikeReset: (state) => {
      return {
        ...state,
        commentLikeFlag: ReducerFlag.INIT,
      };
    },

    getHashTagPostsRequest: (state, payload) => {
      return {
        ...state,
        feedListFlag: ReducerFlag.INIT,
        loadingList: true,
      };
    },

    getHashTagPostsSuccess: (state, { payload }) => {
      const oldData = state.hashtagData || [];
      const currentData = _.get(payload, "data.data", []);
      return {
        ...state,
        loadingList: false,
        feedListFlag: ReducerFlag.SUCCESS,
        hashtagData: [...oldData, ...currentData] || [],
        hashtagPostsDetail: _.get(payload, "data", {}),
      };
    },

    getHashTagPostsFailed: (state, { payload }) => {
      return {
        ...state,
        loadingList: false,
        feedListFlag: ReducerFlag.FAILED,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    resetHashTagPosts: (state) => {
      return {
        ...state,
        loadingList: false,
        feedListFlag: ReducerFlag.INIT,
        message: "",
        errors: "",
      };
    },

    hashtagPostFlagReset: (state) => {
      return {
        ...state,
        feedListFlag: ReducerFlag.INIT,
      };
    },

    handleIsHashtagPage: (state) => {
      return {
        ...state,
        isHashtagPage: true,
      };
    },

  },
});

// Actions
export const {
  getFeedListRequest,
  getFeedListSuccess,
  getFeedListFailed,
  editFeedRequest,
  editFeedSuccess,
  editFeedFailed,
  resetFeedList,
  feedListFlagReset,
  updateFeedListData,
  feedPostRequest,
  feedPostSuccess,
  feedPostFailed,
  resetFeedPostFlagRequest,
  postLikeRequest,
  postLikeSuccess,
  postLikeFailed,
  postLikeReset,
  getFeedDetailsRequest,
  getFeedDetailsSuccess,
  getFeedDetailsFailed,
  updateFeedDetailsRequest,
  deleteFeedSuccess,
  deleteFeedRequest,
  deleteFeedFailed,
  deletePostFlagReset,
  updatePostFlagReset,
  commentPostRequest,
  commentEditRequest,
  commentPostSuccess,
  commentEditSuccess,
  commentPostFailed,
  commentsCreateFlagReset,
  getMoreCommentsRequest,
  getMoreCommentsSuccess,
  getMoreCommentsFailed,
  commentsFlagReset,
  deleteCommentSuccess,
  deleteCommentRequest,
  deleteCommentFailed,
  deleteCommentFlagReset,
  getRepliesRequest,
  getRepliesSuccess,
  getRepliesFailed,
  getRepliesReset,
  changeReplyData,
  commentLikeRequest,
  commentLikeSuccess,
  commentLikeFailed,
  commentLikeReset,
  getHashTagPostsRequest,
  getHashTagPostsSuccess,
  getHashTagPostsFailed,
  resetHashTagPosts,
  hashtagPostFlagReset,
  handleIsHashtagPage
} = slice.actions;

// Reducers
export default slice.reducer;
