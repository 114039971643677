import httpRequest from "../utils/httpRequest"
import { get, omit } from "lodash";
import { objectToParams } from "../utils/helper";
import API_URLS from "../utils/apiUrls";

const _ = { get, omit };

export const searchAll = async (payload: any) => {
  const queryString = await objectToParams(payload);
  const url = `${API_URLS.searchAllConnected}?${queryString}`;
  return httpRequest().get(url)
    .then(res => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        searchTerm: _.get(payload, "q", ""),
        searchType: _.get(payload, "type", ""),
        allResult: _.get(payload, "allResult", false),
        data: _.get(res, "data", {})
      };
    })
    .catch(err => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {})
      };
    });
}

export const removeConnection = (id: any) => {
  return httpRequest().patch(`${API_URLS.removeConnection}/${id}`)
    .then(res => {
      return {
        flag: true,
        message: _.get(res, "message", "")
      };
    })
    .catch(err => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {})
      };
    });
}

export const searchAllCompany = async (payload: any) => {
  const queryString = await objectToParams(payload);
  const url = `${API_URLS.searchAllCompanies}?${queryString}`;
  return httpRequest().get(url)
    .then(res => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        searchTerm: _.get(payload, "q", ""),
        searchType: _.get(payload, "type", ""),
        allResult: _.get(payload, "allResult", false),
        data: _.get(res, "data", {})
      };
    })
    .catch(err => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {})
      };
    });
}

const myNetworkServices = {
  searchAll, removeConnection, searchAllCompany
}

export default myNetworkServices
