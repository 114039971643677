import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import companyPublicView from "../../../services/companyPublicView.services";
import { ReactComponent as HeartActive } from "../../../assets/svg/heart-active.svg";
import { ReactComponent as Comment } from "../../../assets/svg/comment.svg";
import _ from "lodash";
import appRoutes from "../../../routes/app.routes";
import NotFoundPage from "../../../components/common/NotFoundPage";
import { openUrlInNewTab } from "../../../utils/helper";

const Feed = () => {
  const [feeds, setFeeds] = useState<any>([]);
  const { companyPublicProfile } = useSelector(
    ({ companyPublicView }: any) => companyPublicView.companyPublicView
  );

  const fetchData = async () => {
    const feeds: any = await companyPublicView.getCompanyFeeds({
      companyId: companyPublicProfile?.id,
    });

    setFeeds(feeds.data);
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [companyPublicProfile]);

  return !_.isEmpty(feeds) ? (
    <div className="feed-content feed-public-wrapper">
      <div className="edit-wrapper">
        <h1>Feeds</h1>
      </div>
      <div className="feed-content-wrapper">
        {!_.isEmpty(feeds) &&
          feeds.map((post) => (
            <div
              className="feeds-card"
              onClick={() => {
                openUrlInNewTab(
                  appRoutes.FeedDetails.generateFullPath(post?.slug)
                );
              }}
            >
              {!_.isEmpty(post.content) && (
                <div
                  className="feed-content-info wysiwyg-data"
                  dangerouslySetInnerHTML={{
                    __html:
                      post.content.length > 200
                        ? post.content.substring(0, 200) + "<span>...</span>"
                        : post.content,
                  }}
                />
              )}
              {!_.isEmpty(post.post_media) && (
                <div className="img-wrapper">
                  <img src={post.post_media} alt="Feed-thumbnail" />
                </div>
              )}
              <div className="feed-footer">
                <ul>
                  <li>
                    <HeartActive /> {post.post_likes_count}
                  </li>
                  <li>
                    <Comment /> {post.post_comments_count}
                  </li>
                </ul>
              </div>
            </div>
          ))}
      </div>
    </div>
  ) : (
    <NotFoundPage content="No feed found" />
  );
};

export default Feed;
