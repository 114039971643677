import React from "react";
import TagInput from "./tagInput";
import { Button, Divider, makeStyles, Typography, Paper, Chip } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from "react-redux";
import { handleDeleteTag } from "../../../reducers/company/companyMarketingSolution.reducer";
import { rootReducersState } from "../../../reducers";
import { useHistory } from "react-router-dom";
import _ from "lodash"
import appRoutes from "../../../routes/app.routes";


const useStyles = makeStyles({
  contentwrapper: {
    maxWidth: "1516px",
    width: "100%",
    margin: "0 auto",
    backgroundColor: "#fff",
    maxHeight: "407px",
    height: "100%",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 30px",
  },
  backButton: {
    fontSize: "20px",
    color: "#31222A",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  sectionHeading: {
    color: "#31222A",
    fontSize: "20px",
    lineHeight: "normal",
  },
  divider: {
    height: "1px",
  },
  tagsWrapper: {
    padding: "11px 30px"
  },
  continueButton: {
      borderRadius: "4px !important",
      border: "1px solid #FF0990 !important",
      background: "#FF0990",
      "&:hover": {
        backgroundColor: "#FF0990",
      },
      padding: "12px 20px",
      color: "#fff",
      fontSize: "16px"
  },
  continueButtonWrapper: {
    marginLeft: "10px",
    padding: "30px 15px",
    marginTop: "20px",
    "& .MuiButtonBase-root":{
      borderRadius: "4px !important",
    }
  },
  tagWrapper:{
    padding: "20px 4px",
    fontSize: "18px",
    lineHeight: "150%",
    fontStyle: "normal",
    marginRight: "5px",
    borderRadius: "30px"
  },

});

const AddTags = () => {
  // const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();
  const tags = useSelector(({company} : rootReducersState) => company.companyMarketingSolution.tags);
  const dispatch = useDispatch();
  const history = useHistory();
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const companySlug = sessionReducer.currentCompany.slug;


  const handleContinue = () => {
    if(!_.isEmpty(tags)){
      history.push(appRoutes.reviewInfo.generatePath(companySlug))
    }
  }
  return (
    <div className={classes.contentwrapper}>
      <div className={classes.titleWrapper}>
        <Typography
          style={{ fontWeight: "500" }}
          className={classes.sectionHeading}
          component="p"
        >
          Add more contact information
        </Typography>
        <Button className={classes.backButton} onClick={() => history.goBack()}>
          <ArrowBackIosIcon
            width="10"
            height="18"
            style={{ color: "#FF0990A6" }}
          />{" "}
          Back
        </Button>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.tagsWrapper}>
        <TagInput />
         <div style={{ marginTop: "10px", display: "flex", flexWrap: "wrap" }}>
          {tags.map((tag, index) => (
            <Paper key={index} elevation={0} style={{ margin: "5px" }}>
              <Chip
                label={tag}
                variant="outlined"
                onDelete={() =>
                  dispatch(handleDeleteTag(tag))
                }
                deleteIcon={<CloseIcon/>}
                className={classes.tagWrapper}
                style={{fontWeight: "300"}}
              />
            </Paper>
          ))}
      </div>
      </div>
      <div className={classes.continueButtonWrapper}>
          <Button onClick={handleContinue} className={classes.continueButton}>
            Continue
          </Button>
        </div>
    </div>
  );
};

export default AddTags;
