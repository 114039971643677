import React from "react";
import history from "../../../utils/history";
import FeedList from "../FeedList";

const MyFeedList = () => {
  const searchURL = history.location.pathname.split("/");
  const searchParams = searchURL?.[searchURL.length - 1];

  return (
    <div className="max-container">
      <div className="feed-container">
        <FeedList myPosts={searchParams} />
      </div>
    </div>
  );
};

export default MyFeedList;
