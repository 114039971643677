import React from "react";
import Avatar from "../../../../components/common/AvtarImage";

interface IFeedLogo {
  profileLogo: string;
  name: string;
}

const FeedLogo = ({ profileLogo, name }: IFeedLogo) => {
  return (
    <>
      <span className="feed-logo">
        <Avatar
          src={profileLogo}
        ></Avatar>
      </span>
    </>
  );
};
// <Image src={profileLogo} alt="profile logo" />

export default FeedLogo;
