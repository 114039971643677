import { Card, Checkbox, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  AsyncSelect,
  FlashMessage,
  Input,
  Modal,
} from "../../../components/common";
import { CirclePicker } from "react-color";
import schedulerServices from "../../../services/scheduler.services.ts";
import _ from "lodash";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SettingsIcon from "@material-ui/icons/Settings";
// import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useSelector } from "react-redux";
import { rootReducersState } from "../../../reducers";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import copyToClipboard from "copy-to-clipboard";
import { EVENT_COLOR_CODE } from "../../../utils/appConstants";
import { getAppDomain } from "../../../utils/helper";
import ConfirmDialog from "../../../components/common/ConfirmDialog";
import Button from "../../../components/common/Button";
import Avatar from "../../../components/common/AvtarImage.tsx";
import noCalender from "../../../assets/svg/calendar-not-found.svg";
import Image from "../../../components/common/Image.tsx";

type FormFields = {
  event_name: string;
  duration: string;
  description: string;
  event_link: string;
  event_color: string;
};

const eventColorCode = EVENT_COLOR_CODE;

const EventTypes = () => {
  const appDomainFull = getAppDomain({ protocol: true });
  const [eventModal, setEventModal] = useState<boolean>(false);
  const [deleteRow, setDeleteRow] = useState<object>({});
  const [deleteIndex, setDeleteIndex] = useState<number>(0);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
    useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [updateId, setUpdateId] = useState<string>("");
  const [modelTitle, setModelTitle] = useState<string>("");
  const [color, setColor] = useState<string>(eventColorCode);
  const [durationValue, setDurationValues] = useState<string>("");
  const [eventTypes, setEventTypes] = useState<Array<object>>([]);
  const [deleteArr, setDeleteArr] = useState<Array<number>>([]);
  const [userData, setUserData] = useState<object>({});
  let [cardKey, setKey] = useState<number>(0);
  const [workingHour, setWorkingHour] = useState<Array<object>>([]);

  const {
    register,
    formState: { errors, dirtyFields },
    handleSubmit,
    setValue,
    control,
    watch,
    setError,
  } = useForm<FormFields>();
  let link = watch("event_link", "");
  let defaultLink = watch("event_name", "");
  let event = watch("event_link", "");

  const descriptionLimit = 250;
  const descriptionValue = watch("description", "")?.length || 0;

  useEffect(() => {
  }, [descriptionValue]);
  useEffect(() => {
    fetchEventTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isUpdate === false) {
      if (_.get(dirtyFields, 'event_link', '')) {
        setValue("event_link", (event || "").replace(/[^a-z0-9]+/gi, "-"));
      }
      else {
        setValue("event_link", (defaultLink || "").replace(/[^a-z0-9]+/gi, "-"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLink, event, isUpdate, dirtyFields]);

  const confirmSingleDelete = (row, index) => {
    setShowConfirmModal(true);
    setDeleteRow(row);
    setDeleteIndex(index);
  };

  const cancelSingleDelete = () => {
    setShowConfirmModal(false);
    setDeleteRow({});
    setDeleteIndex(0);
  };

  const handleDelete = async (row, index) => {
    setKey(cardKey++);
    try {
      const result = await schedulerServices.removeEventType(row.id);

      if (result.flag) {
        eventTypes.splice(index, 1);
        setKey(cardKey++);
        FlashMessage(result.message);
        setEventTypes(eventTypes);
      } else {
        FlashMessage(result.message, "error");
      }
      setDeleteRow({});
      setDeleteIndex(0);
      setShowConfirmModal(false);
    } catch (error) {
      FlashMessage(error?.message, "error");
    }
  };

  const confirmDeleteSelected = () => {
    if (deleteArr.length === 0) {
      FlashMessage("Select event type", "error");
    } else {
      setShowDeleteConfirmModal(true);
    }
  };

  const handleDeleteSelected = async () => {
    setKey(cardKey++);
    try {
      const result = await schedulerServices.removeMultipleEventTypes(
        deleteArr
      );

      if (result.flag) {
        fetchEventTypes();
        setKey(cardKey++);
        FlashMessage(result.message);
        setEventTypes(eventTypes);
        setDeleteArr([]);
      } else {
        FlashMessage(result.message, "error");
      }
      setShowDeleteConfirmModal(false);
    } catch (error) {
      FlashMessage(error?.message, "error");
    }
  };

  const handleEdit = async (row, index) => {
    setModelTitle("Update Event Type");
    setIsUpdate(true);
    setUpdateId(row.id);
    setEventModal(true);
    setTimeout(() => {
      setValue("event_name", _.get(row, "event_name", ""));
      setValue("duration", {
        label: _.get(row, "duration", "") + " min",
        value: _.get(row, "duration", ""),
      });
      setValue("description", _.get(row, "description", ""));
      setValue("event_link", _.get(row, "event_link", ""));
      setValue("event_color", _.get(row, "event_color", ""));
      setColor(_.get(row, "event_color", ""));
    }, 100);
  };

  // Reducers
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );

  useEffect(() => {
    setUserData(_.get(sessionReducer, "currentUser", {}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEventTypes = async () => {
    try {
      const result = await schedulerServices.getAllEventTypes();
      const workingHours = await schedulerServices.getWorkingHours(
        sessionReducer.currentUser.id
      );
      if (workingHours) {
        setWorkingHour(workingHours.data.workingHours)
      }
      if (result.flag) {
        setEventTypes(result.data);
      } else {
        FlashMessage(result.message, "error");
      }
    } catch (error) {
      FlashMessage(error?.message, "error");
    }
  };

  const createEventType = () => {
    if (eventTypes.length >= 10) {
      FlashMessage("Not allowed more then 10 event types", "error");
    } else {
      setModelTitle("Create Event Type");
      setIsUpdate(false);
      setColor(eventColorCode);
      setEventModal(true);
    }
  };
  const [submitLoading, setSubmitLoading] = useState(false);

  const onSubmit = async (formData) => {
    setSubmitLoading(true);
    const payload = {
      event_name: formData.event_name,
      duration:
        formData.duration.value === "custom"
          ? formData.event_duration
          : formData.duration.value,
      event_link: _.get(formData, "event_link", ""),
      description: formData.description,
      event_color: color,
    };

    if (isUpdate) {
      try {
        const result = await schedulerServices.updateEventType(
          payload,
          updateId
        );

        if (result.flag) {
          FlashMessage(result.message);
          setEventModal(false);
          fetchEventTypes();
          setSubmitLoading(false);
        } else {
          handleErrors(result);
          FlashMessage(result.message, "error");
          setSubmitLoading(false);
        }
      } catch (error) {
        FlashMessage(error?.message, "error");
        setSubmitLoading(false);
      }
    } else {
      try {
        const result = await schedulerServices.addEventType(payload);

        if (result.flag) {
          FlashMessage(result.message);
          setEventModal(false);
          fetchEventTypes();
          setSubmitLoading(false);
        } else {
          handleErrors(result);
          FlashMessage(result.message, "error");
          setSubmitLoading(false);
        }
      } catch (error) {
        FlashMessage(error?.message, "error");
        setSubmitLoading(false);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleErrors = (response: any) => {
    _.forEach(response.errors, (value, key: any) => {
      setError(key, { type: "manual", message: value });
    });
  };

  const handleChangeComplete = (color) => setColor(color.hex);

  const durations = () =>
    new Promise((resolve) => {
      const locationData = [15, 30, 45, 60, "custom"];
      const locations = locationData.map((c) => ({
        value: c,
        label: c + (c === "custom" ? "" : " min"),
      }));
      resolve(locations);
    });

  const durationType = () =>
    new Promise((resolve) => {
      const locationData = ["min"];
      const locations = locationData.map((c) => ({ value: c, label: c }));
      resolve(locations);
    });

  const handleCopy = (row) => {
    const copyLink = `${appDomainFull}scheduler/${_.get(
      userData,
      "slug",
      ""
    )}/${row?.event_link}`;
    copyToClipboard(copyLink);
    FlashMessage("Copied profile link");
  };

  const handleChange = (row) => {
    if (deleteArr.includes(row.id)) {
      let index = deleteArr.indexOf(row.id);
      if (index > -1) deleteArr.splice(index, 1);
    } else {
      deleteArr.push(row.id);
    }
  };

  useEffect(() => {
    setDurationValues(watch("duration", "").value);
  }, [watch]);

  const userCard = (row, index) => {
    return (
      <div className="appointment-card">
        <div
          className="card-header"
          style={{
            background: row?.event_color
              ? row?.event_color
              : "rgba(255,9,144,0.6)",
          }}
        />
        <div className="card-details">
          <div className="card-content flex between">
            <div className="card-left">
              <div className="flex event-name">
                <Checkbox
                  onChange={() => {
                    handleChange(row);
                  }}
                />
                <h3>{_.get(row, "event_name", "")}</h3>
              </div>
              <span className="minutes">{_.get(row, "duration", "")} min</span>
              <a
                href={`${appDomainFull}scheduler/${_.get(
                  userData,
                  "slug",
                  ""
                )}/${_.get(row, "event_link", "")}`}
                target="_blank"
                rel="noreferrer"
                className="pt-5"
              >
                View booking page
              </a>
            </div>
            <div className="card-right flex">
              <Menu
                menuButton={
                  <MenuButton className="card-setting">
                    <SettingsIcon /> <span className="setting">Settings</span>
                  </MenuButton>
                }
                transition
              >
                <MenuItem
                  onClick={() => {
                    handleEdit(row, index);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    confirmSingleDelete(row, index);
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              handleCopy(row);
            }}
          >
            Get Link
          </Button>
          <Button color="secondary" variant="outlined">
            Share
          </Button>
        </div>
      </div>
    );
  };

  if (workingHour.length === 0) {
    return (
      <Card className="card-no-data">
        <div className="no-calender">
          <Image src={noCalender} alt="no calender image" />
        </div>
        <span>
          Set your working hour
        </span>
      </Card>
    )
  }

  return (
    <>
      <div className="appointment-schedule">
        <div className="appointment-top flex between">
          <div className="flex user-info">
            <div>

              <Avatar
                src={_.get(userData, "profile_image", "")}
                size="xsm"
              />
            </div>
            <div>
              <div>
                {_.get(userData, "first_name", "")}{" "}
                {_.get(userData, "last_name", "")}
              </div>
              <div>
                <a
                  href={`${appDomainFull}scheduler/${_.get(
                    userData,
                    "slug",
                    ""
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>{appDomainFull}scheduler/</span>
                  {_.get(userData, "slug", "")}
                </a>
              </div>
            </div>
          </div>
          {workingHour.length > 0 && (
            <div className="flex">
              <Button
                color="secondary"
                variant="outlined"
                // className="button-submit"

                onClick={() => createEventType()}
              >
                Create event type
              </Button>
              <Menu
                menuButton={
                  <MenuButton style={{ background: "white", border: "none" }}>
                    <SettingsIcon /> <ArrowDropDownIcon />
                  </MenuButton>
                }
                transition
              >
                <MenuItem onClick={confirmDeleteSelected}>Delete</MenuItem>
              </Menu>
            </div>
          )}

        </div>
        <div>
          {eventTypes.length === 0 ? (
            <Card className="card-no-data">No Events</Card>
          ) : (
            <div className="grid appointment-wrapper" key={cardKey}>
              {eventTypes &&
                eventTypes.map((row, index) => userCard(row, index))}
            </div>
          )}

        </div>
        <Modal
          visible={eventModal}
          size="medium"
          title={modelTitle}
          className="job-add-modal event-modal-wrapper"
          closeButton={eventModal}
          onClose={() => setEventModal(false)}
        >
          <div className="event-model-wrappper">
            <form
              className={"send-message-form"}
              id="form2"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <label className="form-group-label">Event Name</label>
                <Input
                  type="text"
                  name="event_name"
                  placeholder="Event Name"
                  validationObj={errors}
                  InputProps={{}}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter event name",
                    },
                  })}
                />
              </div>
              <div className="form-group">
                <label className="form-group-label">Duration</label>
                <AsyncSelect
                  onStartSearching={durations}
                  name="duration"
                  placeholder="Select duration"
                  required={true}
                  validationObj={errors}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select duration from list",
                    },
                  }}
                />
                {durationValue === "custom" && (
                  <div className="mt-3">
                    <Grid container spacing={2}>
                      <Grid item xs={7}>
                        <Input
                          type="number"
                          name="event_duration"
                          placeholder="Duration time"
                          validationObj={errors}
                          InputProps={{}}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "Please enter duration time",
                            },
                          })}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <AsyncSelect
                          onStartSearching={durationType}
                          name="duration_type"
                          placeholder="Select duration type"
                          required={true}
                          validationObj={errors}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Please select duration type from list",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}
              </div>
              <div className="form-group description">
                <label className="form-group-label">
                  Description/Instruction
                </label>
                <div className="description">
                  <Input
                    name="description"
                    placeholder="Enter Description....."
                    multiline={true}
                    validationObj={errors}
                    inputRef={register({
                      maxLength: {
                        value: descriptionLimit,
                        message: `Please enter between 2 to ${descriptionLimit} characters long`,
                      },
                      minLength: {
                        value: 2,
                        message: `Please enter between 2 to ${descriptionLimit} characters long`,
                      },
                      required: {
                        value: true,
                        message: "Please enter about your description values",
                      },
                    })}
                    externalLabel={{ label: "Description" }}
                    countValue={descriptionValue}
                    showCount
                    maxLimit={descriptionLimit}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-group-label">Event Link</label>
                <span className="link">
                  {appDomainFull + _.get(userData, "slug", "") + "/" + link}
                </span>
                <Input
                  type="text"
                  name="event_link"
                  placeholder="Event Link"
                  validationObj={errors}
                  InputProps={{}}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter event link",
                    },
                  })}
                />
              </div>
              <div className="form-group">
                <label className="form-group-label">Event Color</label>
                <CirclePicker
                  name="event_color"
                  color={color}
                  onChangeComplete={handleChangeComplete}
                />
              </div>
              <div className="form-group btn-group">
                <Button
                  type="submit"
                  variant="contained"
                  className="button-submit"
                  loading={submitLoading}
                >
                  {isUpdate ? "Update" : "Submit"}
                </Button>{" "}
                &nbsp;&nbsp;
                <Button
                  color="secondary"
                  variant="outlined"
                  className="text-uppercase"
                  onClick={() => {
                    setEventModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className="form-group text-center"></div>
            </form>
          </div>
        </Modal>
        <ConfirmDialog
          visible={showConfirmModal}
          title="Confirm"
          onCancel={() => cancelSingleDelete()}
          bodyText="Are sure want to remove?"
          onConfirm={() => handleDelete(deleteRow, deleteIndex)}
          confirmText="Okay"
        />
        <ConfirmDialog
          visible={showDeleteConfirmModal}
          title="Confirm"
          onCancel={() => setShowDeleteConfirmModal(false)}
          bodyText="Are sure want to remove?"
          onConfirm={() => handleDeleteSelected()}
          confirmText="Okay"
        />
      </div >
    </>
  );
};

export default EventTypes;
