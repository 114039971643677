const PenSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.669417 20C0.575518 20.0006 0.482562 19.9812 0.396659 19.9432C0.310756 19.9052 0.233851 19.8495 0.171001 19.7796C0.10815 19.7097 0.0607763 19.6272 0.0319939 19.5377C0.00321149 19.4481 -0.00632785 19.3535 0.00400292 19.26L0.602875 13.8134C0.619659 13.6619 0.687766 13.5207 0.795845 13.4134L13.4919 0.700272C13.7121 0.478349 13.9739 0.302231 14.2622 0.18205C14.5506 0.0618701 14.8598 0 15.1721 0C15.4844 0 15.7936 0.0618701 16.082 0.18205C16.3703 0.302231 16.6321 0.478349 16.8523 0.700272L19.301 3.12024C19.5225 3.3408 19.6983 3.60308 19.8183 3.89196C19.9382 4.18085 20 4.49065 20 4.80354C20 5.11643 19.9382 5.42624 19.8183 5.71512C19.6983 6.00401 19.5225 6.26628 19.301 6.48685L6.57829 19.2C6.47119 19.3083 6.33027 19.3765 6.17904 19.3933L0.742612 20H0.669417ZM1.90709 14.1867L1.42133 18.58L5.80641 18.0933L16.0477 7.82744L12.1539 3.92628L1.90709 14.1867ZM18.3295 5.5402L16.999 6.87387L13.1056 2.97322L14.4368 1.64026C14.6336 1.44565 14.899 1.33654 15.1754 1.33654C15.4519 1.33654 15.7173 1.44565 15.914 1.64026L18.3295 4.06022C18.5237 4.25735 18.6327 4.52321 18.6327 4.80021C18.6327 5.07721 18.5237 5.34307 18.3295 5.5402ZM5.63558 13.4133L11.677 7.36055L12.6181 8.30334L6.57662 14.3561L5.63558 13.4133Z"
      fill="#FF0990"
    />
  </svg>
);

export default PenSVG;
