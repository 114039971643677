import React from "react";
import { Modal, Box, Typography, Button, makeStyles } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useSelector } from "react-redux";
import { rootReducersState } from "../../../reducers";


const useStyles = makeStyles({
  continueButtonWrapper: {
    marginLeft: "10px",
    padding: "30px 15px",
    marginTop: "20px",
    "& .MuiButtonBase-root":{
      borderRadius: "4px !important",
    }
  },
})

const StatusModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const { numberOfSavedContacts, numberOfFailedContacts } = useSelector(({company}: rootReducersState) => company.companyMarketingSolution);
  console.log(numberOfSavedContacts, ":::numberOfSavedContacts:::", numberOfFailedContacts)
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "807px",
        width: "100%",
        borderRadius: "30px",
        margin: "0 auto"
      }}
    >
      <Box
        bgcolor="white"
        boxShadow={5}
        p={4}
        borderRadius={4}
        textAlign="center"
      >
        <CheckCircleIcon color="primary" style={{ fontSize: 50 }} />
        <Typography variant="body1" id="modal-description" paragraph>
          Successfully imported {numberOfSavedContacts} Contacts, failed {numberOfFailedContacts} contacts
        </Typography>
        <div className={classes.continueButtonWrapper}>
        <Button variant="contained" color="primary" onClick={handleClose}>
          OK
        </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default StatusModal;
