import React, { useEffect, useState } from "react";
import NotificationItem from "./components/NotificationItem";
import NoNotificationFound from "../../components/Candidates/noNotificationFound";
import _ from "lodash";
import PaginationComponent from "../../components/common/Pagination";
import { FlashMessage } from "../../components/common";
import notificationServices from "../../services/notification.services";
import { updateNotificationCount } from "../../reducers/auth/session.reducer";
import { useDispatch, useSelector } from "react-redux";
import { rootReducersState } from "../../reducers";
import { Link } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const defaultFilter = {
  page: 1,
  pageSize: 10,
  isCompany: 1,
};

const ConnectionNotification = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(defaultFilter);
  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<any>({});
  const [key, setKey] = useState<number>(0);

  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );

  const notificationCount = _.get(sessionReducer, "currentUser.notificationCount", 0) > 0 ? _.get(sessionReducer, "currentUser.notificationCount", 0) : 0;
  const companyNotificationCount = _.get(sessionReducer, "currentUser.companyNotificationCount", 0) > 0 ? _.get(sessionReducer, "currentUser.companyNotificationCount", 0) : 0;

  // const {
  //   list: { notificationData, loading, pagination },
  // } = useSelector(({ notification }: any) => notification);

  // useEffect(() => {
  //   dispatch(notificationListRequest({ page, pageSize: 10 }));
  // }, [dispatch, page]);

  const _handlePaginationChange = (value: number) => {
    setPage(value);
    let payload = {
      ...defaultFilter,
      page: value
    }
    setFilter(filter);
    fetchApi(payload)
  };

  // const _handleAllClear = () => {
  //   dispatch(clearAllNotificationRequest());
  //   if (!loading) dispatch(notificationListRequest({ page, pageSize: 10 }));
  // };

  const _handleAllClear = async () => {
    let isCompany = 1
    if (window?.location?.pathname !== '/notifications') isCompany = 2
    try {
      setLoading(true)
      const result = await notificationServices.clearAllNotification({ isCompany: isCompany });

      if (result.flag) {
        // eslint-disable-next-line array-callback-return
        notificationList.map((item: any) => { item.is_read = 0 });
        setNotificationList(notificationList)
        setKey((key) => key + 1)
        setLoading(false)
      } else {
        setLoading(false)
        FlashMessage(result.message, "error");
      }
    } catch (error) {
      FlashMessage(error?.message, "error");
    }
  };

  useEffect(() => {
    setFilter(defaultFilter);
    fetchApi(filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchApi = async (payload) => {
    if (window?.location?.pathname !== '/notifications') {
      payload = { ...payload, isCompany: 2 }
    }
    try {
      setLoading(true)
      const result = await notificationServices.list(payload);

      if (result.flag) {
        setNotificationList(_.get(result, 'data.data', []))
        setPagination(_.get(result, 'data.pagination', {}))
        setLoading(false)
      } else {
        setLoading(false)
        FlashMessage(result.message, "error");
      }
    } catch (error) {
      FlashMessage(error?.message, "error");
    }
  };

  const handleClear = (id) => {
    const index = notificationList.findIndex((item: any) => item.id === id);
    notificationList.splice(index, 1);
    setNotificationList(notificationList)
    setKey((key) => key + 1)
    const payload = {
      notificationCount: notificationCount,
      companyNotificationCount: companyNotificationCount
    }
    if (window.location.pathname !== '/notifications') {
      payload['companyNotificationCount'] = companyNotificationCount === 0 ? companyNotificationCount : companyNotificationCount - 1;
    } else {
      payload['notificationCount'] = notificationCount === 0 ? notificationCount : notificationCount - 1;
    }
    dispatch(updateNotificationCount(payload));
  }

  const handleRead = (id) => {
    // eslint-disable-next-line array-callback-return
    notificationList.map((item: any) => { if (item.id === id) item.is_read = 0 });
    setNotificationList(notificationList)
    setKey((key) => key + 1)
    const payload = {
      notificationCount: notificationCount,
      companyNotificationCount: companyNotificationCount
    }
    if (window.location.pathname !== '/notifications') {
      payload['companyNotificationCount'] = companyNotificationCount === 0 ? companyNotificationCount : companyNotificationCount - 1;
    } else {
      payload['notificationCount'] = notificationCount === 0 ? notificationCount : notificationCount - 1;
    }
    dispatch(updateNotificationCount(payload));
  }

  return (
    <div className="max-container">
      <div className="connection-notification-wrapper">
        <div className="title-notification">
          <h1>Notifications</h1>
          {!_.isEmpty(notificationList) &&
            <Link onClick={_handleAllClear} className="text-uppercase cursor-pointer mr-15">
              READ ALL
            </Link>
          }
        </div>
        <div className="connections-card-wrapper">
          <div className="content" key={key}>
            {loading ? (
              [1, 2, 3, 4, 5].map((row) => (
                <div className="notification-list-content" key={row}>
                  <Skeleton variant="text" width="100%" height={100} />
                </div >
              ))
            ) : _.isEmpty(notificationList) ? (
              <NoNotificationFound label="notifications" />
            ) : (
              <>
                {notificationList.map((notification) => (
                  <NotificationItem notification={notification} handleClear={(e) => { handleClear(e) }} handleRead={(e) => { handleRead(e) }} />
                ))}
              </>
            )}
          </div>
          <div className="pagination">
            <PaginationComponent
              count={_.get(pagination, "totalPages", 0)}
              page={page}
              variant="outlined"
              color="secondary"
              onChange={(value) => _handlePaginationChange(value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectionNotification;
