import React, { useEffect, useState } from "react";
import { Info as InfoIcon } from "@material-ui/icons";
import Modal from "../../../components/common/Modal";
import _ from "lodash";
import {
  Button,
  Checkbox,
  EditableText,
  FlashMessage,
  Input,
} from "../../../components/common";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import ChipInput from "material-ui-chip-input";
import RichTextEditor from "../../../components/common/RichTextEditor";
import { Grid, InputLabel } from "@material-ui/core";
import { stripHTMLTags } from "../../../utils/helper";
import { AsyncCreatableSelect } from "../../../components/common";
import commonServices from "../../../services/common.services";
import moment from "moment";
import {
  candidateUpdatedReset,
  updateCandidateRequest,
  uploadCandidateResumeReset,
} from "../../../reducers/candidate/candidate.reducer";
import { useDispatch, useSelector } from "react-redux";
import { ReducerFlag } from "../../../types/reducer.types";

interface resumeModalProps {
  parsedModal: boolean;
  setParsedModal: any;
  userProfile: any;
  resumeData?: any;
  closeResumeModal?: any;
}

const ResumeModal = ({
  parsedModal,
  setParsedModal,
  userProfile,
  resumeData,
  closeResumeModal,
}: resumeModalProps) => {
  const {
    candidates: { updateLoading, updateProfileFlag, message },
  } = useSelector(({ candidate }: any) => candidate);
  const experiences = _.get(resumeData, "experiences", []);
  const skills = _.get(resumeData, "skills", []);
  const education = _.get(resumeData, "education", []);
  const projects = _.get(resumeData, "projects", []);
  const [skillChip, setSubmitChip] = useState([]);
  const [overRideFlag, setOverRideFlag] = useState<boolean>(false);
  const maxLimit = 1000;
  let searchDelay: NodeJS.Timeout;
  const dispatch = useDispatch();
  const { register, handleSubmit, control, watch, errors, setValue } = useForm({
    defaultValues: {
      experiences: experiences,
      skills: skills,
      projects: projects,
      education: education,
    },
  });

  const experienceChecked: [] = watch("experienceChecked");
  const educationChecked: [] = watch("educationChecked");
  const skillsChecked = watch("includeSkills");
  const projectChecked: [] = watch("projectChecked");
  const nameChecked: boolean = watch("nameChecked");
  const contactChecked: boolean = watch("contactChecked");

  const { append: skillAppend, remove: skillRemove } = useFieldArray({
    control,
    name: "skills",
  });

  useEffect(() => {
    if (!_.isEmpty(skills)) {
      const skillsArray = skills.map((item) => item);
      setSubmitChip(skillsArray);
    }

    // eslint-disable-next-line
  }, [skills, parsedModal]);

  const _handleAddChip = (chip) => {
    const skillsArray: any = [...skillChip];
    skillsArray.push(chip);
    skillAppend({ title: chip });
    setSubmitChip(skillsArray);
  };

  const _handleDeleteChip = (chip, index) => {
    const skillsArray: any = [...skillChip];
    if (index > -1) {
      skillsArray.splice(index, 1);
      setSubmitChip(skillsArray);
      skillRemove(index);
    }
  };

  useEffect(() => {
    if ([ReducerFlag.SUCCESS, ReducerFlag.FAILED].includes(updateProfileFlag)) {
      if (updateProfileFlag === ReducerFlag.SUCCESS) {
        FlashMessage(message);
        setParsedModal(false);
        setOverRideFlag(false);
        dispatch(uploadCandidateResumeReset());
        dispatch(candidateUpdatedReset());
      } else if (updateProfileFlag === ReducerFlag.FAILED) {
        FlashMessage(message, "error");
      }
    }
    // eslint-disable-next-line
  }, [updateProfileFlag]);

  const searchJobTitles = (search: string) =>
    new Promise((resolve) => {
      if (search !== "") {
        clearTimeout(searchDelay);
        searchDelay = setTimeout(async () => {
          const jobTitleDetails = await commonServices.fetchJobTitles(search);
          const locations = _.get(jobTitleDetails, "data", []).map(
            (c: any) => ({
              value: c.id,
              label: c.title,
            })
          );
          resolve(locations);
        }, 1000);
      } else {
        resolve([]);
      }
    });

  const experiencesValue: any = useWatch({
    name: "experiences",
    control,
  });

  const projectsValue: any = useWatch({
    name: "projects",
    control,
  });

  const educationValue: any = useWatch({
    name: "education",
    control,
  });

  const onSubmit = (data) => {
    data?.experiences?.map((item) => {
      if (item.job_title_custom) {
        const jobCustomTitle = item.job_title_custom;
        if (item?.job_title_custom?.__isNew__) {
          return (item["jobTitle"] = {
            label: jobCustomTitle?.label
              ? jobCustomTitle.label
              : jobCustomTitle,
            value: jobCustomTitle?.value
              ? jobCustomTitle.value
              : jobCustomTitle,
            __isNew__: true,
          });
        } else {
          return (item["jobTitle"] = {
            label: jobCustomTitle?.label
              ? jobCustomTitle.label
              : jobCustomTitle,
            value: jobCustomTitle?.value
              ? jobCustomTitle.value
              : jobCustomTitle,
          });
        }
      } else {
        const jobTitle = item.jobTitle;
        return (item["jobTitle"] = {
          label: jobTitle?.label ? jobTitle.label : jobTitle,
          value: jobTitle?.value ? jobTitle.value : jobTitle,
        });
      }
    });
    const formData: any = {};

    const filteredExperiences = _.get(data, "experiences", []).filter(
      (_experience, index) => experienceChecked[index]
    );

    const filteredEducation = _.get(data, "education", []).filter(
      (_education, index) => educationChecked[index]
    );

    const filteredProjects = _.get(data, "projects", []).filter(
      (_project, index) => projectChecked[index]
    );

    if (!_.isEmpty(filteredExperiences)) {
      formData.experiences = filteredExperiences;
    }

    if (nameChecked) {
      const nameParts = data.name.split(" ");

      if (nameParts.length >= 2) {
        formData.first_name = nameParts[0];
        formData.last_name = nameParts.slice(1).join(" ");
      } else {
        formData.first_name = nameParts[0];
      }
    }

    if (contactChecked) {
      formData.contact_info = data.contact_info;
    }

    if (skillsChecked) {
      const skills = skillChip.map((title) => ({
        title: title,
        description: null,
      }));

      formData.skills = skills;
    }

    if (!_.isEmpty(filteredEducation)) {
      formData.education = filteredEducation;
    }

    if (!_.isEmpty(filteredProjects)) {
      formData.projects = filteredProjects;
    }
    if (overRideFlag) {
      formData.overRideFlag = overRideFlag;
    }

    dispatch(updateCandidateRequest(formData));
  };

  const _handlePresentCheckBoxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    const { checked } = e.target;
    if (checked) {
      setValue(
        `experiences[${index}].job_ended`,
        moment().format("YYYY-MM-DD")
      );
    } else {
      setValue(`experiences[${index}].job_ended`, "");
    }
  };

  return (
    <Modal
      visible={parsedModal}
      onClose={() => {
        dispatch(uploadCandidateResumeReset());
        setParsedModal(false);
        closeResumeModal();
        setOverRideFlag(false);
      }}
      size="x-large"
      className="resume-modal"
      title="Update Profile"
    >
      <div className="d-flex">
        <InfoIcon className="font-md-2 text-primary" />{" "}
        <small className="ml-1">
          Our resume parsing feature is currently in the beta stage. While
          efforts are made for accuracy, we advise users to carefully review
          parsed information and make any necessary adjustments as we work
          towards improvement.
        </small>
      </div>
      <div className="d-flex mt-3">
        <InfoIcon className="font-md-2 text-primary" />{" "}
        <small className="ml-1">
          Mark the information you want to Override or Append with your profile
        </small>
      </div>
      <hr className="my-3" />
      <div className="modal-head">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <div className="flex">
              <Checkbox
                inputRef={register}
                externalLabel={{
                  label: <strong>Name:</strong>,
                }}
                name={`nameChecked`}
              />{" "}
              <div className="d-flex user-name">
                <EditableText
                  name="name"
                  inputClassName="editable-input"
                  control={control}
                  textProps={{
                    className: "m-0 font-md",
                  }}
                  country="US"
                  error={errors}
                  rules={{
                    required: { value: true, message: "Name is required" },
                    maxLength: {
                      value: 20,
                      message: "Name must be between 2 to 20 characters long",
                    },
                    minLength: {
                      value: 2,
                      message: "Name must be between 2 to 20 characters long",
                    },
                  }}
                  defaultValue={_.get(resumeData, "name", "")}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="flex">
              <Checkbox
                inputRef={register}
                externalLabel={{
                  label: <b>Contact:</b>,
                }}
                name={`contactChecked`}
              />
              <EditableText
                inputClassName="editable-input"
                control={control}
                defaultValue={_.get(
                  resumeData,
                  "contact_info",
                  userProfile.contact_info
                )}
                name="contact_info"
                country="us"
                error={errors}
                rules={{
                  required: {
                    value: true,
                    message: "Phone is required",
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="edit-modal-wrapper">
        <div className="body-wrapper">
          {!_.isEmpty(experiences) && (
            <div className="modal-content">
              <h5>Experience</h5>
              <div className="experience-row">
                {experiences.map((item, index) => {
                  return (
                    <div
                      className="career-differentiators-form-item"
                      key={index}
                    >
                      <div className="career-differentiators-row">
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <div className="date-fields w-100">
                              <AsyncCreatableSelect
                                name={`experiences[${index}].jobTitle`}
                                control={control}
                                validationObj={errors}
                                placeholder="Search Job..."
                                externalLabel={{
                                  label: (
                                    <div className="title-checkbox">
                                      {" "}
                                      <Checkbox
                                        inputRef={register}
                                        externalLabel={{
                                          label: `Job Title`,
                                        }}
                                        name={`experienceChecked[${index}]`}
                                      />
                                    </div>
                                  ),
                                  // className: "required",
                                }}
                                defaultValue={item?.jobTitle}
                                onStartSearching={searchJobTitles}
                                noOptionsMessage="No matching job found"
                                disabled={!experienceChecked?.[index]}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <div className="date-fields w-100">
                              <Input
                                name={`experiences[${index}].company_name`}
                                placeholder="Name"
                                externalLabel={{ label: "Company Name" }}
                                validationObj={errors}
                                defaultValue={experiences?.[index].company_name}
                                required={false}
                                inputRef={register({
                                  required: {
                                    value: false,
                                    message: "Please enter a valid Project",
                                  },
                                })}
                                disabled={!experienceChecked?.[index]}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <div className="date-input">
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                  <div className="date-fields w-100">
                                    <Input
                                      externalLabel={{ label: "Select Date" }}
                                      name={`experiences[${index}].job_started`}
                                      placeholder="Work Experience"
                                      type="date"
                                      defaultValue={
                                        experiences?.[index].job_started
                                      }
                                      validationObj={errors}
                                      required={false}
                                      inputRef={register({
                                        required: {
                                          value: false,
                                          message:
                                            "Please enter a valid Work Experience",
                                        },
                                      })}
                                      disabled={!experienceChecked?.[index]}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <div className="date-fields edit-education-present w-100">
                                    <Checkbox
                                      onChange={(e) =>
                                        _handlePresentCheckBoxChange(e, index)
                                      }
                                      control={control}
                                      inputRef={register}
                                      externalLabel={{ label: "Present" }}
                                      defaultChecked={
                                        experiences[index].is_present === 1
                                      }
                                      name={`experiences[${index}].is_present`}
                                      disabled={!experienceChecked?.[index]}
                                    />

                                    {experiencesValue?.[index]?.is_present ? (
                                      <InputLabel className="present-label">
                                        Present
                                      </InputLabel>
                                    ) : (
                                      <Input
                                        name={`experiences[${index}].job_ended`}
                                        placeholder="early career"
                                        type="date"
                                        defaultValue={
                                          experiences[index].job_ended
                                        }
                                        validationObj={errors}
                                        required={false}
                                        inputRef={register({
                                          required: {
                                            value: false,
                                            message:
                                              "Please enter a valid Date",
                                          },
                                        })}
                                        disabled={!experienceChecked?.[index]}
                                      />
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <div className="rich-text-editor-job">
                              <RichTextEditor
                                name={`experiences[${index}].description`}
                                placeholder="Job Description"
                                externalLabel={{ label: "Job Description" }}
                                defaultValue={experiences[index]?.description}
                                validationObj={errors}
                                control={control}
                                required={false}
                                rules={{
                                  required: {
                                    value: false,
                                    message:
                                      "Please enter a valid job Description",
                                  },
                                  validate: {
                                    validLength: (value) => {
                                      const tmpText = stripHTMLTags(
                                        value || ""
                                      );
                                      return (
                                        tmpText.length <= maxLimit ||
                                        `Please enter less than ${maxLimit} characters`
                                      );
                                    },
                                  },
                                }}
                                showCount
                                maxLimit={maxLimit}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/* Projets */}
          {!_.isEmpty(projects) && (
            <div className="modal-content">
              <h5>Projects</h5>
              <div className="project-row">
                {projects.map((item, index) => (
                  <div key={index} className="career-differentiators-form-item">
                    <div className="career-differentiators-row">
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <div>
                            <Input
                              name={`projects[${index}].title`}
                              placeholder="Project Title"
                              externalLabel={{
                                label: (
                                  <div className="title-checkbox">
                                    {" "}
                                    <Checkbox
                                      inputRef={register}
                                      externalLabel={{
                                        label: `Project Title`,
                                      }}
                                      name={`projectChecked[${index}]`}
                                    />
                                  </div>
                                ),
                              }}
                              defaultValue={item.title}
                              validationObj={errors}
                              required={false}
                              inputRef={register({
                                required: {
                                  value: false,
                                  message: "Please enter a valid Project Title",
                                },
                              })}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div>
                            <Input
                              name={`projects[${index}].link`}
                              placeholder="Project URL"
                              externalLabel={{ label: "Project URL" }}
                              validationObj={errors}
                              defaultValue={item.link}
                              required={false}
                              inputRef={register({
                                required: {
                                  value: false,
                                  message: "Please enter a valid Project URL",
                                },
                                pattern: {
                                  value:
                                    /[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g,
                                  message: "Enter a valid Project URL",
                                },
                              })}
                              disabled={!projectChecked?.[index]}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <div className="date-input">
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={6}>
                                <div className="date-fields">
                                  <Input
                                    externalLabel={{ label: "Select Date" }}
                                    name={`projects[${index}].start_date`}
                                    placeholder="Work Experience"
                                    type="date"
                                    defaultValue={item.start_date}
                                    validationObj={errors}
                                    required={false}
                                    inputRef={register({
                                      required: {
                                        value: false,
                                        message:
                                          "Please enter a valid Work Experience",
                                      },
                                    })}
                                    disabled={!projectChecked?.[index]}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <div className="date-fields edit-education-present">
                                  <Checkbox
                                    onChange={(e) =>
                                      _handlePresentCheckBoxChange(e, index)
                                    }
                                    inputRef={register}
                                    defaultChecked={
                                      projects[index]?.is_present === 1
                                    }
                                    externalLabel={{ label: "Present" }}
                                    name={`projects[${index}].is_present`}
                                    disabled={!projectChecked?.[index]}
                                  />
                                  {projectsValue?.[index]?.is_present ? (
                                    <InputLabel className="present-label">
                                      Present
                                    </InputLabel>
                                  ) : (
                                    <Input
                                      name={`projects[${index}].end_date`}
                                      defaultValue={item.end_date}
                                      placeholder="End date"
                                      type="date"
                                      validationObj={errors}
                                      required={false}
                                      inputRef={register({
                                        required: {
                                          value: false,
                                          message: "Please enter a valid Date",
                                        },
                                      })}
                                      disabled={!projectChecked?.[index]}
                                    />
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <div className="rich-text-editor">
                            <RichTextEditor
                              name={`projects[${index}].description`}
                              externalLabel={{
                                label: "Project Description",
                              }}
                              defaultValue={item.description}
                              placeholder="Project Description"
                              validationObj={errors}
                              control={control}
                              rules={{
                                required: {
                                  value: false,
                                  message:
                                    "Please enter a valid Project Description",
                                },
                                validate: {
                                  validLength: (value) => {
                                    const tmpText = stripHTMLTags(value || "");
                                    return (
                                      tmpText.length <= maxLimit ||
                                      `Please enter less than ${maxLimit} characters`
                                    );
                                  },
                                },
                              }}
                              showCount
                              maxLimit={maxLimit}
                              disabled={!projectChecked?.[index]}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* Education */}
          {!_.isEmpty(education) && (
            <div className="modal-content">
              <h5>Education</h5>
              <div className="education-row">
                {education.map((item, index) => (
                  <div
                    key={index}
                    className="career-differentiators-form-item  education-form-item"
                  >
                    <div className="career-differentiators-row education-form-row">
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <div>
                            <Input
                              name={`education[${index}].institute_name`}
                              placeholder="Name"
                              externalLabel={{
                                label: (
                                  <div className="title-checkbox">
                                    {" "}
                                    <Checkbox
                                      inputRef={register}
                                      externalLabel={{
                                        label: `Institution Name`,
                                      }}
                                      name={`educationChecked[${index}]`}
                                    />
                                  </div>
                                ),
                              }}
                              defaultValue={item.institute_name}
                              validationObj={errors}
                              required={false}
                              inputRef={register({
                                required: {
                                  value: false,
                                  message:
                                    "Please enter a valid Institution Name",
                                },
                              })}
                              disabled={!educationChecked?.[index]}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div className="date-input">
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={6}>
                                <div>
                                  <Input
                                    externalLabel={{ label: "Select Date" }}
                                    name={`education[${index}].degree_from`}
                                    placeholder="Start Date"
                                    type="date"
                                    defaultValue={item.degree_from}
                                    validationObj={errors}
                                    required={false}
                                    inputRef={register({
                                      required: {
                                        value: false,
                                        message: "Please enter a valid Date",
                                      },
                                    })}
                                    disabled={!educationChecked?.[index]}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <div className="edit-education-present">
                                  <Checkbox
                                    onChange={(e) =>
                                      _handlePresentCheckBoxChange(e, index)
                                    }
                                    inputRef={register}
                                    externalLabel={{ label: "Present" }}
                                    defaultChecked={
                                      education[index]?.is_present === 1
                                    }
                                    name={`education[${index}].is_present`}
                                    disabled={!educationChecked?.[index]}
                                  />
                                  {educationValue?.[index]?.is_present ? (
                                    <InputLabel className="present-label">
                                      Present
                                    </InputLabel>
                                  ) : (
                                    <Input
                                      name={`education[${index}].degree_to`}
                                      defaultValue={item.degree_to}
                                      placeholder="End date"
                                      type="date"
                                      validationObj={errors}
                                      required={false}
                                      inputRef={register({
                                        required: {
                                          value: false,
                                          message: "Please enter a valid Date",
                                        },
                                      })}
                                      disabled={!educationChecked?.[index]}
                                    />
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </div>

                    <div className="career-differentiators-row education-form-row">
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <div>
                            <Input
                              name={`education[${index}].field_of_study`}
                              placeholder="Field of study"
                              externalLabel={{ label: "Field of Study" }}
                              validationObj={errors}
                              defaultValue={item.field_of_study}
                              required={false}
                              inputRef={register({
                                required: {
                                  value: false,
                                  message:
                                    "Please enter a valid field of study",
                                },
                              })}
                              disabled={!educationChecked?.[index]}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div>
                            <Input
                              name={`education[${index}].degree_title`}
                              placeholder="Degree"
                              externalLabel={{ label: "Degree" }}
                              validationObj={errors}
                              defaultValue={item.degree_title}
                              required={false}
                              inputRef={register({
                                required: {
                                  value: false,
                                  message: "Please enter a valid degree",
                                },
                              })}
                              disabled={!educationChecked?.[index]}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* skills */}
          {!_.isEmpty(skills) && (
            <div className="modal-content">
              <Checkbox
                inputRef={register}
                externalLabel={{ label: "Skills" }}
                name={`includeSkills`}
              />
              <h4>Skills</h4>
              <div className="skill-row">
                <ChipInput
                  variant="outlined"
                  placeholder="Add skills"
                  value={skillChip}
                  onAdd={(chip) => _handleAddChip(chip)}
                  onDelete={(chip, index) => _handleDeleteChip(chip, index)}
                  inputRef={register}
                  className="w-100"
                  disabled={!skillsChecked}
                />
              </div>
            </div>
          )}
          <div className="submit-button">
            <Button
              type={"submit"}
              variant="text"
              color="secondary"
              onClick={() => setOverRideFlag(true)}
              loading={updateLoading}
            >
              Override Existing Profile
            </Button>
            <Button type={"submit"} loading={updateLoading}>
              Append to Existing Profile
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ResumeModal;
