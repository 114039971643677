import { useSelector } from "react-redux";
import { rootReducersState } from "../reducers";
import { COMPANY_FLAG } from "../utils/appConstants";
import { useLocation } from "react-router-dom";
const jwt = require("jsonwebtoken");

/**
 * Current Session User details with company
 *
 * @return User information
 */

export interface ResumeInterface {
  id: number;
  original_file: string;
  mime_type: string;
  created_at: string;
}

const useSessionUser = () => {
  const currentLocation = useLocation();
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );

  const sessionUserId = sessionReducer?.currentUser?.id || undefined;
  const IS_COMPANY =
    sessionReducer?.currentUser?.is_company === COMPANY_FLAG.YES ? true : false;
  const currentSession = jwt.decode(sessionReducer.token)?.v157 || "";

  // Add more fields as per requirement
  return {
    // User information
    loggedIn: sessionUserId ? true : false,
    currentSlug: sessionReducer?.currentUser?.slug || "",
    sessionUserId: sessionUserId,
    userProfile: sessionReducer?.currentUser?.profile_image || undefined,
    userFullName: `${sessionReducer?.currentUser?.first_name || ""} ${
      sessionReducer?.currentUser?.last_name || ""
    }`,
    currentUser: sessionReducer?.currentUser || {},
    resumesList: (sessionReducer?.currentUser.resumes ||
      []) as ResumeInterface[],
    currentSession: currentSession || "",

    // if user is singed up as company only, no candidate profile access
    IS_COMPANY: IS_COMPANY,

    // Company information
    currentCompanyName:
      sessionReducer?.currentCompany?.company_name || undefined,
    currentCompanyLogo:
      sessionReducer?.currentCompany?.company_logo || undefined,
    currentCompanySlug: sessionReducer?.currentCompany?.slug || undefined,
    onCompanyRoute: currentLocation?.pathname?.indexOf("/employer/") >= 0,
  };
};

export default useSessionUser;
