import httpRequest from "../utils/httpRequest";
import { get, omit } from "lodash";
import { objectToParams } from "../utils/helper";
import API_URLS from "../utils/apiUrls";

const _ = { get, omit };

export const getCompanyProfile = async (payload: any) => {
  const url = `${API_URLS.companyPublicView}/${payload.companySlug}`;
  return httpRequest()
    .get(url)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const getCompanyJobs = async (payload: any) => {
  const queryString = await objectToParams(payload);
  const url = `${API_URLS.companyPublicView}/jobs/${payload.companyId}?${queryString}`;
  return httpRequest()
    .get(url)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
        pagination: _.get(res, "data.pagination", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const getCompanyFeeds = async (payload: any) => {

  const url = `${API_URLS.companyPublicView}/feeds/${payload.companyId}`;
  return httpRequest()
    .get(url)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const claimPage = async (formData: any) => {

  const url = `${API_URLS.companyPublicView}/claim`;
  return httpRequest()
    .post(url, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "data.message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const companyPublicView = {
  getCompanyProfile,
  getCompanyJobs,
  getCompanyFeeds,
  claimPage,
};

export default companyPublicView;
