import React, { useEffect, useState } from "react";
import _ from "lodash";
import Skeleton from "@material-ui/lab/Skeleton";
import copyToClipboard from "copy-to-clipboard";
import FeedBodyHeader from "./components/FeedHeader";
import FeedCommonFooter from "../components/FeedFooter";
import FeedListContent from "../components/FeedListContent";
import { rootReducersState } from "../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import {
  getFeedListRequest,
  resetFeedList,
  postLikeReset,
  updateFeedListData,
  postLikeRequest,
  feedListFlagReset,
  changeReplyData,
  resetHashTagPosts,
  getHashTagPostsRequest,
  hashtagPostFlagReset,
} from "../../../reducers/feeds/feedPost.reducer";
import { ReducerFlag } from "../../../types/reducer.types";
import appRoutes from "../../../routes/app.routes";
import { FlashMessage } from "../../../components/common";
import { Card, CircularProgress, Grid } from "@material-ui/core/";
import { myPost, myArticles } from "../../../services/feedPage.services";
import Image from "../../../components/common/Image";
// import Comments from "../components/Comments";
import noPostData from "../../../assets/svg/noPostData.svg"

const pageSize = 20;

interface FeedListProps {
  isHashTagPage?: boolean;
  searchParams?: string
  myPosts?: string
}

const FeedList = (props: FeedListProps) => {
  const feedReducer = useSelector(
    ({ postFeed }: rootReducersState) => postFeed
  );

  const isHashTagPage = _.get(props, "isHashTagPage", false);
  const searchParams = _.get(props, "searchParams", '');
  const myPostParams = _.get(props, "myPosts", '');
  const dispatch = useDispatch();
  const postResponse = _.get(feedReducer, "postFeed", {});
  const targetData = isHashTagPage ? 'hashtagData' : "data";
  const targetPagination = isHashTagPage ? 'hashtagPostsDetail.pagination' : "detail.pagination";
  const targetDetails = isHashTagPage ? 'hashtagPostsDetail.data' : "detail.data"
  // const postData = myPost.length > 0 ? myPost : _.get(postResponse, targetData, []);
  const [myPostsAndArticles, setMyPostsAndArticles] = useState<Array<object>>([]);
  const [myPostPagination, setMyPagePagination] = useState({ totalPages: 0 });
  const postData = myPostParams ? myPostsAndArticles : _.get(postResponse, targetData, []);
  const pagination = myPostParams ? myPostPagination : _.get(postResponse, targetPagination, {});
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);

  const likeFlag = _.get(feedReducer, "postFeed.postLikeFlag", false);
  const postFeedLikeData = _.get(feedReducer, "postFeed.likedData", {});
  const feedListFlag = _.get(feedReducer, "postFeed.feedListFlag", "");
  window.onscroll = _.debounce(() => {
    const docBottom =
      document.documentElement.getBoundingClientRect().bottom - 1200;
    const reachedBottom = window.innerHeight > docBottom;
    if (reachedBottom && !isFetching && page < pagination.totalPages) {
      setPage((prevPage) => prevPage + 1);
      setIsFetching(true);
    }
  }, 100);

  useEffect(() => {
    return () => {
      dispatch(resetFeedList());
      dispatch(resetHashTagPosts())
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (myPostParams.length > 0) {
      fetchMyPost()
    }
    // eslint-disable-next-line
  }, [myPostParams]);

  useEffect(() => {
    // call a fetch API once page is changed
    if (isHashTagPage) {
      dispatch(getHashTagPostsRequest({ searchParams, page, pageSize }));
    } else if (myPostParams) {
      fetchMyPost();
    }
    else {
      dispatch(getFeedListRequest({ page, pageSize }));

    }
    // eslint-disable-next-line
  }, [page]);

  // Reset the flag once post list API is done with success of failed
  useEffect(() => {
    if ([ReducerFlag.SUCCESS, ReducerFlag.FAILED].includes(feedListFlag)) {
      filterFetchData();
      setIsFetching(false);
      dispatch(feedListFlagReset());
      dispatch(hashtagPostFlagReset());
    }

    // eslint-disable-next-line
  }, [feedListFlag]);

  // Hook, post like and unlike
  useEffect(() => {
    if (likeFlag === ReducerFlag.SUCCESS) {
      const updatedData = postData.map((row) => {
        if (row.id === postFeedLikeData.postId) {
          return {
            ...row,
            liked: postFeedLikeData.liked,
            post_likes_count: postFeedLikeData.totalLikes,
          };
        } else {
          return row;
        }
      });
      dispatch(updateFeedListData(updatedData));
      dispatch(postLikeReset());
    }

    // eslint-disable-next-line
  }, [likeFlag]);

  const filterFetchData = () => {
    let listData = _.get(postResponse, targetDetails, []);
    let replyList = [];
    listData.forEach((post) => {
      let comments = _.get(post, "post_comments", []);
      comments.forEach((comment) => {
        let replies = _.get(comment, "reply_comments", []);
        replyList.push({
          count: replies.length,
          rows: replies,
          comment_id: _.get(comment, "id", "").toString(),
          post_id: _.get(post, "id", ""),
        });
      });
    });
    dispatch(changeReplyData(replyList));
  };

  const fetchMyPost = async () => {
    const payload = {
      page: page,
      pageSize: pageSize
    }
    let response;
    if (myPostParams === 'my-posts') {
      response = await myPost(payload);
    } else {
      response = await myArticles(payload);
    }
    const data = response.data;
    if (data.data.length > 0) {
      const myPostPagination = response.data.pagination.totalPages
      setMyPagePagination({ totalPages: myPostPagination })

      if (page > 1) {
        setMyPostsAndArticles((prevPosts) => [...prevPosts, ...data.data]);
      } else {
        setMyPostsAndArticles(data.data);
      }

      if (page === myPostPagination) {
        setIsFetching(false)
      }

    } else {
      setIsFetching(false)
    }
  }
  if (!(postData.length > 0) && myPostParams) {
    return (
      <Card className="post-no-data">
        <div className="no-calender">
          <Image src={noPostData} alt="no calender image" />
        </div>
        <span>
          {`No ${myPostParams === 'my-article' ? 'Articles' : 'Posts'} Found`}
        </span>
      </Card>
    )
  }
  const handlePostLike = (postDetails: any) => {
    dispatch(postLikeRequest({ post_id: postDetails.id }));
  };

  const handleCopyPostLink = (postDetails: any) => {
    const postLink = appRoutes.FeedDetails.generateFullPath(postDetails.slug);
    copyToClipboard(postLink);
    FlashMessage("Copied Post link");
  };

  return (
    <>
      {(postData || [])?.map((data, key) => {
        return (
          <div key={key}>
            {searchParams && (
              <div className="hash-tag" key={key}>#{searchParams}</div>
            )}
            <div className="feed-content" key={key}>

              <>
                <FeedBodyHeader data={data} index={key} />
                <FeedListContent data={data} />
                <FeedCommonFooter
                  data={data}
                  postLike={(postDetails) => handlePostLike(postDetails)}
                  handleCopyPostLink={(postDetails) =>
                    handleCopyPostLink(postDetails)
                  }
                />
              </>
            </div>
          </div>
        );
      })}
      {(_.isEmpty(postData) && !myPostParams) && (
        [1, 2, 3].map((index) => {
          return (
            <div className="empty-list-wrapper" key={index}>
              <div className="loading-post">
                <Grid container>
                  <Grid item sm={1} >
                    <Skeleton variant="circle" width={50} height={50} />
                  </Grid>
                  <Grid item md={1} >
                    <Skeleton variant="text" width={300} />
                    <Skeleton variant="text" width={300} />
                  </Grid>
                </Grid>
                <Grid>
                  <Skeleton variant="text" width={1000} />
                  <Skeleton variant="text" width={1000} />
                </Grid>
              </div>
            </div>
          )
        })
      )}
      {isFetching && (
        <CircularProgress color="secondary" className="circular-progress" />
      )}
    </>
  );
};
export default FeedList;
