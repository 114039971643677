import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
  Modal,
  Input,
  SelectNew,
  EditableText,
  Checkbox,
  Button,
  Radio,
  FlashMessage,
  ProfileImageCrop,
  AsyncCreatableSelect,
} from "../../common";
import { Grid } from "@material-ui/core";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { AsyncSelect } from "../../../components/common";
import ProfileUploadIcon from "../../../assets/images/cloud-upload-icon.png";
import {
  CUSTOM_GENDER_OPTIONS,
  ImageMimeTypes,
  WorkStatusType,
} from "../../../utils/appConstants";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import commonServices from "../../../services/common.services";
import { extractFirstLastName, stripHTMLTags } from "../../../utils/helper";
import candidateServices from "../../../services/candidate.services";
import { updateBasicInformation } from "../../../reducers/auth/session.reducer";
import { validateFileSize } from "../../helpers/formValidation";
import { readFile } from "../../../utils/cropImageHelper";
import { sanitizeBody } from "../../../utils/helper";
import Avatar from "../../common/AvtarImage";

let searchDelay: NodeJS.Timeout;

function EditBasicInformationModal({ profile, basicProfile, setBasicProfile }) {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLDivElement>(null);
  const [candidatePronoun, setCandidatePronoun] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showCropImage, setShowCropImage] = useState<boolean>(false);
  const [cropImage, setCropImage] = useState(null);
  const [croppedProfileImage, setCroppedProfileImage] = useState("");
  const [uploadFile, setUploadFile] = useState<Blob>();
  const [defaultJobTitle, setDefaultJobTitle] = useState<object>({});
  const {
    register,
    handleSubmit,
    errors,
    control,
    clearErrors,
    setError,
    watch,
    reset,
  } = useForm({});
  const workStatusFilled = _.get(profile, "profile.work_status", "");

  useEffect(() => {
    const fullName = `${_.get(profile, "first_name", "")} ${_.get(profile, "last_name", "") || ""}`;

    const pronoun = _.get(profile, "gender", "") || "";
    setCandidatePronoun(pronoun);
    const tmpGenderPronoun = _.get(profile, "gender_pronoun", "") || "";
    const genderPronoun =
      CUSTOM_GENDER_OPTIONS.find(
        (row) => row.value === String(tmpGenderPronoun)
      ) || {};

    let jobTitle = {};

    if (_.get(profile, "profile.job_title_custom")) {
      jobTitle = {
        label: _.get(profile, "profile.job_title_custom"),
        value: _.get(profile, "profile.job_title_custom"),
      };
    } else {
      jobTitle = {
        label: _.get(profile, "profile.jobTitle.title", ""),
        value: _.get(profile, "profile.jobTitle.id", ""),
      };
    }

    setDefaultJobTitle(jobTitle);
    reset({
      first_name: fullName,
      gender: pronoun,
      gender_pronoun: genderPronoun,
      gender_optional: _.get(profile, "gender_optional", "") || "",
      contact_info: _.get(profile, "contact_info", "") || "",
      city_id: {
        label: _.get(profile, "profile.location.location", ""),
        value: _.get(profile, "profile.location.id", ""),
      },
      job_title: jobTitle,
      work_status: _.get(profile, "profile.work_status", ""),
    });

    // eslint-disable-next-line
  }, [profile]);

  const saveBasicInfo = async (formPayload) => {
    setLoading(true);
    let formData = new FormData();
    let open_to_other = formPayload.open_to_other === true ? "1" : "0";
    let open_to_remote = formPayload.open_to_remote === true ? "1" : "0";
    if (uploadFile) {
      formData.append("profile_image", uploadFile, 'image.png');
    }

    let workStatusFilledUpdated = workStatusFilled;
    if (!_.isEmpty(formPayload.work_status)) {
      workStatusFilledUpdated = formPayload.work_status.value
        ? formPayload.work_status.value
        : formPayload.work_status;
    }

    const fullName = extractFirstLastName(formPayload.first_name);
    const firstName = fullName.firstName;
    const lastName = fullName.lastName;

    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("gender", candidatePronoun);
    formData.append(
      "gender_pronoun",
      _.get(formPayload, "gender_pronoun.value", "")
    );
    formData.append(
      "gender_optional",
      _.get(formPayload, "gender_optional", "")
    );
    formData.append("work_status", workStatusFilledUpdated);
    formData.append("city_id", _.get(formPayload, "city_id.value", ""));
    formData.append("contact_info", _.get(formPayload, "contact_info", ""));

    const jobTitle = _.get(formPayload, "job_title.value", "");

    if (typeof jobTitle === "number") {
      formData.append("job_title", jobTitle.toString());
      formData.append("job_title_custom", "");
    } else {
      formData.append("job_title", "");
      formData.append("job_title_custom", jobTitle.toString());
    }
    formData.append("open_to_other", open_to_other);
    formData.append("open_to_remote", open_to_remote);
    formData.append(
      "personal_values",
      _.get(formPayload, "personal_values", "")
    );

    try {
      const responseData = await candidateServices.updateCandidateBasicInfo(
        formData
      );

      if (_.get(responseData, "flag") === true) {
        // Call candidate api update reducer
        const profileImage = _.get(responseData, "data.profile_image", "");
        const updatedData = {
          first_name: firstName,
          last_name: lastName,
          gender: candidatePronoun,
          gender_pronoun: _.get(formPayload, "gender_pronoun.value", 0) || 0,
          gender_optional: _.get(formPayload, "gender_optional", ""),
          contact_info: _.get(formPayload, "contact_info", ""),
          profile: {
            jobTitle: {
              id: _.get(formPayload, "job_title.value", ""),
              title: _.get(formPayload, "job_title.label", ""),
            },
            job_title_custom: _.get(formPayload, "job_title.value")
              ? ""
              : formPayload?.job_title?.__isNew__
                ? _.get(formPayload, "job_title_custom", "")
                : _.get(profile, "profile.job_title_custom")
                  ? _.get(profile, "profile.job_title_custom")
                  : "",
            work_status: workStatusFilledUpdated,
            open_to_other: open_to_other,
            open_to_remote: open_to_remote,
            location: {
              id: formPayload.city_id.value,
              location: formPayload.city_id.label,
            },
            personal_values: _.get(formPayload, "personal_values", ""),
          },
        };

        if (profileImage) {
          updatedData["profile_image"] = profileImage;
        }
        sanitizeBody(updatedData);
        dispatch(updateBasicInformation(updatedData));

        setBasicProfile(false);
      } else {
        // Show the form errors
        const errors = _.get(responseData, "errors", {});
        if (!_.isEmpty(errors) && Object.keys(errors).length > 0) {
          for (const [fieldName, errMsg] of Object.entries(errors)) {
            setError(fieldName, {
              type: "manual",
              message: errMsg as string,
            });
          }
        } else {
          FlashMessage(
            _.get(
              responseData,
              "message",
              "Unable to update the Basic information"
            ),
            "error"
          );
        }
      }
    } catch (error) {
      FlashMessage(
        _.get(error, "message", "Unable to update the Basic information")
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCropImage = async (blobData) => {
    setUploadFile(blobData);
    const src = URL.createObjectURL(blobData);
    setCroppedProfileImage(src);
  };
  const onChangePicture = async (e) => {
    if (!_.isEmpty(e.target.files)) {
      const file = e.target.files[0];
      const fileType = file.type;
      const fileSize = validateFileSize(file.size, 5);
      if (fileSize) {
        FlashMessage(fileSize, "error");
      } else if (ImageMimeTypes.includes(fileType)) {
        const imageDataUrl: any = await readFile(file);
        setCropImage(imageDataUrl);
        setShowCropImage(true);
      } else {
        FlashMessage("Please select valid png or jpeg file", "error");
      }
    }
  };

  const handleCancel = () => {
    setBasicProfile(false);
    setCroppedProfileImage("");
  };

  // Search location with delay of 1 second
  const searchLocations = (search: string) =>
    new Promise((resolve) => {
      clearTimeout(searchDelay);
      searchDelay = setTimeout(async () => {
        const locationData = await commonServices.fetchLocations(search);
        const locations = _.get(locationData, "data.data", []).map((c) => ({
          value: c.id,
          label: c.location,
        }));

        resolve(locations);
      }, 1000);
    });

  const searchJobTitles = (search: string) =>
    new Promise((resolve) => {
      clearTimeout(searchDelay);
      searchDelay = setTimeout(async () => {
        const jobTitleDetails = await commonServices.fetchJobTitles(search);
        const locations = _.get(jobTitleDetails, "data", []).map((c: any) => ({
          value: c.id,
          label: c.title,
        }));
        resolve(locations);
      }, 1000);
    });
  const personalValueLimit = 1000;
  const personalValue = watch("personal_values");
  const [personalValueLength, setPersonalValueLength] = useState(
    _.get(profile, "profile.personal_values", "")?.length || 0
  );

  useEffect(() => {
    const tmpText = stripHTMLTags(personalValue);
    setPersonalValueLength(tmpText.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalValue]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalValueLength]);

  useEffect(() => {
    setPersonalValueLength(
      _.get(profile, "profile.personal_values", "")?.length || 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, basicProfile]);

  return (
    <>
      <Modal
        title="Basic Information"
        visible={basicProfile}
        className="profile-modal editable-profile-modal editable-modal info-wrapper basic-info-wrapper"
        onClose={() => { setBasicProfile(false); setCroppedProfileImage("") }}
      >
        <form
          onSubmit={(e) => {
            clearErrors();
            handleSubmit(saveBasicInfo)(e);
          }}
          className="edit-modal-wrapper"
        >
          <div className="info-wrapper basic-info-wrapper">
            <div className="user-info-row">
              <div className="user-img-col">
                <div className="edit-profile-image d-flex profile-img">
                  <Input
                    name="profile_image"
                    type="file"
                    onChange={(e: any) => onChangePicture(e)}
                    placeholder="profile"
                    inputRef={inputRef}
                    validationObj={errors}
                  />
                  <img
                    src={ProfileUploadIcon}
                    className="profile-upload-icon"
                    alt="Profile Upload Icon"
                    onClick={() => {
                      if (inputRef.current) {
                        inputRef.current?.click();
                      }
                    }}
                  />
                  {croppedProfileImage ? (
                    <Avatar
                      size="md"
                      src={croppedProfileImage}
                    ></Avatar>
                  ) : (
                    <Avatar
                      className="candidate-avatar"
                      size="cmd"
                      src={_.get(profile, "profile_image", "")}
                      type="candidate"
                    ></Avatar>
                  )
                  }
                </div>
              </div>
              <div className="user-info-col">
                <div className="d-flex user-name">
                  <div className="d-flex flex-col">
                    <EditableText
                      name="first_name"
                      inputClassName="editable-input"
                      control={control}
                      textProps={{
                        className: "m-0 font-lg",
                      }}
                      country="US"
                      error={errors.first_name}
                      rules={{
                        required: { value: true, message: "Name is required" },
                        maxLength: {
                          value: 20,
                          message:
                            "Name must be between 2 to 20 characters long",
                        },
                        minLength: {
                          value: 2,
                          message:
                            "Name must be between 2 to 20 characters long",
                        },
                      }}
                    />
                    <div className="d-flex mt-3">
                      <div className="form-group gender-group">
                        <Radio
                          name="gender"
                          control={control}
                          wrapperClassName="gender-radio-wrapper w-100"
                          labelClassName="gender-radio"
                          validationObj={errors}
                          onChange={(e: React.ChangeEvent<any>) => {
                            const pronounce = _.get(e, "target.value", "");
                            setCandidatePronoun(pronounce);
                          }}
                          onBlur={(e) => { }}
                          options={[
                            { label: "Female", value: "female" },
                            { label: "They", value: "they" },
                            { label: "Custom", value: "custom" },
                          ]}
                          inputRef={register("gender", {
                            required: {
                              value: true,
                              message: "Please select gender",
                            },
                          })}
                        />
                      </div>
                    </div>
                    <div className="d-flex">
                      {candidatePronoun === "custom" && (
                        <div className="d-flex gap-15px pronoun-fields">
                          <div>
                            <SelectNew
                              name="gender_pronoun"
                              placeholder="Select your pronoun"
                              className="select-box"
                              options={CUSTOM_GENDER_OPTIONS}
                              isSearchable={false}
                              validationObj={errors}
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Please select your pronoun",
                                },
                              }}
                            />
                          </div>
                          <div>
                            <Input
                              name="gender_optional"
                              size="small"
                              placeholder="Customize your pronoun"
                              validationObj={errors}
                              inputRef={register({
                                required: {
                                  value: true,
                                  message: "Customize your pronoun",
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    "Can't exceed more then 15 Character",
                                },
                              })}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  {/* <p className="about">A handy Lorem Ipsum Generator that.</p> */}
                  {/* <div className="access-level">
								<StarIcon />
								<p>Super Admin</p>
								</div> */}
                  <div>
                    <div className="d-flex email-phone">
                      {!_.isUndefined(errors.email) && (
                        <ReportProblemIcon className="problem-icon" />
                      )}
                      <strong>E-mail :</strong>
                      <p className="m-0">{_.get(profile, "email", "")}</p>
                    </div>
                    <div className="d-flex email-phone mt-3">
                      {!_.isUndefined(errors.phone) && (
                        <ReportProblemIcon className="problem-icon" />
                      )}
                      <strong className="phone-basic-lbl">Phone :</strong>
                      <EditableText
                        inputClassName="editable-input"
                        control={control}
                        name="contact_info"
                        country="us"
                        error={errors.contact_info}
                        rules={{
                          required: {
                            value: true,
                            message: "Phone is required",
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="user-job-info-row">
              <div className="user-job-inputs">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <div>
                      {/* <label className={"required"}>Job Location</label> */}
                      <AsyncSelect
                        name="city_id"
                        placeholder="Search City, State or country..."
                        externalLabel={{
                          label: "Location",
                          className: "required",
                        }}
                        onStartSearching={searchLocations}
                        validationObj={errors}
                        control={control}
                        noOptionsMessage="No matching locations found"
                        rules={{
                          required: {
                            value: true,
                            message: "Please select location from list",
                          },
                        }}
                      />
                    </div>

                    <div
                      className={`user-job-remote-row ${!_.isUndefined(profile?.errors?.profile?.open_to) &&
                        "error-radio"
                        }`}
                    >
                      <Checkbox
                        name="open_to_remote"
                        externalLabel={{
                          label: "Open to remote opportunities",
                        }}
                        color="primary"
                        inputRef={register()}
                        defaultChecked={
                          _.get(profile, "profile.open_to_remote", 0) === 1
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div>
                      <SelectNew
                        name="work_status"
                        externalLabel={{ label: "Work Status" }}
                        placeholder={_.get(profile, "profile.work_status", "")}
                        defaultValue={{
                          value: _.get(profile, "profile.work_status", ""),
                          label: _.get(profile, "profile.work_status", ""),
                        }}
                        value={_.get(profile, "profile.work_status", "")}
                        options={WorkStatusType}
                        validationObj={errors}
                        control={control}
                        required={true}
                        rules={{
                          required: {
                            value: true,
                            message: "Please select work status",
                          },
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="job-title">
                      <AsyncCreatableSelect
                        control={control}
                        validationObj={errors}
                        placeholder="Search Job..."
                        externalLabel={{
                          label: "Job Title",
                          className: "required",
                        }}
                        defaultValue={defaultJobTitle}
                        name="job_title"
                        onStartSearching={searchJobTitles}
                        noOptionsMessage="No matching job found"
                        rules={{
                          required: {
                            value: true,
                            message: "Please select job",
                          },
                        }}
                      />
                    </div>
                    <div
                      className={`user-job-remote-row ${!_.isUndefined(profile?.errors?.profile?.open_to) &&
                        "error-radio"
                        }`}
                    >
                      <Checkbox
                        name="open_to_other"
                        externalLabel={{ label: "Open to other opportunities" }}
                        color="primary"
                        inputRef={register()}
                        defaultChecked={
                          _.get(profile, "profile.open_to_other", 0) === 1
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <div className="user-pv-row">
                  <Input
                    name="personal_values"
                    placeholder="Personal values are stable, long-lasting beliefs about what is important to you."
                    multiline={true}
                    validationObj={errors}
                    defaultValue={_.get(profile, "profile.personal_values", "")}
                    inputRef={register({
                      maxLength: {
                        value: personalValueLimit,
                        message: `Please enter between 2 to ${personalValueLimit} characters long`,
                      },
                      minLength: {
                        value: 2,
                        message: `Please enter between 2 to ${personalValueLimit} characters long`,
                      },
                      required: {
                        value: true,
                        message: "Please enter about your personal values",
                      },
                    })}
                    externalLabel={{ label: "Personal Values" }}
                    countValue={personalValueLength}
                    showCount
                    maxLimit={personalValueLimit}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="d-flex justify-content-center mt-30">
            <Button
              type="submit"
              color="primary"
              className="submit-button text-uppercase"
              loading={loading}
            >
              Save
            </Button>
            <Button
              variant="text"
              color="secondary"
              className="cancel-button text-uppercase"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal >
      <ProfileImageCrop
        visible={showCropImage}
        cropImage={cropImage}
        onClose={() => setShowCropImage(false)}
        onCrop={(data) => handleCropImage(data)}
      />
    </>
  );
}

export default EditBasicInformationModal;
