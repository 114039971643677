import React, { useState, useEffect, ChangeEvent } from "react";
import httpRequest from "../../utils/httpRequest";
import API_URLS from "../../utils/apiUrls";
import {
  Grid,
  IconButton, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Tooltip
} from "@material-ui/core";
import { Button, ConfirmDialog, FlashMessage, Input, Modal, Spinner } from "../../components/common";
import _ from "lodash";
import Scrollbars from "react-custom-scrollbars";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { useForm } from "react-hook-form";
import copyToClipboard from "copy-to-clipboard";
import { ReactComponent as CopyIcon } from "../../assets/svg/copy-icon.svg";
import Pagination from "@material-ui/lab/Pagination";

interface DeveloperApiType {
  id: number;
  created_by: number;
  api_name: string;
  expired_date: string;
  api_key: string;
  created_at: string;
}

interface Column {
  id: keyof DeveloperApiType | "actions";
  label: string;
  minWidth?: number;
  align?: "right" | "center";
  format?: (value: any) => any;
}

function DeveloperApi() {
  const [developerApiData, setDeveloperApiData] = useState<DeveloperApiType[]>([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteApiId, setDeleteApiId] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({});


  const {
    register, handleSubmit, errors, reset,
  } = useForm();
  const currentDate = new Date();
  const minDate = new Date();
  minDate.setDate(currentDate.getDate() + 2);

  const listDeveloperApi = async () => {
    try {
      setApiLoading(true);
      const response = await httpRequest().get(`${API_URLS.developerApiList}/?page=${page}`);
      setPagination({
        current:
          _.get(response, "data.pagination.page", 0) === 0
            ? 1
            : _.get(response, "data.pagination.page", 0),
        total: _.get(response, "data.pagination.totalPages", 0),
        pageSize: _.get(response, "data.pagination.pageSize", 10),
        totalRecords: _.get(response, "data.pagination.pageRecords", 0),
        totalResult: _.get(response, "data.pagination.totalResult", 0),
      })

      const data = response.data.data.rows.map((e) => {
        const createdName = e.user.first_name + " " + e.user.last_name || '';
        return {
          ...e,
          created_by: createdName
        }
      });

      if (data) {
        setDeveloperApiData(data);
      }
      setApiLoading(false);
    } catch (error) {
      setApiLoading(false);
      console.log("Error fetching API data", error);
    }
  };

  useEffect(() => {
    listDeveloperApi();
    // eslint-disable-next-line
  }, [page]);

  const columns: Column[] = [
    { id: "api_name", label: "Api Name", minWidth: 170 },
    { id: "api_key", label: "Api Key", minWidth: 100 },
    { id: "created_by", label: "Created by", minWidth: 100 },
    { id: "expired_date", label: "Expired Date", minWidth: 80 },
    { id: "actions", label: "Actions", minWidth: 80, align: "center" },
  ];

  const handleDelete = (id: any) => {
    setDeleteModal(true);
    setDeleteApiId(id);
  };

  const deleteApi = async (apiId: any) => {
    try {
      setApiLoading(true);
      const response = await httpRequest().delete(API_URLS.removeDeveloperApi(apiId));
      if (response.status === 200) {
        listDeveloperApi()
      }
      setApiLoading(false);
    } catch (error) {
      setApiLoading(false);
      FlashMessage(error?.message, "error");
    }
  };

  const handleDeleteApi = () => {
    deleteApi(deleteApiId);
    setDeleteModal(false);
    setDeleteApiId("");
  };

  const handleClose = () => {
    setOpen(false);
    reset();
    setSelectedDate(null)

  };

  const GenerateApi = async (formPayload: any) => {
    const { api_name, expired_date } = formPayload;

    const formData = new FormData();
    formData.append("api_name", api_name);
    formData.append("expired_date", expired_date);

    try {
      const response = await httpRequest().post(API_URLS.generateDeveloperApi, formData);
      if (response.data) {
        listDeveloperApi();
        setOpen(false);
        reset();
        setSelectedDate(null);
      }
    } catch (error) {
      console.log("Error generating API", error);
    }
  };

  const _copyProfileLink = (apiKey: string) => {
    copyToClipboard(apiKey);
    FlashMessage("Copied profile link");
  };

  const maskApiKey = (apiKey: string) => {
    // Show last 4 characters, mask the rest
    return `${apiKey.slice(0, 6).replace(/./g, 'x')}${apiKey.slice(-6)}`;
  };

  const handlePaginationChange = (
    event: ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };
  return (
    <>
      <div className="custom-tab-wrapper">
        <div className="custom-tab-left">
          <ul className="tab-menu company-tabs">
            <li>
              <Button className="active">
                List of APIs
              </Button>
            </li>
          </ul>
        </div>
        <div className="custom-tab-right">
          <Button className="text-uppercase" onClick={() => setOpen(true)}>
            Add API
          </Button>
        </div>
      </div>

      <div className="developer-api-list">
        <Paper className="paper-wrapper">
          <Spinner visible={apiLoading} loadingTip="">
            {_.isEmpty(developerApiData) ? (
              <div>No data found</div>
            ) : (
              <Scrollbars renderThumbHorizontal={() => <div />}>
                <TableContainer className="api-list-wrapper">
                  <Table stickyHeader aria-label="API listing table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {developerApiData.map((row) => (
                        <TableRow hover key={row.id}>
                          {columns.map((column) => {
                            const value = row[column.id as keyof DeveloperApiType];
                            if (column.id === "api_key") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {maskApiKey(value as string)}  {/* Mask the API key */}
                                </TableCell>
                              );
                            }

                            return column.id === "actions" ? (
                              <TableCell key="actions" align="center">
                                <Tooltip title="Copy" placement="top" arrow>
                                  <IconButton className="copy-button" aria-label="fileCopy" onClick={(e) => _copyProfileLink(row.api_key)}>
                                    <CopyIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete" placement="top" arrow>

                                  <IconButton onClick={() => handleDelete(row.id)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>

                              </TableCell>
                            ) : (
                              <TableCell key={column.id} align={column.align}>
                                {column.format ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbars>
            )}
          </Spinner>
        </Paper>
        <Grid container justify="center">
          {!_.isEmpty(developerApiData) && (
            <Pagination
              count={_.get(pagination, "total", 0)}
              defaultPage={page}
              variant="outlined"
              color="secondary"
              className="jobs-pagination"
              onChange={(e, value) => handlePaginationChange(e, value)}
            />
          )}
        </Grid>
      </div>

      <Modal
        visible={open}
        size="medium"
        title="Generate Developer API"
        onClose={handleClose}
        className="developer-api-modal"
      >
        <form noValidate onSubmit={handleSubmit(GenerateApi)} >
          <Input
            name="api_name"
            placeholder="Enter API name"
            validationObj={errors}
            inputRef={register({ required: "Please enter API name" })}
          />

          <div className="Date-wrapper">
            <p >Expired Date</p>
            <Input
              name="expired_date"
              type="date"
              value={
                selectedDate ? selectedDate.toISOString().slice(0, 10) : ""
              }
              onChange={(e) => {
                const selected = new Date(e.target.value);
                if (selected >= minDate) {
                  setSelectedDate(selected);
                } else {
                  setSelectedDate(null)
                }
              }}
              inputRef={register({
                validate: (value) => {
                  const selected = new Date(value);
                  if (selected < minDate) {
                    return "The selected date cannot be in the past.";
                  }
                  return true;
                },
              })}
              validationObj={errors}
            />
          </div>

          <div className="d-flex justify-content-center mt-30">
            <Button
              type="submit"
              color="primary"
              className="submit-button text-uppercase"
            >
              Generate
            </Button>
            <Button
              variant="text"
              color="secondary"
              className="cancel-button text-uppercase"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal>

      <ConfirmDialog
        visible={deleteModal}
        bodyText="Are you sure you want to delete this API?"
        title="Confirm"
        confirmText="Delete"
        onConfirm={handleDeleteApi}
        onCancel={() => setDeleteModal(false)}
      />
    </>
  );
}

export default DeveloperApi;
