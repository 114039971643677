import React from "react";
import { useSelector } from "react-redux";
import { Button } from '../../components/common';
import { getCurrentTab } from "../../utils/helper";
import { isEmpty, isUndefined } from 'lodash';
import ViewIcon from '@material-ui/icons/Visibility';

const _ = { isEmpty, isUndefined };
interface ActionProps {
	handleClick: (type: string, text?: string) => any;
}

const Actions = (props: ActionProps) => {

	const { handleClick } = props;
	const { innerTab } = useSelector(({ company }: any) => company);
	const currentSubTab = getCurrentTab(props, true);
	const activeTab = _.isEmpty(innerTab) ? _.isUndefined(currentSubTab) ? 'People' : currentSubTab : innerTab;
	const checktab = activeTab.toLowerCase();

	return (
		<div className='custom-tab-wrapper'>
			<div className='custom-tab-left'>
				<ul className='tab-menu'>
					<li>
						<Button className={`${checktab === 'all' ? 'active' : ''}`} onClick={() => handleClick('All')}> Home </Button>
					</li>
					<li>
						<Button className={`${checktab === 'people' ? 'active' : ''}`} onClick={() => handleClick('People')}> People </Button>
					</li>
					{/* <li>
            <Button className={`${checktab === 'jobs' ? 'active' : ''}`} onClick={() => handleClick('Jobs')}> Jobs </Button>
          </li>
          <li>
            <Button className={`${checktab === 'news' ? 'active' : ''}`} onClick={() => handleClick('News')}> News </Button>
          </li>
          <li>
            <Button className={`${checktab === 'events' ? 'active' : ''}`} onClick={() => handleClick('Events')}> Events </Button>
          </li> */}
				</ul>
			</div>
			<div className='custom-tab-right'>
				<Button startIcon={<ViewIcon />} onClick={() => handleClick('Preview')} className={'preview-btn primary-btn text-uppercase'}>Preview</Button>
				<Button onClick={() => handleClick('Add')} color="secondary" className={'custom-tab-save-btn save-button text-uppercase'}>Save</Button>
				<Button onClick={() => handleClick('Publish')} className="publish-button text-uppercase">Publish</Button>
			</div>
		</div>
	);
};

export default Actions;
