import { Card, Grid, Paper, Tooltip } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FlashMessage } from "../../../components/common";
import schedulerServices from "../../../services/scheduler.services.ts";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useHistory, useLocation, useParams } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";

const List = () => {
  const [eventTypes, setEventTypes] = useState<Array<object>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { pathname } = useLocation();
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    fetchEventTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEventTypes = async () => {
    setLoading(true);
    try {
      const result = await schedulerServices.getAllEventTypesBySlug(
        pathname.split("/")[2] || ""
      );
      if (result.flag) {
        setEventTypes(result.data);
      } else {
        FlashMessage(result.message, "error");
      }
    } catch (error) {
      FlashMessage(error?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const selectCard = (row) => {
    const link = _.get(params, 'candidateSlug', '') + "/" + _.get(row, "event_link", "");
    history.push(`${link}`);
  };

  const userCard = (row, index) => {
    return (
      <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
        <Paper
          onClick={(e) => {
            selectCard(row);
          }}
        >
          <Grid item className="scheduler-item">
            <div className="content">
              <div className="event-title">
                <div
                  className="color-circle"
                  style={{ backgroundColor: _.get(row, "event_color", "") }}
                ></div>
                <Tooltip title={row.event_name} placement="top" arrow>
                  <span>{row.event_name}</span>
                </Tooltip>
              </div>
              <div><strong>Duration:</strong> {row.duration} Minutes</div>
              {row?.description && (
                <div className="event-description">{row.description}</div>
              )}
              <ArrowForwardIcon />
            </div>
          </Grid>
        </Paper>
      </Grid>
    );
  };

  const userCardSkeleton = (index) => {
    return (
      <Grid item xs={12} sm={6} md={6} lg={4} key={index} >
        <Paper>
          <Grid item className="scheduler-item">
            <div className="content">
              <div className="event-title">
                <div
                  className="color-circle"
                > <Skeleton variant="circle" width={40} height={40} /></div>
                <Tooltip title placement="top" arrow>
                  <span> <Skeleton variant="text" width={100} /></span>
                </Tooltip>
              </div>
              <div> <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={200} />
              </div>
            </div>
          </Grid>
        </Paper>
      </Grid>
    );
  };



  return (
    <Card className="main-card">

      {loading === true && (
        <Grid container spacing={3}>
          {[1, 2, 3].map((index) => userCardSkeleton(index))}
        </Grid>
      )}

      {loading === false && eventTypes.length === 0 && (
        <Card className="card-no-data">No Events</Card>
      )}

      {loading === false && eventTypes.length > 0 && (
        <Grid container spacing={3}>
          {eventTypes.map((row, index) => userCard(row, index))}
        </Grid>
      )}
    </Card>
  );
};

export default List;
