import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import SearchIcon from '@material-ui/icons/Search';
import { openUrlInNewTab } from "../../../utils/helper";
import { isEmpty, isUndefined, get } from "lodash";
import { CircularProgress } from "@material-ui/core";
import companyPublicView from "../../../services/companyPublicView.services";
import { companyJobsList } from "../../../reducers/companyPublicView/companyPublicView.reducer";
import appRoutes from "../../../routes/app.routes";
import { Skeleton } from "@material-ui/lab";
import NotFoundPage from "../../../components/common/NotFoundPage";
import Avatar from "../../../components/common/AvtarImage";
import {
  formatDate
} from "../../../utils/helper";
const _ = { isEmpty, isUndefined, get };

const Jobs = () => {
  const { companyPublicProfile, companyJobs } = useSelector(
    ({ companyPublicView }: any) => companyPublicView.companyPublicView
  );
  const [jobs, setJobs] = useState<any>([]);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  console.log('jobs', jobs);
  const jobsCard = (row) => {
    console.log("row", row);
    return (
      <div onClick={() => {
        openUrlInNewTab(
          appRoutes.jobDetail.generatePath(_.get(row, "slug", ""))
        );
      }} className="job-item">
        <div className="job-item-inner">
          <div className="job-item-header">
            <div className="avatar">
              <Avatar
                src={_.get(
                  companyPublicProfile,
                  "companyProfile.company_logo",
                  ""
                )}
                size="xsm"
                type="job"
              />
            </div>
            <div className="meta">
              <h4>{`${_.get(row, "title", "")}`}</h4>
              <div>
                <p className="meta-location">{_.get(row, "location", "")}</p>
                <p className="meta-company">{_.get(row, "company_name", "")}</p>
              </div>
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: _.get(row, "description", ""),
            }}
            className="job-item-content wysiwyg-data"
          ></div>
          <div className="job-item-footer">
            <p>Posted On: {formatDate(_.get(row, "updated_at", ""))}</p>
          </div>
        </div>
      </div>
    );
  };
  const dispatch = useDispatch();
  const fetchData = async () => {
    setBtnLoading(true);
    const jobs = await companyPublicView.getCompanyJobs({
      companyId: companyPublicProfile?.id,
      pageSize: pageSize,
      page: page,
    });
    setTotalCount(jobs?.pagination?.totalRecords);
    let jobsArray = jobs.data ? jobs?.data : [];
    const mergeJobs = [...companyJobs, ...jobsArray];
    setJobs(mergeJobs);
    setCount(mergeJobs.length);
    dispatch(companyJobsList(mergeJobs));
    setPage(page + 1);
    setBtnLoading(false);
  };
  useEffect(() => {
    setPageSize(20);
    fetchData();
    // eslint-disable-next-line
  }, [companyPublicProfile]);

  return !_.isEmpty(jobs) ? (
    <div>
      <div className="list-wrapper">
        {(jobs || []).map((row) => {
          return btnLoading ? (
            <Skeleton variant="rect" width={"100%"} height={195} />
          ) : (
            jobsCard(row)
          );
        })}
      </div>
      <div style={{ textAlign: "center" }}>
        <div>
          {btnLoading ? (
            <CircularProgress color="secondary" size={15} />
          ) : (
            <span
              className="span-link"
              hidden={count === totalCount}
              onClick={() => {
                fetchData();
              }}
            >
              SEE MORE
            </span>
          )}
        </div>
      </div>

    </div >
  ) : (
    <NotFoundPage />
  );
};

export default Jobs;
