import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Button, Input } from './../../components/common';
import { ReactComponent as Footerlogo } from "./../../assets/svg/footerlogo.svg";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import { Link } from "react-router-dom";
import appRoutes from "../../routes/app.routes";
import { FlashMessage } from "../../components/common";
import API_URLS from "../../utils/apiUrls";
import moment from 'moment';
import { openHttpRequest } from "../../utils/httpRequest"
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

type Inputs = {
	email: string;
};

export default function FullWidthGrid() {
	const { register, errors, handleSubmit, reset: resetForm } = useForm<Inputs>();
	const classes = useStyles();
	const [loader, setLoader] = useState<boolean>(false);
	const _handleSubscription = async (formdata: Inputs) => {
		setLoader(true);
		openHttpRequest().post(`${API_URLS.subscribe}`, { email: formdata.email })
			.then(res => {
				FlashMessage(res.data.message);
				setLoader(false);
				resetForm();
			})
			.catch(err => {
				FlashMessage(err.response.data.errors.email, "error");
				setLoader(false)
			}
			);
	}
	return (
		<Container fixed>
			<div className={`footer-wrap ${classes.root}`}>
				<Grid container spacing={3}>
					<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
						<div className="f-column">
							<Footerlogo />
							<ul className="social-icon">
								<li>
									<a href="https://www.facebook.com/employher/" target="_blank" rel="noreferrer" className="fb"> <FacebookIcon /> </a>
								</li>
								<li>
									<a href="https://twitter.com/employher" target="_blank" rel="noreferrer" className="tw"> <TwitterIcon /> </a>
								</li>
								<li>
									<a href="https://www.instagram.com/employherinc/" target="_blank" rel="noreferrer" className="ig"> <InstagramIcon /> </a>
								</li>
							</ul>
						</div>
					</Grid>
					<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
						<div className="f-column">

						</div>
					</Grid>
					<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
						<div className="f-column">

						</div>
					</Grid>
					<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
						<div className="f-column">
							<h5>Newsletter</h5>
							<form onSubmit={handleSubmit(_handleSubscription)}>
								<div className="">
									<Input
										name="email"
										externalLabel={{ label: "" }}
										placeholder="Your Email"
										validationObj={errors}
										inputRef={register({
											required: {
												value: true,
												message: "Please enter email address",
											},
											pattern: {
												value: /\S+@\S+\.\S+/,
												message: "Enter valid email address",
											},
										})}
									/>
									<Button type="submit" loading={loader}>Subscribe</Button>
								</div>
							</form>
						</div>
					</Grid>
				</Grid>
			</div>
			<div className="footer-mini">
				<div className="f-mini-left">
					<p>Copyright &copy; {moment().format('YYYY')} employ<span>HER</span>. All Rights Reserved.</p>
				</div>
				<div className="f-mini-right">
					<Link to={appRoutes.privacyPolicy.generatePath()} className="tu-link link">Terms of Use</Link>
					<Link to={appRoutes.privacyPolicy.generatePath()} className="pp-link link">Privacy Policy</Link>
				</div>

			</div>
		</Container>
	);
}
