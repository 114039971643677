import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmDialog } from "../../../components/common";
import FlashMessage from "../../../components/common/FlashMessage";
import { rootReducersState } from "../../../reducers";
import { updateCandidateConnectionRequest } from "../../../reducers/SearchAndConnect/searchAndConnect.reducer";
import { ReducerFlag } from "../../../types/reducer.types";
import { RESEND_CONNECTION_REQUEST_TIME_LIMIT } from "../../../utils/appConstants";

const CancelRequestModal = (props) => {
  const dispatch = useDispatch();
  const { updateCandidateConnectionRequestFlag, message } = useSelector(
    ({ search }: rootReducersState) => search.search
  );
  const _handleCancel = () => {
    dispatch(
      updateCandidateConnectionRequest({
        id: _.get(props, "data.id"),
        type: "cancelled",
      })
    );
  };

  useEffect(() => {
    if (
      [ReducerFlag.SUCCESS, ReducerFlag.FAILED].includes(
        updateCandidateConnectionRequestFlag
      )
    ) {
      if (updateCandidateConnectionRequestFlag === ReducerFlag.SUCCESS) {
        props.handleOnClose();
      } else if (updateCandidateConnectionRequestFlag === ReducerFlag.FAILED) {
        FlashMessage(message, "error");
      }
    }
    // eslint-disable-next-line
  }, [updateCandidateConnectionRequestFlag]);

  return (
    <ConfirmDialog
      visible={props.status}
      title={`Cancel sent request`}
      onCancel={props.handleOnClose}
      bodyText={`Request to cancel will not allow to reconnect with this person for up to ${RESEND_CONNECTION_REQUEST_TIME_LIMIT} hours.`}
      onConfirm={_handleCancel}
      confirmText="Okay"
    />
  );
};

export default CancelRequestModal;
