import React, { useState } from "react";
import { Button } from "../../components/common";
import Appointments from "./components/Appointments";
import WorkingHours from "./components/WorkingHours";
import EventTypes from "./components/EventTypes";

const Scheduler = () => {
  const [checkTab, setCheckTab] = useState("appointments");

  const handleButtonClick = (type: string) => {
    switch (type) {
      case "appointments":
        setCheckTab(type);
        break;
      case "workingHours":
        setCheckTab(type);
        break;
      case "eventTypes":
        setCheckTab(type);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="main-container">
        <div className="event-container">
          <div className="custom-tab-wrapper">
            <div className="custom-tab-left">
              <ul className="tab-menu">
                <li>
                  <Button
                    className={`${checkTab === "appointments" ? "active" : ""}`}
                    onClick={() => handleButtonClick("appointments")}
                  >
                    {" "}
                    Appointments{" "}
                  </Button>
                </li>
                <li>
                  <Button
                    className={`${checkTab === "workingHours" ? "active" : ""}`}
                    onClick={() => handleButtonClick("workingHours")}
                  >
                    {" "}
                    Working Hours{" "}
                  </Button>
                </li>
                <li>
                  <Button
                    className={`${checkTab === "eventTypes" ? "active" : ""}`}
                    onClick={() => handleButtonClick("eventTypes")}
                  >
                    {" "}
                    Event Types{" "}
                  </Button>
                </li>
              </ul>
            </div>
          </div>
          {checkTab === "appointments" && <Appointments />}
          {checkTab === "workingHours" && <WorkingHours />}
          {checkTab === "eventTypes" && <EventTypes />}
        </div>
      </div>
    </div>
  );
};
export default Scheduler;
