import React from "react";
import { Link, useLocation } from "react-router-dom";
import appRoutes from "../../routes/app.routes";

const CompanySidebar = (prop: any) => {
  const { pathname } = useLocation();
  const currentSubTab = pathname.split("/")[2]
    ? pathname.split("/")[2]
    : "profile-visibility";

  return (
    <div
      className={`sidebar-container setting-container sidebar-wrap tab-sidebar-wrap ${currentSubTab === "security-login" ? "security-sidebar-wrap" : ""
        }`}
      style={{ position: "inherit" }}
    >
      <div className="setting-title">
        <h2>Settings</h2>
      </div>
      <div
        className={`sidebar-menu-wrap ${currentSubTab === "notifications"
          ? "notification-sidebar"
          : currentSubTab === "security-login"
            ? "security-sidebar"
            : ""
          }`}
      >
        <ul className="setting-list">
          <li>
            <Link
              key="link_dashboard"
              to={appRoutes.candidateProfileSettings.generatePath(
                "profile-visibility"
              )}
              className={`dashboard-menu ${currentSubTab === "profile-visibility" ? "active" : ""
                }`}
            >
              <span>Profile and Visibility</span>
            </Link>
          </li>
          <li>
            <Link
              key="link_candidates"
              to={appRoutes.candidateProfileSettings.generatePath(
                "security-login"
              )}
              className={`candidate-menu ${currentSubTab === "security-login" ? "active" : ""
                }`}
            >
              <span>Security and Login</span>
            </Link>
          </li>
          <li>
            <Link
              key="link_candidates"
              to={appRoutes.candidateProfileSettings.generatePath(
                "notifications"
              )}
              className={`candidate-menu ${currentSubTab === "notifications" ? "active" : ""
                }`}
            >
              <span>Notifications</span>
            </Link>
          </li>
          <li>
            <Link
              key="link_candidates"
              to={appRoutes.candidateProfileSettings.generatePath(
                "account-preferences"
              )}
              className={`candidate-menu ${currentSubTab === "account-preferences" ? "active" : ""
                }`}
            >
              <span>Account Preferences</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CompanySidebar;
