import { createSlice } from "@reduxjs/toolkit";

type initialStateInterface = {
  video: boolean;
  professional_passions: boolean;
  work_experience: boolean;
  projects: boolean;
  skills: boolean;
  education: boolean;
  messageSendType: string;
};

const initialState: initialStateInterface = {
  video: true,
  professional_passions: false,
  work_experience: false,
  projects: false,
  skills: false,
  education: false,
  messageSendType: "",
};

const candidateProfilePersists = createSlice({
  name: "candidateProfilePersists",
  initialState: initialState,
  reducers: {
    setCandidateProfileAccordion: (state, payload) => {
      return { ...state, ...payload.payload };
    },
  },
});

const messageSendType = createSlice({
  name: "messageSendType",
  initialState: "clickSend",
  reducers: {
    setMessageSendType: (state, payload) => {
      return payload.payload;
    },
  },
});

// Export actions
export const { setCandidateProfileAccordion, setMessageSendType } = {
  ...candidateProfilePersists.actions,
  ...messageSendType.actions,
};

// Export reducers
export default candidateProfilePersists.reducer;
