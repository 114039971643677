import React, { useState } from "react";
import Image from "../../../components/common/Image";
import { stripHTMLTags } from "../../../utils/helper";
import ReactImageVideoLightbox from "react-image-video-lightbox";
// import "react-image-lightbox/style.css";

import _, { toInteger } from "lodash";
import appRoutes from "../../../routes/app.routes";
import { openUrlInNewTab } from "../../../utils/helper";
import Avatar from "../../../components/common/AvtarImage";
import ReactPlayer from "react-player";



interface FeedList {
  data: any;
}

const FeedListContent = ({ data }: FeedList) => {
  const [readMore, setReadMore] = useState<boolean>(false);
  const [media, setMedia] = useState<{
    url: string;
    type: string;
  } | undefined>();
  const postText =
    data?.content || data?.post_text
      ? String(data?.content || data?.post_text)
      : "";
  const stripHTMLText = stripHTMLTags(postText);
  // for image lightbox
  const [index, setIndex] = useState(-1);
  const readTime = toInteger(data.content?.length / 250)
  const handleReadTime = (readTime) => {
    if (readTime < 1) {
      return 1
    } else {
      return readTime
    }
  }

  const handleClick = (index: number) => setIndex(index);
  const handleClose = () => setMedia(undefined);
  const postType = _.get(data, 'post_type')
  const parseTextWithAnchorTags = (text) => {
    const mentionHashTag =
      /<span class="mention"[^>]*data-denotation-char="#".*?data-value="([^"]+)".*?><span class="ql-mention-denotation-char">#<\/span>(.*?)<\/span>/g;
    const hashtagRegex = /#(\w+)/g;

    return text
      .replace(mentionHashTag, (match, hashtagValue, hashtagText) => {
        return `<a href=${appRoutes.Feed.path}/hash-tag/${hashtagValue} target="_blank">#${hashtagText}</a>`;
      })
      .replace(
        hashtagRegex,
        `<a href=${appRoutes.Feed.path}/hash-tag/$1 target="_blank">#$1</a>`
      );
  };

  return (
    <div
      className={`feed-wrapper ${data.content && "feed-details-content"} 
		 ${data.post_media?.length > 1 ? "feed-details-img" : ""} 
		 ${data.post_media?.length === 0 ? "no-image" : ""}`}
    >
      {!_.isEmpty(postText) && (
        <>
          <div>
            {stripHTMLText.length > 100 && !readMore ? (
              <div
                className="feed-content-info wysiwyg-data"
                dangerouslySetInnerHTML={{
                  __html:
                    parseTextWithAnchorTags(postText.substring(0, 500)) + "...",
                }}
              />
            ) : (
              <div
                className="feed-content-info wysiwyg-data"
                dangerouslySetInnerHTML={{
                  __html: parseTextWithAnchorTags(postText),
                }}
              />
            )}
            {stripHTMLText.length > 100 && (
              <span
                className="span-link d-block mt-2"
                onClick={() => setReadMore((prevState) => !prevState)}
              >
                Read {readMore ? "less" : "more"}
              </span>
            )}
            {postType === 1 && (
              <div className="list-article">
                <span onClick={() => {
                  // history.push(appRoutes.viewArticle.path);
                  openUrlInNewTab(
                    appRoutes.viewArticle.generatePath(data?.slug)
                  );
                }
                }>
                  <div>
                    <div className="article-image">
                      {data?.cover_image?.length > 0 && (
                        <Image src={data.cover_image} alt="media image" />
                      )}
                    </div>
                  </div>

                  <div className="article-short-description">
                    <div className="article-title">{data.title}</div>
                    <div  >{data.user.first_name + " " + data.user.last_name + (`. ${handleReadTime(readTime)} min read`)}</div>
                  </div>
                </span>
              </div>
            )}

          </div>
        </>
      )}

      {data.post_media?.length > 0 && (
        <div className="feed-img-wrapper">
          {data.post_media.map((image, i) => {
            return (
              <div
                onClick={() => {
                  handleClick(i);
                  setMedia(data.post_media.map(mediaItem => ({
                    url: mediaItem.media_thumbnail,
                    type: mediaItem.media_type === 'image' ? 'photo' : mediaItem.media_type
                  })));
                  // setToggler(!toggler) 
                }}
                className="img-wrapper"
                key={i}
              >
                {image.media_type === 'image' ? (

                  <Avatar
                    type="post"
                    src={image.media_thumbnail || data.post_media[i]}
                  />
                ) : (
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    url={image.media_thumbnail}
                    playing={false}
                    controls={true}
                  />
                )}

              </div>
            );
          })}
        </div>
      )
      }
      {!!media && (
        /* @ts-ignore */
        <div className="media-preview">
          <ReactImageVideoLightbox
            data={media}
            startIndex={index}
            onCloseCallback={handleClose}
          />
        </div>
      )
      }
    </div >
  );
};

export default FeedListContent;
