import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
interface PhoneProps {
	country: string;
	value: any;
	autoFocus?: boolean;
	onChange?(value: any, data: {}): void;
	onBlur?(event: any, data: {}): void;
}
function PhoneNumberInput(props: PhoneProps) {
	return <>
		<PhoneInput
			onChange={props.onChange}
			onBlur={props.onBlur}
			country={props.country}
			value={"+" + props.value}
			prefix="+"
			inputProps={{
				autoFocus: true
			}}
		/>
	</>;
}

export default PhoneNumberInput;
