import { createSlice } from "@reduxjs/toolkit";
// Initial state for reducer
const initialState = {
  companyPublicProfile: {},
  companyJobs: [],
  companyFeeds: []
};

const slice = createSlice({
  name: "companyPublicView",
  initialState: initialState,
  reducers: {
    companyPublicProfile: (state, { payload }) => {
      return {
        ...state,
        companyPublicProfile: payload,
      };
    },
    companyJobsList: (state, { payload }) => {
      return {
        ...state,
        companyJobs: payload,
      };
    },
    companyFeedList: (state, { payload }) => {
      return {
        ...state,
        companyFeeds: payload,
      };
    },
  },
});

// Actions
export const {
  companyPublicProfile,
  companyJobsList,
  companyFeedList
} = slice.actions;

// Reducers
export default slice.reducer;
