export const validateFileSize = (
  currentFileSize: any,
  maxFileSizeInMB: any
) => {
  if (currentFileSize > maxFileSizeInMB * 1000 * 1000) {
    return `Max file size must be less than ${maxFileSizeInMB} mb`;
  } else {
    return "";
  }
};
