import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Button } from "../../components/common";

import { ReactComponent as NotFound } from "../../assets/svg/not-found-404.svg";
import appRoutes from "../../routes/app.routes";

const NotFoundError = () => {
  return (
    <div className="max-container">
      <div className="notFound-error-wrapper">
        <Grid
          item
          xs={12}
          container
          spacing={3}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className="notFound-error-image">
              <NotFound />
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className="notFound-error-text">
              <h3 className="oops"> Oops! </h3>
              <p className="notFound-error-message">
                {" "}
                We cannot find the page you are looking for.
              </p>
              <Link to={appRoutes.home.path} className="notFound-link">
                <Button className="notFound-btn">Go to Home</Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default NotFoundError;
