import axios from "axios";
import _ from "lodash";
import store from "../store";
import { sessionOut } from "../reducers/auth/session.reducer";
import { extractCompanySlug } from "./helper";

/**
 * Export a default instance for requesting an APIs
 *
 * This instance includes auth Header
 */
const httpRequest = () => {
  const companySlug = extractCompanySlug();
  const storeData = store.getState();
  const token = _.get(storeData, "session.token", null);

  const httpRequest = axios.create({
    baseURL: process.env.REACT_APP_API_END_POINT,
    timeout: 90000, // 1 minute
    headers: {
      Authorization: token,
      "x-company": companySlug,
    },
  });

  // Watch every response received from the server
  httpRequest.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        store.dispatch(sessionOut());
      }
      return Promise.reject(error);
    }
  );

  return httpRequest;
};

export default httpRequest;

/**
 * Export an Axios instance
 *
 * This instance does not include auth Header
 * This will be used to call open APIs which does not accept Auth Header
 */
export const openHttpRequest = () =>
  axios.create({
    baseURL: process.env.REACT_APP_API_END_POINT,
    timeout: 60000, // 1 minutes
  });
