import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getJobRequest, jobDetailReset } from "../../reducers/job/jobs.reducer";
import JobDetailHeader from "./components/JobDetailHeader";
import NotFoundPage from "../../components/ErrorPages/NotFound";
import { Skeleton } from "@material-ui/lab";
import { rootReducersState } from "../../reducers";
import appRoutes from "../../routes/app.routes";

function JobDetail({
  match: {
    params: { slug },
  },
}) {
  const jobApplicationsLoading = useSelector((state: rootReducersState) =>
    _.get(state, "candidate.candidates.jobApplications.loading", false)
  );
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const token = _.get(sessionReducer, "token", "");
  const dispatch = useDispatch();
  const {
    jobs: { detailLoading, detail },
  } = useSelector(({ job }: rootReducersState) => job);

  useEffect(() => {
    dispatch(getJobRequest({ jobSlug: slug, isPublic: true }));
    // On umount reset job detail
    return () => {
      dispatch(jobDetailReset());
    };
  }, [dispatch, slug]);

  if (_.isEmpty(detail) && !detailLoading)
    return <NotFoundPage redirectTo={appRoutes.candidateJobSearch.path} />;

  return (
    <div className="max-container">
      <div className="job-detail-wrapper">
        <div className="job-detail-content">
          <JobDetailHeader
            loading={detailLoading || jobApplicationsLoading}
            {...detail}
            token={token}
            slug={slug}
          />
          <span className="separator" />
          <div>
            {detailLoading || jobApplicationsLoading ? (
              <div className="loading">
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="40%" />
                <Skeleton variant="text" width="20%" />
              </div>
            ) : (
              <div
                className="wysiwyg-data"
                dangerouslySetInnerHTML={{
                  __html: _.get(detail, "description", ""),
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobDetail;
