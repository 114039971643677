/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Typography } from "@material-ui/core";
import { OpenInNew as OpenInNewIcon } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  formatDate,
  formatNumber,
  openUrlInNewTab,
} from "../../../utils/helper";
import Button from "../../../components/common/Button";
import { ReactComponent as Level } from "../images/level.svg";
import { ReactComponent as Department } from "../images/department.svg";
import { ReactComponent as Employment } from "../images/employment.svg";
import { ReactComponent as Salary } from "../images/salary.svg";
import { ReactComponent as Experience } from "../images/experience.svg";
import ProfileViewModal from "../../../components/Candidates/ProfileViewModal";
import { useDispatch, useSelector } from "react-redux";
import useSessionUser from "../../../hooks/useSessionUser";
import {
  applyJobReset,
  saveJobRequest,
} from "../../../reducers/candidate/candidate.reducer";
import { rootReducersState } from "../../../reducers";
import { ReducerFlag } from "../../../types/reducer.types";
import { jobDetailUpdate } from "../../../reducers/job/jobs.reducer";
import FlashMessage from "../../../components/common/FlashMessage";
import { REMOTE_CHOICE } from "../../../utils/appConstants";
import LinkSVG from "../../../assets/svg/LinkSVG";
import JobShareModal from "../../../components/ProfileView/components/JobShareModal";
import copyToClipboard from "copy-to-clipboard";
import appRoutes from "../../../routes/app.routes";
import Avatar from "../../../components/common/AvtarImage";
interface IJobDetailHeader {
  company_image: string;
  job_department_title: string;
  min_experience: string;
  max_experience: string;
  job_level_title: string;
  job_type_title: string;
  city: any;
  max_salary: number;
  min_salary: number;
  posted_on: string;
  salary_term: string;
  title: string;
  currency: string;
  loading?: boolean;
  applied?: number;
  saved?: number;
  token: string;
  slug: string;
  ats_link?: string;
}

const SKELETON_WIDTH = 350;

const JobDetailHeader: React.FC<IJobDetailHeader> = ({
  loading,
  token,
  company_image,
  city,
  posted_on,
  title,
  slug,
  applied,
  saved,
  ...rest
}) => {
  const atsLink = rest?.ats_link || "";
  const [openProfileViewModal, setOpenProfileViewModal] = useState(false);
  const [publicProfileLink, setPublicProfileLink] = useState("");
  const [profileShareModal, setProfileShareModal] = useState(false);
  const dispatch = useDispatch();
  const { IS_COMPANY } = useSessionUser();
  const companyName = _.get(rest, "companyProfile.company_name", "") || "";
  const { detail: jobDetails } = useSelector(
    ({ job }: rootReducersState) => job?.jobs
  );
  const {
    jobSavedFlagMessage,
    jobSavedFlag,
    jobSavedFlagLoading,
    jobSavedFlagData,
  } = useSelector(({ candidate }: rootReducersState) => candidate?.candidates);

  const INFO_ITEMS = useMemo(
    () => [
      // {
      //   id: 1,
      //   name: "Experience",
      //   icon: Experience,
      //   value:
      //     _.get(rest, "min_experience", "0") === "0" ||
      //     _.get(rest, "min_experience", "0") === "" ||
      //     _.get(rest, "max_experience", "0") === "0" ||
      //     _.get(rest, "max_experience", "0") === ""
      //       ? "HIDE"
      //       : `${_.get(rest, "min_experience", "")}-${_.get(
      //           rest,
      //           "max_experience",
      //           ""
      //         )} Years`,
      // },
      {
        id: 1,
        name: "Level",
        icon: Level,
        value: <p className="ml-nrml">{_.get(rest, "job_level_title", "-")}</p>,
      },
      {
        id: 2,
        name: "Department",
        icon: Department,
        value:
          (_.get(rest, "job_department_title", "HIDE") || "HIDE") === "HIDE" ? (
            "HIDE"
          ) : (
            <p className="ml-nrml">{_.get(rest, "job_department_title")}</p>
          ),
      },
      {
        id: 3,
        name: "Employment",
        icon: Employment,
        value: <p className="ml-nrml">{_.get(rest, "job_type_title", "-")}</p>,
      },
      {
        id: 4,
        name: "Support Visa",
        icon: Experience,
        value:
          (_.get(rest, "support_visa", null)) === null ? (
            "HIDE"
          ) : (
            <p className="ml-nrml">{_.get(rest, "support_visa") === 1 ? 'Visa support' : 'No visa support'}</p>
          ),
      },
      {
        id: 5,
        name: `Salary ${_.get(rest, "min_salary") || _.get(rest, "max_salary")
          ? rest.salary_term === "per_hour"
            ? " - Hourly"
            : rest.salary_term === "annual"
              ? " - Annual"
              : ""
          : ""
          }`,
        icon: Salary,
        value:
          !_.get(rest, "min_salary") || !_.get(rest, "max_salary") ? (
            <p className="ml-nrml">N/A</p>
          ) : (
            <p>
              {`${_.get(rest, "currency", "")}`} &nbsp;{" "}
              {`${formatNumber(
                Number(_.get(rest, "min_salary", 0))
              )} - ${formatNumber(Number(_.get(rest, "max_salary", 0)))}`}
            </p>
          ),
      },
    ],
    [rest]
  );

  // Check job application status, Success of Failed
  useEffect(() => {
    if (jobSavedFlag === ReducerFlag.SUCCESS) {
      dispatch(applyJobReset());

      // Saved applied job status
      dispatch(
        jobDetailUpdate({
          ...jobDetails,
          saved: _.get(jobSavedFlagData, "saved", null) || null,
        })
      );
    } else if (jobSavedFlag === ReducerFlag.FAILED) {
      FlashMessage(jobSavedFlagMessage, "error");
      dispatch(applyJobReset());
    }
  }, [jobSavedFlag]);

  const _handleSaveJob = () => {
    const jobId = _.get(rest, "id", 0);
    dispatch(saveJobRequest(jobId));
  };

  const shareProfile = () => {
    const link = appRoutes.jobDetail.generatePath(slug);
    setProfileShareModal(true);
    setPublicProfileLink(link);
  };
  const _copyProfileLink = () => {
    const link = appRoutes.jobDetail.generatePath(slug);
    copyToClipboard(link);
    FlashMessage("Copied profile link");
  };

  return (
    <div className="job-detail-header">
      <div className="upper">
        <div className="logo">
          {loading ? (
            <Skeleton variant="circle" />
          ) : (
            <Avatar
              src={company_image}
              type="job"
            />
          )}
        </div>
        <div className="content">
          <div>
            {loading ? (
              <Skeleton width={SKELETON_WIDTH} variant="text" />
            ) : (
              <Typography className="title" variant="h3">
                {title}
              </Typography>
            )}

            <div className="d-flex company-details">
              {loading ? (
                <Skeleton width={SKELETON_WIDTH / 2} />
              ) : (
                <>
                  <p className="location">
                    <span>
                      {city?.location}
                      <span className="remote">
                        (
                        {REMOTE_CHOICE.find(
                          (row) => row.value === _.get(rest, "is_remote", "1")
                        )?.label || "On-Site"}
                        )
                      </span>
                    </span>
                  </p>
                  <p className="name">
                    <span>{companyName}</span>
                  </p>
                </>
              )}
            </div>
            <div className="posted">
              {loading ? (
                <Skeleton width={SKELETON_WIDTH / 3} />
              ) : (
                <p>
                  <strong>Posted on: </strong>
                  {formatDate(posted_on)}
                </p>
              )}
            </div>
          </div>
          {!IS_COMPANY && (
            <div className="details-buttons-Wrapper">
              {loading ? (
                <Skeleton variant="rect" width={130} height={40} />
              ) : (
                token &&
                (atsLink ? (
                  <Button
                    onClick={() => openUrlInNewTab(atsLink)}
                    color={"primary"}
                    className="apply-now"
                    endIcon={<OpenInNewIcon />}
                  >
                    Apply Now
                  </Button>
                ) : (
                  <Button
                    disabled={applied === 1}
                    onClick={() => setOpenProfileViewModal(true)}
                    color={applied === 1 ? "none" : "primary"}
                    className="apply-now"
                  >
                    {applied === 1 ? "Applied" : "Apply Now"}
                  </Button>
                ))
              )}
              {loading ? (
                <Skeleton variant="rect" width={160} height={40} />
              ) : (
                token && (
                  <Button
                    onClick={() => _handleSaveJob()}
                    loading={jobSavedFlagLoading}
                    color="secondary"
                    className="save"
                    variant="outlined"
                  >
                    {saved === 1 ? "SAVED" : "SAVE"}
                  </Button>
                )
              )}
              {loading ? (
                <Skeleton variant="rect" width={160} height={40} />
              ) : (
                token && (
                  <span
                    className="share-profile cursor-pointer"
                    onClick={() => shareProfile()}
                  >
                    <LinkSVG />
                  </span>
                )
              )}
            </div>
          )}
        </div>
      </div>
      <div className="info">
        {INFO_ITEMS.filter((row) => row.value !== "HIDE").map(
          ({ id, name, icon: Icon, value }) => {
            return (
              <div key={id} className="item">
                <div>
                  {loading ? <Skeleton variant="circle" /> : <Icon />}
                  {loading ? <Skeleton variant="text" /> : name}
                </div>
                {loading ? <Skeleton variant="text" width={80} /> : value}
              </div>
            );
          }
        )}
      </div>
      <ProfileViewModal
        className="profile-view-modal"
        visible={openProfileViewModal}
        size="x-large"
        jobData={rest}
        onClose={() => setOpenProfileViewModal(false)}
      />
      <JobShareModal
        publicProfileLink={publicProfileLink}
        open={profileShareModal}
        onClose={() => setProfileShareModal(false)}
        copyProfileLink={_copyProfileLink}
      />
    </div>
  );
};

export default JobDetailHeader;
