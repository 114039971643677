import React, { useRef, useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  ProfileImageCrop,
  Input,
  FlashMessage,
  Spinner,
} from "../../../../components/common";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  uploadCompanyProfileImageRequest,
  uploadCompanyBannerImageRequest,
  uploadCompanyProfileImageReset,
  getCompanyDetailSuccess,
} from "../../../../reducers/company/companyProfile.reducer";
import { readFile } from "../../../../utils/cropImageHelper";
import { ImageMimeTypes } from "../../../../utils/appConstants";
import Image from "../../../../components/common/Image";
import { ProfileImageLayout } from "../../../../components/common/ProfileImageCrop";
import ProfileUploadIcon from "../../../../assets/images/cloud-upload-icon.png";
import PenSVG from "../../../../assets/svg/PenSVG";
import { validateFileSize } from "../../../../components/helpers/formValidation";
import { rootReducersState } from "../../../../reducers";
import Banner from "../../../../components/common/Banner";
import Avatar from "../../../../components/common/AvtarImage";

const CompanyBanner = () => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLDivElement>(null);
  const [layoutType, setLayoutType] =
    useState<ProfileImageLayout>("profileImage");
  const [bannerPath, setBannerPath] = useState("");
  const [picture, setPicData] = useState("");
  const [showCropImage, setShowCropImage] = useState(false);
  const [showCropImageBanner, setShowCropImageBanner] = useState(false);

  const [cropImage, setCropImage] = useState<any>("");
  const [fileName, setFileName] = useState("");
  const companyReducer = useSelector(
    ({ company }: rootReducersState) => company
  );
  const uploadBannerImageFlag = _.get(
    companyReducer,
    "companyProfile.uploadBannerImageFlag",
    null
  );
  const uploadProfileImageLoading = _.get(
    companyReducer,
    "companyProfile.uploadProfileImageLoading",
    null
  );
  const uploadProfileImageData = _.get(
    companyReducer,
    "companyProfile.uploadProfileImageData",
    null
  );

  const companyProfileData = _.get(companyReducer, "companyProfile.data", {});
  const uploadBannerImageLoading = _.get(
    companyReducer,
    "companyProfile.uploadBannerImageLoading",
    false
  );

  const companyDetail = _.get(companyProfileData, "companyProfile", {});
  const companyLogo = _.get(
    companyProfileData,
    "companyProfile.company_logo",
    ""
  );

  useEffect(() => {
    if (!_.isEmpty(companyDetail)) {
      const bannerImage = _.get(companyDetail, "banner_image", null);
      if (!_.isEmpty(bannerImage)) {
        setBannerPath(_.get(companyDetail, "banner_image", null));
      }
    }
  }, [companyDetail]);

  useEffect(() => {
    if (!_.isEmpty(companyLogo)) {
      setPicData(companyLogo);
    }
  }, [companyLogo]);

  // Set image to profile store once updates and reset it
  useEffect(() => {
    if (_.get(uploadProfileImageData, "signedUrl", "")) {
      const signedUrl = _.get(uploadProfileImageData, "signedUrl", "");

      dispatch(
        getCompanyDetailSuccess({
          data: { ...companyProfileData, profile_image: signedUrl },
          message: "",
        })
      );
      dispatch(uploadCompanyProfileImageReset());
    }

    // eslint-disable-next-line
  }, [uploadProfileImageData]);

  const onChangeBanner = async (e) => {
    if (!_.isEmpty(e.target.files)) {
      setLayoutType("banner");
      const file = e.target.files[0];
      const fileType = file.type;
      const fileSize = validateFileSize(file.size, 5);
      if (fileSize) {
        FlashMessage(fileSize, "error");
      } else if (ImageMimeTypes.includes(fileType)) {
        setFileName(file.name);
        let imageDataUrl: any = await readFile(file);
        setCropImage(imageDataUrl);
        setShowCropImageBanner(true);
      } else {
        FlashMessage("Please select valid png or jpeg file", "error");
      }
    }
  };

  const onChangePicture = async (e) => {
    if (!_.isEmpty(e.target.files)) {
      setLayoutType("profileImage");
      const file = e.target.files[0];
      const fileType = file.type;
      const fileSize = validateFileSize(file.size, 5);
      if (fileSize) {
        FlashMessage(fileSize, "error");
      } else if (ImageMimeTypes.includes(fileType)) {
        setFileName(file.name);
        let imageDataUrl = await readFile(file);
        setCropImage(imageDataUrl);
        setShowCropImage(true);
      } else {
        FlashMessage("Please select valid png or jpeg file", "error");
      }
    }
  };

  const handleCropImage = async (blobData) => {
    const src = URL.createObjectURL(blobData);

    const payload = new FormData();
    payload.append("uploadType", "image");
    payload.append("files", blobData, fileName);
    if (showCropImageBanner) {
      const data = { type: "banner", payload: payload };
      setBannerPath(src);
      dispatch(uploadCompanyBannerImageRequest(data));
    } else {
      const data = { type: "image", payload: payload };
      setPicData(src);
      dispatch(uploadCompanyProfileImageRequest(data));
    }
  };

  // const onUploadVideo = (e) => {
  // 	if (!_.isEmpty(e.target.files)) {
  // 		const file = e.target.files[0];
  // 		const fileType = file.type;

  // 		// Check file type
  // 		if (VideoMimeTypes.includes(fileType)) {
  // 			const payload = new FormData();
  // 			payload.append("files", file);
  // 			const data = { type: "video", payload: payload };
  // 			dispatch(uploadCompanyFileRequest(data));
  // 		} else {
  // 			FlashMessage("Please select valid mp4 or mov file", "error");
  // 		}
  // 	}
  // };
  console.log('picture', picture);
  const bannerClass = _.isEmpty(bannerPath)
    ? "banner-image"
    : "banner-image banner-image-selected";
  const pictureClass = _.isEmpty(picture)
    ? "select-profile"
    : "select-profile selected-profile";

  return (
    <>
      <div>
        <Spinner visible={uploadBannerImageLoading}>
          <div className="profile-banner">
            <div className={bannerClass}>
              <Input
                className="select-banner-btn"
                name="banner"
                type="file"
                onChange={(e) => onChangeBanner(e)}
              />
              <span className="banner-wrapper">
                {_.isEmpty(bannerPath) ? (
                  uploadBannerImageFlag ? (
                    <Skeleton height={440} />
                  ) : (
                    <>
                      <Banner height="100%" className="no-banner" />
                      <AddIcon />
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Add a profile banner
                      </Typography>
                    </>
                  )
                ) : (
                  <>
                    <div className="banner-edit-icon">
                      <PenSVG />
                    </div>
                    <Image
                      src={bannerPath}
                      className="banner-img"
                      alt="eh-company-banner"
                      banner
                      width="100%"
                    />
                  </>
                )}
              </span>
            </div>
          </div>
        </Spinner>
        <div className="profile-picture">
          <div className={pictureClass}>
            <Input
              name="company-profile-image"
              type="file"
              inputRef={inputRef}
              onChange={(e) => onChangePicture(e)}
            />
            <span className="profile-picture-wrapper">
              <img
                src={ProfileUploadIcon}
                className="profile-upload-icon"
                alt="Profile Upload Icon"
                onClick={() => {
                  if (inputRef.current) {
                    inputRef.current?.click();
                  }
                }}
              />
              <Spinner visible={uploadProfileImageLoading}>
                <Avatar
                  className={picture ? "company-avatar" : ""}
                  size="comd"
                  src={picture}
                  type="company"
                />
              </Spinner>
            </span>
          </div>
        </div>
        {/* <div className="upload-video">
          <Input
            className="custom-upload-video-btn"
            name="banner"
            type="file"
            onChange={(e) => onUploadVideo(e)}
            disabled={!isParentAdmin}
          />
        </div> */}
      </div>
      <ProfileImageCrop
        layout={layoutType}
        visible={showCropImage || showCropImageBanner}
        cropImage={cropImage}
        btnCropText="Save"
        onClose={() => {
          setShowCropImage(false);
          setShowCropImageBanner(false);
        }}
        onCrop={(data) => handleCropImage(data)}
      />
    </>
  );
};
export default CompanyBanner;
