import { FlashMessage } from "../components/common";
import appRoutes from "../routes/app.routes";
import history from "./history";

interface commpanyProps {
  slug: string;
  access_level: string;
}
export const commpanyStatus = (companyAccess: commpanyProps) => {
  const { access_level, slug } = companyAccess;

  if (access_level === "admin") {
    return "admin";
  } else if (access_level === "sale_recruiter") {
    if (
      !history.location.pathname.includes("dashboard") &&
      !history.location.pathname.includes("candidates") &&
      !history.location.pathname.includes("jobs")
    ) {
      FlashMessage("Acces Forbidden", "error");
      return history.push(appRoutes.companyDashboard.generatePath(slug));
    }
    return "sale_recruiter";
  } else if (access_level === "marketing") {
    if (!history.location.pathname.includes("dashboard")) {
      FlashMessage("Acces Forbidden", "error");
      return history.push(appRoutes.companyDashboard.generatePath(slug));
    }
    return "marketing";
  }
};
