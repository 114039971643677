import React, { useState, useEffect } from "react";
import "quill-mention";
import {
  SingleFileDropZone,
  Button,
  Modal,
  Input
} from "../../components/common";
import _ from 'lodash';
import { Grid, Menu, MenuItem } from "@material-ui/core";
import Image from "../../components/common/Image";
import { ReactComponent as DeleteIcon } from "../../assets/svg/delete-icon.svg";
import { readFile } from "../../utils/cropImageHelper";
import ProfileImageCrop from "../../components/common/ProfileImageCrop";
import RichTextEditor from "../../components/common/RichTextEditor";
import httpRequest from "../../utils/httpRequest";
import API_URLS from "../../utils/apiUrls";
import FlashMessage from "../../components/common/FlashMessage";
import { useHistory, useParams } from "react-router-dom";
import appRoutes from "../../routes/app.routes";

interface ArticleType {
  seo_title: string;
  seo_description: string;
  title: string;
  description: string;
}

function Article() {

  const [showCropImage, setShowCropImage] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [cropPreviewImage, setPreviewImage] = useState("");
  const [uploadImage, setUploadImage] = useState<Blob>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [articleData, setArticleData] = useState<ArticleType>({} as ArticleType);
  const params = useParams();
  const articleId = _.get(params, "id", "");
  const flag = articleId ? "update" : "create";
  const history = useHistory();

  useEffect(() => {
    if (articleId) {
      fetchArticle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fileUploadSuccess = async (file) => {
    let imageFile: any = await readFile(file)
    setShowCropImage(true);
    setCropImage(imageFile);
  };
  const removeMedia = () => {
    setCropImage(null);
    setPreviewImage("");
    setUploadImage(undefined);
  };
  const handleCropImage = async (blobData) => {
    setUploadImage(blobData);
    const src = URL.createObjectURL(blobData);
    setPreviewImage(src);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const _handleCloseSettingsModel = () => {
    setShowSettingsModal(false);
    setAnchorEl(null)
  };

  const validateArticle = async () => {
    const title = articleData.title;
    const description = articleData.description;
    if (!title || title.length > 250) {
      FlashMessage("please enter headLine", "error");
      return false;
    }
    if (!description) {
      FlashMessage("Please enter description", "error");
      return false;
    }
    return true
  }
  const saveArticle = async () => {
    const isValid = await validateArticle(); // Await the result of validateArticle
    if (!isValid) {
      return; // Exit function if validation fails
    }
    try {
      const payload = new FormData();
      const title = articleData.title || "";
      const description = articleData.description || "";
      const seo_title = articleData.seo_title || "";
      const seo_description = articleData.seo_description || "";

      payload.append("title", title);
      payload.append("description", description);
      payload.append("seo_title", seo_title);
      payload.append("seo_description", seo_description);
      if (uploadImage) {
        payload.append("cover_image", uploadImage || "");
      }
      /*jslint eqeq: true*/
      if (flag === "create") {
        const data = await httpRequest().post<{
          data: ArticleType;
        }>(API_URLS.article, payload);
        if (data.status === 200) {
          history.push(appRoutes.Feed.path);
        }
      } else {
        const updateData = await httpRequest().put(API_URLS.articleUpdate(articleId), payload);

        const data = {
          title: updateData.data.data.title,
          description: updateData.data.data.content || "",
          seo_title: updateData.data.data.seo_title || "",
          seo_description: updateData.data.data.seo_description || "",
        }
        const coverImage = updateData.data.data.cover_image || ''

        if (coverImage) {
          setPreviewImage(coverImage);
        }
        setArticleData(data)
        if (updateData.status === 200) {
          history.push(appRoutes.Feed.path);
        }
      }


    } catch (error) {
      console.log('error.response.data.errors', error.response.data.errors);
      const firstErrorKey = Object.keys(error.response.data.errors || {})[0]; // Get the first error key
      const firstErrorMessage = error.response.data.errors[firstErrorKey]; // Get the first error message
      if (firstErrorMessage) {
        FlashMessage(firstErrorMessage, "error"); // Display the first error message
      } else {
        FlashMessage(error?.message || "An error occurred", "error");
      }

    }
  };
  const fetchArticle = async () => {

    try {
      const oldData = await httpRequest().get(API_URLS.fetchArticle(articleId));
      console.log('oldData', oldData.data.data);
      const data = {
        title: oldData.data.data.title,
        description: oldData.data.data.content || "",
        seo_title: oldData.data.data.seo_title || "",
        seo_description: oldData.data.data.seo_description || "",
      }
      const image = oldData.data.data.cover_image || "";
      setPreviewImage(image);
      setArticleData(data);

    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <>
      <div className="article-main-container">
        <>
          <Button color="secondary"
            id="article-menu-button"
            aria-controls={open ? 'article-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}>
            Menu
          </Button>

          <Menu
            id="article-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'article-menu-button',
            }}
          >
            <MenuItem onClick={() => setShowSettingsModal(true)}>Setting</MenuItem>

          </Menu>
        </>
        <div className="article-container">
          <textarea className="title"
            id="title"
            maxLength={150}
            placeholder="HeadLine"
            rows={1} cols={30}
            value={articleData?.title || ''}
            onChange={(e) => {
              const data = e?.currentTarget?.value || "";
              setArticleData((prevState) => ({
                ...prevState,
                title: data
              }))
            }}
          >
          </textarea>
          <div className="article-image">
            <Grid container item xs={12} sm={12}>
              <Grid item xs={12}>
                <div className="article-input-type-file">
                  {cropPreviewImage ?

                    <div className="article-src" >
                      <Image src={cropPreviewImage} alt="media image" />{" "}
                      <span
                        className="article-close"
                        onClick={() => removeMedia()}
                      >
                        <DeleteIcon />
                      </span>
                    </div>
                    :
                    <SingleFileDropZone
                      allowFileTypes={["png", "jpg", "jpeg"]}
                      allowFileSize={1048576}
                      content={
                        <div className="flex-column gap-2">
                          <p>Add a cover image that complements your article to attract more readers.</p>
                          <p>We recommend uploading an image that is 1920x1080 pixels.</p>
                        </div>
                      }
                      onSuccess={(file) => fileUploadSuccess(file)}
                    />
                  }
                </div>
              </Grid>
            </Grid>
          </div>
          <RichTextEditor
            name="description"
            value={articleData?.description || ''}
            onChange={(value) => {
              // setArticleData(e)
              const data = value || "";
              setArticleData((prevState) => ({
                ...prevState,
                description: data
              }))
            }}

          >
          </RichTextEditor>
          <Button onClick={saveArticle}>Save</Button>
        </div>
      </div >

      <ProfileImageCrop
        layout={'banner'}
        visible={showCropImage}
        cropImage={cropImage}
        onClose={() => setShowCropImage(false)}
        onCrop={(data) => handleCropImage(data)}
      />
      <Modal
        className="article-settings"
        visible={showSettingsModal}
        title="Settings"
        size="width-1000"
        onClose={() => _handleCloseSettingsModel()}
      >
        <Grid item xs={12} className="sub-description-main">
          <p>SEO title</p>
          <p className="sub-description">We’ll use your added SEO title in place of your article title for search engine result pages, such as Google search.</p>
          <p>Title</p>
          <Input
            name="seo_title"
            className="article"
            type="text"
            placeholder="Ex:My Article"
            value={articleData?.seo_title || ''}
            onChange={(e) => {
              const data = e?.currentTarget?.value || "";
              setArticleData((prevState) => ({
                ...prevState,
                seo_title: data
              }))
            }}
          />
          <p>SEO description</p>
          <p className="sub-description">We’ll use the SEO description in place of the first few lines of your article on search engine result pages. We suggest utilizing keywords, summarizing your writing, and aiming to write between 140-160 characters.</p>
          <p>Description</p>
          <Input
            name="seo_description"
            className="article"
            type="text"
            placeholder="Ex:A recap about what the article is about"
            value={articleData?.seo_description || ''}
            onChange={(e) => {
              const data = e?.currentTarget?.value || "";
              setArticleData((prevState) => ({
                ...prevState,
                seo_description: data
              }))
            }}
          />
        </Grid>
        <div className="d-flex justify-content-center mt-20">
          {/* <Grid className="article-button " spacing={8}> */}
          <Button
            className="submit-button text-uppercase"
            onClick={() => {
              saveArticle();
              _handleCloseSettingsModel()
            }}>Save</Button>
          <Button
            className="cancel-button text-uppercase"
            color="secondary"
            onClick={() => {
              _handleCloseSettingsModel()
            }}
          >
            Cancel
          </Button>

          {/* </Grid> */}
        </div>
      </Modal>

    </>
  );
}

export default Article;
