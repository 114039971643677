import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ReducerFlag } from "../../types/reducer.types";

// Initial state for reducer
const initialState = {
  loading: false,
  updateflag: false,
  jobAppliedFlag: ReducerFlag.INIT,
  jobAppliedLoading: false,
  jobAppliedMessage: "",
  jobSavedFlag: ReducerFlag.INIT,
  jobSavedFlagLoading: false,
  jobSavedFlagMessage: "",
  jobSavedFlagData: {},
  uploadLoading: false,
  uploadResumeLoading: false,
  deleteResumeLoading: false,
  deleteResumeFlag: ReducerFlag.INIT,
  resumeFile: "",
  resumeData: {},
  resumeDataFlag: false,
  deleteflag: false,
  videoLoading: "",
  detail: {},
  jobApplications: {
    loading: false,
    error: null,
    data: { applied: [], saved: [] },
    appliedPagination: {},
    savedPagination: {},
  },
  profileData: {},
  message: "",
  imageUrl: "",
  candidateViewPageUpdateFlag: "",
  profileUpdateErrors: {},
  videoUrl: "",
  profile_visibility: 0,
  updateLoading: false,
  updateProfileFlag: ReducerFlag.INIT,
  parseResumeLoading: false,
  candidateUpdatedFlag: false,
  resumeParsedMessage: '',
  uploadResumeFlag: ReducerFlag.INIT,
  parseResumeFlag: ReducerFlag.INIT,

};

const slice = createSlice({
  name: "candidate",
  initialState: initialState,
  reducers: {
    candidateReset: (state) => {
      return { ...state, ...initialState };
    },
    getCandidateProfileDetailRequest: (state, payload) => {
      return { ...state, loading: true, errors: {} };
    },
    getCandidateProfileDetailSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        errors: {},
        data: _.get(payload, "data", {}),
        message: _.get(payload, "message", ""),
      };
    },
    getCandidateProfileDetailFail: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    updateCandidateRequest: (state, payload) => {
      return {
        ...state,
        loading: true,
        updateLoading: true,
        updateProfileFlag: ReducerFlag.INIT,
      };
    },
    updateCandidateSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        updateflag: true,
        updateLoading: false,
        updateProfileFlag: ReducerFlag.SUCCESS,
        message: payload.message,
      };
    },
    updateCandidateFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        updateflag: false,
        updateLoading: false,
        updateProfileFlag: ReducerFlag.FAILED,
        message: payload.message || "",
        errors: payload.errors || {},
      };
    },
    resetUpdateProfile: (state, payload) => {
      return {
        ...state,
        updateProfileFlag: ReducerFlag.INIT,
        loading: false,
        updateflag: false,
        updateLoading: false,
        message: "",
      };
    },
    getCandidateJobApplicationsRequest: (state, { payload }) => {
      return {
        ...state,
        jobApplications: { ...state.jobApplications, loading: true },
      };
    },
    getCandidateJobApplicationsAppliedSuccess: (state, { payload }) => {
      return {
        ...state,
        jobApplications: {
          ...state.jobApplications,
          data: {
            ...state.jobApplications.data,
            applied: _.get(payload, "data.data", []),
          },
          appliedPagination: _.get(payload, "data.pagination", {}),
        },
      };
    },
    getCandidateJobApplicationsSavedSuccess: (state, { payload }) => {
      return {
        ...state,
        jobApplications: {
          ...state.jobApplications,
          data: {
            ...state.jobApplications.data,
            saved: _.get(payload, "data.data", []),
          },
          savedPagination: _.get(payload, "data.pagination", {}),
        },
      };
    },
    getCandidateJobApplicationsFulfilled: (state) => {
      return {
        ...state,
        jobApplications: { ...state.jobApplications, loading: false },
      };
    },
    getCandidateJobApplicationsFailed: (state, { payload }) => {
      return {
        ...state,
        jobApplications: {
          ...state.jobApplications,
          loading: false,
          error: payload.message,
        },
      };
    },
    applyJobRequest: (state, payload) => {
      return {
        ...state,
        jobAppliedFlag: ReducerFlag.INIT,
        jobAppliedLoading: true,
        jobAppliedMessage: "",
      };
    },
    applyJobSuccess: (state, { payload }) => {
      return {
        ...state,
        jobAppliedLoading: false,
        jobAppliedFlag: ReducerFlag.SUCCESS,
        jobAppliedMessage: payload.message || "",
      };
    },
    applyJobFailed: (state, { payload }) => {
      return {
        ...state,
        jobAppliedLoading: false,
        jobAppliedFlag: ReducerFlag.FAILED,
        jobAppliedMessage: payload.message || "",
      };
    },
    applyJobReset: (state) => {
      return {
        ...state,
        jobAppliedLoading: false,
        jobAppliedFlag: ReducerFlag.INIT,
        jobAppliedMessage: "",
      };
    },
    saveJobRequest: (state, payload) => {
      return {
        ...state,
        jobSavedFlag: ReducerFlag.INIT,
        jobSavedFlagLoading: true,
        jobSavedFlagData: {},
        jobSavedFlagMessage: "",
      };
    },
    saveJobSuccess: (state, { payload }) => {
      return {
        ...state,
        jobSavedFlag: ReducerFlag.SUCCESS,
        jobSavedFlagLoading: false,
        jobSavedFlagData: payload.data,
        jobSavedFlagMessage: payload.message,
      };
    },
    saveJobFailed: (state, { payload }) => {
      return {
        ...state,
        jobSavedFlag: ReducerFlag.FAILED,
        jobSavedFlagLoading: false,
        jobSavedFlagData: {},
        jobSavedFlagMessage: payload.message,
      };
    },
    saveJobReset: (state) => {
      return {
        ...state,
        jobSavedFlag: ReducerFlag.INIT,
        jobSavedFlagLoading: false,
        jobSavedFlagData: {},
        jobSavedFlagMessage: "",
      };
    },
    companyCandidatesRequest: (state, action) => {
      return { ...state, loading: true };
    },
    companyCandidatesSuccess: (state, { payload }) => {
      return { ...state, loading: false, flag: true };
    },
    companyCandidatesFail: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        flag: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    uploadCandidateFileRequest: (state, payload) => {
      return { ...state, uploadLoading: true };
    },
    uploadCandidateFileSuccess: (state, { payload }) => {
      return {
        ...state,
        uploadLoading: false,
        imageUrl: _.get(payload, "data.url", ""),
        message: payload.message,
        videoLoading: "Success",
      };
    },
    uploadCandidateFileFailed: (state, { payload }) => {
      return {
        ...state,
        uploadLoading: false,
        message: payload.message || "",
        errors: payload.errors || {},
      };
    },
    setCandidateProfileVideoURL: (state, { payload }) => {
      return {
        ...state,
        uploadLoading: false,
        videoUrl: _.get(payload, "data.videoUrl", ""),
        message: payload.message,
      };
    },
    updateCandidatePersonalValuesRequest: (state, payload) => {
      return { ...state, loading: true, candidateViewPageUpdateFlag: "" };
    },
    updateCandidatePersonalValuesSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        payload,
        message: payload.message,
        candidateViewPageUpdateFlag: "success",
      };
    },
    updateCandidatePersonalValuesFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        message: payload.message || "",
        errors: payload.errors || {},
        candidateViewPageUpdateFlag: "failed",
      };
    },
    updateCandidateProfessionalPassionRequest: (state, payload) => {
      return { ...state, loading: true, candidateViewPageUpdateFlag: "" };
    },
    updateCandidateProfessionalPassionSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        payload,
        message: payload.message,
        candidateViewPageUpdateFlag: "success",
      };
    },
    updateCandidateProfessionalPassionFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        message: payload.message || "",
        errors: payload.errors || {},
        candidateViewPageUpdateFlag: "failed",
      };
    },
    updateCandidateWorkExperienceRequest: (state, payload) => {
      return { ...state, loading: true, candidateViewPageUpdateFlag: "" };
    },
    updateCandidateWorkExperienceSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        payload,
        message: payload.message,
        candidateViewPageUpdateFlag: "success",
      };
    },
    updateCandidateWorkExperienceFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        message: payload.message || "",
        errors: payload.errors || {},
        candidateViewPageUpdateFlag: "failed",
      };
    },
    updateCandidateProjectsRequest: (state, payload) => {
      return { ...state, loading: true, candidateViewPageUpdateFlag: "" };
    },
    updateCandidateProjectsSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        payload,
        message: payload.message,
        candidateViewPageUpdateFlag: "success",
      };
    },
    updateCandidateProjectsFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        message: payload.message || "",
        errors: payload.errors || {},
        candidateViewPageUpdateFlag: "failed",
      };
    },
    updateCandidateSkillsRequest: (state, payload) => {
      return { ...state, loading: true, candidateViewPageUpdateFlag: "" };
    },
    updateCandidateSkillsSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        payload,
        message: payload.message,
        candidateViewPageUpdateFlag: "success",
      };
    },
    updateCandidateSkillsFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        payload,
        message: payload.message,
        candidateViewPageUpdateFlag: "failed",
      };
    },
    updateCandidateEducationRequest: (state, payload) => {
      return { ...state, loading: true, candidateViewPageUpdateFlag: "" };
    },
    updateCandidateEducationSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        payload,
        message: payload.message,
        candidateViewPageUpdateFlag: "success",
      };
    },
    updateCandidateEducationFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        payload,
        message: payload.message,
        candidateViewPageUpdateFlag: "failed",
      };
    },
    deleteVideoRequest: (state) => {
      return {
        ...state,
        deleteflag: false,
        loading: true,
      };
    },
    deleteVideoSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        deleteflag: true,
        message: _.get(payload, "message", ""),
      };
    },
    deleteVideoFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        deleteflag: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    candidateViewPageUpdateFlagReset: (state) => {
      return { ...state, candidateViewPageUpdateFlag: "" };
    },
    uploadCandidateResumeRequest: (state, payload) => {
      return { ...state, uploadResumeLoading: true, resumeDataFlag: false, uploadResumeFlag: ReducerFlag.INIT};
    },
    uploadCandidateResumeSuccess: (state, { payload }) => {
      return {
        ...state,
        uploadResumeLoading: false,
        resumeFile: _.get(payload, "data.resume_file", ""),
        resumeData: _.get(payload, "data.parsed", {}),
        resumeDataFlag: !_.isEmpty(_.get(payload, "data.parsed", {}))
          ? true
          : false,
        message: payload.message,
        resumeParsedMessage:  _.get(payload, "resumeParsedMessage", ""),
        uploadResumeFlag: ReducerFlag.SUCCESS
      };
    },
    uploadCandidateResumeFailed: (state, { payload }) => {
      return {
        ...state,
        uploadResumeLoading: false,
        resumeDataFlag: false,
        message: payload.message || "",
        errors: payload.errors || {},
        uploadResumeFlag: ReducerFlag.FAILED
      };
    },

    uploadCandidateResumeReset: (state) => {
      return {
        ...state,
        uploadResumeLoading: false,
        resumeFile: "",
        resumeData: "",
        resumeDataFlag: false,
        uploadResumeFlag: ReducerFlag.INIT
      };
    },

    candidateUpdatedReset: (state) => {
      return {
        ...state,
        candidateUpdatedFlag: true,
      }
    },
    
    parseCandidateResumeRequest: (state, payload) => {
      return { ...state, parseResumeLoading: true, parseResumeFlag: ReducerFlag.INIT  };
    },
    parseCandidateResumeSuccess: (state, { payload }) => {
      return {
        ...state,
        parseResumeLoading: false,
        resumeData: _.get(payload, "data", {}),
        resumeDataFlag: !_.isEmpty(_.get(payload, "data", {}))
          ? true
          : false,
        message: payload.message,
        parseResumeFlag: ReducerFlag.SUCCESS
      };
    },

    parseCandidateResumeFailed: (state, { payload }) => {
      return {
        ...state,
        parseResumeLoading: false,
        message: payload.message || "",
        errors: payload.errors || {},
        parseResumeFlag: ReducerFlag.FAILED
      };
    },

    parseCandidateResumeReset: (state) => {
      return {
        ...state,
        message: '',
        parseResumeFlag: ReducerFlag.INIT
      };
    },

    deleteCandidateResumeRequest: (state) => {
      return {
        ...state,
        deleteResumeLoading: true,
        deleteResumeFlag: ReducerFlag.INIT,
      };
    },
    deleteCandidateResumeSuccess: (state, { payload }) => {
      return {
        ...state,
        deleteResumeLoading: false,
        deleteResumeFlag: ReducerFlag.SUCCESS,
        message: payload.message,
      };
    },
    deleteCandidateResumeFailed: (state, { payload }) => {
      return {
        ...state,
        deleteResumeLoading: false,
        deleteResumeFlag: ReducerFlag.FAILED,
        message: payload.message || "",
        errors: payload.errors || {},
      };
    },
    resetDeleteCandidateResume: (state) => {
      return {
        ...state,
        deleteResumeLoading: false,
        deleteResumeFlag: ReducerFlag.INIT,
        message: "",
        errors: {},
      };
    },
  },
});

// Actions
export const {
  applyJobRequest,
  applyJobSuccess,
  applyJobFailed,
  applyJobReset,
  saveJobRequest,
  saveJobFailed,
  saveJobSuccess,
  deleteVideoRequest,
  deleteVideoSuccess,
  deleteVideoFailed,
  updateCandidateRequest,
  updateCandidateSuccess,
  updateCandidateFailed,
  resetUpdateProfile,
  companyCandidatesRequest,
  companyCandidatesSuccess,
  companyCandidatesFail,
  uploadCandidateFileRequest,
  uploadCandidateFileSuccess,
  uploadCandidateFileFailed,
  setCandidateProfileVideoURL,
  candidateReset,
  getCandidateProfileDetailRequest,
  getCandidateProfileDetailSuccess,
  getCandidateProfileDetailFail,
  getCandidateJobApplicationsRequest,
  getCandidateJobApplicationsAppliedSuccess,
  getCandidateJobApplicationsSavedSuccess,
  getCandidateJobApplicationsFailed,
  getCandidateJobApplicationsFulfilled,
  updateCandidateEducationRequest,
  updateCandidateEducationSuccess,
  updateCandidateEducationFailed,
  updateCandidateSkillsRequest,
  updateCandidateSkillsSuccess,
  updateCandidateSkillsFailed,
  updateCandidateProjectsRequest,
  updateCandidateProjectsSuccess,
  updateCandidateProjectsFailed,
  updateCandidateWorkExperienceRequest,
  updateCandidateWorkExperienceSuccess,
  updateCandidateWorkExperienceFailed,
  updateCandidatePersonalValuesRequest,
  updateCandidatePersonalValuesSuccess,
  updateCandidatePersonalValuesFailed,
  updateCandidateProfessionalPassionRequest,
  updateCandidateProfessionalPassionSuccess,
  updateCandidateProfessionalPassionFailed,
  candidateViewPageUpdateFlagReset,
  uploadCandidateResumeRequest,
  uploadCandidateResumeSuccess,
  uploadCandidateResumeFailed,
  uploadCandidateResumeReset,
  deleteCandidateResumeRequest,
  deleteCandidateResumeSuccess,
  deleteCandidateResumeFailed,
  resetDeleteCandidateResume,
  parseCandidateResumeRequest,
  parseCandidateResumeSuccess,
  parseCandidateResumeFailed,
  candidateUpdatedReset,
  parseCandidateResumeReset
} = slice.actions;

// Reducers
export default slice.reducer;
