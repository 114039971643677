import React, { useEffect, useState } from "react";
import { Button, Checkbox, MenuItem, Select } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import moment from "moment";
import { ReactComponent as Delete } from "../../../assets/svg/delete-admin-icon.svg";
import schedulerServices from "../../../services/scheduler.services.ts";
import { FlashMessage, SelectNew } from "../../../components/common";
import _ from "lodash";
import { useSelector } from "react-redux";
import { rootReducersState } from "../../../reducers";
import { TIME_ZONE_LIST } from "../../../utils/appConstants.ts";
import { useForm } from "react-hook-form";
import 'moment-timezone';


const WorkingHours = () => {
  let [myKey, setMyKey] = useState<number>(0);
  const [timeStops, setTimeStops] = useState<Array<string>>([]);
  const [workingHour, setWorkingHour] = useState<Array<object>>([
    { selected: false, day: "monday", from_time: "", to_time: "" },
    { selected: false, day: "tuesday", from_time: "", to_time: "" },
    { selected: false, day: "wednesday", from_time: "", to_time: "" },
    { selected: false, day: "thursday", from_time: "", to_time: "" },
    { selected: false, day: "friday", from_time: "", to_time: "" },
    { selected: false, day: "saturday", from_time: "", to_time: "" },
    { selected: false, day: "sunday", from_time: "", to_time: "" },

  ]);
  const [timeZone, setTimeZone] = useState<{
    value: string;
    label: string;
  } | undefined>();
  const [changeTimeZone, setChangeTimeZone] = useState<boolean>(false);
  const [userTimeZone, setUserTimeZone] = useState<{
    value: string;
    label: string;
  } | undefined>();
  const { watch } = useForm();
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );

  useEffect(() => {
    setMyKey(myKey++);
    setTimeStops(getTimeStops("1:00", "23:59"));
    // eslint-disable-next-line
  }, []);

  const _displayOptions = () => {
    if (Array.isArray(timeStops) && timeStops) {
      return timeStops.map((o) => (
        < MenuItem value={o} key={o} >
          {o}  {slotEx(o)}
        </MenuItem >
      ));
    }
  };

  useEffect(() => {
    const locationId = watch("location_id", "").value;
    if (locationId) setTimeZone(locationId);
  }, [watch]);
  const getTimeStops = (start: string, end: string) => {
    var startTime = moment(start, "HH:mm");
    var endTime = moment(end, "HH:mm");

    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }

    var timeStops: any = [];

    while (startTime <= endTime) {
      timeStops.push(new moment(startTime).format("HH:mm"));
      startTime.add(30, "minutes");
    }
    return timeStops;
  };

  const handleChangeFrom = (e: any, row: string) => {
    setMyKey(myKey++);
    workingHour.forEach((item, index) => {
      if (_.get(item, "day", "") === row) workingHour[index]["from_time"] = e;
    });
    setWorkingHour(workingHour);
    changeSelection(row);
  };

  const handleChangeTo = (e: any, row: string) => {
    setMyKey(myKey++);
    workingHour.forEach((item, index) => {
      if (_.get(item, "day", "") === row) workingHour[index]["to_time"] = e;
    });
    setWorkingHour(workingHour);
    changeSelection(row);
  };

  useEffect(() => {
    setMyKey(myKey++);
  }, [myKey]);

  const changeSelection = (row: string) => {
    workingHour.forEach((item) => {
      if (
        _.get(item, "day", "") === row &&
        _.get(item, "from_time", "") !== "" &&
        _.get(item, "to_time", "") !== ""
      )
        _.set(item, "selected", true);
    });
    setWorkingHour(workingHour);
    setMyKey(myKey++);
  };

  const handleDelete = (row: string) => {
    setMyKey(myKey++);
    workingHour.forEach((item, index) => {
      if (_.get(item, "day", "") === row) {
        workingHour[index]["from_time"] = "";
        workingHour[index]["to_time"] = "";
      }
    });

    setMyKey(myKey++);
    setWorkingHour(workingHour);
  };

  const handleSubmit = async () => {
    try {
      const workingHourPayload = workingHour.filter((row) => {
        return row.id || row.from_time !== ''
      })
      const payload = {
        workingHour: workingHourPayload,
        timeZone: timeZone
      }
      const result = await schedulerServices.addWorkingHours(payload);

      if (result.flag) {
        FlashMessage(result.message);
        fetchApi();
      } else {
        FlashMessage(result.message, "error");
      }
    } catch (error) {
      FlashMessage(error?.message, "error");
    }
  };

  const slotEx = (hour: any) => {
    console.log('hour', hour);
    if (parseInt(hour.split(":")[0]) >= 12) return "PM";
    else return "AM";
  };
  const arrangeArray = (result) => {
    const hoursArr: any = [];
    const days = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];
    days.forEach((day) => {
      const item = result.find((item: object) => _.get(item, "day", "") === day);
      if (item) {
        hoursArr.push(item);
      } else {
        hoursArr.push({
          day,
          from_time: "",
          to_time: ""
        });
      }
    });
    return hoursArr;
  };

  const fetchApi = async () => {
    try {
      const result = await schedulerServices.getWorkingHours(
        sessionReducer.currentUser.id
      );
      const time = result.data.timeZone
      if (result.flag) {
        if (result.data.workingHours.length > 0) {
          const arrangedData = await arrangeArray(result.data.workingHours);
          setWorkingHour(arrangedData);

        }
        if (time) {
          const finalTimeZone = TIME_ZONE_LIST.find(row => row.value === time)
          setTimeZone(finalTimeZone)
          setUserTimeZone(finalTimeZone);
        }
      } else {
        FlashMessage(result.message, "error");
      }
    } catch (error) {
      FlashMessage(error?.message, "error");
    }
  };

  useEffect(() => {
    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userCard = (row: object) => {

    return (
      <div className="d-flex  working-hours-wrapper">
        <div className="flex">
          <Checkbox
            checked={
              _.get(row, "from_time", "") !== "" &&
              _.get(row, "to_time", "") !== ""
            }
            key={myKey}
            disabled
          />
          <div className="week-label">{_.get(row, "day", "")}</div>
        </div>
        <div className="select-wrapper">
          <div className="week-box">
            <Select
              variant="outlined"
              inputProps={{ "aria-label": "Without label" }}
              onChange={(e) => {
                handleChangeFrom(e.target.value, _.get(row, "day", ""));
              }}
              value={_.get(row, "from_time", "")}
              key={myKey}
            >
              {<MenuItem value="" disabled></MenuItem>}
              {_displayOptions()}
            </Select>
          </div>
          <div className="week-space">-</div>
          <div className="week-box">
            <Select
              variant="outlined"
              value={_.get(row, "to_time", "")}
              inputProps={{ "aria-label": "Without label" }}
              onChange={(e) => {
                handleChangeTo(e.target.value, _.get(row, "day", ""));
              }}
              key={myKey}
            >
              {<MenuItem value="" disabled></MenuItem>}
              {_displayOptions()}
            </Select>
          </div>
        </div>
        <div className="week-trash">
          <Delete
            onClick={() => {
              handleDelete(_.get(row, "day", ""));
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="cal-container">
        <div className="working-hours-container">
          <div className="time-zone">
            <h5>Time Zone</h5>
            <SelectNew name="location_id"
              options={TIME_ZONE_LIST.map(row => ({
                value: row.value,
                label: row.label
              }))}
              placeholder={timeZone?.label}
              defaultValue={{
                value: timeZone?.value,
                label: timeZone?.label,
              }}

              onChange={(row) => {
                if (userTimeZone?.value === row.value) {
                  setChangeTimeZone(false)
                } else {
                  setChangeTimeZone(true)
                  setTimeZone(row)
                }

              }}

            />
          </div>

          {changeTimeZone && (
            <div className="timeZone-warning">
              <Warning style={{ color: '#ffc107' }} />
              <span>
                By changing the time zone, your current events will be displayed in the selected time zone.
              </span>
            </div>
          )}


          <div className="event-main-wrapper">
            {(workingHour || []).map((row) => {
              return userCard(row);
            })}
          </div>
          <div>
            <Button
              variant="contained"
              className="button-submit"
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkingHours;
