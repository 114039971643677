import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import ErrorTextHelper from "./ErrorTextHelper";
import ReactQuill from "react-quill";
import { Controller } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import { stripHTMLTags } from "../../utils/helper";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "quill-mention/dist/quill.mention.css";
import "quill-mention";
import { getMentioned } from "../../services/feedPage.services";

// We will explicity destruct props which are not Mui props or want to manipulates
// "...props" will have all the props which is accepted by Mui component, e.g. variant, onChange etc...
interface externalLabel {
  [key: string]: any;
}
type Mention = { value: string; id: number };
interface RichTextEditorProps {
  // Primary props
  name: string;
  control?: any; // Must be react-hook-form control
  externalLabel?: externalLabel;
  validationObj?: object;
  showError?: boolean;
  // other props of the component which directly used in component
  [key: string]: any;
  showCount?: boolean;
  maxLimit?: number;
  counterAlign?: "left" | "center" | "right";
  useMention?: boolean;
  AIflag?: boolean; 
}
const RichTextEditor = ({
  name,
  control,
  validationObj,
  className,
  placeholder,
  showError = true,
  showCount,
  maxLimit = 1000,
  counterAlign = "right",
  useMention = false,
  AIflag = false,
  ...props
}: RichTextEditorProps) => {
  //  External label
  const externalLabel = _.get(props, "externalLabel.label", "") || "";
  let externalLabelClass = _.get(props, "externalLabel.className", "") || "";
  const externalLabelClasses = _.chain([externalLabelClass])
    .uniq()
    .join(" ")
    .split(" ")
    .uniq()
    .join(" ")
    .trim()
    .value();
  // Error message
  const errorMessage = _.get(validationObj, `${name}.message`, null);

  // Use when Form Hook control is not used
  const quillValue = props?.value || "";
  const quillRef = useRef<any>(null);
  const handleOnChange = (text: string) => {
    if (typeof props?.onChange === "function") {
      props.onChange(text);
    }
  };
  // const staticHashtags = [
  //   { id: 3, value: "world" },
  //   { id: 4, value: "quill" },
  // ];
  let searchDelay: NodeJS.Timeout;
  const [quillInstance, setQuillInstance] = useState<any>(null);
  const fetchMentionValues = (search: string) =>
    new Promise((resolve) => {
      clearTimeout(searchDelay);
      searchDelay = setTimeout(async () => {
        const apiData: any = await getMentioned(search);
        const responseData = _.get(apiData, "data.data", []);
        resolve(responseData);
        return responseData;
      }, 1000);
    });

  useEffect(() => {
    if (!quillRef.current) {
      return; // Wait for the ref to be assigned
    }
    if (quillRef.current) {
      const editor: any = new Quill(quillRef.current, {
        theme: "snow",
        placeholder: placeholder || "",
        modules: {
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["@"],
            dataAttributes: ["disabled"],
            showDenotationChar: true,
            source: async (
              searchTerm: string,
              renderList: (items: Mention[], searchTerm: string) => void,
              mentionChar: string
            ) => {
              let values: Mention[] = [];

              if (mentionChar === "@" && searchTerm === "") {
                // Case 1: Call the API immediately when "@" is typed
                try {
                  const data: any = await fetchMentionValues("");
                  renderList(data, searchTerm);
                  if (props?.setOverflow) {
                    props?.setOverflow(true);
                  }
                } catch (error) {
                  console.error("Error fetching mention values:", error);
                }
                return;
              }

              if (mentionChar === "@" && searchTerm.length > 1) {
                // Case 2: Call the API with the typed text in the payload
                try {
                  const data: any = await fetchMentionValues(searchTerm);
                  if (props?.setOverflow) {
                    props?.setOverflow(true);
                  }
                  renderList(data, searchTerm);
                } catch (error) {
                  console.error("Error fetching mention values:", error);
                }
                return;
              }

              if (searchTerm.length === 0) {
                if (props?.setOverflow) {
                  props?.setOverflow(true);
                }
                // Case 3: User has cleared the input, render an empty list
                renderList(values, searchTerm);
              } else {
                // Case 4: Filter values based on the typed text
                const matches = values?.filter((item: any) =>
                  item?.value?.toLowerCase()?.includes(searchTerm.toLowerCase())
                );
                if (props?.setOverflow) {
                  props?.setOverflow(false);
                }
                renderList(matches, searchTerm);
              }
            },
          },
        },
      });
      setQuillInstance(editor);
      const defaultValue = control.getValues(name) || "";
      editor.root.innerHTML = defaultValue;
      editor.on("text-change", () => {
        const content = editor.root.innerHTML;
        control.setValue(name, content);
        if (props?.setOverflow) {
          props?.setOverflow(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (quillInstance) {
      quillInstance.root.innerHTML = props.value;
    }

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AIflag]);

  return (
    <>
      {externalLabel && (
        <label htmlFor={props.name} className={externalLabelClasses}>
          {externalLabel}
        </label>
      )}
      {control ? (
        <>
          <Controller
            name={name}
            rules={props.rules || {}}
            control={control}
            defaultValue={props.defaultValue}
            render={({ onChange, value }: any) => (
              <>
                {useMention ? (
                  <div className="quill">
                    <div ref={quillRef} />
                  </div>
                ) : (
                  <ReactQuill
                    value={value || ""}
                    placeholder={placeholder || ""}
                    onChange={(content: any) => {
                      const initValue = [
                        "<p><br></p>",
                        "<p><br></p><p><br></p>",
                        "<h1><br></h1>",
                        "<h2><br></h2>",
                        "<h3><br></h3>",
                        "<ol><li><br></li></ol>",
                        "<ul><li><br></li></ul>",
                      ];

                      // Check if content starts with any of the initial values
                      const startsWithInitValue = initValue.some(
                        (value) => value.trim() === content.trim()
                      );

                      if (startsWithInitValue) {
                        onChange("");
                      } else {
                        onChange(content);
                      }
                    }}
                  />
                )}
                {showCount && (
                  <p
                    className={`${className} mt-2 global-counter ${
                      (counterAlign === "center" || counterAlign === "right") &&
                      `align-${counterAlign}`
                    } ${
                      stripHTMLTags(value).length > maxLimit && "error-text"
                    }`}
                  >
                    {`${stripHTMLTags(value).length}/${maxLimit}`}
                  </p>
                )}
              </>
            )}
          />
          {errorMessage && showError && <ErrorTextHelper text={errorMessage} />}
        </>
      ) : (
        <ReactQuill
          value={quillValue || ""}
          placeholder={placeholder || ""}
          onChange={(content: any) => {
            const initValue = [
              "<p><br></p>",
              "<h1><br></h1>",
              "<h2><br></h2>",
              "<h3><br></h3>",
              "<ol><li><br></li></ol>",
              "<ul><li><br></li></ul>",
            ].filter((char) => content.indexOf(char) === 0);
            // Check if blank value is added
            if (initValue.length > 0) {
              handleOnChange("");
            } else {
              handleOnChange(content);
            }
          }}
        />
      )}
    </>
  );
};
export default RichTextEditor;
