import React, { useEffect, useState } from "react";
import { getAppDomain } from "../../utils/helper";
import { rootReducersState } from "../../reducers";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Button, FlashMessage } from "../../components/common";
import _ from "lodash";
import candidateServices from "../../services/candidate.services";
import { candidateVisibilityStatus } from "../../utils/appConstants";
import {
  updateCandidateVisibilityRequest,
  updateCandidateSlug,
} from "../../reducers/auth/session.reducer";
import unknownProfile from "../../assets/images/unknownProfile.jpeg";
import moment from "moment";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { FormControlLabel } from "@material-ui/core";
import PenSVG from "../../assets/svg/PenSVG";
import useSessionUser from "../../hooks/useSessionUser";
import Avatar from "../../components/common/AvtarImage";

const ProfileAndVisibility = () => {
  const { handleSubmit, clearErrors } = useForm({});
  const [profileStatus, setProfileStatus] = useState<string>("public");
  const [slug, setSlug] = useState<string>("");
  const [btnState, setBtnState] = useState<boolean>(false);
  const dispatch = useDispatch();
  const appDomainFull = getAppDomain({ protocol: true });
  const { currentSlug } = useSessionUser();

  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session.currentUser
  );
  console.log('sessionReducer', sessionReducer);

  useEffect(() => {
    setSlug(currentSlug);
    let visible = sessionReducer.profile_visibility;
    let keyVal = visible === 2 ? "private" : "public";
    setProfileStatus(keyVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exitTextBox = () => {
    setBtnState(false);
    setSlug(slug);
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape" && btnState) {
        event.preventDefault();

        exitTextBox();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btnState]);

  const onSubmit = async () => {
    if (!btnState) {
      try {
        const result = await candidateServices.updateCandidateVisibility({
          visibility: candidateVisibilityStatus[profileStatus],
        });

        if (result.flag) {
          dispatch(
            updateCandidateVisibilityRequest(
              candidateVisibilityStatus[profileStatus]
            )
          );
          FlashMessage(result.message, "success");
        } else {
          FlashMessage(result.message, "error");
        }
      } catch (error) {
        FlashMessage(error?.message, "error");
      }
    }
  };

  const editSlug = (e) => {
    setBtnState(true);
  };

  const onClickEnter = async (e) => {
    setBtnState(true);

    const regex = /^\w[a-zA-Z0-9-]{2,50}$/;

    if (e.code === "Enter" || e.code === "NumpadEnter") {
      if (regex.test(slug)) {
        try {
          if (slug !== currentSlug) {
            const result = await candidateServices.updateSlug({
              slug,
            });

            if (result.flag) {
              setSlug(slug);

              dispatch(updateCandidateSlug(slug));
              FlashMessage(result.message, "success");
            } else {
              setSlug(currentSlug);
              FlashMessage(result.message, "error");
            }
          }
        } catch (error) {
          setSlug(currentSlug);
          FlashMessage(error?.message, "error");
        }
      } else {
        FlashMessage("Please enter valid slug", "error");
        setSlug(_.get(sessionReducer, "slug", ""));
      }
    }
  };

  return (
    <div className="profile-container">
      <div>
        <h2 className="title">Profile and visibility</h2>
        <p>
          Mange your personal information. From here, you have control over who
          can sees your profile information on Employher.
        </p>
        <p className="d-flex">
          <Avatar
            src={_.get(sessionReducer, "profile_image", unknownProfile)}
            size="xsm"
          />
          <div className="ml-10">
            <b>
              {_.get(sessionReducer, "first_name", "")}{" "}
              {_.get(sessionReducer, "last_name", "")},
            </b>{" "}
            {_.get(sessionReducer, "email", "")}
            <br />
            <div className="date-title">
              This account was created at{" "}
              {moment(_.get(sessionReducer, "created_at", Date())).format(
                "LLLL"
              )}
            </div>
          </div>
        </p>
        <p>
          <b>Your current email address is </b>
          {_.get(sessionReducer, "email", "")}
          <div className="date-title">
            (Add on{" "}
            {moment(_.get(sessionReducer, "created_at", Date())).format("LLL")})
          </div>
        </p>
        Control who can search you on employ<span className="title">HER</span>?
        <form
          onSubmit={(e) => {
            clearErrors();
            setBtnState(false);
            handleSubmit(onSubmit)(e);
          }}
          className="edit-modal-wrapper"
        >
          <div className="form-group mt-15">
            <RadioGroup
              value={profileStatus}
              onChange={(e: React.ChangeEvent<any>) => {
                setProfileStatus(_.get(e, "target.value", ""));
              }}
            >
              <FormControlLabel
                value="public"
                control={<Radio />}
                label="Anyone on employHER"
              />
              {/* <FormControlLabel
                value="follow"
                control={<Radio />}
                label="Only people you follow"
              /> */}
              <FormControlLabel
                value="private"
                control={<Radio />}
                label="Turn off - no one can"
              />
            </RadioGroup>
            <div className="form-group btn-group btn-right btn-submit">
              <Button type="submit" color="primary">
                Save
              </Button>
            </div>
          </div>
          <hr />
          <div className="mt-20">
            <b>Personalize the URL for your profile</b>
            <p>
              Your custom URL must contain 3-50 letters, numbers or dash. Please
              do not use spaces, symbols, or special characters.
            </p>
            <b>
              {appDomainFull}profile/view/
              {!btnState && <span>{_.get(sessionReducer, "slug", "")} </span>}
              <span
                onKeyPress={(e) => {
                  onClickEnter(e);
                }}
              >
                <input
                  type="text"
                  className="slug-input"
                  value={slug}
                  hidden={!btnState}
                  onChange={(e) => {
                    setSlug(e.target.value);
                  }}
                />
              </span>
              <span
                onClick={editSlug}
                hidden={btnState}
                className="cursor-pointer"
              >
                <PenSVG />
              </span>
            </b>
          </div>

          {btnState && (
            <span>Press enter to save or escape to cancel the action.</span>
          )}
        </form>
      </div>
    </div>
  );
};

export default ProfileAndVisibility;
