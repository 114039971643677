import React from "react";

interface ChipProps {
  label: string;
  size?: "small" | "medium";
  color?: "primary" | "secondary";
}

const Chip = ({ label, size = "medium", color = "primary" }: ChipProps) => {
  return (
    <span className={`eh-chip eh-chip-size-${size} eh-chip-color-${color}`}>
      {label}
    </span>
  );
};

export default Chip;
