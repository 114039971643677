import React from "react"
import { useSelector } from "react-redux"
import { Button, Input } from "../../../components/common"
import SearchIcon from "@material-ui/icons/Search"
import { getCurrentTab } from "../../../utils/helper"
import { isEmpty, isUndefined } from "lodash"

const _ = { isEmpty, isUndefined }
interface ActionProps {
  handleClick: (type: string, text?: string) => any
}

let searchDelay: any = null

const Actions = (props: ActionProps) => {
  const { handleClick } = props
  const { innerTab } = useSelector(({ company }: any) => company)
  const currentSubTab = getCurrentTab(props, true)
  const activeTab = _.isEmpty(innerTab)
    ? _.isUndefined(currentSubTab)
      ? "All"
      : currentSubTab
    : innerTab
  const checkTab = activeTab.toLowerCase()

  const handleInstantSearch = (e) =>
    new Promise((resolve) => {
      clearTimeout(searchDelay)
      searchDelay = setTimeout(async () => {
        resolve(handleClick("Search", e.target.value))
      }, 1000)
    })

  return (
    <div className="custom-tab-wrapper">
      <div className="custom-tab-left">
        <ul className="tab-menu company-tabs">
          <li>
            <Button
              className={`${checkTab === "active" ? "active" : ""}`}
              onClick={() => handleClick("Active")}
            >
              {" "}
              Active{" "}
            </Button>
          </li>
          <li>
            <Button
              className={`${checkTab === "drafts" ? "active" : ""}`}
              onClick={() => handleClick("Drafts")}
            >
              {" "}
              Drafts{" "}
            </Button>
          </li>
          <li>
            <Button
              className={`${checkTab === "archive" ? "active" : ""}`}
              onClick={() => handleClick("Archive")}
            >
              {" "}
              Archive{" "}
            </Button>
          </li>
          <li>
            <Button
              className={`${checkTab === "all" ? "active" : ""}`}
              onClick={() => handleClick("All")}
            >
              {" "}
              All{" "}
            </Button>
          </li>
        </ul>
      </div>
      <div className="custom-tab-right">
        <Input
          name="search"
          placeholder="Search"
          className="custom-search-wrapper"
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          onKeyUp={(e) => handleInstantSearch(e)}
        />
        <Button className="text-uppercase" onClick={() => handleClick("Add")}>
          Add Job
        </Button>
      </div>
    </div>
  )
}

export default Actions
