import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'; // Import React
import socketIOClient, { Socket } from "socket.io-client";
import { rootReducersState } from '../../reducers';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { updateConnectionReceivedCount, updateMessageCount, updateNotificationCount } from '../../reducers/auth/session.reducer';

// Define the type for the Socket context
type SocketContextType = Socket | null;

// Create a Context for the socket with a default value of null
const SocketContext = createContext<SocketContextType>(null);

type SocketProviderProps = {
  children: ReactNode;
};


// Define a provider component
export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const dispatch = useDispatch()
  const tmpCurrentUser = _.get(sessionReducer, "currentUser", {});
  const userId = _.get(tmpCurrentUser, "id", 0);
  // const params = useParams<{ companySlug: string }>();
  // const companySlug = params?.companySlug ? params.companySlug : null;
  const [socket, setSocket] = useState<Socket | null>(null); // Ensure type is Socket | null
  // console.log('userId------', userId);
  useEffect(() => {
    // Initialize the socket connection
    const socketInstance = socketIOClient(String(process.env.REACT_APP_API_END_POINT)) as Socket;
    socketInstance.on("connect", () => {
      console.log('socket connected',);
      socketInstance.emit('register', userId);

    })
    setSocket(socketInstance);

    // Listen for incoming messages
    socketInstance.on('message', (message) => {
      console.log('message', message);
      const messageCountPayload = {
        candidateMessageCount: message.totalCount,
        companyMessageCount: message.totalCount,
      }
      dispatch(updateMessageCount(messageCountPayload));
    });

    //notification
    socketInstance.on('notification', (notification) => {
      console.log('notification', notification);
      const totalCount = notification.count
      const payload = {
        notificationCount: totalCount,
        companyNotificationCount: totalCount
      }
      dispatch(updateNotificationCount(payload));
    });

    //connection
    socketInstance.on('searchAndConnect', (payload) => {
      console.log('payload', payload.count);
      const updatedCount = payload.count
      dispatch(updateConnectionReceivedCount(updatedCount));
    });


    // Cleanup on unmount
    return () => {
      socketInstance.disconnect();
      console.log("Socket disconnected");
    };
    // eslint-disable-next-line
  }, [userId]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

// Create a custom hook for using the socket
export const useSocket = (): Socket => {
  const context = useContext(SocketContext);
  if (context === null) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};

