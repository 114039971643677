  import React from "react";
  import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Tooltip,
    makeStyles,
  } from "@material-ui/core";
  import Pagination from '@material-ui/lab/Pagination';

  import {formatDate} from "../../../utils/helper";
  import Rating from "./contactRating";

  const useStyles = makeStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
      marginTop: "21px",
      border: '1px solid #EBEBEB',
      borderRadius: '10px 10px 0px 0px',
      background: '#FBFBFB'
    },
    table: {
      minWidth: "800px",
      whiteSpace: 'nowrap',
    },
    columnStyle: {
      color: "#31222A",
      fontFamily: "Work Sans",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "24px",
      letterSpacing: "-0.053px",
    },
    emailColumn: {
      color: "#31A0FA",
      fontFamily: "Work Sans",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "22px",
      letterSpacing: "0.3px",
    },
    dataColumns: {
      color: "#31222A",
      fontFamily: "Work Sans",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "22px",
      letterSpacing: "0.3px",
    },
    customPagination: {
      "& .MuiPagination-root": {
        display: "flex",
        justifyContent: "center",
        padding: "10px 0px",
        "& .Mui-selected":{
          backgroundColor: "#FF0990"
        }
      }
    }
  });

  const Contacts = ({ contactList, page, setPage, count, fetchContactsForPage }) => {
    const classes = useStyles();
    const renderTags = (tags) => {
      if (Array.isArray(tags)) {
        return tags[0];
      }
      return tags;
    };

    const handlePageChange = (event, pageNumber) => {
      setPage(pageNumber);
      fetchContactsForPage(pageNumber);
    };

    return (
      <TableContainer component={Paper} className={classes.root}>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.columnStyle}>Email</TableCell>
              <TableCell className={classes.columnStyle}>Name</TableCell>
              <TableCell className={classes.columnStyle}>Address</TableCell>
              <TableCell className={classes.columnStyle}>Phone Number</TableCell>
              <TableCell className={classes.columnStyle}>Tags</TableCell>
              <TableCell className={classes.columnStyle}>Email Marketing</TableCell>
              <TableCell className={classes.columnStyle}>Birthday</TableCell>
              <TableCell className={classes.columnStyle}>Created By</TableCell>
              <TableCell className={classes.columnStyle}>Contact Rating</TableCell>
              <TableCell className={classes.columnStyle}>Created At</TableCell>
              <TableCell className={classes.columnStyle}>Last Changed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contactList.map((contact) => (
              <TableRow key={contact.id}>
                <TableCell className={classes.emailColumn}>
                  {contact.email}
                </TableCell>
                <TableCell
                  className={classes.dataColumns}
                >{`${contact.first_name} ${contact.last_name}`}</TableCell>
                <TableCell className={classes.dataColumns}>
                  {contact.address_1}
                </TableCell>
                <TableCell className={classes.dataColumns}>
                  {contact.phone_number}
                </TableCell>
                <TableCell className={classes.dataColumns}>
                  <Tooltip
                    title={
                      Array.isArray(contact.tags)
                        ? contact.tags.join(", ")
                        : contact.tags
                    }
                    placement="top"
                  >
                    <span>{renderTags(contact.tags)}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.dataColumns}>
                {contact.email_permission ? 'Subscribed' : 'Unsubscribed'}
                </TableCell>
                <TableCell className={classes.dataColumns}>
                  {contact.date_of_birth}
                </TableCell>
                <TableCell className={classes.dataColumns}>
                  {contact.creator.first_name + " " + contact.creator.last_name}
                </TableCell>
                <TableCell className={classes.dataColumns}>
                    <Rating value= {contact.contact_rating}/>
                </TableCell>
                <TableCell className={classes.dataColumns}>
                  {formatDate(contact.created_at)}
                </TableCell>
                <TableCell className={classes.dataColumns}>
                  {formatDate(contact.updated_at)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.customPagination}>
          <Pagination
            count={count} 
            page={page}
            onChange={handlePageChange} 
          />
        </div>
      </TableContainer>
    );
  };

  export default Contacts;
