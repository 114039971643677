import { put, takeLatest } from 'redux-saga/effects';
import { call } from "typed-redux-saga";
import _ from "lodash";
import {
    saveImportedContactsRequest,
    saveImportedContactsSuccess,
    saveImportedContactsFail,
	getContactsListRequest,
	getContactsListSuccess,
	getContactsListFail
} from '../reducers/company/companyMarketingSolution.reducer';
import marketingSolutionServices from '../services/marketingSolution.services';

interface payloadInterface {
	type: String
	payload: any
}


function* parseContactsWorker({ payload }: payloadInterface) {

    console.log("payload:::", payload);
	const response = yield* call(marketingSolutionServices.saveContacts, payload);

	try {
		if (response.flag === true) {
			console.log("response:::", response)
			const data = _.get(response, 'data', []);
			if (data) {
				console.log("insideee", data)
				yield put(saveImportedContactsSuccess({
					message: _.get(response, "message", ""),
					data: _.get(response, "data", []),
					numberOfFailedContacts: _.get(response, "numberOfFailedContacts", 0),
					numberOfSavedContacts: _.get(response, "numberOfSavedContacts", 0),
				}));
			} else {
				yield put(saveImportedContactsFail({
					message: _.get(response, "message", ""),
					errors: _.get(response, "errors", {})
				}));
			}
		} else {
			yield put(saveImportedContactsFail({ message: _.get(response, "message", "") }));
		}
	} catch (error) {
		yield put(saveImportedContactsFail({ message: _.get(error, "message", "") }));
	}
}

export function* parseContactsWatcher() {
	yield takeLatest(saveImportedContactsRequest.type, parseContactsWorker);
}

function* getContactsListWorker({ payload }: payloadInterface) {
	// calling the API
	const response = yield* call(marketingSolutionServices.fetchContacts, payload);
	try {
	  if (response.flag === true) {
		yield put(
		  getContactsListSuccess({
			message: _.get(response, "message", ""),
			data: _.get(response, "data", {}),
		  })
		);
	  } else {
		yield put(getContactsListFail({ message: _.get(response, "message", "") }));
	  }
	} catch (error) {
	  yield put(getContactsListFail({ message: _.get(error, "message", "") }));
	}
  }
  
  export function* getContactsListWatcher() {
	yield takeLatest(getContactsListRequest.type, getContactsListWorker);
  }
  


