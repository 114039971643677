import { Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Send as SendIcon,
} from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import _ from "lodash";
import React from "react";
import Chip from "../../../components/common/Chip";
import { companyUserAccessLevel } from "../../../utils/appConstants";
import { UserDataProps } from "./List";
import NoAdminsFound from "./NoAdminsFound";
import Avatar from "../../../components/common/AvtarImage";
interface AdminProps {
  data: UserDataProps[];
  handleAdd: () => any;
  handleEdit: (data: UserDataProps) => void;
  handleDelete: (data: UserDataProps) => void;
  handleOpen: (id: number) => any;
  resendInvitation: (id: number | undefined) => void;
  loading: boolean;
}

const CompanyAdmin = (props: AdminProps) => {
  const {
    data,
    handleAdd,
    handleEdit,
    handleDelete,
    resendInvitation,
    loading,
  } = props;

  // let cards = []
  if (loading) {
    return [1, 2, 3].map((i) => {
      return (
        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
          spacing={2}
          className="admin-card-wrapper"
        >
          <Skeleton className="admin-card-skeleton" />
        </Grid>
      );
    });
  } else if (_.isEmpty(data)) {
    return <NoAdminsFound handleClick={() => handleAdd()} />;
  } else {
    return (data || []).map((admin, i) => {
      const name = _.get(admin, "name", "");
      const profileImage = _.get(admin, "profile_image", "");
      const role = _.get(admin, "role", "");
      const roleText =
        companyUserAccessLevel.find((row) => row.value === role)?.label || "";
      const status = Number(_.get(admin, "status", ""));
      return (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            spacing={2}
            className="admin-info-wrapper"
            key={i}
          >
            <Paper className="admin-info">
              <div className="admin-avatar">
                <Avatar
                  src={profileImage}
                  size="sm"
                />

              </div>
              <div className="admin-intro">
                <div className="admin-name">
                  <Typography className="heading-title">
                    {name} &nbsp;
                    {status === 0 && <Chip label="Pending" size="small" />}
                  </Typography>
                  <Typography className="heading-role">{roleText}</Typography>
                </div>
                <div className="admin-role-btn">
                  {/* <Button className="btn" variant="outlined">
                    Root Access
                  </Button>
                  <Button>User Management</Button>
                  <Button>Analytic Access</Button>
                  <Button>General Access</Button> */}
                </div>
              </div>
              <div className="admin-actions">
                {status === 0 && (
                  <Tooltip title="Resend Invitation" placement="top" arrow>
                    <SendIcon
                      color="primary"
                      onClick={() => resendInvitation(admin.id)}
                    />
                  </Tooltip>
                )}
                <Tooltip title="Change Access" placement="top" arrow>
                  <EditIcon color="primary" onClick={() => handleEdit(admin)} />
                </Tooltip>
                <Tooltip title="Revoke Access" placement="top" arrow>
                  <DeleteIcon
                    color="primary"
                    onClick={() => handleDelete(admin)}
                  />
                </Tooltip>
              </div>
            </Paper>
          </Grid>
        </>
      );
    });
  }
};

export default CompanyAdmin;
