import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import JobList from "./components/List"
import Actions from "./components/Actions"
import { tabChangeRequest } from "../../reducers/company/tabChange.reducer"
import {
  createUpdateJobReset,
  getJobRequest,
  jobAddFilter,
  jobDetailReset,
} from "../../reducers/job/jobs.reducer"
import appRoutes from "../../routes/app.routes"
import { isUndefined } from "lodash"
import { rootReducersState } from "../../reducers"

const _ = { isUndefined }

const Jobs = (props) => {
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(1)
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    companyProfile: {
      data: { slug },
    },
  } = useSelector(({ company }: any) => company)
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  )
  const {
    jobs: { jobFilter },
  } = useSelector(({ job }: any) => job)

  const companySlug = sessionReducer.currentCompany.slug

  const handleTabClick = (type: string) => {
    dispatch(jobAddFilter({ ...jobFilter, page: 1, q: "" }))
    dispatch(tabChangeRequest({ tab: "jobs", innerTab: type }))
    if (type === "Active") {
      history.push(appRoutes.companyActiveJobs.generatePath(companySlug))
    } else if (type === "Archive") {
      history.push(appRoutes.companyJobsArchive.generatePath(companySlug))
    } else if (type === "Drafts") {
      history.push(appRoutes.companyJobsDrafts.generatePath(companySlug))
    } else {
      history.push(appRoutes.companyJobs.generatePath(companySlug))
    }
  }

  const handleClose = () => {
    dispatch(jobDetailReset())
    dispatch(createUpdateJobReset())
    setOpen(false)
  }

  const handleOpen = (_id = undefined) => {
    !_.isUndefined(_id) && dispatch(getJobRequest({ id: _id }))
    setOpen(true)
  }

  const handleSearch = (text: any) => {
    dispatch(jobAddFilter({ ...jobFilter, page: page, q: text.trim() }))
  }

  const setProfilePreview = () => {
    if (!_.isUndefined(slug)) {
      const win = window.open(
        appRoutes.companyPublicPageJobs.generatePath(slug),
        "_blank"
      )
      win.focus()
    }
  }

  const handleButtonClick = (type: string, text: any) => {
    switch (type) {
      case "Add":
        setOpen(true)
        break
      case "Active":
      case "Archive":
      case "Drafts":
      case "All":
        handleTabClick(type)
        break
      case "Search":
        handleSearch(text)
        break
      case "Preview":
        setProfilePreview()
        break
      default:
        break
    }
  }

  return (
    <>
      <Actions handleClick={handleButtonClick} {...props} />
      <div className="company-body-content job-body-content">
        <div className="page-jobs">
          <div className="jobs-wrapper">
            <div className="job-content">
              <div className="job-list">
                <JobList
                  open={open}
                  page={page}
                  setPage={setPage}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  {...props}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Jobs
