import React, { useEffect, useState } from "react";
import { Checkbox, Grid, Paper } from "@material-ui/core";
import {
  AsyncSelect,
  Button,
  EditableText,
  FlashMessage,
  Input,
} from "../../../components/common";
import { useFieldArray, useForm } from "react-hook-form";
import _ from "lodash";
import commonServices from "../../../services/common.services";
import marketingSolutionServices from "../../../services/marketingSolution.services";
import ChipInput from "material-ui-chip-input";
import { AddCircleSharp, CheckCircle } from "@material-ui/icons";
import appRoutes from "../../../routes/app.routes";
import { useSelector } from "react-redux";
import { rootReducersState } from "../../../reducers";
import { useHistory } from "react-router-dom";

let searchDelay: NodeJS.Timeout;
type Inputs = {
  email: string;
  first_name: string;
  last_name: string;
  address_1: string;
  address_2: string;
  postal_zip: string;
  city_id: number;
  birth_day: number;
  birth_month: number;
  phone_number: string;
  email_permission: boolean;
  profile_permission: boolean;
};

const AddSubscriber = () => {
  const { register, handleSubmit, errors, setError, control } =
    useForm<Inputs>();

  const history = useHistory();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [handleAdd, setHandleAdd] = useState<boolean>(false);
  const [tag, setTag] = useState<string>("");
  const [tags, setSubmitChip] = useState([]);
  const { append: tagAppend, remove: tagRemove } = useFieldArray({
    control,
    name: "tags",
  });
  const [tagArray, setTagArray] = useState<Array<any>>([]);
  const [listTag, setListTag] = useState<Array<any>>([]);
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const companySlug = sessionReducer.currentCompany.slug;

  // Search location with delay of 1 second
  const searchLocations = (search: string) =>
    new Promise((resolve) => {
      clearTimeout(searchDelay);
      searchDelay = setTimeout(async () => {
        const locationData = await commonServices.fetchLocations(search);
        const locations = _.get(locationData, "data.data", []).map((c) => ({
          value: c.id,
          label: c.location,
        }));
        resolve(locations);
      }, 1000);
    });
  
  const handleDateOfBirth = (day, month) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const monthText = months[month - 1];

    return `${day}-${monthText}`;
  };

  const onSubmit = async (formData) => {
    const tags = tagArray;
    const payload = {
      email: _.get(formData, "email", ""),
      first_name: _.get(formData, "first_name", ""),
      last_name: _.get(formData, "last_name", ""),
      address_1: _.get(formData, "address_1", ""),
      address_2: _.get(formData, "address_2", ""),
      postal_zip: _.get(formData, "postal_zip", ""),
      city_id: _.get(formData, "city_id.value", ""),
      date_of_birth: handleDateOfBirth(_.get(formData, "birth_day", ""), _.get(
        formData,
        "birth_month",
        ""
      )),
      phone_number: _.get(formData, "contact_info", ""),
      email_permission: _.get(formData, "email_permission", "false"),
      profile_permission: _.get(formData, "profile_permission", "false"),
      tags: tags.map((item) => item.value),
    };
    setSubmitLoading(true);
    try {
      const result = await marketingSolutionServices.addSubscriber(payload);

      if (result.flag) {
        FlashMessage(result.message);
        history.push(appRoutes.contactList.generatePath(companySlug));
        setSubmitLoading(false);
      } else {
        handleErrors(result);
        FlashMessage(result.message, "error");
        setSubmitLoading(false);
      }
    } catch (error) {
      FlashMessage(error?.message, "error");
      setSubmitLoading(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleErrors = (response: any) => {
    _.forEach(response.errors, (value, key: any) => {
      setError(key, { type: "manual", message: value });
    });
  };

  const getTagList = async () => {
    const result = await marketingSolutionServices.getListTag();
    setListTag(result?.data?.rows || []);
  };

  useEffect(() => {
    getTagList();
  }, []);

  const uppendNewTag = (text: any, id: any, status) => {
    const tagsArray = [...tags];
    tagsArray.push(text);
    tagAppend({ title: text });
    setSubmitChip(tagsArray);
    setHandleAdd(status);
    setTagArray([...tagArray, ...[{ label: text, value: id }]]);
  };

  const handleAddTag = async (text: string) => {
    try {
      const result = await marketingSolutionServices.addNewTag({
        tag_name: text,
      });

      if (result.flag) {
        // FlashMessage(result.message);
        uppendNewTag(text, _.get(result, "data.id"), false);
        const newTag = _.get(result, "data", []);
        const merged = [...listTag, ...[newTag]];
        setListTag(merged);
      } else {
        handleErrors(result);
        FlashMessage(result.message, "error");
      }
    } catch (error) {
      FlashMessage(error?.message, "error");
      setSubmitLoading(false);
    }
  };

  const _handleAddChip = (chip) => {
    handleAddTag(chip);
  };

  const _handleDeleteChip = (chip, index) => {
    tagArray.splice(
      tagArray.findIndex((e) => e.label === chip),
      1
    );
    const tagsArray = [...tags];
    if (index > -1) {
      tagsArray.splice(index, 1);
      setSubmitChip(tagsArray);
      tagRemove(index);
    }
  };

  const tagChange = (text) => {
    setTag(text);
  };

  const handleSelect = (row) => {
    const { tag_name, id } = row;
    uppendNewTag(tag_name, id, true);
  };

  const availableTag = (row) => {
    const exist = tagArray.findIndex((e) => e.label === row.tag_name);
    if (exist > -1) {
      return true;
    } else {
      return false;
    }
  };

  const tagCard = (row) => {
    return (
      <div className="pl-5 mt-1">
        {availableTag(row) ? (
          <div className="cursor-pointer selected-tag">
            <CheckCircle className="select-icon" />
            <span className="mb-5">
              {row.tag_name}
              <hr />
            </span>
          </div>
        ) : (
          <div
            className="cursor-pointer ml-20"
            onClick={() => {
              handleSelect(row);
            }}
          >
            {row.tag_name}
            <hr />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Paper className="job-form-wrapper">
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div>
              <div>
                <Grid container spacing={2} xs={12}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Input
                      name="email"
                      externalLabel={{ label: "Email" }}
                      validationObj={errors}
                      placeholder="Enter the email"
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Please enter email address",
                        },
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Enter valid email address",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Input
                      name="first_name"
                      externalLabel={{ label: "First Name" }}
                      validationObj={errors}
                      placeholder="Enter the first name"
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Please enter first name",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Input
                      name="last_name"
                      externalLabel={{ label: "Last Name" }}
                      validationObj={errors}
                      placeholder="Enter the last name"
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Please enter last name",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Input
                      name="address_1"
                      rows={6}
                      externalLabel={{ label: "Address 1" }}
                      placeholder="Address 1"
                      inputRef={register}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Input
                      name="address_2"
                      rows={6}
                      externalLabel={{ label: "Address 2" }}
                      placeholder="Address 2"
                      inputRef={register}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Input
                      name="postal_zip"
                      rows={6}
                      externalLabel={{ label: "Postal zip" }}
                      placeholder="Postal zip"
                      inputRef={register}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <AsyncSelect
                      name="city_id"
                      placeholder="Search City, State or country..."
                      externalLabel={{
                        label: "Location",
                        className: "required",
                      }}
                      onStartSearching={searchLocations}
                      validationObj={errors}
                      control={control}
                      noOptionsMessage="No matching locations found"
                      rules={{
                        required: {
                          value: true,
                          message: "Please select location from list",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Grid container spacing={1}>
                      <label> BirthDay</label>
                      <Grid item xs={2}>
                        <br />
                        <Input
                          type="number"
                          name="birth_day"
                          placeholder="DD"
                          validationObj={errors}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "Please enter day",
                            },
                            pattern: {
                              value: /^([1-9]|[12][0-9]|3[01])$/,
                              message: "Must be enter correct value",
                            },
                          })}
                        ></Input>
                      </Grid>
                      <Grid item xs={2}>
                        <br />
                        <Input
                          type="number"
                          name="birth_month"
                          placeholder="MM"
                          validationObj={errors}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "Please enter month",
                            },
                            pattern: {
                              value: /^([1-9]|1[012])$/,
                              message: "Must be enter correct value",
                            },
                          })}
                        ></Input>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <p>Phone</p>
                    <EditableText
                      control={control}
                      name="contact_info"
                      country="us"
                      error={errors}
                      rules={register({
                        required: { value: true, message: "Phone is required" },
                      })}
                    />
                  </Grid>
                  <Grid>
                    <label className="ml-10">Tags</label>
                    <div className="d-flex ml-10">
                      <div style={{ width: "520px" }}>
                        <ChipInput
                          variant="outlined"
                          placeholder="Add tags"
                          value={tags}
                          onDelete={(chip, index) =>
                            _handleDeleteChip(chip, index)
                          }
                          inputRef={register}
                          className="w-100"
                        />
                      </div>
                      <div
                        onClick={() => {
                          setHandleAdd(true);
                        }}
                      >
                        <AddCircleSharp />
                      </div>
                      {handleAdd && (
                        <div>
                          <Paper className="pt-10 ml-10">
                            <div className="d-flex m-10">
                              <Input
                                name={"testad"}
                                onChange={(e) => {
                                  tagChange(e.target.value);
                                }}
                              />
                              <Button
                                className="ml-10"
                                onClick={(e) => {
                                  _handleAddChip(tag);
                                }}
                              >
                                Add
                              </Button>
                            </div>
                            <div className="m-20 card-body">
                              {(listTag || []).map((row) => {
                                return tagCard(row);
                              })}
                            </div>
                          </Paper>
                        </div>
                      )}
                    </div>
                  </Grid>
                  <div>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Checkbox
                          name="email_permission"
                          inputRef={register({
                            required: {
                              value: false,
                              message: "Please select email permission",
                            },
                          })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <p className="mt-10">
                          This person gave me permission to email them{" "}
                        </p>
                        <p>
                          This person will not receive a confirmation email from
                          Mailchimp. Since you’re adding this recipient
                          manually, they won’t have an opt-in IP address or date
                          in your records, so be extra sure you have permission
                          first.{" "}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Checkbox
                          name="profile_permission"
                          inputRef={register({
                            required: {
                              value: false,
                              message: "Please select profile permission",
                            },
                          })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <p className="mt-10">
                          If this person is already in my audience, update their
                          profile{" "}
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </div>
            </div>
          </div>
          <div className="m-10 mt-20">
            <Button type="submit" loading={submitLoading}>
              Continue
            </Button>
          </div>
        </form>
      </Paper>
    </>
  );
};
export default AddSubscriber;
