import React from "react";
import { useSelector } from "react-redux";
import { Button } from "../../../components/common";
import { getCurrentTab } from "../../../utils/helper";
import { isEmpty, isUndefined } from "lodash";

const _ = { isEmpty, isUndefined };
interface ActionProps {
  handleClick: (type: string, text?: string) => any;
  handleAdminDetailModal: (id: number) => any;
  showDetail: boolean;
}

const Actions = (props: ActionProps) => {
  const { handleClick } = props;
  const { innerTab } = useSelector(({ company }: any) => company);
  const currentSubTab = getCurrentTab(props, true);

  const activeTab = _.isEmpty(innerTab)
    ? _.isUndefined(currentSubTab)
      ? "All"
      : currentSubTab
    : innerTab;
  const checktab = activeTab.toLowerCase();

  return (
    <div className="custom-tab-wrapper">
      <div className="custom-tab-left">
        {
          <ul className="tab-menu">
            <li>
              <Button
                className={`${checktab === "all" ? "active" : ""}`}
                onClick={() => handleClick("All")}
              >
                {" "}
                All{" "}
              </Button>
            </li>
          </ul>
        }
      </div>

      <div className="custom-tab-right">
        <Button className="text-uppercase" onClick={() => handleClick("Add")}>
          Add New
        </Button>
      </div>
    </div>
  );
};

export default Actions;
