import React from "react";
import DefaultHeader from "./DefaultHeader";
import DefaultFooter from "./DefaultFooter";

interface Props {
	children: React.ReactChild
	[key: string]: any
}

const DefaultLayout = ({ children }: Props) => {
	return (
		<div className="default-container">
			<div className="bg-pink-circle"></div>
			<DefaultHeader />
			{children}
			<DefaultFooter />
		</div>
	)
}

export default DefaultLayout;
