import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rootReducersState } from "../../reducers";
import _ from "lodash";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';
import PenSVG from "../../assets/svg/PenSVG";
import Avatar from "../../components/common/AvtarImage";
import { Skeleton } from "@material-ui/lab";
import { fetchUsersListRequest, resetUsersList } from "../../reducers/messages/usersList.reducer";
import { useLocation, useParams } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import Input from "../../components/common/Input";
import { fetchAndClearNewMessageInfo } from "../../utils/helper";
import { useSocket } from "../../components/common/SocketIo";
import ConversationList from "../Messages/components/conversationList";
import moment from "moment";
import MessagesList from "../Messages/components/messagesList";
import Emoji from "../Messages/components/emoji";
import EmojiPicker from "emoji-picker-react";
import { Button, FlashMessage } from "../../components/common";
import {
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  Radio as MuiRadio,
  RadioGroup,
  InputAdornment
} from "@material-ui/core";
import usePrevious from "../../hooks/usePrevious";
import useSessionUser from "../../hooks/useSessionUser";
import messagesServices from "../../services/messages.services";
import { updateMessageCount } from "../../reducers/auth/session.reducer";
import { setMessageSendType } from "../../reducers/common/candidateProfilePersists.reducer";

let tmpActiveRoomDetails = {};

function MessageUser() {
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const userId = _.get(sessionReducer, "currentUser.id", 0);
  const profileImage = _.get(sessionReducer, "currentUser.profile_image", 0);
  let searchDelay: any = null;

  const dispatch = useDispatch();
  const messagesReducer = useSelector(({ message }: any) => message);
  const usersList = _.get(messagesReducer, "usersList.list", []) || [];
  const usersListLoading = _.get(messagesReducer, "usersList.loading", null);
  const params = useParams<{ companySlug: string }>();
  const companySlug = params?.companySlug ? params.companySlug : null;
  const [active, setActive] = useState(true);
  const [activeDiv, setActiveDiv] = useState('focused');
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [messagesList, setMessagesList] = useState<Array<any>>([]);
  const [activeRoomDetails, setActiveRoomDetails] = useState<any>({});
  const [totalMessagesCount, setTotalMessagesCount] = useState<number>(0);
  const [conversationList, setConversationList] = useState<Array<any>>([]);
  const [isSearch, setIsSearch] = useState(false);
  const [messageText, setMessageText] = useState<any>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showMessageList, SetShowMessageList] = useState(true);
  const [openIndex, setOpenIndex] = useState<number>(0);
  const [textIndex, setTextIndex] = useState<number>();
  const [loadMoreMessagesFlag, setLoadMoreMessagesFlag] =
    useState<boolean>(false);
  const [openMessage, setOpenMessage] = useState(false);
  const messageInputRef = useRef<HTMLTextAreaElement | null>(null);
  const [changeRoom, setChangeRoom] = useState(false)
  const [userActive, setUserActive] = useState<any[]>([]);
  const [anchorMenu, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorMenu);
  const candidateMessageCount = _.get(
    sessionReducer,
    "currentUser.candidateMessageCount",
    0
  );
  const tokenData = _.get(sessionReducer, "currentUser", {});
  const messageSendType = useSelector(
    ({ commonPersist }: rootReducersState) => commonPersist.messageSendType
  );
  const prevMessageText = usePrevious(messageText);
  const sessionUser = useSessionUser();
  const [togglePicker, setTogglePicker] = useState<boolean>(false);
  const emojiRef = useRef<any>(null);
  const [isMessagePage, setIsMessagePage] = useState(false)
  const hideEmojis = (event) => {
    if (emojiRef.current && !emojiRef.current.contains(event.target)) {
      setTogglePicker(false);
    }
  };
  const chatMesRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const path = location.pathname
  const socketInstance = useSocket();
  const handleDivClick = (divName) => {
    setActiveDiv(divName);
  };

  const _handleSearchUsers = ({ currentTarget }) => {

    const { value } = currentTarget;

    clearTimeout(searchDelay);

    if ((value || "").trim()) {
      searchDelay = setTimeout(async () => {
        dispatch(
          fetchUsersListRequest({
            q: (value || "").trim(),
            companySlug: companySlug,
          })
        );
      }, 1000);
    } else {
      dispatch(resetUsersList());
    }
  };



  const conversationFetchedRef = useRef(false);
  // const socketConnection = socketInstance.connected;

  const resetData = () => {
    setMessagesList([]);
    setConversationList([]);
    setMessageText("");
    setActiveRoomDetails({});
    setTotalMessagesCount(0);
    setCurrentPage(1);
    // Reset other states as necessary
  };
  useEffect(() => {
    // Define event handlers
    const handleInviteRoom = (data) => {
      const { chatRoom } = data;
      socketInstance.emit("joinRoom", chatRoom);
    };
    const fetchConversationList = () => {

      // setListLoading(true);
      conversationFetchedRef.current = false;
      socketInstance.emit('fetchConversationList', { userId, companySlug });
    };

    fetchConversationList();

    const handleFetchConversationList = async (data) => {

      // if (conversationFetchedRef.current) {
      //   return;  // Prevent handling the same event multiple times
      // }

      // conversationFetchedRef.current = true;

      setListLoading(false);
      const tmpList = _.get(data, "conversationList.data", []);
      setConversationList(tmpList);

      tmpList.forEach(conversation => {
        const chatRoom = conversation.chatRoom;
        socketInstance.emit("joinRoom", chatRoom);
      });

      const chatData = fetchAndClearNewMessageInfo();
      if (_.get(chatData, "uuid", "")) {
        await _handleInitializeNewChat(chatData, tmpList);
      } else if (!activeRoomDetails?.uuid && tmpList?.length > 0) {
        const firstConversation = tmpList[0];
        const chatRoom = firstConversation.chatRoom;
        setActiveRoomDetails(firstConversation);
        tmpActiveRoomDetails = firstConversation;
        socketInstance.emit("createRoom", chatRoom);
        setCurrentPage(1);
      }
    };

    const handleEmitMessage = (data) => {

      const messageData = _.get(data, "newPayload", {});
      const unreadCount = _.get(data, "newPayload.unreadMessageCount");
      if (typeof messageData === "object") {
        setConversationList((prevList) =>

          [...prevList].map((row) => {

            if (row.chatRoom === messageData.chatRoom) {
              const updateRow = {
                ...row,
                new: true,
                unread_count: unreadCount,
              }
              _handleClickConversion(updateRow);
              setChangeRoom(false);
              return {
                ...row,
                lastMessage: messageData.message,
                lastMessageOn: messageData.timeStamp,
                unread_count: unreadCount,
              };
            }
            return row;
          })
        );

        setUserActive((prevUserActive) => {
          const existingIndex = prevUserActive.findIndex(item => item.chatRoom === messageData.chatRoom);
          if (existingIndex !== -1) {
            const updatedUserActive = [...prevUserActive];
            updatedUserActive[existingIndex] = {
              ...updatedUserActive[existingIndex],
              messagesList: [
                ...(updatedUserActive[existingIndex].messagesList || []),
                { ...messageData }
              ]
            };
            return updatedUserActive;
          }
          return prevUserActive;
        });
      }
    };

    const handleFetchChatMessages = (data) => {
      const chatRoom = data.messagesList.roomId;
      const candidateMessageCount = data.messagesList.count;
      const totalUnread = data.messagesList.totalCount.count;
      setConversationList((prevList) =>
        [...prevList].map((row) => {
          if (row.chatRoom === chatRoom) {
            return {
              ...row,
              unread_count: candidateMessageCount,
            };
          }
          return row;
        })
      );
      const messageCountPayload = {
        candidateMessageCount: totalUnread,
        companyMessageCount: companySlug ? totalUnread : 0
      };
      dispatch(updateMessageCount(messageCountPayload));

      setTotalMessagesCount(_.get(data, "messagesList.data.count", 0));

      let tmpMessagesList = (
        _.get(data, "messagesList.data.rows", []) || []
      ).map((row) => ({
        chatRoom: row.room_id,
        fromUser: row.user_id,
        message: row.message,
        timeStamp: moment(row.created_at).format(),
        messageFrom: row.user_id === _.get(tmpActiveRoomDetails, "senderId", 0) ? "me" : "",
      }));

      const page = _.get(data, "messagesList.pagination.page", 0);
      tmpMessagesList = _.reverse(tmpMessagesList);
      setMessagesList((prevMessages) => [...tmpMessagesList, ...prevMessages]);
      setLoadMoreMessagesFlag(false);

      if (page === 1) {
        scrollToDown();
      }

      setUserActive((prevUserActive) => {
        const existingIndex = prevUserActive.findIndex(item => item.chatRoom === chatRoom);
        if (existingIndex !== -1) {
          const updatedUserActive = [...prevUserActive];
          updatedUserActive[existingIndex] = {
            ...updatedUserActive[existingIndex],
            messagesList: [
              ...tmpMessagesList,
              ...(updatedUserActive[existingIndex].messagesList || [])
            ]
          };
          return updatedUserActive;
        }
        return prevUserActive;
      });
    };


    socketInstance.on("inviteRoom", handleInviteRoom);
    socketInstance.on("conversationListFetched", handleFetchConversationList);
    socketInstance.on("emitMessage", handleEmitMessage);
    socketInstance.on("fetchChatMessages", handleFetchChatMessages);

    // Emit fetchConversationList
    // socketInstance.emit("fetchConversationList", { userId, companySlug });

    return () => {
      // Cleanup
      document.removeEventListener("click", hideEmojis, true);
      resetData();

      // // Detach event listeners
      // socketInstance.off("inviteRoom", handleInviteRoom);
      // socketInstance.off("conversationListFetched", handleFetchConversationList);
      // socketInstance.off("emitMessage", handleEmitMessage);
      // socketInstance.off("fetchChatMessages", handleFetchChatMessages);


    };

    // eslint-disable-next-line
  }, [socketInstance, userId, companySlug]);

  useEffect(() => {
    if (path === '/messages') {
      setIsMessagePage(true);
      setActive(false);
      setUserActive((prevUserActive) =>
        prevUserActive.map(item => ({
          ...item,
          active: false
        }))
      );
    } else {
      setIsMessagePage(false)
      setActive(true)
    }
  }, [path]);

  const handleFocus = () => {
    setTextIndex(activeRoomDetails.chatRoom)
  }

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    focusOnTextInput();
  }, [textIndex]);

  const focusOnTextInput = () => {
    setTimeout(() => {
      messageInputRef?.current?.focus();
    }, 500);
  };
  useEffect(() => {
    if (activeRoomDetails.chatRoom) {
      handleFocus();
    }
    // eslint-disable-next-line
  }, [activeRoomDetails])
  const scrollToDown = () => {
    const chatMessageList =
      chatMesRef?.current?.querySelector("#chat-message-list");
    if (chatMessageList) {
      chatMessageList.scrollTop = chatMessageList.scrollHeight + 2000;
    }
  };

  const onSendMessage = () => {
    const finalMessage = (messageText?.trim() || "").replace(
      /\r?\n|\r/g,
      "<br />"
    );

    if (!finalMessage) {
      setMessageText(prevMessageText || "");
      FlashMessage("Please type something", "error");
      return;
    }
    const lastMessageOn = moment().format();
    const payload = {
      chatRoom: activeRoomDetails.chatRoom,
      isCompany: activeRoomDetails?.isCompany || null,
      fromUser: activeRoomDetails?.senderId || 0,
      companyHandler: sessionUser.sessionUserId,
      message: finalMessage,
      timeStamp: lastMessageOn,
    };

    // Send the message
    socketInstance.emit("emitMessage", payload);

    setMessagesList((prevMsg) => [
      ...prevMsg,
      { ...payload, messageFrom: "me" },
    ]);
    setUserActive((prevUserActive) => {
      const existingIndex = prevUserActive.findIndex(item => item.chatRoom === payload.chatRoom);

      if (existingIndex !== -1) {
        // Update the active flag to false
        const updatedUserActive = [...prevUserActive];
        updatedUserActive[existingIndex] = {
          ...updatedUserActive[existingIndex],
          messagesList: [
            // Prepend new messages to the list
            ...(updatedUserActive[existingIndex].messagesList || []),
            { ...payload, messageFrom: "me" }
          ]
        };
        return updatedUserActive;
      }

      return prevUserActive; // If the room was not found, return unchanged
    });

    // Clear message
    setMessageText("");
    setTogglePicker(false);

    // Set last message in the left chat list
    setConversationList((prevList) =>
      [...prevList].map((row) => {
        if (row.chatRoom === activeRoomDetails.chatRoom) {
          return { ...row, lastMessage: finalMessage, lastMessageOn };
        }
        return row;
      })
    );

    setTimeout(() => scrollToDown(), 10);
  };

  const handleOpenRoom = (room) => {
    if (!room) {
      return;
    }
    // Find if room already exists in userActive
    const roomData = userActive.find(item => item.chatRoom === room);
    if (roomData) {
      _handleClickConversion(roomData);
    } else {
      console.error('Room data not found in userActive');
    }

  };

  const _handleClickConversion = (payload) => {

    setOpenMessage(true);
    setActiveRoomDetails('')
    if (activeRoomDetails.chatRoom !== payload.chatRoom) {
      setMessagesList([]); // reset messages list
      const chatRoom = payload.chatRoom;
      setActiveRoomDetails(payload);
      tmpActiveRoomDetails = payload;
      setUserActive((prevUserActive) => {
        const existingIndex = prevUserActive.findIndex(item => item.chatRoom === payload.chatRoom);
        if (existingIndex !== -1) {
          // Update the active flag to false
          const updatedUserActive = [...prevUserActive];
          updatedUserActive[existingIndex] = { ...updatedUserActive[existingIndex], active: !updatedUserActive[existingIndex].active };
          return updatedUserActive;
        }
        return prevUserActive; // If the room was not found, return unchanged
      });


      if (!changeRoom) {

        setUserActive(prevUserActive => {
          const existingIndex = prevUserActive.findIndex(item => item.chatRoom === payload.chatRoom);
          const updatedPayload = {
            ...payload,
            active: isMessagePage ? false : true,
            new: payload.new ? true : false,
            activeRoomDetails: payload
          };
          if (existingIndex !== -1) {
            // Room is already in the list, update its details
            const updatedUserActive = [...prevUserActive];
            updatedUserActive[existingIndex] = {
              ...updatedUserActive[existingIndex],
              ...updatedPayload,
              active: !updatedUserActive[existingIndex].active // Toggle active status
            };
            return updatedUserActive;

          }

          // Start Room
          socketInstance.emit("createRoom", chatRoom);

          // Fetch chat room messages + Reset the previous chat data only when change the room
          setCurrentPage(1);
          socketInstance.emit("fetchChatMessages", {
            userId,
            chatRoom,
            isCompany: payload.isCompany,
            page: 1,
            companySlug,
            isNew: true,
          });
          // Room is not in the list, add it and keep only the last 3
          setOpenIndex(payload.chatRoom);
          return [...prevUserActive, updatedPayload].slice(-3);
        });
      }
      else {
        setOpenIndex(payload.chatRoom)
      }

      // Clear the message
      setMessageText("");

      // Focus on text input after conversation is selected
      // focusOnTextInput();
    }

  };


  const _handleInitializeNewChat = async (data, tmpList = []) => {

    setOpenMessage(true)
    const result = await messagesServices.checkChatRoom({
      id: data.uuid,
      companySlug: companySlug,
    });

    if (_.get(result, "data.data")) {
      const roomData: any = _.get(result, "data.data", {});

      const chatRoom = roomData.chatRoom;
      const chatRoomSplit = roomData?.chatRoom?.split("_");
      const chatRoomData = {
        chatRoom: roomData.chatRoom,
        id: roomData.id,
        lastMessage: roomData.lastMessage,
        lastMessageOn: roomData.lastMessageOn,
        slug: data.slug,
        isCompany: data?.isCompany || null,
        senderId: Number(chatRoomSplit[0] || 0),
        receiverId: Number(chatRoomSplit[1] || 0),
        receiverName: `${data.first_name} ${data.last_name}`,
        receiverProfile: data.profile_image,
        redirect: data?.isCompany ? "company" : "candidate",
        roomUUID: roomData.roomUUID,
      };

      // Only for new conversion which are coming from other places
      const newConversationList = _.isEmpty(tmpList)
        ? conversationList
        : tmpList;

      // Check if conversion already exists
      const exists = _.find([...newConversationList], { chatRoom });

      if (exists) {
        setConversationList((prevList) =>
          [...newConversationList].map((row) => {
            if (row.chatRoom === chatRoom) {
              return chatRoomData;
            }
            return row;
          })
        );
      } else {
        setConversationList((prevList) => [
          { ...chatRoomData },
          ...newConversationList,
        ]);
      }

      // Initialize the chat room and close the window gently

      setMessagesList([]); // reset messages list
      setActiveRoomDetails(chatRoomData);
      tmpActiveRoomDetails = chatRoomData;

      if (socketInstance) {
        // Start Room
        socketInstance.emit("createRoom", chatRoom);

        // Fetch chat room messages + Reset the previous chat data only when change the room
        setCurrentPage(1);
        // _handleCloseNewMessageModal();
        socketInstance.emit("fetchChatMessages", {
          chatRoom,
          isCompany: data?.isCompany || null,
          page: 1,
        });
      } else {
        FlashMessage("Something went wrong while initialing message", "error");
      }
    } else {
      FlashMessage("Something went wrong while initialing message", "error");
    }
  };

  const loadMoreMessages = () => {
    // Check if we have loaded all messages
    if (messagesList.length < totalMessagesCount && !loadMoreMessagesFlag) {
      setLoadMoreMessagesFlag(true);

      // Fetch chat room messages + Reset the previous chat data only when change the room
      const chatRoom = activeRoomDetails.chatRoom;
      const page = currentPage + 1;
      setCurrentPage(page);
      socketInstance.emit("fetchChatMessages", {
        userId,
        chatRoom,
        isCompany: activeRoomDetails?.isCompany || null,
        page,
        companySlug,
        isNew: true
      });
    }
  };

  const onClickReadMessage = (chatRoom: string) => {

    const roomId = chatRoom
    socketInstance.emit("ReadMessage", {
      userId,
      roomId,
      isCompany: activeRoomDetails?.isCompany || null,
      companySlug,
    });
    setUserActive((prevUserActive) => {
      const existingIndex = prevUserActive.findIndex(item => item.chatRoom === chatRoom);
      if (existingIndex !== -1) {
        // Update the active flag to false
        const updatedUserActive = [...prevUserActive];
        updatedUserActive[existingIndex] = { ...updatedUserActive[existingIndex], unread_count: 0, new: false };
        return updatedUserActive;
      }
      return prevUserActive;
    });

    // Set last message in the conversation chat
    setConversationList((prevList) =>
      [...prevList].map((row) => {
        if (row.chatRoom === chatRoom) {
          return {
            ...row,
            unread_count: 0,
          };
        }
        return row;
      })
    );

    socketInstance.on("ReadMessage", (data) => {
      const totalUnread = data.conversationList.totalCount.count
      const messageCountPayload = {
        candidateMessageCount: totalUnread,
        companyMessageCount: companySlug ? totalUnread : 0
      }
      dispatch(updateMessageCount(messageCountPayload));
    })

  }

  const handleRemove = (chatRoom: string) => {
    setUserActive((prevUserActive) => {
      const existingIndex = prevUserActive.findIndex(item => item.chatRoom === chatRoom);

      if (existingIndex !== -1) {  // Check if index is found
        const updatedUserActive = [...prevUserActive];
        updatedUserActive.splice(existingIndex, 1); // Remove the item at the found index
        return updatedUserActive;
      }

      return prevUserActive; // If the room was not found, return unchanged
    });

    SetShowMessageList(true);
  };


  return (
    <>
      {tokenData && Object.keys(tokenData).length > 0 && (
        <div>

          <div className="message-pop-up">
            {(openMessage && userActive) && (
              <div className="message-user-data" >
                {userActive.map((e, index) => (
                  <div className="sub-message-user-data" key={e.id}  >
                    <div className={`message-container-header ${e.new && "active"}`} onClick={() => { handleOpenRoom(e.chatRoom); setChangeRoom(true) }}>
                      <div className="message-header-left"
                        onClick={() => {
                          if (openIndex === e.chatRoom && showMessageList) {
                            SetShowMessageList(false); // Close the message list if the same item is clicked
                          } else {
                            SetShowMessageList(true); // Open the message list for a new item
                          }
                        }}
                      >
                        <Avatar
                          src={e.receiverProfile}
                          type={(e?.isCompany) ? "company" : "candidate"}
                        />
                        <div className="title">{e.receiverName}{e.unread_count > 0 && (<span className="unread-count" >{e.unread_count}</span>)} </div>
                      </div>
                      <div className="message-header-right">
                        <MoreHorizIcon />
                        <PenSVG />
                        <div className="icon" onClick={() => {
                          handleRemove(e.chatRoom)
                        }
                        }>
                          <CloseIcon />
                        </div>
                      </div>
                    </div>
                    {e.active && (
                      <div className="message-list-data" key={index}>
                        <MessagesList
                          messagesList={e.messagesList}
                          activeRoomDetails={e.activeRoomDetails}
                          loadMoreMessages={() => loadMoreMessages()}
                        />
                        <div className="chat-form" onClick={() => { setTextIndex(e.chatRoom); onClickReadMessage(e.chatRoom) }}>
                          {textIndex === e.chatRoom ? (
                            <>
                              <textarea
                                rows={4}
                                ref={messageInputRef}
                                // value={messageText}
                                value={e.messagesList && e.messagesList.length === 0 && !messageText
                                  ? `Hi ${e.receiverName},\n\nIt's great connecting with you. How have you been?`
                                  : messageText}

                                onChange={(e) =>
                                  setMessageText(e?.target?.value || "")
                                }
                                onKeyUp={(e) => {
                                  if (
                                    messageSendType === "enterToSend" &&
                                    e.key === "Enter"
                                  ) {
                                    e.preventDefault();
                                    onSendMessage();
                                  }
                                }}
                                placeholder="Type a message..."
                              />

                            </>
                          ) : (
                            <textarea
                              rows={4}
                              placeholder="Type a message..."
                            />
                          )}
                          {/* <textarea
                        rows={4}
                        ref={messageInputRef}
                        value={messageText}
                        onChange={(e) =>
                          setMessageText(e?.currentTarget?.value || "")
                        }
                        onKeyUp={(e) => {
                          if (
                            messageSendType === "enterToSend" &&
                            e.key === "Enter"
                          ) {
                            e.preventDefault();
                            onSendMessage();
                          }
                        }}
                        placeholder="Type a message..."
                      /> */}
                        </div>
                        <div className="message-send-action">
                          <div className="send-left">
                            <div className="emoji-wrapper-common-message" ref={emojiRef}>
                              <Emoji
                                onClick={() =>
                                  setTogglePicker((prevState) => !prevState)
                                }
                              />
                              {togglePicker && textIndex === e.chatRoom && (
                                <EmojiPicker
                                  onEmojiClick={(e) => {

                                    setMessageText(
                                      (prevMsg) => prevMsg + " " + e.emoji
                                    )
                                    setTogglePicker((prevState) => !prevState)
                                  }
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <div className="send-right">
                            {textIndex === e.chatRoom ? (
                              <>
                                {messageSendType === "clickToSend" && (
                                  <Button
                                    aria-label="send"
                                    className="message-submit-btn"
                                    disabled={!messageText}
                                    onClick={() => onSendMessage()}
                                  >
                                    Send
                                  </Button>
                                )}
                                {messageSendType === "enterToSend" && (
                                  <small>Press Enter to Send</small>
                                )}
                                <IconButton
                                  aria-label="more"
                                  size="small"
                                  aria-controls="long-menu"
                                  aria-haspopup="true"
                                  onClick={handleOpenMenu}
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                                <Menu
                                  anchorEl={anchorMenu}
                                  keepMounted
                                  open={openMenu}
                                  classes={{ paper: "border-radius-md" }}
                                  onClose={handleMenuClose}
                                >
                                  <RadioGroup
                                    aria-label="gender"
                                    name="gender1"
                                    className="pt-5 pb-5 pl-10 pr-10"
                                    value={messageSendType}
                                    onChange={(e) => {
                                      dispatch(
                                        setMessageSendType(e.currentTarget.value)
                                      );
                                      handleMenuClose();
                                    }}
                                  >
                                    <FormControlLabel
                                      value="enterToSend"
                                      control={<MuiRadio />}
                                      label={
                                        <div className="flex-column">
                                          <span>Press Enter to Send</span>
                                          <small>Press Enter will send message</small>
                                        </div>
                                      }
                                    />
                                    <FormControlLabel
                                      value="clickToSend"
                                      control={<MuiRadio />}
                                      label={
                                        <div className="flex-column">
                                          <span>Click Send</span>
                                          <small>
                                            Clicking Send button will send message
                                          </small>
                                        </div>
                                      }
                                    />
                                  </RadioGroup>
                                </Menu>
                              </>

                            ) : (
                              <>
                                {messageSendType === "clickToSend" && (
                                  <Button
                                    aria-label="send"
                                    className="message-submit-btn"
                                    disabled
                                  >
                                    Send
                                  </Button>
                                )}
                                {messageSendType === "enterToSend" && (
                                  <small>Press Enter to Send</small>
                                )}
                                <IconButton
                                  aria-label="more"
                                  size="small"
                                  aria-controls="long-menu"
                                  aria-haspopup="true"
                                  onClick={handleOpenMenu}
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                                <Menu
                                  anchorEl={anchorMenu}
                                  keepMounted
                                  open={openMenu}
                                  classes={{ paper: "border-radius-md" }}
                                  onClose={handleMenuClose}
                                >
                                  <RadioGroup
                                    aria-label="gender"
                                    name="gender1"
                                    className="pt-5 pb-5 pl-10 pr-10"
                                    value={messageSendType}
                                    onChange={(e) => {
                                      dispatch(
                                        setMessageSendType(e.currentTarget.value)
                                      );
                                      handleMenuClose();
                                    }}
                                  >
                                    <FormControlLabel
                                      value="enterToSend"
                                      control={<MuiRadio />}
                                      label={
                                        <div className="flex-column">
                                          <span>Press Enter to Send</span>
                                          <small>Press Enter will send message</small>
                                        </div>
                                      }
                                    />
                                    <FormControlLabel
                                      value="clickToSend"
                                      control={<MuiRadio />}
                                      label={
                                        <div className="flex-column">
                                          <span>Click Send</span>
                                          <small>
                                            Clicking Send button will send message
                                          </small>
                                        </div>
                                      }
                                    />
                                  </RadioGroup>
                                </Menu>
                              </>
                            )}


                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  // </div>
                ))}
              </div>

            )}
          </div >
          <div className="main-message-container">
            <div className="sub-message-container">

              <div className="main-list-message-container">
                <div className="message-container">
                  <div className="message-header-left">
                    <Avatar src={profileImage} alt="" />
                    <div className="title">Messaging {candidateMessageCount > 0 && (<span className="unread-count">{candidateMessageCount}</span>)}</div>

                  </div>
                  <div className="message-header-right">
                    <MoreHorizIcon />
                    <PenSVG />
                    <div className="icon" onClick={() => setActive(!active)}>
                      {active ? <div className="triangle-down" /> : <div className="triangle-left" />}</div>
                  </div>
                </div>
                {active && (
                  <div className="message-container">
                    {/* <HeaderSearchBar /> */}
                    <div className="user-search-data">
                      <div className='user-searchBar' onClick={() => setIsSearch(true)}>
                        <Input
                          name="input_search"
                          className="navbar-searchBox"
                          type="text"
                          variant="outlined"
                          size="small"
                          placeholder="Search"
                          autoComplete="off"
                          onChange={(e) => _handleSearchUsers(e)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="end"
                                className="navbar-searchBoxIconContainer"
                              >
                                <SearchIcon className="navbar-searchBoxIcon" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div className="focused-container">
                        <div
                          className={`item ${activeDiv === 'focused' ? 'active' : ''}`}
                          onClick={() => { handleDivClick('focused'); setIsSearch(false) }}
                        >
                          Focused
                        </div>
                        <div
                          className={`item ${activeDiv === 'other' ? 'active' : ''}`}
                          onClick={() => handleDivClick('other')}
                        >
                          Other
                        </div>
                      </div>


                      {isSearch ? (
                        <Grid container spacing={1} className="messages-modal">
                          <Grid item xs={12}>
                            <ul className="user-msg-list slim-scrollbar">
                              {usersList.length === 0 ? (
                                <div className="text-center pink-text">
                                  Search Your message by recipient, content, or conversation name
                                </div>
                              ) : (
                                <>
                                  {!usersListLoading ? (

                                    usersList.length === 0 && usersListLoading === false ? (
                                      <div className="text-center">No results found</div>
                                    ) : (
                                      usersList.map((row, key) => (
                                        <li
                                          key={key}
                                          className="d-flex"
                                          onClick={() => _handleInitializeNewChat(row)}
                                        >
                                          <Avatar
                                            src={_.get(row, "profile_image", "")}
                                            size="xsm"
                                          />
                                          <div className="ml-15">
                                            <span>{`${row.first_name} ${row.last_name}`}</span>
                                            <p className="m-0 font-sm mt-1 text-gray">
                                              {row.receiverJobTitle}
                                            </p>
                                          </div>
                                        </li>
                                      ))
                                    )
                                  ) : (
                                    [1, 2, 3].map((key) => (
                                      <li key={key} className="d-flex">
                                        <Skeleton variant="circle" width={40} height={40} />
                                        <span className="ml-15">
                                          <Skeleton variant="text" width={155} />
                                        </span>
                                      </li>
                                    ))
                                  )}</>
                              )}

                            </ul>
                          </Grid>
                        </Grid>
                      ) : (
                        <ConversationList
                          conversationList={conversationList}
                          activeRoomDetails={activeRoomDetails}
                          loading={listLoading}
                          onClickConversion={(payload) => {
                            _handleClickConversion(payload);
                            setChangeRoom(false);

                          }}

                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>
      )}
    </>
  );
}

export default MessageUser;
