import httpRequest from "../utils/httpRequest";
import _ from "lodash";
import { objectToParams } from "../utils/helper";
import API_URLS from "../../src/utils/apiUrls";

export const createPost = (payload: any) => {
  return httpRequest()
    .post(API_URLS.posts, payload)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const getList = (payload: any) => {
  const queryString = objectToParams(payload);
  return httpRequest()
    .get(`${API_URLS.posts}?${queryString}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

// like api
export const postLike = (payload: any) => {
  return httpRequest()
    .post(`${API_URLS.posts}/like`, payload)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

// details api
export const feedDetails = (payload: any) => {
  return httpRequest()
    .get(`${API_URLS.posts}/${payload}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

// edit api

export const editPost = (payload: any) => {
  return httpRequest()
    .put(`${API_URLS.posts}/${payload.id}`, payload.payloadData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};
// delete Feed

export const deleteFeed = (id: any) => {
  return httpRequest()
    .delete(`${API_URLS.posts}/${id}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(
          err,
          "response.data.message",
          "Something went wrong while deleting"
        ),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

// add comment
export const commentPost = (payload: any) => {
  return httpRequest()
    .post(`${API_URLS.posts}/comment`, payload)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

// load more comments
export const getCommentList = (payload: any) => {
  const queryString = objectToParams(payload.query);
  return httpRequest()
    .get(`${API_URLS.posts}/comments/${payload.id}?${queryString}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

// load more comments
export const getReplyList = (payload: any) => {
  const queryString = objectToParams(payload.query);
  return httpRequest()
    .get(`${API_URLS.posts}/replies/${payload.id}?${queryString}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};
// edit api

export const editComment = (payload: any) => {
  return httpRequest()
    .put(`${API_URLS.posts}/comment/${payload.id}`, payload)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

// delete comment

export const deleteComment = (payload: any) => {
  const bodyData = {
    postId: payload.postId,
    isLast: payload.isLast,
  };

  return httpRequest()
    .delete(`${API_URLS.posts}/comment/${payload.id}`, { data: bodyData })
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
        payload: _.get(res, "payload", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(
          err,
          "response.data.message",
          "Something went wrong while deleting"
        ),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

// comment like api
export const commentLike = (payload: any) => {
  return httpRequest()
    .post(`${API_URLS.posts}/comment/like`, payload)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

// comment like api
export const hashTagPosts = async (payload: any) => {
  const queryString = objectToParams(payload);
  return httpRequest()
    .get(`${API_URLS.posts}/hash-tag/${payload.searchParams}?${queryString}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const myPost = async (payload: any) => {
  const queryString = objectToParams(payload);
  return httpRequest()
    .get(`${API_URLS.posts}/my/?${queryString}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const myArticles = async (payload: any) => {
  const queryString = objectToParams(payload);
  return httpRequest()
    .get(`${API_URLS.posts}/my/article/?${queryString}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

// get followed candidates and company
export const getMentioned = async (search: string) => {
  return httpRequest()
    .get(`${API_URLS.posts}/mentioned/candidate/${search}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const feedPageServices = {
  createPost,
  getList,
  postLike,
  feedDetails,
  editPost,
  deleteFeed,
  commentPost,
  getCommentList,
  editComment,
  deleteComment,
  getReplyList,
  commentLike,
  hashTagPosts,
  getMentioned,
  myArticles
};

export default feedPageServices;
