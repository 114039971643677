import React from "react";

import CompanyQuote from "./components/CompanyAbout";
import CompanyVideos from "./components/CompanyVideo";
import CompanyLeaders from "./components/CompanyLeaders";
// import Recommendations from "./components/Recommendations";

const PublicHome = () => {
	return (
		<div className="company-page-profile-container">
			<CompanyQuote />
			<CompanyLeaders />
			<CompanyVideos />
			{/* <Recommendations /> */}
		</div>
	)
}

export default PublicHome;
