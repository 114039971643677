import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, FlashMessage } from "../../components/common";
import _ from "lodash";
import candidateServices from "../../services/candidate.services";
import { Grid } from "@material-ui/core";
import { rootReducersState } from "../../reducers";
import { updateCandidateNotifications } from "../../reducers/auth/session.reducer";

const Notifications = () => {
  const dispatch = useDispatch();
  const [alertKey, setAlertKey] = useState<number>(0);
  const [alerts, setAlerts] = useState<object>({});
  const [activityKey, setActivityKey] = useState<number>(0);
  const [activities, setActivities] = useState<object>({});
  const [supportKey, setSupportKey] = useState<number>(0);
  const [supports, setSupports] = useState<object>({});
  const [alertsToggle, setAlertsToggle] = useState<boolean>(false);
  const [activitiesToggle, setActivitiesToggle] = useState<boolean>(false);
  const [supportsToggle, setSupportsToggle] = useState<boolean>(false);

  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session.currentUser
  );

  useEffect(() => {
    if (_.isEmpty(_.get(sessionReducer, "alert_notifications", {}))) {
      setAlerts({
        communication: "no",
        activity: "no",
        reminders: "no",
      });
    } else {
      setAlerts(JSON.parse(_.get(sessionReducer, "alert_notifications", {})));
    }
    if (_.isEmpty(_.get(sessionReducer, "activity_notifications", {}))) {
      setActivities({
        comment: "no",
        mention: "no",
        invite: "no",
        accepts_invitation: "no",
        follow: "no",
      });
    } else {
      setActivities(
        JSON.parse(_.get(sessionReducer, "activity_notifications", {}))
      );
    }
    if (_.isEmpty(_.get(sessionReducer, "support_notifications", {}))) {
      setSupports({
        privacy_policy: "no",
        unsubscribe: "no",
      });
    } else {
      setSupports(
        JSON.parse(_.get(sessionReducer, "support_notifications", {}))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlertsChange = (event) => {
    const { checked, name } = event.target;
    checked ? (alerts[name] = "yes") : (alerts[name] = "no");
    setAlerts(alerts);
    setAlertKey((alertKey) => alertKey + 1);
  };

  const handleActivityChange = (event) => {
    const { checked, name } = event.target;
    checked ? (activities[name] = "yes") : (activities[name] = "no");
    setActivities(activities);
    setActivityKey((activityKey) => activityKey + 1);
  };

  const handleSupportChange = (event) => {
    const { checked, name } = event.target;
    checked ? (supports[name] = "yes") : (supports[name] = "no");
    setSupports(supports);
    setSupportKey((supportKey) => supportKey + 1);
  };

  const handleAlertsToggle = (data, status) => {
    setAlertsToggle(status);
    Object.keys(data).forEach(function (key) {
      data[key] = status ? "yes" : "no";
    });
    setAlertKey((alertKey) => alertKey + 1);
  };

  const handleActivityToggle = (data, status) => {
    setActivitiesToggle(status);
    Object.keys(data).forEach(function (key) {
      data[key] = status ? "yes" : "no";
    });
    setActivityKey((activityKey) => activityKey + 1);
  };

  const handleSupportsToggle = (data, status) => {
    setSupportsToggle(status);
    Object.keys(data).forEach(function (key) {
      data[key] = status ? "yes" : "no";
    });
    setSupportKey((supportKey) => supportKey + 1);
  };

  const handleUpdateNotification = async () => {
    const payload = {
      alerts,
      activities,
      supports,
    };
    try {
      const result = await candidateServices.updateNotifications({
        payload,
      });

      if (result.flag) {
        FlashMessage(result.message, "success");
        dispatch(updateCandidateNotifications(payload));
      } else {
        FlashMessage(result.message, "error");
      }
    } catch (error) {
      FlashMessage(error?.message, "error");
    }
  };

  return (
    <div className="profile-container">
      <div>
        <h2 className="title">Notifications</h2>
        <p>
          Choose notification preferences and how you want to be contacted.
          Controls to make sure you only get notified about what’s important to
          you.
        </p>
        <div>
          <Grid container justify="space-between">
            <Grid item md={10} className="notification-card-left">
              <h5 className="mt-10">Alerts & Notifications</h5>
            </Grid>
            <Grid item md={2} className="d-flex justify-content-between">
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  handleAlertsToggle(alerts, !alertsToggle);
                }}
              >
                Toggle All
              </Button>
            </Grid>
          </Grid>

          <div>
            <div className="d-flex form-group" key={alertKey}>
              <Checkbox
                checked={
                  _.get(alerts, "communication", "") === "yes" ? true : false
                }
                name="communication"
                onChange={(e) => {
                  handleAlertsChange(e);
                }}
                id="communication"
              />
              <span>
                <label htmlFor="communication">
                  employ<span className="title">HER</span> Communication
                  <br />
                  <span className="info-text">
                    Get employ<span className="title">HER</span> news,
                    announcements, and products updates
                  </span>
                </label>
              </span>
            </div>
            <div className="d-flex form-group">
              <Checkbox
                checked={_.get(alerts, "activity", "") === "yes" ? true : false}
                name="activity"
                onChange={(e) => {
                  handleAlertsChange(e);
                }}
                id="activity"
              />
              <span>
                <label htmlFor="activity">
                  employ<span className="title">HER</span> Activity <br />
                  <span className="info-text">
                    Get important notifications about you or activity you’ve
                    missed
                  </span>
                </label>
              </span>
            </div>
            <div className="d-flex form-group">
              <Checkbox
                checked={
                  _.get(alerts, "reminders", "") === "yes" ? true : false
                }
                name="reminders"
                onChange={(e) => {
                  handleAlertsChange(e);
                }}
                id="reminders"
              />
              <span>
                <label htmlFor="reminders">
                  Reminders
                  <br />
                  <span className="info-text">
                    Receive appointment remainders, and other reminders related
                    to your activities on employHER.
                  </span>
                </label>
              </span>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <Grid container justify="space-between">
            <Grid item md={10} className="notification-card-left">
              <h5 className="mt-10">Account Activity</h5>
              <p>Email me when :</p>
            </Grid>
            <Grid item md={2} className="d-flex justify-content-between">
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  handleActivityToggle(activities, !activitiesToggle);
                }}
              >
                Toggle All
              </Button>
            </Grid>
          </Grid>
          <div>
            <div className="flex form-group" key={activityKey}>
              <Checkbox
                checked={
                  _.get(activities, "comment", "") === "yes" ? true : false
                }
                name="comment"
                onChange={(e) => {
                  handleActivityChange(e);
                }}
                id="comment"
              />
              <label htmlFor="comment">
                Someone comments on one of my job post
              </label>
            </div>
            <div className="flex form-group">
              <Checkbox
                checked={
                  _.get(activities, "mention", "") === "yes" ? true : false
                }
                name="mention"
                onChange={(e) => {
                  handleActivityChange(e);
                }}
                id="mention"
              />
              <label htmlFor="mention">Someone mentions me</label>
            </div>
            <div className="flex form-group">
              <Checkbox
                checked={
                  _.get(activities, "invite", "") === "yes" ? true : false
                }
                name="invite"
                onChange={(e) => {
                  handleActivityChange(e);
                }}
                id="invite"
              />
              <label htmlFor="invite">
                I receive invitations to invite new post
              </label>
            </div>
            <div className="flex form-group">
              <Checkbox
                checked={
                  _.get(activities, "accepts_invitation", "") === "yes"
                    ? true
                    : false
                }
                name="accepts_invitation"
                onChange={(e) => {
                  handleActivityChange(e);
                }}
                id="accepts_invitation"
              />
              <label htmlFor="accepts_invitation">
                Someone accepts my invitation
              </label>
            </div>
            <div className="flex form-group">
              <Checkbox
                checked={
                  _.get(activities, "follow", "") === "yes" ? true : false
                }
                name="follow"
                onChange={(e) => {
                  handleActivityChange(e);
                }}
                id="follow"
              />
              <label htmlFor="follow">anyone follows me</label>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <Grid container justify="space-between">
            <Grid item md={10} className="notification-card-left">
              <h5 className="mt-10">Account Support</h5>
              <p>Email me when :</p>
            </Grid>
            <Grid item md={2} className="d-flex justify-content-between">
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  handleSupportsToggle(supports, !supportsToggle);
                }}
              >
                Toggle All
              </Button>
            </Grid>
          </Grid>
          <div>
            <div className="flex form-group" key={supportKey}>
              <Checkbox
                checked={
                  _.get(supports, "privacy_policy", "") === "yes" ? true : false
                }
                name="privacy_policy"
                onChange={(e) => {
                  handleSupportChange(e);
                }}
                id="privacy_policy"
              />
              <label htmlFor="privacy_policy">
                Get notified when employ<span className="title">HER</span>{" "}
                changed in the privacy policy and terms of services.
              </label>
            </div>
            <div className="flex form-group">
              <Checkbox
                checked={
                  _.get(supports, "unsubscribe", "") === "yes" ? true : false
                }
                name="unsubscribe"
                onChange={(e) => {
                  handleSupportChange(e);
                }}
                id="unsubscribe"
              />
              <label htmlFor="unsubscribe">
                Unsubscribe from all the marketing emails
              </label>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <div className="btn-deactivate">
            <Button
              variant="outlined"
              className="text-uppercase"
              color="primary"
              onClick={() => handleUpdateNotification()}
            >
              Update email notifications
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
