import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Button } from "../../components/common";
import { ReactComponent as UnauthorizedErrorIcon } from "../../assets/svg/unauthorized_error.svg";
import appRoutes from '../../routes/app.routes';

const UnauthorizedError = () => {

	return (

		<div className="max-container">
			<div className="unauthorized-error-wrapper">
				<Grid
					item
					xs={12}
					container
					spacing={3}
					direction="row"
					justify="space-between"
					alignItems="center"
				>
					<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
						<div className="unauthorized-error-image">
							<UnauthorizedErrorIcon />
						</div>
					</Grid>
					<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
						<div className="unauthorized-error-text">
							<h3 className='unauthorized-error-msg'> Not Authorized </h3>
							<p className='unauthorized-error-body'> Access to this page is allowed only for authorized users.</p>
							<Button className='unauthorized-error-btn'>Switch An Account</Button>
							<Link to={appRoutes.home.path} className='unauthorized-error-link'>Go to Home Page </Link>
						</div>
					</Grid>
				</Grid>
			</div>
		</div>
	)
};

export default UnauthorizedError;
