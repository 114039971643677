import React from "react";

const JobIcon = () => {
  return (
    <svg id="ehJob" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="256px" height="256px" viewBox="-15 -15 93 93" enableBackground="new 0 0 64 64" xmlSpace="preserve" fill="#000000" stroke="#000000" strokeWidth="0.00064" transform="rotate(0)">
      <g id="eh_bgCarrier" strokeWidth={0} transform="translate(0,0), scale(1)">
        <rect x="-14.08" y="-14.08" width={91} height={91} rx={45} style={{ fill: '#ffe6f4', strokeWidth: 1, stroke: '#ff0a90' }} />
      </g>
      <g id="eh_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.384" />
      <g id="eh_iconCarrier">
        <path fill="#ff0a90" d="M60,13H48V4c0-2.211-1.789-4-4-4H20c-2.211,0-4,1.789-4,4v9H4c-2.211,0-4,1.789-4,4v43c0,2.211,1.789,4,4,4 h56c2.211,0,4-1.789,4-4V17C64,14.789,62.211,13,60,13z M18,4c0-1.104,0.896-2,2-2h24c1.104,0,2,0.896,2,2v9h-2V5 c0-0.553-0.447-1-1-1H21c-0.553,0-1,0.447-1,1v8h-2V4z M42,6v7H22V6H42z M62,60c0,1.104-0.896,2-2,2H4c-1.104,0-2-0.896-2-2V42h10v5 c0,0.553,0.447,1,1,1h6c0.553,0,1-0.447,1-1v-5h24v5c0,0.553,0.447,1,1,1h6c0.553,0,1-0.447,1-1v-5h10V60z M14,46V36h4v10H14z M46,46V36h4v10H46z M62,40H52v-5c0-0.553-0.447-1-1-1h-6c-0.553,0-1,0.447-1,1v5H20v-5c0-0.553-0.447-1-1-1h-6 c-0.553,0-1,0.447-1,1v5H2V17c0-1.104,0.896-2,2-2h56c1.104,0,2,0.896,2,2V40z" />
      </g>
    </svg>
  );
}

export default JobIcon;