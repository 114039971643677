import React from 'react';
import { Button } from "@material-ui/core";
import Image from "../../../../components/common/Image";

interface FeedButtonprop {
  svg: any;
  type: string;
  name: string;
  form: any;
  onUploadImage: any;
  accept: string;
  value: any;
  id: string;
}
function FeedButton(props: FeedButtonprop) {
  return <ul>
    <li>
      <label htmlFor={props.id} className="uplaod-btn">
        <input accept={props.accept}
          id={props.id}
          multiple
          type={props.type}
          style={{ display: "none" }}
          ref={props.form.register()}
          name={props.name}
          // value={props.value}
          onChange={(e) => props.onUploadImage(e)}
        />
        <Button
          variant="contained"
          component="span">
          <Image src={props.svg} alt="image uplaod" />Upload Media
        </Button>
      </label>
    </li>
  </ul>
}

export default FeedButton;
