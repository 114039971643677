import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Input,
  Modal,
  Button,
  SelectNew,
  AsyncSelect,
  RichTextEditor,
  FlashMessage,
} from "../../../components/common";
import { Grid } from "@material-ui/core";
import { pluckFromArray, stripHTMLTags } from "../../../utils/helper";
import _, { isEmpty } from "lodash";
import {
  createJobRequest,
  updateJobRequest,
  jobTypesRequest,
  jobLevelsRequest,
  salaryRangesRequest,
  jobStatusRequest,
  jobDepartmentListRequest,
} from "../../../../src/reducers/job/jobs.reducer";
import { currencyListRequest } from "../../../../src/reducers/currency/currency.reducer";
import {
  jobSalaryTerms,
  REMOTE_CHOICE,
  SUPPORT_VISA,
} from "../../../../src/utils/appConstants";
import { makeStyles, Theme } from "@material-ui/core";
import commonServices from "../../../services/common.services";
import { OptionsProp } from "../../../components/common/SelectNew";
import { rootReducersState } from "../../../reducers";
import { ReducerFlag } from "../../../types/reducer.types";

let searchDelay: NodeJS.Timeout;
type Inputs = {
  title: string;
  job_id: string;
  city_id: OptionsProp;
  job_department_id: OptionsProp;
  level: OptionsProp;
  type: OptionsProp;
  min_experience: number;
  max_experience: number;
  is_remote: OptionsProp;
  support_visa: OptionsProp;
  min_salary: number;
  max_salary: number;
  currency_id: OptionsProp;
  salary_term: OptionsProp;
  description: string;
  ats_link: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Form = (props) => {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const {
    jobs: {
      detail,
      jobTypes,
      jobStatuses,
      jobLevels,
      jobCreateFlag,
      jobCreateLoading,
      jobCreateErrors,
      jobUpdateFlag,
      jobUpdateLoading,
      jobUpdateErrors,
      detailLoading,
      salaryRanges,
      errors: jobErrors,
    },
  } = useSelector(({ job }: rootReducersState) => job);
  const jobDepartments = useSelector(({ job }: rootReducersState) =>
    _.get(job, "jobs.jobDepartments.data", [])
  );
  const {
    currencies: { data: currencyList },
  } = useSelector(({ currency }: rootReducersState) => currency);

  const modalTitle = _.isEmpty(detail) ? "Add a Job" : "Update a Job";

  const [jobTypeOption, setJobTypeOption] = useState<OptionsProp[]>([]);
  const [jobLevelOption, setJobLevelOption] = useState<OptionsProp[]>([]);
  const [currencyOption, setCurrencyOption] = useState<OptionsProp[]>([]);
  const [departmentOptions, setDepartmentOption] = useState<OptionsProp[]>([]);
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    errors,
    setError,
    reset: resetFormValues,
    clearErrors,
    getValues,
    setValue,
    control,
    watch,
  } = useForm<Inputs>({
    defaultValues: {
      title: "",
      job_id: "",
      city_id: {},
      job_department_id: {},
      level: {},
      type: {},
      min_experience: 0,
      max_experience: 0,
      is_remote: {},
      min_salary: 0,
      max_salary: 0,
      currency_id: {},
      support_visa: {},
      salary_term: {},
      description: "",
      ats_link: "",
    },
  });
  const watchMaxSalary = Number(watch("max_salary") || 0);
  const watchMinSalary = Number(watch("min_salary") || 0);
  const watchMaxExp = watch("max_experience");
  const watchMinExp = watch("min_experience");
  const salaryTerm = watch("salary_term");

  useEffect(() => {
    _.isEmpty(jobTypes) && dispatch(jobTypesRequest());
    _.isEmpty(jobLevels) && dispatch(jobLevelsRequest());
    _.isEmpty(jobStatuses) && dispatch(jobStatusRequest());
    _.isEmpty(salaryRanges) && dispatch(salaryRangesRequest());
    _.isEmpty(currencyList) && dispatch(currencyListRequest());
    _.isEmpty(departmentOptions) && dispatch(jobDepartmentListRequest());

    // eslint-disable-next-line
  }, []);

  // Reset the form on close
  useEffect(() => {
    if (open === false) {
      resetFormValues({});
    } else if (_.isEmpty(detail)) {
      // Add job
      resetFormValues({
        currency_id: {
          label: "USD",
          value: "1",
        },
        type: {
          label: "Full-Time",
          value: "1",
        },
        salary_term: {
          value: "annual",
          label: "Annual",
        },
      });
    }

    // eslint-disable-next-line
  }, [open]);

  //Hook for set maximum salary validation.
  useEffect(() => {
    clearErrors("min_salary");
    if (watchMaxSalary < 0) {
      setValue("max_salary", 0);
    } else if (watchMaxSalary < watchMinSalary) {
      setError("max_salary", {
        type: "manual",
        message: "Must be greater than Min. salary",
      });
    } else {
      clearErrors("max_salary");
    }

    // eslint-disable-next-line
  }, [watchMaxSalary]);

  //Hook for set minimum salary validation.
  useEffect(() => {
    clearErrors("max_salary");

    if (watchMinSalary < 0) {
      setValue("min_salary", 0);
    } else if (watchMinSalary > watchMaxSalary) {
      setError("min_salary", {
        type: "manual",
        message: "Must be less than Max. salary",
      });
    } else {
      clearErrors("min_salary");
    }

    // eslint-disable-next-line
  }, [watchMinSalary]);

  //Hook for set maximum exp validation.
  useEffect(() => {
    clearErrors("min_experience");

    const minExp = getValues("min_experience") || 0;
    if (watchMaxExp < 0) {
      setValue("max_experience", 0);
    } else if (watchMaxExp < minExp && minExp !== 0) {
      setError("max_experience", {
        type: "manual",
        message: "Must be greater than Min. exp",
      });
    } else {
      clearErrors("max_experience");
    }

    // eslint-disable-next-line
  }, [watchMaxExp]);

  //Hook for set minimum exp validation.
  useEffect(() => {
    clearErrors("max_experience");

    const maxExp = getValues("max_experience") || 0;

    if (watchMinExp < 0) {
      setValue("min_experience", 0);
    } else if (watchMinExp > maxExp && maxExp !== 0) {
      setError("min_experience", {
        type: "manual",
        message: "Must be less than Max. exp",
      });
    } else {
      clearErrors("min_experience");
    }

    // eslint-disable-next-line
  }, [watchMinExp]);

  useEffect(() => {
    if (!_.isEmpty(detail)) {
      let payload = {
        title: _.get(detail, "title", ""),
        job_id: _.get(detail, "job_id", ""),
        city_id: {
          label: _.get(detail, "city.location", ""),
          value: _.get(detail, "city.id", ""),
        },
        is_remote: REMOTE_CHOICE.find(
          (row) => row.value === _.get(detail, "is_remote", "0")
        ),
        ats_link: _.get(detail, "ats_link", ""),
        job_department_id: {
          label: _.get(detail, "jobDepartment.title", ""),
          value: _.get(detail, "jobDepartment.id", ""),
        },
        level: {
          label: _.get(detail, "jobLevel.title", ""),
          value: _.get(detail, "jobLevel.id", ""),
        },
        max_experience: _.get(detail, "max_experience", ""),
        min_experience: _.get(detail, "min_experience", ""),
        type: {
          label: _.get(detail, "jobType.title", ""),
          value: _.get(detail, "jobType.id", ""),
        },
        description: _.get(detail, "description", ""),
        min_salary: _.get(detail, "min_salary", 0),
        max_salary: _.get(detail, "max_salary", 0),
        currency_id: {
          label: _.get(detail, "currency.code", ""),
          value: _.get(detail, "currency.id", ""),
        },
        salary_term: jobSalaryTerms.find(
          (option) => option.value === _.get(detail, "salary_term", "")
        ) || {
          label: "",
          value: "",
        },
      };
      
      if (detail?.support_visa !== null) {       
        payload['support_visa'] = SUPPORT_VISA.find(
          (row) => row.value === _.get(detail, "support_visa").toString()
        )
      }
      resetFormValues({ ...payload });
    }

    // eslint-disable-next-line
  }, [detail]);

  useEffect(() => {
    if (!_.isEmpty(currencyList)) {
      const setCurrencyOptions = pluckFromArray(
        currencyList,
        "id",
        "code",
        "value",
        "label"
      ) as OptionsProp[];
      setCurrencyOption(setCurrencyOptions);
    }

    // eslint-disable-next-line
  }, [currencyList]);

  useEffect(() => {
    if (!_.isEmpty(jobDepartments)) {
      const setDepartmentOptions = pluckFromArray(
        jobDepartments,
        "id",
        "title",
        "value",
        "label"
      ) as OptionsProp[];
      setDepartmentOption(setDepartmentOptions);
    }

    // eslint-disable-next-line
  }, [jobDepartments]);

  useEffect(() => {
    if (!_.isEmpty(jobTypes)) {
      const setJobTypeOptions = pluckFromArray(
        jobTypes,
        "id",
        "title",
        "value",
        "label"
      ) as OptionsProp[];
      setJobTypeOption(setJobTypeOptions);
    }

    // eslint-disable-next-line
  }, [jobTypes]);

  useEffect(() => {
    if (!_.isEmpty(jobLevels)) {
      const setJobLevelOptions = pluckFromArray(
        jobLevels,
        "id",
        "title",
        "value",
        "label"
      ) as OptionsProp[];
      setJobLevelOption(setJobLevelOptions);
    }

    // eslint-disable-next-line
  }, [jobLevels]);

  // Job error Hook
  useEffect(() => {
    _.forEach(jobUpdateErrors, (value, key: any) => {
      setError(key, { type: "manual", message: value });
    });

    // eslint-disable-next-line
  }, [jobUpdateErrors]);

  useEffect(() => {
    _.forEach(jobCreateErrors, (value, key: any) => {
      setError(key, { type: "manual", message: value });
    });

    // eslint-disable-next-line
  }, [jobCreateErrors]);

  useEffect(() => {
    _.forEach(jobErrors, (value, key: any) => {
      setError(key, { type: "manual", message: value });
    });

    // eslint-disable-next-line
  }, [jobErrors]);

  // Create Job error handling
  useEffect(() => {
    if (jobCreateFlag === ReducerFlag.FAILED) {
      if (
        typeof jobCreateErrors === "object" &&
        Object.keys(jobCreateErrors).length > 0
      ) {
        const errorMsg = Object.values(jobCreateErrors)[0] as string;
        FlashMessage(errorMsg, "error");
      }
    }

    if (jobUpdateFlag === ReducerFlag.FAILED) {
      if (
        typeof jobUpdateErrors === "object" &&
        Object.keys(jobUpdateErrors).length > 0
      ) {
        const errorMsg = Object.values(jobUpdateErrors)[0] as string;
        FlashMessage(errorMsg, "error");
      }
    } else if (
      jobUpdateFlag === ReducerFlag.SUCCESS ||
      jobCreateFlag === ReducerFlag.SUCCESS
    ) {
      resetFormValues({});
    }

    // eslint-disable-next-line
  }, [jobCreateFlag, jobUpdateFlag]);

  const onSubmit = (formData, e) => {
    const is_draft = _.get(e, "nativeEvent.submitter.value", "");
    let payload = {
      title: _.get(formData, "title", ""),
      job_id: _.get(formData, "job_id", ""),
      city_id: _.get(formData, "city_id.value", ""),
      is_remote: _.get(formData, "is_remote.value", "0"),
      job_department_id: _.get(formData, "job_department_id.value", ""),
      level: _.get(formData, "level.value", ""),
      ...(!isEmpty(formData?.max_experience) &&
      !isEmpty(formData?.min_experience)
        ? {
            max_experience: _.get(formData, "max_experience", 0),
            min_experience: _.get(formData, "min_experience", 0),
          }
        : {}),
      type: _.get(formData, "type.value", ""),
      ats_link: _.get(formData, "ats_link", null),
      description: _.get(formData, "description", ""),
      min_salary: parseInt(_.get(formData, "min_salary", 0) || 0),
      max_salary: parseInt(_.get(formData, "max_salary", 0) || 0),
      status: _.get(formData, "status", 1),
      currency_id: _.get(formData, "currency_id.value", 0),
      support_visa: _.get(formData, "support_visa.value", null),
      salary_term: _.get(formData, "salary_term.value", ""),
      is_archived: 0,
      is_public: is_draft === "is_draft" ? "0" : "1",
    };

    if (!_.get(detail, "id", "")) {
      dispatch(createJobRequest(payload));
      resetFormValues({ ...formData });
    } else {
      dispatch(updateJobRequest({ ...payload, id: _.get(detail, "id", "") }));
    }
  };

  const _handleCloseModal = () => {
    if (typeof handleClose === "function") {
      handleClose();
      resetFormValues({});
    }
  };

  // Search location with delay of 1 second
  const searchLocations = (search: string) =>
    new Promise((resolve) => {
      clearTimeout(searchDelay);
      searchDelay = setTimeout(async () => {
        const locationData = await commonServices.fetchLocations(search);
        const locations = _.get(locationData, "data.data", []).map((c) => ({
          value: c.id,
          label: c.location,
        }));

        resolve(locations);
      }, 1000);
    });

  const maxLimit = 10000;
  const jobValue = watch("description");

  const personalValueError = (value: number) => {
    if (value > maxLimit) {
      const maxLengthError = {
        type: "maxLength",
        message: `Text must be between 2 to ${maxLimit} characters long`,
      };
      setError("description", maxLengthError);
    } else {
      setError("description", {});
    }
  };

  useEffect(() => {
    const tmpText = stripHTMLTags(jobValue);
    personalValueError(tmpText.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobValue]);

  return (
    <Modal
      visible={open}
      size="large"
      title={modalTitle}
      loading={jobCreateLoading || detailLoading || jobUpdateLoading}
      className="job-add-modal"
      closeButton={open}
      onClose={() => _handleCloseModal()}
    >
      <div className={classes.root}>
        <div className="job-form-wrapper slim-scrollbar">
          <form
            className={"job-form"}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} sm={6} className="job-form-field">
                <Input
                  name="title"
                  externalLabel={{ label: "Job Title" }}
                  placeholder="Job Title"
                  required={true}
                  validationObj={errors}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter job title",
                    },
                    minLength: {
                      value: 2,
                      message: "Title must be minimum 2 characters long",
                    },
                    maxLength: {
                      value: 200,
                      message: "Title must be maximum 200 characters long",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="job-form-field">
                <Input
                  name="job_id"
                  externalLabel={{ label: "Job I.D." }}
                  placeholder="Job I.D."
                  validationObj={errors}
                  required={false}
                  inputRef={register({
                    minLength: {
                      value: 2,
                      message: "Job I.D. must be minimum 2 characters long",
                    },
                    maxLength: {
                      value: 50,
                      message: "Job I.D. must be maximum 50 characters long",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="job-form-field">
                <AsyncSelect
                  name="city_id"
                  placeholder="Search City, State or country..."
                  externalLabel={{
                    label: "Location",
                    className: "required",
                  }}
                  onStartSearching={searchLocations}
                  validationObj={errors}
                  control={control}
                  noOptionsMessage="No matching locations found"
                  rules={{
                    required: {
                      value: true,
                      message: "Please select location from list",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectNew
                  name="is_remote"
                  externalLabel={{ label: "Location Type" }}
                  placeholder="Select Location Type"
                  className="select-box"
                  options={REMOTE_CHOICE}
                  isSearchable={true}
                  validationObj={errors}
                  control={control}
                  required={true}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select Location Type",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} className="job-form-field">
                <SelectNew
                  name="job_department_id"
                  externalLabel={{ label: "Industry" }}
                  placeholder="Select Industry"
                  className="select-box"
                  options={departmentOptions}
                  isSearchable={true}
                  validationObj={errors}
                  control={control}
                  required={true}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select Industry",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} className="job-form-field">
                <SelectNew
                  name="level"
                  externalLabel={{ label: "Job Level" }}
                  placeholder="Select job level"
                  className="select-box"
                  options={jobLevelOption}
                  isSearchable={true}
                  validationObj={errors}
                  control={control}
                  required={true}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select job level",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} className="job-form-field">
                <SelectNew
                  name="type"
                  externalLabel={{ label: "Job Type" }}
                  placeholder="Select job type"
                  className="select-box"
                  options={jobTypeOption}
                  isSearchable={true}
                  validationObj={errors}
                  control={control}
                  required={true}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select an industry",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} className="job-form-field">
                <SelectNew
                  name="support_visa"
                  externalLabel={{ label: "Support Visa" }}
                  placeholder="Support visa"
                  className="select-box"
                  options={SUPPORT_VISA}
                  isSearchable={true}
                  validationObj={errors}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={3} className="job-form-field">
                <SelectNew
                  name="currency_id"
                  externalLabel={{ label: "Currency" }}
                  placeholder="Currency"
                  className="select-box"
                  options={currencyOption}
                  isSearchable={true}
                  validationObj={errors}
                  control={control}
                  required={true}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select currency",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} className="job-form-field">
                <SelectNew
                  name="salary_term"
                  externalLabel={{ label: "Salary Term" }}
                  placeholder="Term"
                  className="select-box"
                  options={jobSalaryTerms}
                  isSearchable={true}
                  validationObj={errors}
                  control={control}
                  required={true}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select term",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} className="job-form-field">
                <Input
                  name="min_salary"
                  type="number"
                  min={0}
                  externalLabel={{
                    label:
                      salaryTerm?.value === "per_hour"
                        ? "Per hour"
                        : "Min Salary",
                  }}
                  placeholder="Minimum"
                  validationObj={errors}
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={12} sm={3} className="job-form-field">
                <Input
                  name="max_salary"
                  type="number"
                  min={0}
                  externalLabel={{
                    label:
                      salaryTerm?.value === "per_hour"
                        ? "Per hour"
                        : "Max Salary",
                  }}
                  placeholder="Maximum"
                  validationObj={errors}
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={12} sm={12} className="job-form-field">
                <Input
                  name="ats_link"
                  externalLabel={{
                    label:
                      "ATS Link (e.g. https://yourdomain.com/application-link)",
                  }}
                  placeholder="Enter your ATS link where candidate can apply for a job"
                  validationObj={errors}
                  required={false}
                  inputRef={register({
                    validate: {
                      validLength: (value) => {
                        if (value) {
                          var regex = new RegExp(
                            "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
                          );
                          return regex.test(value) || "Please enter valid Link";
                        }

                        return true;
                      },
                    },
                  })}
                />
                <small>
                  Note: employ<span className="text-primary">HER</span> won't be
                  able to track the candidate applications when ATS link is
                  provided.
                </small>
              </Grid>
              <Grid item spacing={2} xs={12}>
                <RichTextEditor
                  name="description"
                  externalLabel={{
                    label: "Job Description",
                    className: "required",
                  }}
                  placeholder="Enter Job Description"
                  validationObj={errors}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter job description",
                    },
                    validate: {
                      validLength: (value) => {
                        const tmpText = stripHTMLTags(value || "");
                        return (
                          tmpText.length <= maxLimit ||
                          `Please enter less than ${maxLimit} characters`
                        );
                      },
                    },
                  }}
                  showCount
                  maxLimit={maxLimit}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} className="action-buttons text-center mt-20">
              <Button
                value="is_draft"
                type="submit"
                disabled={jobCreateLoading || jobUpdateLoading}
                color="secondary"
              >
                Save as Draft
              </Button>
              <Button
                type="submit"
                loading={jobCreateLoading || jobUpdateLoading}
              >
                Post this Job
              </Button>
            </Grid>
            <Grid item xs={12} className="text-center mt-10"></Grid>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default Form;
