import React from "react";
import { useSelector } from "react-redux";
import { Button } from "../../../../components/common";
// import { getCurrentTab } from "../../../../utils/helper";
import { isEmpty, isUndefined, get } from "lodash";

const _ = { isEmpty, isUndefined, get };
interface ActionProps {
  handleClick: (type: string, text?: string) => any;
}

const Actions = (props: ActionProps) => {
  const { handleClick } = props;
  // const { innerTab } = useSelector(({ company }: any) => company);
  const navBarReducer = useSelector(({ common }: any) => common);
  // const currentSubTab = getCurrentTab(props, true);
  const saveButtonLoader = _.get(
    navBarReducer,
    "navBar.saveButtonLoading",
    false
  );
  // const activeTab = _.isEmpty(innerTab) ? _.isUndefined(currentSubTab) ? 'All' : currentSubTab : innerTab;
  // const checktab = activeTab.toLowerCase();
  return (
    <div className="custom-tab-wrapper">
      {/* <div className='custom-tab-left'>
        <ul className='tab-menu'>
          <li>
            <Button className={`${checktab === 'all' ? 'active' : ''}`} onClick={() => handleClick('All')}> Home </Button>
          </li>
          <li>
            <Button className={`${checktab === 'people' ? 'active' : ''}`} onClick={() => handleClick('People')}> People </Button>
          </li> */}
      {/* <li>
            <Button className={`${checktab === 'jobs' ? 'active' : ''}`} onClick={() => handleClick('Jobs')}> Jobs </Button>
          </li>
          <li>
            <Button className={`${checktab === 'news' ? 'active' : ''}`} onClick={() => handleClick('News')}> News </Button>
          </li>
          <li>
            <Button className={`${checktab === 'events' ? 'active' : ''}`} onClick={() => handleClick('Events')}> Events </Button>
          </li> */}
      {/* </ul>
      </div> */}
      <div className="custom-tab-right">
        <Button
          loading={saveButtonLoader}
          disabled={saveButtonLoader}
          onClick={() => handleClick("view-profile")}
          color="secondary"
          className="save-button view-profile"
        >
          View Profile
        </Button>
        <Button
          loading={saveButtonLoader}
          disabled={saveButtonLoader}
          onClick={() => handleClick("Save")}
          color="secondary"
          className="save-button text-uppercase"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default Actions;
