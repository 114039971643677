/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  PictureAsPdf as PictureAsPdfIcon,
  Description as DescriptionIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Modal, Button, FlashMessage } from "./../common";
import ProfileView from "../ProfileView/ProfileView";
import { rootReducersState } from "../../reducers";
import {
  applyJobRequest,
  applyJobReset,
} from "../../reducers/candidate/candidate.reducer";
import useSessionUser, { ResumeInterface } from "../../hooks/useSessionUser";
import { ReducerFlag } from "../../types/reducer.types";
import { jobDetailUpdate } from "../../reducers/job/jobs.reducer";
import RichTextEditor from "../common/RichTextEditor";
import httpRequest from "../../utils/httpRequest";
import API_URLS from "../../utils/apiUrls";
import { bytesMb, resumeTypes } from "../../utils/appConstants";
import { setCandidateResumes } from "../../reducers/auth/session.reducer";
import { formatDate } from "../../utils/helper";
import ConfirmDialog from "../common/ConfirmDialog";

interface ProfileModalProps {
  children?: React.ReactNode;
  className?: string;

  visible: boolean;
  size?: "small" | "medium" | "large" | "x-large";
  //title?: React.ReactNode;
  closeButton?: boolean;
  closeOnBackDrop?: boolean;
  onClose: () => void;

  loading?: Boolean;
  loaderSize?: number;
  [key: string]: any;
}

type RESUME_SUBMIT_STEPS = "review" | "submit";

const ProfileViewModal = ({
  className,
  visible,
  size,
  closeButton,
  closeOnBackDrop,
  onClose,
  jobData,
  ...props
}: ProfileModalProps) => {
  const dispatch = useDispatch();
  const resumeUploadFile = useRef<HTMLInputElement>(null);
  const { loggedIn, resumesList } = useSessionUser();
  const [displayAppliedJobPrompt, setDisplayAppliedJobPrompt] =
    useState<boolean>(false);
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const [currentResumeData, setCurrentResumeData] = useState<ResumeInterface>(
    {} as ResumeInterface
  );
  const [deleteResumeModal, setDeleteResumeModal] = useState<boolean>(false);
  const [deleteResumeLoading, setDeleteResumeLoading] =
    useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<RESUME_SUBMIT_STEPS>("review");
  const [coverLetterText, setCoverLetterText] = useState<string>("");
  const [selectedResume, setSelectedResume] = useState<number>(0);
  const { jobAppliedMessage, jobAppliedFlag, jobAppliedLoading } = useSelector(
    ({ candidate }: rootReducersState) => candidate?.candidates
  );
  const { detail: jobDetails } = useSelector(
    ({ job }: rootReducersState) => job?.jobs
  );

  // Check job application status, Success of Failed
  useEffect(() => {
    if (jobAppliedFlag === ReducerFlag.SUCCESS) {
      setDisplayAppliedJobPrompt(true);
      dispatch(applyJobReset());

      // Saved applied job status
      dispatch(
        jobDetailUpdate({
          ...jobDetails,
          applied: 1,
        })
      );

      setTimeout(() => {
        handleOnClose();
      }, 3000);
    } else if (jobAppliedFlag === ReducerFlag.FAILED) {
      FlashMessage(jobAppliedMessage, "error");
      dispatch(applyJobReset());
    }
  }, [jobAppliedFlag]);

  const handleOnClose = () => {
    if (typeof onClose === "function") {
      onClose();
      setTimeout(() => resetModal(), 500);
    }
  };

  const resetModal = () => {
    setUploadingFile(false);
    setDeleteResumeModal(false);
    setDeleteResumeLoading(false);
    setCurrentStep("review");
    setSelectedResume(0);
  };

  const handleSubmitProfileButtonClick = () => {
    const jobId = jobData?.id || "";
    dispatch(
      applyJobRequest({
        id: jobId,
        formData: {
          resume: selectedResume,
          coverLetter: coverLetterText,
        },
      })
    );
  };

  const handleUploadResume = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    try {
      if (event?.target?.files?.length) {
        const file = event.target.files[0];

        // Validate file size
        if (file.size > bytesMb.mb_2) {
          FlashMessage("File should be less than or equal to 2mb", "error");
          return;
        }

        // Validate file type
        if (!resumeTypes.includes(file.type)) {
          FlashMessage("File should be doc,docx or pdf format", "error");
          return;
        }

        setUploadingFile(true);
        const payload = new FormData();
        payload.append("resume", file, file.name);
        const { data } = await httpRequest().post<{
          data: ResumeInterface;
        }>(API_URLS.resumeUpload, payload);

        const resumeData = data.data || {};
        const updatedResumeList = [...resumesList, resumeData];
        dispatch(setCandidateResumes(updatedResumeList));

        if (selectedResume <= 0) {
          setSelectedResume(resumeData.id || 0);
        }
      } else {
        FlashMessage("Please upload a file", "error");
      }
    } catch (error: any) {
      const errMsg =
        error?.response?.data?.errors?.files ||
        error?.response?.data?.message ||
        "Unable to upload a file";
      FlashMessage(errMsg, "error");
    }
    setUploadingFile(false);
  };

  const handleDeleteResume = async (
    resumeData: ResumeInterface
  ): Promise<void> => {
    try {
      if (resumeData.id) {
        setDeleteResumeLoading(true);
        await httpRequest().delete(API_URLS.deleteUpload(resumeData.id));
        const updatedResumeList = [...resumesList].filter(
          (row) => row.id !== resumeData.id
        );
        dispatch(setCandidateResumes(updatedResumeList));

        // Reset resume selection
        if (selectedResume === resumeData.id) {
          setSelectedResume(0);
        }
      } else {
        FlashMessage("Please select resume to delete", "error");
      }
    } catch (error: any) {
      const errMsg =
        error?.response?.data?.errors?.files ||
        error?.response?.data?.message ||
        "Unable to upload a file";
      FlashMessage(errMsg, "error");
    }
    setDeleteResumeModal(false);
    setDeleteResumeLoading(false);
  };

  return (
    <>
      <Modal
        className={className}
        visible={visible}
        size={size}
        closeButton={closeButton}
        closeOnBackDrop={closeOnBackDrop}
        onClose={() => handleOnClose()}
      >
        <Spinner visible={!loggedIn}>
          <div className="pb-5 profile-view-modal-body">
            {displayAppliedJobPrompt ? (
              <div className="successful-submit">
                <div>
                  <h2>Your profile was successfully submitted for this job.</h2>
                  <p>Wishing you the best of luck!</p>
                </div>
                <small>Window will auto-close in 3seconds...</small>
              </div>
            ) : (
              <div className="pt-15 pb-15 submit-application-container">
                {currentStep === "review" && (
                  <>
                    <p className="title">Review Profile Information</p>
                    <Button
                      color="primary"
                      onClick={() => setCurrentStep("submit")}
                    >
                      Next
                    </Button>
                  </>
                )}
                {currentStep === "submit" && (
                  <>
                    <p className="title">Review Profile Information</p>
                    <div className="flex-row gap-10">
                      <Button
                        color="secondary"
                        disabled={jobAppliedLoading}
                        onClick={() => setCurrentStep("review")}
                      >
                        Back
                      </Button>
                      <Button
                        color="primary"
                        loading={jobAppliedLoading}
                        onClick={() => handleSubmitProfileButtonClick()}
                      >
                        Submit Application
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
            <div
              id="profile-view"
              className="modal-profile-view slim-scrollbar"
            >
              {currentStep === "review" ? (
                <ProfileView showEditButton={false} />
              ) : (
                <div className="profile-submit-container">
                  <div className="resume-section">
                    <span>
                      {resumesList.length > 0 &&
                        "Select a resume from the list"}
                    </span>
                    <div className="resumes-list">
                      {[...resumesList].map((row, key) => (
                        <div
                          title="Click to select/unselect"
                          key={key}
                          className={
                            row.id === selectedResume ? "selected-resume" : ""
                          }
                          onClick={() =>
                            setSelectedResume(() =>
                              row.id !== selectedResume ? row.id : 0
                            )
                          }
                        >
                          <CheckCircleIcon className="selected-icon" />
                          {row.mime_type === "application/pdf" ? (
                            <PictureAsPdfIcon className="resume-icon pdf" />
                          ) : (
                            <DescriptionIcon className="resume-icon doc" />
                          )}
                          <div>
                            <span>
                              {_.truncate(row.original_file, { length: 22 })}
                            </span>
                            <small className="">
                              Uploaded on : {formatDate(row.created_at)}
                            </small>
                          </div>
                          <DeleteIcon
                            className="delete-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              setCurrentResumeData(row);
                              setDeleteResumeModal(true);
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    {resumesList.length === 0 && (
                      <small className="no-resume">
                        You don't have any saved resume.
                      </small>
                    )}
                    {resumesList.length < 4 && (
                      <div className="flex-row gap-10 align-items-center">
                        <input
                          type="file"
                          ref={resumeUploadFile}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => handleUploadResume(event)}
                          className="d-none"
                        />
                        <Button
                          variant="outlined"
                          color="rounded"
                          disabled={uploadingFile}
                          className="upload-resume-btn"
                          onClick={() => resumeUploadFile.current?.click()}
                        >
                          <span>
                            {uploadingFile ? "Uploading..." : "Upload resume"}
                          </span>
                        </Button>
                        <small className="font-sm">
                          DOC, DOCX, PDF upto 2 MB
                        </small>
                      </div>
                    )}
                  </div>
                  <div className="cover-letter">
                    <span>Cover letter</span>
                    <RichTextEditor
                      name="cover_letter"
                      onChange={(value) => setCoverLetterText(value)}
                      value={coverLetterText}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Spinner>
      </Modal>
      <ConfirmDialog
        visible={deleteResumeModal}
        bodyText="Are you sure want to Delete this resume?"
        confirmText="Delete"
        loading={deleteResumeLoading}
        onConfirm={() => handleDeleteResume(currentResumeData)}
        onCancel={() => setDeleteResumeModal(false)}
      />
    </>
  );
};

export default ProfileViewModal;
