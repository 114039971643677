import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const IndustryExpertise = () => {

    const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
		<div  className="privacy-hub-wrapper-new">
		<p className="header-content"> Industry Expertise </p>
			<div className="privacy-hub-tabs-new">
			<div className="privacy-sectionIE">
				<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
					<AccordionSummary
						expandIcon={<ArrowDropDownIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>01. <span className="drop-title">Aviation & Airport Operations</span></Typography>
					</AccordionSummary>
					<AccordionDetails>
						<span> Focuses on the management, operation, and logistics of aviation facilities and services, including airports, airlines, and air traffic control.</span>
					</AccordionDetails>
				</Accordion>
			</div>

			<div className="privacy-sectionIE">
				<Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
					<AccordionSummary
						expandIcon={<ArrowDropDownIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>02. <span className="drop-title">Banking, Financial & Fintech</span></Typography>
					</AccordionSummary>
					<AccordionDetails>
						<span> Deals with financial institutions, services, and technologies, including banking, investment, and financial technology innovations.</span>
					</AccordionDetails>
				</Accordion>
			</div>

			<div className="privacy-sectionIE">
				<Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
					<AccordionSummary
						expandIcon={<ArrowDropDownIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography>03. <span className="drop-title">Business, Sales & Marketing</span></Typography>
					</AccordionSummary>
					<AccordionDetails>
						<span> Involves strategies, tools, and techniques for boosting sales, marketing products, and services, and nurturing customer relationships to drive revenue growth. </span>
					</AccordionDetails>
				</Accordion>
			</div>

			<div className="privacy-sectionIE">
				<Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
					<AccordionSummary
						expandIcon={<ArrowDropDownIcon />}
						aria-controls="panel4a-content"
						id="panel4a-header"
					>
						<Typography>04. <span className="drop-title">Cannabis</span></Typography>
					</AccordionSummary>
					<AccordionDetails>
						<span>Encompasses businesses involved in the cultivation, distribution, and sale of cannabis products for medicinal and recreational use.</span>
					</AccordionDetails>
				</Accordion>
			</div>

			<div className="privacy-sectionIE">
				<Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
					<AccordionSummary
						expandIcon={<ArrowDropDownIcon />}
						aria-controls="panel5a-content"
						id="panel5a-header"
					>
						<Typography>05. <span className="drop-title">Construction, Environmental & Energy</span></Typography>
					</AccordionSummary>
					<AccordionDetails>
						<span> Involves the construction industry, with a focus on sustainable practices, environmental conservation, and energy efficiency.</span>
					</AccordionDetails>
				</Accordion>
			</div>

			<div className="privacy-sectionIE">
				<Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
					<AccordionSummary
						expandIcon={<ArrowDropDownIcon />}
						aria-controls="panel6a-content"
						id="panel6a-header"
					>
						<Typography>06. <span className="drop-title">Edtech</span></Typography>
					</AccordionSummary>
					<AccordionDetails>
						<span>Refers to integrating technology in education, including online learning platforms, educational apps, and digital teaching tools.</span>
					</AccordionDetails>
				</Accordion>
			</div>

			<div className="privacy-sectionIE">
				<Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
					<AccordionSummary
						expandIcon={<ArrowDropDownIcon />}
						aria-controls="panel7a-content"
						id="panel7a-header"
					>
						<Typography>07. <span className="drop-title">Engineering, Automation & Robotics</span></Typography>
					</AccordionSummary>
					<AccordionDetails>
						<span>Focuses on the design, development, and implementation of engineering solutions, automation technologies, and robotic systems across various industries.</span>
					</AccordionDetails>
				</Accordion>
			</div>

			<div className="privacy-sectionIE">
				<Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
					<AccordionSummary
						expandIcon={<ArrowDropDownIcon />}
						aria-controls="panel8a-content"
						id="panel8a-header"
					>
						<Typography>08. <span className="drop-title">Government & Legal</span></Typography>
					</AccordionSummary>
					<AccordionDetails>
						<span> Involves governmental institutions, legal services, and technologies to enhance governance, regulation, and legal processes.</span>
					</AccordionDetails>
				</Accordion>
			</div>

			<div className="privacy-sectionIE">
				<Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
					<AccordionSummary
						expandIcon={<ArrowDropDownIcon />}
						aria-controls="panel9a-content"
						id="panel9a-header"
					>
						<Typography>09. <span className="drop-title">Healthcare, Life Sciences & Femtech</span></Typography>
					</AccordionSummary>
					<AccordionDetails>
						<span> Covers healthcare services, pharmaceuticals, medical devices, and technologies specifically addressing women's health needs.</span>
					</AccordionDetails>
				</Accordion>
			</div>

			<div className="privacy-sectionIE">
				<Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
					<AccordionSummary
						expandIcon={<ArrowDropDownIcon />}
						aria-controls="panel10a-content"
						id="panel10a-header"
					>
						<Typography>10. <span className="drop-title">Insurance & Insurtech</span></Typography>
					</AccordionSummary>
					<AccordionDetails>
						<span>Deals with insurance companies, policies, and emerging technologies aimed at improving insurance processes and customer experiences.</span>
					</AccordionDetails>
				</Accordion>
			</div>

			<div className="privacy-sectionIE">
				<Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
					<AccordionSummary
						expandIcon={<ArrowDropDownIcon />}
						aria-controls="panel11a-content"
						id="panel11a-header"
					>
						<Typography>11. <span className="drop-title">Technology & Internet of Things (IoT)</span></Typography>
					</AccordionSummary>
					<AccordionDetails>
						<span>Encompasses companies developing technologies, devices, and platforms for the interconnectedness of devices and data exchange over the Internet.</span>
					</AccordionDetails>
				</Accordion>
			</div>

			<div className="privacy-sectionIE">
				<Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
					<AccordionSummary
						expandIcon={<ArrowDropDownIcon />}
						aria-controls="panel12a-content"
						id="panel12a-header"
					>
						<Typography>12. <span className="drop-title">Media, Telecommunications (TMT), Business, Sales & Marketing</span></Typography>
					</AccordionSummary>
					<AccordionDetails>
						<span> Includes companies involved in media production, content distribution, telecommunications services, business development, sales, and marketing strategies to promote products and services to consumers and businesses.</span>
					</AccordionDetails>
				</Accordion>
			</div>

			<div className="privacy-sectionIE">
				<Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
					<AccordionSummary
						expandIcon={<ArrowDropDownIcon />}
						aria-controls="panel13a-content"
						id="panel13a-header"
					>
						<Typography>13. <span className="drop-title">Manufacturing & Advanced Manufacturing</span></Typography>
					</AccordionSummary>
					<AccordionDetails>
						<span> Involves producing goods using traditional and cutting-edge technologies, including advanced manufacturing processes such as 3D printing, IoT integration, and automation.</span>
					</AccordionDetails>
				</Accordion>
			</div>
			</div>
		</div>
		</>
	)
}

export default IndustryExpertise;
