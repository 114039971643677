import React, { useState } from "react";
import { ReactComponent as Comment } from "../../../assets/svg/comment.svg";
import { rootReducersState } from "../../../../reducers";
import { useSelector } from "react-redux";
import _ from "lodash";
import { FlashMessage } from "../../../components/common";

function FeedComment({ data }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [postReplyState, setPostReplyState] = useState<boolean>(false);

  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const token = _.get(sessionReducer, "token", null);
  const userData = _.get(sessionReducer, "currentUser", {});

  const handleReply = () => {
    setPostReplyState(true);
  };
  const postComment = () => {
    userData && token
      ? handleReply()
      : FlashMessage("Please log in to comment the post", "error");
  };
  return (
    <>
      <ul>
        {/* <li className={postLikeState ? "active" : null} onClick={() => postLike()}>
				<Heart /> <span></span>
			</li> */}
        <li>
          <Comment onClick={() => postComment()} />{" "}
          <span>{_.get(data, "post_comments_count", 0)}</span>
        </li>
      </ul>
    </>
  );
}

export default FeedComment;
