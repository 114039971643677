import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { ReducerFlag } from "../../types/reducer.types";
const _ = { get };

interface IState {
  loading: boolean;
  data: Array<any>;
  message: string;
  searchAdminData: Array<any>;
  searchLoading: boolean;
  createFlag: string;
  createLoading: boolean;
  deleteflag: boolean;
  deleteLoading: boolean;
  editflag: boolean;
  editLoading: boolean;
  updateFlag: string;
  createMessage: string;
  deleteMessage: string;
  updateMessage: string;
}
// Initial state for reducer
const initialState: IState = {
  data: [],
  loading: false,
  message: "",
  searchAdminData: [],
  searchLoading: false,
  createFlag: ReducerFlag.INIT,
  createLoading: false,
  deleteflag: false,
  deleteLoading: false,
  editflag: false,
  editLoading: false,
  updateFlag: ReducerFlag.INIT,
  createMessage: "",
  deleteMessage: "",
  updateMessage: "",
};

const slice = createSlice({
  name: "company/admins",
  initialState: initialState,
  reducers: {
    companyAdminListRequest: (state) => {
      return { ...state, loading: true };
    },
    companyAdminListSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        data: _.get(payload, "data.data", []),
      };
    },
    companyAdminListFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    getConpamyAdminRequest: (state, payload) => {
      return { ...state, detailLoading: true };
    },
    getCompanyAdminSuccess: (state, { payload }) => {
      return { ...state, detailLoading: false, detail: payload.data };
    },
    getCompanyAdminFailed: (state, { payload }) => {
      return {
        ...state,
        detailLoading: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    searchAdminRequest: (state, payload) => {
      return { ...state, searchLoading: true };
    },
    searchAdminSuccess: (state, { payload }) => {
      return {
        ...state,
        searchLoading: false,
        searchAdminData: _.get(payload, "data.data", []),
        message: _.get(payload, "message", ""),
      };
    },
    searchAdminFailed: (state, { payload }) => {
      return {
        ...state,
        searchLoading: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    createCompanyAdminRequest: (state, payload) => {
      return { ...state, createLoading: true, createFlag: ReducerFlag.INIT };
    },
    createCompanyAdminSuccess: (state, { payload }) => {
      const newAdmin = payload?.data;
      return {
        ...state,
        createLoading: false,
        createFlag: ReducerFlag.SUCCESS,
        createMessage: _.get(payload, "message", ""),
        data: [{ ...newAdmin }, ...state.data],
      };
    },
    createCompanyAdminFailed: (state, { payload }) => {
      return {
        ...state,
        createLoading: false,
        createFlag: ReducerFlag.FAILED,
        createMessage: _.get(payload, "message", ""),
        error: _.get(payload, "errors", {}),
      };
    },
    deleteAdminRequest: (state, { payload }) => {
      return { ...state, deleteflag: false, deleteLoading: true };
    },
    deleteAdminSuccess: (state, { payload }) => {
      const _data = state.data.filter(
        (item: any) => item.id !== payload?.data?.id
      );
      return {
        ...state,
        deleteLoading: false,
        deleteflag: true,
        deleteMessage: _.get(payload, "message", ""),
        data: [..._data],
      };
    },
    deleteAdminFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        deleteLoading: false,
        deleteMessage: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    editAdminRequest: (state, payload) => {
      return {
        ...state,
        editLoading: true,
        updateFlag: ReducerFlag.INIT,
      };
    },
    editAdminSuccess: (state, { payload }) => {
      const { data } = payload;
      const updatedData = state.data.map((item: any) => {
        if (item.id === data?.id) {
          item = data;
        }
        return item;
      });
      return {
        ...state,
        editLoading: false,
        flag: false,
        updateFlag: ReducerFlag.SUCCESS,
        updateMessage: _.get(payload, "message", ""),
        data: updatedData,
      };
    },

    editAdminFailed: (state, { payload }) => {
      return {
        ...state,
        editLoading: false,
        updateFlag: ReducerFlag.FAILED,
        updateMessage: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    resetCompanyAdminReducer: () => {
      return { ...initialState };
    },
  },
});

// Actions
export const {
  companyAdminListFailed,
  companyAdminListRequest,
  companyAdminListSuccess,
  getCompanyAdminFailed,
  getCompanyAdminSuccess,
  getConpamyAdminRequest,
  createCompanyAdminSuccess,
  createCompanyAdminFailed,
  deleteAdminRequest,
  deleteAdminSuccess,
  deleteAdminFailed,
  searchAdminFailed,
  createCompanyAdminRequest,
  searchAdminRequest,
  searchAdminSuccess,
  editAdminFailed,
  editAdminRequest,
  editAdminSuccess,
  resetCompanyAdminReducer,
} = slice.actions;

// Reducers
export default slice.reducer;
