import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import {
  Button,
  Modal,
  FlashMessage,
  Input,
  ConfirmDialog,
} from "../../../components/common";
import messagesServices from "../../../services/messages.services";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { rootReducersState } from "../../../reducers";

const Appointments = () => {
  const [allInvitations, setAllInvitations] = useState<Array<object>>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [removeId, setRemoveId] = useState<string>("");
  const [data, setData] = useState<object>({});
  const [reason, setReason] = useState<string>("");
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const userData = sessionReducer.currentUser;
  const currentUserTimeZone = userData.timeZone
  useEffect(() => {
    getMeetings();
    // eslint-disable-next-line
  }, []);
  const getMeetings = async () => {
    try {
      const allInvitations = await messagesServices.fetchInvitationList();
      setAllInvitations(allInvitations?.data.map((allInvitation: any) => {
        let invitation = allInvitation.invitation
        let finalDate = moment(invitation.date).format("YYYY-MM-DD");
        let convertedDate = moment(finalDate).utc().tz(currentUserTimeZone).format("YYYY-MM-DD")
        let userFromTimeZone = moment.tz(`${finalDate} ${invitation.from_time}`, invitation.location_id).tz(currentUserTimeZone);
        let userToTimeZone = moment.tz(`${finalDate} ${invitation.to_time}`, invitation.location_id).tz(currentUserTimeZone)
        if (!(invitation.location_id === currentUserTimeZone)) {
          return {
            ...invitation,
            date: convertedDate,
            from_time: userFromTimeZone.format("HH:mm:ss"),
            to_time: userToTimeZone.format("HH:mm:ss")
          }
        }
        return invitation;
      }))

    } catch (error) {
      FlashMessage(error.message, "error");
    }
  };

  const slotEx = (hour: string) => {
    if (parseInt(hour.split(":")[0]) > 12) return "p";
    else return "a";
  };

  const slotNumber = (hour: string) => {
    if (hour) {
      if (parseInt(hour.split(":")[0]) < 12) return hour.slice(0, -3);
      else return parseInt(hour.split(":")[0]) - 12 + ":" + hour.split(":")[1];
    }
  };

  const removeEvent = async (id) => {
    setShowDetailsModal(false);
    setShowConfirmModal(true);
    setRemoveId(id);
  };

  const _handleRemove = async () => {
    try {
      const result = await messagesServices.removeInvitation(removeId, reason);

      FlashMessage(result.message);
      getMeetings();
      setRemoveId("");
      setReason("");
    } catch (error) {
      FlashMessage(error.message, "error");
    }
    setShowDetailsModal(false);
    setShowConfirmModal(false);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <Grid
          item
          className={
            eventInfo.event._def.extendedProps.is_deleted
              ? "text-delete pointer"
              : "pointer"
          }
        >
          <span>
            &#x2022;
            {slotNumber(
              _.get(eventInfo.event._def.extendedProps, "from_time", "")
              // moment(eventTimeZone).utc().tz(currentUserTimeZone).format("HH:mm:ss")
            )}
            {slotEx(
              _.get(eventInfo.event._def.extendedProps, "from_time", "")
              // moment(eventTimeZone).utc().tz(currentUserTimeZone).format("HH:mm:ss")
            )}
          </span>
          <span>
            <b>
              &nbsp;
              {_.get(
                eventInfo.event._def.extendedProps,
                "agenda_of_call",
                ""
              )}
            </b>
          </span>
        </Grid>
      </>
    );
  };

  const _handleCloseNewMessageModal = () => {
    setShowConfirmModal(false);
  };

  const _handleCloseDetailsModal = () => {
    setShowDetailsModal(false);
  };

  const _handleClick = (e) => {
    setRemoveId(e.event.id)
    setShowDetailsModal(true);
    setData(e.event._def.extendedProps);
  };

  const handleChange = (value) => {
    setReason(value);
  };

  return (
    <>
      <div className="cal-container">
        <div>
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            eventContent={renderEventContent}
            events={allInvitations}
            dayMaxEventRows={3}
            eventClick={(e) => {
              _handleClick(e);
            }}
            buttonText={{ today: "Today" }}
          />
        </div>
        <ConfirmDialog
          visible={showConfirmModal}
          title="Confirm"
          onCancel={() => _handleCloseNewMessageModal()}
          onConfirm={() => _handleRemove()}
          bodyText={
            <>
              <p>Are sure want to remove ?</p>
              <div>
                <Input
                  name="reason"
                  className="reason-text"
                  placeholder="Enter reason"
                  onChange={(e) => {
                    handleChange(e.target.value);
                  }}
                />
              </div>
            </>
          }
          confirmText="Ok"
        />
        <Modal
          className="new-msg-model"
          visible={showDetailsModal}
          closeButton={true}
          title="Details"
          onClose={() => _handleCloseDetailsModal()}
        >
          <table className="my-table">
            <tr className="tr-class">
              <td className="td-class">Agenda Of Call</td>
              <td className="td-class">{_.get(data, "agenda_of_call", "")}</td>
            </tr>
            <tr className="tr-class">
              <td className="td-class">Time</td>
              <td className="td-class">
                {slotNumber(_.get(data, "from_time", "")) + slotEx(_.get(data, "from_time", ""))
                  + " To " +
                  slotNumber(_.get(data, "to_time", "")) + slotEx(_.get(data, "to_time", ""))}
              </td>
            </tr>
            <tr className="tr-class">
              <td className="td-class">Time Zone</td>
              <td className="td-class">{_.get(data, "location_id", "")}</td>
            </tr>
            <tr className="tr-class">
              <td className="td-class">Additional Notes</td>
              <td className="td-class">
                {_.get(data, "additional_notes", "")}
              </td>
            </tr>
            <tr className="tr-class">
              <td className="td-class">Meeting Type</td>
              <td className="td-class">
                {data?.meeting_type === "video_meeting"
                  ? "Video Meeting"
                  : "Phone Call"}
              </td>
            </tr>
            {data?.meeting_type === "video_meeting" ? (
              <tr className="tr-class">
                <td className="td-class">video_link</td>
                <td className="td-class">{_.get(data, "video_link", "")}</td>
              </tr>
            ) : (
              <tr className="tr-class">
                <td className="td-class">Phone Number</td>
                <td className="td-class">{_.get(data, "phone_Number", "")}</td>
              </tr>
            )}
          </table>
          <div className="d-flex justify-content-center gap-1 mt-20">
            {!(data?.is_deleted) && (
              <Button color="primary"
                onClick={() => removeEvent(removeId)}
              >
                Cancel Event
              </Button>
            )}

            <Button
              onClick={() => _handleCloseDetailsModal()}
              color="secondary"
            >
              Close
            </Button>

          </div >
        </Modal >
      </div >
    </>
  );
};

export default Appointments;
