import { createSlice } from "@reduxjs/toolkit";
import { get, set } from "lodash";
import { ReducerFlag } from "../../types/reducer.types";
const _ = { get, set };
// Initial state for reducer
interface IState {
  [key: string]: any;
}
const initialState: IState = {
  connectionsData: [],
  loading: false,
  pagination: {},
  data: [],
  sendInviteFlag: ReducerFlag.INIT,
  companyFollowFlag: ReducerFlag.INIT,
  updateCandidateConnectionRequestFlag: ReducerFlag.INIT,
  updateCandidateConnectionRequestMessage: "",
  sendInviteData: {},
  companyFollowData: {},
  message: "",
  requestFlag: ReducerFlag.INIT,
};

const slice = createSlice({
  name: "searchAndConnect",
  initialState: initialState,
  reducers: {
    searchListRequest: (state, action) => {
      return { ...state, searchModalLoader: true };
    },
    searchListSuccess: (state, { payload }) => {
      return {
        ...state,
        searchModalLoader: false,
        data: _.get(payload, "data.data", []),
        searchTerm: _.get(payload, "searchTerm", ""),
        allResult: _.get(payload, "allResult", false),
        pagination: {
          current:
            _.get(payload, "data.pagination.page", 0) === 0
              ? 1
              : _.get(payload, "data.pagination.page", 0),
          total: _.get(payload, "data.pagination.totalPages", 0),
          pageSize: _.get(payload, "data.pagination.pageSize", 10),
          totalRecords: _.get(payload, "data.pagination.pageRecords", 0),
        },
      };
    },
    searchListFailed: (state, { payload }) => {
      return {
        ...state,
        searchModalLoader: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    clearAllRequest: (state) => {
      return {
        ...state,
        searchData: [],
      };
    },
    searchAllRequest: (state, action) => {
      return { ...state, loading: true };
    },
    searchAllSuccess: (state, { payload }) => {
      const oldData = state?.searchData || [];
      const currentData = _.get(payload, "data.data", []);
      const _data = [...oldData, ...currentData];
      return {
        ...state,
        loading: false,
        searchData: _data,
        searchTerm: _.get(payload, "searchTerm", ""),
        searchType: _.get(payload, "searchType", ""),
        allResult: _.get(payload, "allResult", false),
        pagination: {
          current:
            _.get(payload, "data.pagination.page", 0) === 0
              ? 1
              : _.get(payload, "data.pagination.page", 0),
          totalCount: _.get(payload, "data.pagination.totalCount", 0),
          totalPages: _.get(payload, "data.pagination.totalPages", 0),
          pageSize: _.get(payload, "data.pagination.pageSize", 10),
          totalRecords: _.get(payload, "data.pagination.pageRecords", 0),
        },
      };
    },
    searchAllFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    updateSearchAllListData: (state, { payload }) => {
      return { ...state, searchData: payload };
    },

    sendInvitationRequest: (state, action) => {
      return { ...state, sendInviteFlag: ReducerFlag.INIT };
    },
    sendInvitationSuccess: (state, { payload }) => {
      return {
        ...state,
        sendInviteFlag: ReducerFlag.SUCCESS,
        sendInviteData: _.get(payload, "data", {}),
        message: _.get(payload, "message", ""),
      };
    },
    sendInvitationFailed: (state, { payload }) => {
      return {
        ...state,
        message: _.get(payload, "message", ""),
        sendInviteData: {},
        errors: _.get(payload, "errors", {}),
        sendInviteFlag: ReducerFlag.FAILED,
      };
    },
    resetSendInvitationFlag: (state) => {
      return {
        ...state,
        message: "",
        errors: {},
        sendInviteData: {},
        sendInviteFlag: ReducerFlag.INIT,
      };
    },

    getCandidateProfileRequest: (state, action) => {
      return { ...state, profileLoading: true };
    },
    getCandidateProfileSuccess: (state, { payload }) => {
      return {
        ...state,
        profileLoading: false,
        profile: _.get(payload, "data.data", []),
      };
    },
    getCandidateProfileFailed: (state, { payload }) => {
      return {
        ...state,
        profileLoading: false,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },

    candidateProfileReset: (state, { payload }) => {
      return { ...state, profile: _.set(payload, "data.data", []) };
    },

    connectionRequestsListRequest: (state, action) => {
      return { ...state, loading: true };
    },

    connectionRequestsListSuccess: (state, { payload }) => {
      const oldData = _.get(state, "connectionsData", []);
      const currentData = _.get(payload, "data.data", []);
      const allData = [...oldData, ...currentData];
      return {
        ...state,
        loading: false,
        requestFlag: ReducerFlag.SUCCESS,
        connectionsData: allData,
        pagination: {
          current:
            _.get(payload, "data.pagination.page", 0) === 0
              ? 1
              : _.get(payload, "data.pagination.page", 0),
          total: _.get(payload, "data.pagination.totalPages", 0),
          pageSize: _.get(payload, "data.pagination.pageSize", 10),
          count: _.get(payload, "data.pagination.count", 0),
          totalRecords: _.get(payload, "data.pagination.pageRecords", 0),
        },
      };
    },
    connectionRequestsListReset: (state, { payload }) => {
      const currentData = _.get(payload, "data.data", []);
      return {
        ...state,
        requestFlag: ReducerFlag.SUCCESS,
        connectionsData: currentData,
        pagination: {
          current:
            _.get(payload, "data.pagination.page", 0) === 0
              ? 1
              : _.get(payload, "data.pagination.page", 0),
          total: _.get(payload, "data.pagination.totalPages", 0),
          pageSize: _.get(payload, "data.pagination.pageSize", 10),
          count: _.get(payload, "data.pagination.count", 0),
          totalRecords: _.get(payload, "data.pagination.pageRecords", 0),
        },
      };
    },
    connectionRequestsListFailed: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        requestFlag: ReducerFlag.FAILED,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    connectionListFlagReset: (state) => {
      return {
        ...state,
        requestFlag: ReducerFlag.INIT,
      };
    },

    companyRequest: (state, { payload }) => {
      // const oldData = state?.companyData || [];
      // const currentData = _.get(payload, "data", []);
      // const _data = [...oldData, ...currentData];
      return {
        ...state,
        // companyData: _data,
        loading: true,
      };
    },
    companyResetData: (state) => {
      return {
        ...state,
        companyData: [],
      };
    },
    updateCompanyRequest: (state, { payload }) => {
      return { ...state, companyData: payload };
    },
    followCompanyRequest: (state, action) => {
      return {
        ...state,
        companyFollowFlag: ReducerFlag.INIT,
        companyFollowData: {},
      };
    },
    followCompanySuccess: (state, { payload }) => {
      return {
        ...state,
        message: _.get(payload, "message", ""),
        companyFollowFlag: ReducerFlag.SUCCESS,
        companyFollowData: payload.data || {},
      };
    },
    followCompanyFailed: (state, { payload }) => {
      return {
        ...state,
        message: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
        companyFollowData: {},
        companyFollowFlag: ReducerFlag.FAILED,
      };
    },
    followCompanyReset: (state) => {
      return {
        ...state,
        message: "",
        companyFollowFlag: ReducerFlag.INIT,
        companyFollowData: {},
      };
    },
    updateCandidateConnectionRequest: (state, action) => {
      return {
        ...state,
        updateCandidateConnectionRequestFlag: ReducerFlag.INIT,
      };
    },
    updateCandidateConnectionSuccess: (state, { payload }) => {
      const _data = state.connectionsData.filter(
        (item: any) => item.id !== payload?.data?.id
      );
      return {
        ...state,
        connectionsData: [..._data],
        requestType: payload?.data.type,
        updateCandidateConnectionRequestFlag: ReducerFlag.SUCCESS,
        message: _.get(payload, "message", ""),
      };
    },
    updateCandidateConnectionFailed: (state, { payload }) => {
      return {
        ...state,
        updateCandidateConnectionRequestFlag: ReducerFlag.FAILED,
        updateCandidateConnectionRequestMessage: _.get(payload, "message", ""),
        errors: _.get(payload, "errors", {}),
      };
    },
    updateCandidateConnectionReset: (state) => {
      return {
        ...state,
        updateCandidateConnectionRequestFlag: ReducerFlag.INIT,
        updateCandidateConnectionRequestMessage: "",
        errors: {},
      };
    },
  },
});

// Actions
export const {
  searchListRequest,
  searchListSuccess,
  searchListFailed,
  updateSearchAllListData,
  searchAllRequest,
  clearAllRequest,
  searchAllSuccess,
  searchAllFailed,
  sendInvitationRequest,
  sendInvitationSuccess,
  sendInvitationFailed,
  resetSendInvitationFlag,
  getCandidateProfileRequest,
  getCandidateProfileSuccess,
  getCandidateProfileFailed,
  candidateProfileReset,
  connectionRequestsListRequest,
  connectionRequestsListSuccess,
  connectionRequestsListFailed,
  followCompanyRequest,
  companyRequest,
  companyResetData,
  updateCompanyRequest,
  followCompanySuccess,
  followCompanyFailed,
  followCompanyReset,
  updateCandidateConnectionRequest,
  updateCandidateConnectionSuccess,
  updateCandidateConnectionFailed,
  updateCandidateConnectionReset,
  connectionListFlagReset,
  connectionRequestsListReset,
} = slice.actions;

// Reducers
export default slice.reducer;
