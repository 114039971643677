import React, { useState, useEffect, useRef } from "react";
import "quill-mention";
import _ from 'lodash';
// import { Avatar } from "@material-ui/core";
import Avatar from '../../../components/common/AvtarImage'
import Image from "../../../components/common/Image";
import httpRequest from "../../../utils/httpRequest";
import API_URLS from "../../../utils/apiUrls";
import { useParams } from "react-router-dom";
import Emoji from "../../Messages/components/emoji";
import EmojiPicker from "emoji-picker-react";
import { useForm } from "react-hook-form";
import dots from "../../../assets/svg/dots.svg";
import { useSelector } from "react-redux";
import { rootReducersState } from "../../../reducers";
import FlashMessage from "../../../components/common/FlashMessage";
import { ReactComponent as Heart } from "../../../assets/svg/heart.svg";
import { ReactComponent as HeartActive } from "../../../assets/svg/heart-active.svg";
import { ReactComponent as ShareIcon } from "../../../assets/svg/share-icon.svg";
import { ReactComponent as CopyLink } from "../../../assets/svg/copy-link.svg";
import copyToClipboard from "copy-to-clipboard";
import appRoutes from "../../../routes/app.routes";
import { ReactComponent as Comment } from "../../../assets/svg/comment.svg";
interface Article {
  seo_title: string;
  seo_description: string;
  title: string;
  content: string;
  job_title: string;
  user_name: string;
  cover_image: string;
  id: number;
  profile_image: string;
  post_comments_count: number;
  post_likes_count: number;
  liked: number;
  user_id: number,
  slug: string
};

interface ArticleComment {

  post_id: string,
  id: number,
  parent_id: number
  comment_text: string,
  user_id: number,
  comment_replies_count: number,
  comment_likes_count: number,
  liked: number,
  reply_count: number,
  reply_comments: {
    id: number,
    parent_id: number,
    user_id: number,
    comment_text: string,
    post_id: string,
    comment_likes_count: number,
    liked: number,
    user: {
      first_name: string,
      last_name: string,
      profile_image: string
    },
    candidateProfile: {
      job_title_custom: string,
      jobTitle: {
        title: string;
      }
    },
  }[],
  user: {
    first_name: string,
    last_name: string,
    profile_image: string
  },
  candidateProfile: {
    job_title_custom: string,
    jobTitle: {
      title: string;
    }
  },
};

interface AddComment {
  comment_text: string,
  post_id: string,
  parent_id: string
}


function ArticleView() {
  const [viewData, setViewData] = useState<Article>({} as Article);
  const [page, setPage] = useState<number>(2);
  const params = useParams();
  const slug = _.get(params, "slug", "");
  const [togglePicker, setTogglePicker] = useState<boolean>(false);
  const [commonTogglePicker, setCommonTogglePicker] = useState<boolean>(false);
  const emojiRef = useRef<any>(null);
  const PostId = viewData.id;
  const [comments, setComments] = useState<ArticleComment[]>([]);
  const [replyComment, setReplyComment] = useState<any>({});
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [commonCommentText, setCommonCommentText] = useState<any>({ comment_text: "" });
  const [commentText, setCommentText] = useState<any>({ comment_text: "" });
  const [replyText, setReplyText] = useState<any>({ reply_text: "" });
  const [commentIndex, setCommentIndex] = useState<number>();
  const [replyIndex, setReplyIndex] = useState<number>();
  const [editComment, setEditComment] = useState<any>({});
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const hideMenu = useRef<any>(null);
  const currentUserId = _.get(sessionReducer, "currentUser.id", "");
  const currentUserImage = _.get(
    sessionReducer,
    "currentUser.profile_image",
    ""
  );
  const [shareDropDown, setShareDropDown] = useState(false);
  const ShareDropDownStatus = () => {
    shareDropDown ? setShareDropDown(false) : setShareDropDown(true);
  };

  const hideEmojis = (event) => {
    if (emojiRef.current && !emojiRef.current.contains(event.target)) {
      setTogglePicker(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", hideEmojis, true);
    return () => {
      document.removeEventListener("click", hideEmojis, true);
    };
  }, []);

  const {
    register
  } = useForm<AddComment>();


  useEffect(() => {
    fetchArticle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowMenu = () => {
    setShowMenu((prevState) => !prevState)
    if (showMenu === false) {
      setReplyIndex(-1)
      setCommentIndex(-1)
    }
  }

  const fetchArticle = async () => {
    try {
      const oldData = await httpRequest().get(API_URLS.viewArticle(slug));
      const firstName = oldData.data.data.user.first_name;
      const lastName = oldData.data.data.user.last_name;
      const username = firstName + " " + lastName;
      const comment = oldData?.data?.data?.post_comments
      const jobTitle = oldData?.data?.data?.candidateProfile?.job_title_custom || oldData?.data?.data?.candidateProfile?.jobTitle?.title;
      const data = {
        id: oldData.data.data.id,
        slug: oldData.data.data.slug,
        title: oldData.data.data.title,
        content: oldData.data.data.content || "",
        seo_title: oldData.data.data.seo_title || "",
        seo_description: oldData.data.data.seo_description || "",
        job_title: jobTitle,
        user_name: username,
        cover_image: oldData.data.data.va_cover_image || null,
        profile_image: oldData.data.data.user.profile_image || "",
        post_comments_count: oldData.data.data.post_comments_count || 0,
        liked: oldData.data.data.liked || 0,
        user_id: oldData.data.data.user_id,
        post_likes_count: oldData.data.data.post_likes_count || 0
      }
      setViewData(data);
      setComments(comment);
    } catch (error) {
      console.log(error);
    }
  }
  const fetchArticleComment = async () => {
    try {
      const getComment = await httpRequest().get(`${API_URLS.fetchArticleComment}/?id=${PostId}&page=${page}`)
      const allComment = getComment.data.data
      setComments((prevState) => ([...prevState, ...allComment]))
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchArticleReply = async (id) => {
    try {
      const reply = await httpRequest().get(`${API_URLS.fetchArticleReply}/?post_id=${PostId}&parent_id=${id}&page=${page}`);
      let allReply = reply.data.data
      setComments(comments.map((comment: any) => {
        if (parseInt(comment.id) === parseInt(id)) {
          return {
            ...comment,
            reply_comments: [...comment.reply_comments, ...allReply],
          };
        }
        return comment;
      }));
      setPage((prePage) => prePage + 1)
    } catch (e) {
      console.log(e)
    }
  }

  const addComment = async (e: any, id?: number) => {
    try {
      const parentId = id;
      const flag = parentId ? "reply" : "comment";
      const keyCode = e.keyCode ? e.keyCode : e.which;
      if (keyCode === 13 && !e.shiftKey) {

        const payload = {
          'comment_text': flag === 'comment' ? commonCommentText.comment_text.trim() : replyText.reply_text.trim(),
          'parent_id': parentId,
          'post_id': PostId
        };


        if (flag === "comment") {
          const { data } = await httpRequest().post<{
            data: ArticleComment;
          }>(`${API_URLS.addComment}`, payload);
          const newComment = data.data
          setComments([newComment, ...comments])
          setViewData((prevState) => ({
            ...prevState,
            post_comments_count: prevState.post_comments_count + 1,
          }));
        } else {

          const { data } = await httpRequest().post<{
            data: ArticleComment;
          }>(`${API_URLS.addComment}`, payload);

          const newComment = data.data
          setComments(comments.map((comment: any) => {
            if (parseInt(comment.id) === id) {
              const replyCount = comment.reply_count + 1
              return {
                ...comment,
                reply_count: replyCount,
                reply_comments: [newComment, ...comment.reply_comments],
              };
            }
            return comment;
          }));
        }
        setReplyText((prevState) => ({
          ...prevState,
          reply_text: ""
        }));
        setCommonCommentText((prevState) => ({
          ...prevState,
          comment_text: ""
        }))
      }


    } catch (error) {
      FlashMessage(
        _.get(error, "message", "Unable to add comment")
      );
    }
  }
  const likeComment = async (id) => {
    const payload = {
      'post_id': PostId,
      'comment_id': id
    }
    const data = await httpRequest().post(`${API_URLS.posts}/comment/like`, payload);
    const parentID = data.data.parentId;
    const commentId = data.data.commentId;

    if (!parentID) {
      comments.map((comment) => {
        if (comment.id === commentId) {
          comment.liked = data.data.liked;
          comment.comment_likes_count = data.data.totalLikes;
        }
        return comment;
      });
    } else {
      replyComment.map((reply) => {
        if (reply.id === commentId) {
          reply.liked = data.data.liked;
          reply.comment_likes_count = data.data.totalLikes;
        }
        return reply;
      })
    }
    fetchArticle();
  }
  const handleReply = (commentText: any) => {
    setReplyComment(commentText);
  };
  const handleEdit = (commentText: any) => {
    setEditComment(commentText);
    setCommentText((prevState) => ({
      ...prevState,
      comment_text: commentText.comment_text
    }))
    setShowMenu(false)
  };
  const editComments = async (e: any, id: number, parent_id?: any) => {

    const payload = {
      comment_text: commentText.comment_text,
      id: id,
      post_id: PostId,
      parent_id: parent_id
    }
    const keyCode = e.keyCode ? e.keyCode : e.which;
    if (keyCode === 13 && !e.shiftKey) {
      const data = await httpRequest().put<{
        data: AddComment
      }>(API_URLS.editComment, payload);

      const comment_text = data.data.data.comment_text || ''
      const parentId = parent_id

      if (!parentId) {
        setComments(comments.map((comment: any) => {
          if (parseInt(comment.id) === id) {
            return {
              ...comment, comment_text: comment_text,
            };
          }
          return comment;
        }));
      } else {
        const replyId = id;
        setComments((prevState) => prevState.map((comment: any) => {
          if (parseInt(comment.id) === parseInt(parentId)) {
            let row = comment.reply_comments.map((row) => {
              if (row.id === replyId) {
                return {
                  ...row,
                  comment_text: comment_text
                };
              } else {
                return row;
              }
            });
            return {
              ...comment,
              reply_comments: row || []
            }
          }
          return comment;
        }))

      }
      setEditComment("")
    }

  }
  const deleteComment = async (id: any, parent_id?: any) => {
    const commentId = id;
    const parentId = parent_id;
    await httpRequest().delete<{
      data: AddComment;
    }>(`${API_URLS.deleteComment}/?id=${commentId}&post_id=${PostId}&parent_id=${parentId}`);

    if (!parentId) {
      const commentData = [...comments];
      const filterData = commentData.filter(comment => comment.id !== id);
      setComments(filterData)
    } else {
      const replyId = id;
      setComments((prevState) => prevState.map((comment: any) => {
        if (parseInt(comment.id) === parseInt(parentId)) {
          const replies = comment.reply_comments.filter(row => row.id !== replyId)
          return {
            ...comment,
            reply_comments: [...replies],
          };
        }
        return comment;
      }))

    }

  }

  const handleCopyPostLink = (articleDetails: any) => {
    const postLink = appRoutes.viewArticle.generateFullPath(articleDetails.slug);
    copyToClipboard(postLink);
    FlashMessage("Copied post link");
  };
  const postLike = async (id: number) => {
    const payload = {
      'post_id': id
    }
    const like = await httpRequest().post(`${API_URLS.posts}/like`, payload);
    const totalLikes = like.data.data.totalLikes;
    const liked = like.data.data.liked
    setViewData((prevState) => ({
      ...prevState,
      post_likes_count: totalLikes,
      liked: liked
    }));
  }

  return (

    <div className="article-post-container">
      <div className="article-post-sub-container">
        {viewData.cover_image && (
          <div className="article-post">
            <Image src={viewData.cover_image} alt="media image" />
          </div>
        )}
        <div >
          <h1>{viewData?.title || ''}</h1>
        </div>
        <div className="article-post-avatar">
          <Avatar
            src={viewData?.cover_image}>
          </Avatar>
          <div className="article-post-user">
            <span className="span-user">{viewData?.user_name || ''}</span>
            <span className="span-job-title">{viewData?.job_title || ''}</span>
          </div>

        </div>


        <div className="article-post-description"
          dangerouslySetInnerHTML={{
            __html: (viewData?.content),
          }}
        />
        <div className="feed-footer">
          <ul>
            <li
              className={_.get(viewData, "liked", 0) === 1 ? "active" : ""}
              onClick={() => {
                postLike(viewData.id)
              }}
            >
              {_.get(viewData, "liked", 0) === 1 ? <HeartActive /> : <Heart />}{" "}
              <span>{viewData.post_likes_count}</span>
            </li>
            <li>
              <Comment /> {viewData.post_comments_count}
            </li>

          </ul>
          <div className="feed-share">
            <div className="share-button" onClick={() => ShareDropDownStatus()}>
              <ShareIcon /> <span>Share</span>
              {shareDropDown ? (
                <ul className="share-dropDown">
                  <li
                    onClick={() => {
                      if (typeof handleCopyPostLink === "function") {
                        handleCopyPostLink(viewData);
                      }
                    }}
                  >
                    {" "}
                    <CopyLink /> <span> Copy Link</span>{" "}
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>
        <div>
          <div className="article-comment">
            <Avatar src={currentUserImage}></Avatar>
            <div className="comment-input-wrapper">
              <textarea
                name="comment_text"
                placeholder="Add a comment..."
                onKeyDown={(e) => addComment(e)}
                value={commonCommentText?.comment_text}
                onChange={(e) => {
                  const data = e?.currentTarget?.value || "";
                  setCommonCommentText((prevState) => ({
                    ...prevState,
                    comment_text: data
                  }))
                }}

                ref={register({
                  maxLength: {
                    value: 1000,
                    message: "Can not exceed more then 1000 characters",
                  },
                  required: {
                    value: true,
                    message: "Comment can't be empty",
                  },
                })}
              />
              <div className="submit-emoji-wrapper">
                <div className="emoji-icon-wrapper" ref={emojiRef}>
                  <Emoji onClick={() => setCommonTogglePicker(!commonTogglePicker)} />

                  {commonTogglePicker && (
                    <EmojiPicker
                      onEmojiClick={(e) => {
                        const data = commentText?.comment_text + e.emoji
                        setCommentText((prevState) => ({
                          ...prevState, comment_text: data
                        }))
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {comments.map((comment) => (
            <div className="comment">
              <div className="comment-list">
                <Avatar src={comment.user.profile_image}></Avatar>
                <div className="comment-list-data">
                  <div className=" comment-card">
                    <h6 className="user-name">{comment?.user?.first_name + " " + comment?.user?.last_name}
                      {viewData.user_id === comment.user_id && (
                        <span className="author">Author</span>
                      )}
                    </h6>

                    {(comment?.candidateProfile?.job_title_custom || comment?.candidateProfile?.jobTitle?.title) && (
                      <div className="user-field">{comment?.candidateProfile?.job_title_custom || comment?.candidateProfile?.jobTitle?.title}</div>
                    )}


                    <div>
                      {_.get(editComment, "id") === comment.id ? (
                        <div className="edit">
                          <div className="comment-input-wrapper">
                            <textarea className="data"
                              id="title"
                              // placeholder={comment?.comment_text}
                              value={commentText?.comment_text}
                              onKeyDown={(e) => editComments(e, comment.id)}
                              onChange={(e) => {
                                const data = e?.currentTarget?.value || "";
                                setCommentText((prevState) => ({
                                  ...prevState,
                                  comment_text: data
                                }))
                              }}
                            ></textarea>
                            <div className="submit-emoji-wrapper">
                              <div className="emoji-icon-wrapper" ref={emojiRef}>
                                <Emoji onClick={() => setTogglePicker(!togglePicker)} />

                                {togglePicker && (_.get(editComment, "id") === comment.id) && (
                                  <EmojiPicker
                                    onEmojiClick={(e) => {

                                      const data = commentText?.comment_text + e.emoji
                                      setCommentText((prevState) => ({
                                        ...prevState, comment_text: data
                                      }))
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="data">{comment?.comment_text}</div>
                      )}
                    </div>
                  </div>

                  {currentUserId === comment.user_id && (<div
                    className="feed-header-more "
                  >
                    <div className="icon-more" ref={hideMenu}>
                      <Image
                        src={dots}
                        alt="postImage"
                        className="dot-icon"
                        onClick={() => {
                          handleShowMenu()
                          setCommentIndex(comment.id)
                        }
                        }
                      />
                    </div>

                    {(showMenu && commentIndex === comment.id) && (
                      <ul>
                        <li
                          onClick={() => {
                            deleteComment(comment.id)
                            handleShowMenu()
                          }}
                        >
                          Delete Comment
                        </li>
                        <li>
                          copy link to comment
                        </li>

                        <li
                          className="font-sm cursor-pointer"
                          onClick={() => {
                            handleEdit(comment);
                          }}
                        >edit
                        </li>
                        <li>
                          Report
                        </li>
                      </ul>
                    )}</div>
                  )}

                  <span className="like-btn cursor-pointer">
                    {_.get(comment, "liked", 0) === 1 ? (
                      <span className="like-active">
                        <HeartActive
                          width={13}
                          height={13}
                          onClick={() => {
                            likeComment(comment.id)
                          }}
                        />
                      </span>
                    ) : (
                      <Heart
                        width={13}
                        height={13}
                        onClick={() => {
                          likeComment(comment.id)
                        }}
                      />
                    )}
                  </span>
                  <span className="font-sm">
                    {" "}
                    {_.get(comment, "comment_likes_count", 0)}{" "}
                  </span>
                  <span
                    className="font-sm cursor-pointer"
                    onClick={() => {
                      handleReply(comment);
                    }}
                  >
                    | Reply
                  </span>
                  <span className="font-sm">
                    &bull; {_.get(comment, "reply_count", 0)}{" "}
                    {_.get(comment, "reply_count", 0) <= 1
                      ? "reply"
                      : "replies"}
                  </span>
                </div>
              </div>
              <div className="comment-like-reply">
                {/* <button className="btn-like" onClick={() => likeComment(comment.id)}>like</button> */}


                <div>
                  {_.get(replyComment, "id") === comment.id && (
                    <div className="article-comment">
                      <Avatar src={currentUserImage}></Avatar>
                      <div className="comment-input-wrapper">
                        <textarea
                          name="content"
                          placeholder="Add a reply..."
                          onKeyDown={(e) => addComment(e, comment.id)}
                          value={replyText?.reply_text}
                          onChange={(e) => {
                            const data = e?.currentTarget?.value || "";
                            setReplyText((prevState) => ({
                              ...prevState,
                              reply_text: data
                            }))
                          }}
                          ref={register({
                            maxLength: {
                              value: 1000,
                              message: "Can not exceed more then 1000 characters",
                            },
                            required: {
                              value: true,
                              message: "Reply can't be empty",
                            },
                          })}
                        />
                        <div className="submit-emoji-wrapper">
                          <div className="emoji-icon-wrapper" ref={emojiRef}>
                            <Emoji onClick={() => setTogglePicker(!togglePicker)} />
                            {togglePicker && (
                              <EmojiPicker
                                onEmojiClick={(e) => {
                                  const data = replyText?.reply_text + e.emoji
                                  setReplyText((prevState) => ({
                                    ...prevState, reply_text: data
                                  }))
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {comment?.reply_comments?.map((reply) => (
                <div className="comment-reply">
                  <div className="comment-sub-reply">
                    <Avatar src={reply.user.profile_image}></Avatar>
                    <div className="comment-reply-data">
                      {currentUserId === reply.user_id && (
                        <div
                          onClick={() => {
                            // setShowMenu((prevState) => !prevState)
                            handleShowMenu()
                            setReplyIndex(reply.id)
                          }
                          }
                          className="feed-header-more "
                          ref={hideMenu}
                        >
                          <Image
                            src={dots}
                            alt="postImage"
                            className="dot-icon"
                          />  {(replyIndex === reply.id && showMenu) && (
                            <ul>
                              <li
                                onClick={() => {
                                  deleteComment(reply.id, reply.parent_id)
                                }}
                              >
                                Delete Comment
                              </li>
                              <li
                                className="font-sm cursor-pointer"
                                onClick={() => {
                                  handleEdit(reply)
                                }}
                              >edit
                              </li>
                              <li>
                                Report
                              </li>
                            </ul>
                          )}</div>)}
                      <div className=" comment-card">
                        <h6 className="user-name">{reply?.user?.first_name + " " + reply?.user?.last_name}
                          {viewData.user_id === reply.user_id && (
                            <span className="author">Author</span>
                          )}
                        </h6>
                        {(reply?.candidateProfile?.job_title_custom || reply?.candidateProfile?.jobTitle?.title) && (
                          <div className="user-field">{reply?.candidateProfile?.job_title_custom || reply?.candidateProfile?.jobTitle?.title}</div>

                        )}
                        <div>
                          {_.get(editComment, "id") === reply.id ? (
                            <div className="comment-input-wrapper">
                              <textarea className="data"
                                id="title"
                                placeholder={reply?.comment_text}
                                value={commentText?.comment_text}
                                onKeyDown={(e) => editComments(e, reply.id, reply.parent_id)}
                                onChange={(e) => {
                                  const data = e?.currentTarget?.value || "";
                                  setCommentText((prevState) => ({
                                    ...prevState,
                                    comment_text: data
                                  }))
                                }}
                              ></textarea>
                              <div className="submit-emoji-wrapper">
                                <div className="emoji-icon-wrapper" ref={emojiRef}>
                                  <Emoji onClick={() => setTogglePicker(!togglePicker)} />
                                  {togglePicker && (
                                    <EmojiPicker
                                      onEmojiClick={(e) => {
                                        const data = commentText?.comment_text + e.emoji
                                        setCommentText((prevState) => ({
                                          ...prevState, comment_text: data
                                        }))
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="data">{reply?.comment_text}</div>
                          )}
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="comment-like-reply">
                    {/* <button className="btn-like" onClick={() => likeComment(reply.id)}>like</button> */}
                    <span className="like-btn cursor-pointer">
                      {_.get(reply, "liked", 0) === 1 ? (
                        <span className="like-active">
                          <HeartActive
                            width={13}
                            height={13}
                            onClick={() => {
                              likeComment(reply.id)
                            }}
                          />
                        </span>
                      ) : (
                        <Heart
                          width={13}
                          height={13}
                          onClick={() => {
                            likeComment(reply.id)
                          }}
                        />
                      )}
                    </span>
                    <span className="font-sm">
                      {" "}
                      {_.get(reply, "comment_likes_count", 0)}{" "}
                    </span>
                    <span
                      className="font-sm cursor-pointer"
                      onClick={() => {
                        handleReply(reply);
                      }}
                    >
                      | Reply
                    </span>
                    <div>
                      {_.get(replyComment, "id") === reply.id && (
                        <div className="article-comment">

                          <Avatar src={currentUserImage}></Avatar>
                          <div className="comment-input-wrapper">
                            <textarea
                              name="content"
                              placeholder="Add a reply..."
                              onKeyDown={(e) => addComment(e, reply.parent_id)}
                              value={commentText?.comment_text}
                              onChange={(e) => {
                                const data = e?.currentTarget?.value || "";
                                setCommentText((prevState) => ({
                                  ...prevState,
                                  comment_text: data
                                }))
                              }}
                              ref={register({
                                maxLength: {
                                  value: 1000,
                                  message: "Can not exceed more then 1000 characters",
                                },
                                required: {
                                  value: true,
                                  message: "reply can't be empty",
                                },
                              })}
                            />
                            <div className="submit-emoji-wrapper">
                              <div className="emoji-icon-wrapper" ref={emojiRef}>
                                <Emoji onClick={() => setTogglePicker(!togglePicker)} />
                                {togglePicker && (
                                  <EmojiPicker
                                    onEmojiClick={(e) => {
                                      const data = commentText?.comment_text + e.emoji
                                      setCommentText((prevState) => ({
                                        ...prevState, comment_text: data
                                      }))
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>


                </div>
              ))
              }
              {/* <div> */}
              {comment.comment_replies_count > 0 && comment.reply_comments.length < comment.comment_replies_count && (
                <div className="load-more reply" onClick={() => fetchArticleReply(comment?.id)}>
                  <span>
                    <b>Load more reply</b>
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
        {
          viewData.post_comments_count > 1 && comments.length < viewData.post_comments_count && (
            <div className="load-more" onClick={() => fetchArticleComment()}>
              <span>
                <b>Load more comment</b>
              </span>
            </div>
          )
        }
      </div>
    </div >
  );
}

export default ArticleView;
