import React, {useState} from "react";
import { Button, Divider, makeStyles, Typography, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDispatch, useSelector } from "react-redux";
import { saveImportedContactsRequest } from "../../../reducers/company/companyMarketingSolution.reducer";
import { rootReducersState } from "../../../reducers";
import StatusModal from "./statusModal";
import appRoutes from "../../../routes/app.routes";
import { useHistory } from "react-router-dom";

// import _ from "lodash"


const useStyles = makeStyles({
  contentwrapper: {
    maxWidth: "1516px",
    width: "100%",
    margin: "0 auto",
    backgroundColor: "#fff",
    maxHeight: "407px",
    height: "100%",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 30px",
  },
  backButton: {
    fontSize: "20px",
    color: "#31222A",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  sectionHeading: {
    color: "#31222A",
    fontSize: "20px",
    lineHeight: "normal",
  },
  divider: {
    height: "1px",
  },
  tagsWrapper: {
    padding: "11px 30px"
  },
  continueButton: {
      
      border: "1px solid #FF0990 !important",
      background: "#FF0990",
      "&:hover": {
        backgroundColor: "#FF0990",
      },
      padding: "12px 35px",
      color: "#fff",
      fontSize: "16px",
      
  },
  cancelButton: {
      color: "#31222A",
      fontSize: "16px",
      lineHeight: "normal",
      padding: "12px 35px",
      marginLeft: "30px",
      border: "1px solid #FF0990",
      "&:hover": {
        backgroundColor: "#FFF",
      },
  },
  continueButtonWrapper: {
    marginLeft: "10px",
    padding: "30px 15px",
    marginTop: "20px",
    "& .MuiButtonBase-root":{
      borderRadius: "4px !important",
    }
  },
  reviewInfoList:{
    "& .MuiListItem-dense": {
       marginBottom: "10px",
       "& .MuiListItemText-primary":{
        fontSize: "18px",
        fontStyle: "normal",
        lineHeight: "186%",
        fontWeight: "400",
        color: "#666"
       },
       "& .MuiListItemIcon-root": {
        color: "#FF0990",       
       }
    }
  },

});

const ReviewInfo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {parsedContacts, tags, loading, flag, subscription_status} = useSelector(({company}: rootReducersState) => company.companyMarketingSolution);
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const companySlug = sessionReducer.currentCompany.slug;
  
  const handleContinue = () => {
    const formData = new FormData();
    formData.append("contacts", JSON.stringify(parsedContacts));
    formData.append("tags", JSON.stringify(tags));
    formData.append("is_subscribe", subscription_status);
    dispatch(saveImportedContactsRequest(formData));
    setIsOpen(true);
  }
  const handleClose = () => {
    setIsOpen(false);
    history.push(appRoutes.contactList.generatePath(companySlug))
  }
  return (
    <>
    <div className={classes.contentwrapper}>
      <div className={classes.titleWrapper}>
        <Typography
          style={{ fontWeight: "500" }}
          className={classes.sectionHeading}
          component="p"
        >
          Review and complete your import contact information
        </Typography>
        <Button className={classes.backButton} onClick={() => history.goBack()}>
          <ArrowBackIosIcon
            width="10"
            height="18"
            style={{ color: "#FF0990A6" }}
          />{" "}
          Back
        </Button>
      </div>
      <Divider className={classes.divider} />
      <div>
        <List className={classes.reviewInfoList}>
          <ListItem  dense>
            <ListItemIcon>
              <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Imported from: File upload"/>
          </ListItem>
          <ListItem  dense>
            <ListItemIcon>
              {subscription_status ? <CheckCircleIcon /> : <CancelIcon />}
            </ListItemIcon>
            <ListItemText primary={`Email Marketing Status: ${subscription_status ? 'Subscribe' : 'Unsubscribe' }`}/>
          </ListItem>
          <ListItem  dense>
            <ListItemIcon>
              <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Update Existing contacts: No"/>
          </ListItem>
          <ListItem  dense>
            <ListItemIcon>
              <CheckCircleIcon fill="#FF0990" />
            </ListItemIcon>
            <ListItemText primary="Tagged: Member"/>
          </ListItem>
      </List>
      </div>
      <div className={classes.continueButtonWrapper}>
          <Button onClick={handleContinue} className={classes.continueButton}>
            Continue
          </Button>
          <Button onClick={()=> console.log("cancel clicked")} variant="outlined" className={classes.cancelButton}>
            Cancel
          </Button>
        </div>
    </div>
    {!loading && flag && isOpen && (
      <StatusModal open = {isOpen} handleClose={handleClose} />
    )}
    </>
  );
};

export default ReviewInfo;
