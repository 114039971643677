import { put, takeLatest } from "redux-saga/effects";
import {
  companyAdminListFailed,
  companyAdminListRequest,
  companyAdminListSuccess,
  getCompanyAdminFailed,
  getCompanyAdminSuccess,
  getConpamyAdminRequest,
  createCompanyAdminRequest,
  createCompanyAdminSuccess,
  createCompanyAdminFailed,
  deleteAdminRequest,
  deleteAdminSuccess,
  deleteAdminFailed,
  searchAdminFailed,
  searchAdminRequest,
  searchAdminSuccess,
  editAdminFailed,
  editAdminRequest,
  editAdminSuccess,
} from "../reducers/company/companyAdmins.reducer";
import adminServices from "../services/companyAdmin.services";
import { call } from "typed-redux-saga";
import { get } from "lodash";

const _ = { get };

interface payloadInterface {
  type: String;
  payload: any;
}

// Admin List
function* getCompanyAdminListWorker({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(adminServices.getCompanyAdminsList);

  try {
    if (response.flag === true) {
      const data = _.get(response, "data", []);
      if (data) {
        yield put(
          companyAdminListSuccess({
            message: _.get(response, "message", ""),
            data: _.get(response, "data", []),
          })
        );
      } else {
        yield put(
          companyAdminListFailed({
            message: _.get(response, "message", ""),
            errors: _.get(payload, "errors", {}),
          })
        );
      }
    } else {
      yield put(
        companyAdminListFailed({ message: _.get(response, "message", "") })
      );
    }
  } catch (error) {
    yield put(companyAdminListFailed({ message: _.get(error, "message", "") }));
  }
}

export function* getAdminListWatcher() {
  yield takeLatest(companyAdminListRequest.type, getCompanyAdminListWorker);
}

function* getSearchAdminWorker({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(adminServices.searchCompanyAdmins, payload);

  try {
    if (response.flag === true) {
      const data = _.get(response, "data.data", {});
      if (data) {
        yield put(
          searchAdminSuccess({
            message: _.get(response, "message", ""),
            data: _.get(response, "data", {}),
          })
        );
      } else {
        yield put(
          searchAdminFailed({
            message: _.get(response, "message", ""),
            errors: _.get(payload, "errors", {}),
          })
        );
      }
    } else {
      yield put(searchAdminFailed({ message: _.get(response, "message", "") }));
    }
  } catch (error) {
    yield put(searchAdminFailed({ message: _.get(error, "message", "") }));
  }
}

export function* getSearchAdminWatcher() {
  yield takeLatest(searchAdminRequest.type, getSearchAdminWorker);
}

// Get Admin
function* getCompanyAdminWorker({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(adminServices.getAdminDetail, payload);

  try {
    if (response.flag === true) {
      const data = _.get(response, "data", {});
      if (data) {
        yield put(
          getCompanyAdminSuccess({
            message: _.get(response, "message", ""),
            data: _.get(response, "data", {}),
          })
        );
      } else {
        yield put(
          getCompanyAdminFailed({
            message: _.get(response, "message", ""),
            errors: _.get(payload, "errors", {}),
          })
        );
      }
    } else {
      yield put(
        getCompanyAdminFailed({ message: _.get(response, "message", "") })
      );
    }
  } catch (error) {
    yield put(getCompanyAdminFailed({ message: _.get(error, "message", "") }));
  }
}

export function* getAdminWatcher() {
  yield takeLatest(getConpamyAdminRequest.type, getCompanyAdminWorker);
}

// // Get Admin History
// function* getAdminAuditLogWorker({ payload }: payloadInterface) {
// 	// calling the API
// 	const response = yield* call(adminServices.getAdminHistory, payload);

// 	try {
// 		if (response.flag === true) {
// 			const data = _.get(response, 'data', {});
// 			if (data) {
// 				yield put(getAdminAuditLogSuccess({ message: _.get(response, "message", ""), data: _.get(response, "data", {}) }));
// 			} else {
// 				yield put(getAdminAuditLogFailed({
// 					message: _.get(response, "message", ""),
// 					errors: _.get(payload, "errors", {})
// 				}));
// 			}
// 		} else {
// 			yield put(getAdminAuditLogFailed({ message: _.get(response, "message", "") }));
// 		}
// 	} catch (error) {
// 		yield put(getAdminAuditLogFailed({ message: _.get(error, "message", "") }));
// 	}
// }

// export function* getAdminAuditLogWatcher() {
// 	yield takeLatest(getAdminAuditLogRequest.type, getAdminAuditLogWorker);
// }

// Admin Create
function* adminCreateWorker({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(adminServices.createCompanyAdmin, payload);

  try {
    if (response.flag === true) {
      yield put(
        createCompanyAdminSuccess({
          data: _.get(response, "data", ""),
          message: _.get(response, "message", ""),
        })
      );
    } else {
      yield put(
        createCompanyAdminFailed({
          message: _.get(response, "message", ""),
          errors: _.get(response, "errors", {}),
        })
      );
    }
  } catch (error) {
    yield put(
      createCompanyAdminFailed({ message: _.get(error, "message", "") })
    );
  }
}

export function* adminCreateWatcher() {
  yield takeLatest(createCompanyAdminRequest.type, adminCreateWorker);
}

// Delete Admin
function* adminDeleteWorker({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(adminServices.deleteAdmin, payload);
  try {
    if (response.flag === true) {
      yield put(
        deleteAdminSuccess({
          message: _.get(response, "message", ""),
          data: payload,
        })
      );
    } else {
      yield put(
        deleteAdminFailed({
          message: _.get(response, "message", ""),
          errors: _.get(payload, "errors", {}),
        })
      );
    }
  } catch (error) {
    yield put(deleteAdminFailed({ message: _.get(error, "message", "") }));
  }
}

export function* adminDeleteWatcher() {
  yield takeLatest(deleteAdminRequest.type, adminDeleteWorker);
}

// editAdmin

function* editAdminPost({ payload }: payloadInterface) {
  // calling the API
  const response = yield* call(adminServices.editAdmin, payload);
  try {
    if (response.flag === true) {
      yield put(
        editAdminSuccess({
          message: _.get(response, "message", ""),
          data: payload,
        })
      );
    } else {
      yield put(editAdminFailed({ message: _.get(response, "message", "") }));
    }
  } catch (error) {
    yield put(editAdminFailed({ message: _.get(error, "message", "") }));
  }
}

export function* editFeedPostWatcher() {
  yield takeLatest(editAdminRequest.type, editAdminPost);
}
