import React from "react";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { isEmpty, isUndefined, get } from "lodash";
import ReactPlayer from "react-player";
import NotFoundPage from "../../../components/common/NotFoundPage";
import Avatar from "../../../components/common/AvtarImage";
const _ = { isEmpty, isUndefined, get };

const Home = (props) => {
  const { companyPublicProfile } = useSelector(
    ({ companyPublicView }: any) => companyPublicView.companyPublicView
  );

  const videoCard = (row) => {
    return (
      <div className="video-card">
        <ReactPlayer
          width="320px"
          height="287px"
          url={`${_.get(row, "url", "")}`}
          controls
        />
      </div>
    );
  };

  const companyPeopleCard = (row) => {
    return (
      <div className="leader-card">
        <div className="card-img">
          <Avatar
            src={_.get(row, "profile_image", "")}
          />
        </div>
        <div className="card-info">
          <h5 className="company-people-label">{`${_.get(
            row,
            "name",
            ""
          )}`}</h5>
          {/* <p>{_.get(row, "role", "")}</p> */}
        </div>
      </div>
    );
  };

  return !isEmpty(companyPublicProfile) ? (
    <div className="tab-wrapper">
      {!isEmpty(companyPublicProfile?.length) && (
        <>
          <h2>Our Mission and Vision</h2>
          <div className="card-wrapper">
            <p>
              {props.loading ? (
                <Skeleton variant="rect" width={"100%"} height={50} />
              ) : (
                <>
                  {_.get(
                    companyPublicProfile,
                    "companyProfile.mission_and_vision",
                    ""
                  )}
                </>
              )}
            </p>
          </div>
        </>
      )}
      {!isEmpty(companyPublicProfile?.companyPeople) && (
        <>
          <h2>Our Leaders</h2>
          <div className="card-wrapper">
            <div className="veiw-cards-wrapper">
              {props.loading ? (
                [1, 2, 3, 4].map((item) => (
                  <Skeleton
                    key={item}
                    variant="rect"
                    width={"100%"}
                    height={245}
                  />
                ))
              ) : (
                <>
                  {(companyPublicProfile?.companyPeople || [1, 2, 3, 4]).map(
                    (row) => {
                      return companyPeopleCard(row);
                    }
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
      {companyPublicProfile?.companyVideos?.length > 0 && (
        <>
          <h2>Our Videos</h2>
          <div className="card-wrapper">
            <div className="veiw-cards-wrapper">
              {props.loading ? (
                [1, 2, 3, 4].map((item) => (
                  <Skeleton
                    key={item}
                    variant="rect"
                    width={"100%"}
                    height={245}
                  />
                ))
              ) : (
                <>
                  {companyPublicProfile?.companyVideos?.map((row) => {
                    return videoCard(row);
                  })}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  ) : (
    <NotFoundPage content="No content found" />
  );
};

export default Home;
