import httpRequest from "../utils/httpRequest";
import { get, omit } from "lodash";
import API_URLS from "../utils/apiUrls";
import { objectToParams } from "../utils/helper";

const _ = { get, omit };

const addSubscriber = (formData: any) => {
  return httpRequest()
    .post(`${API_URLS.addSubscriber}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const addNewTag = (formData: any) => {
  return httpRequest()
    .post(`${API_URLS.addNewTag}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const addBulkContacts = (formData: any) => {
  return httpRequest()
    .post(`${API_URLS.bulkContacts}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const getListTag = () => {
  return httpRequest()
    .get(`${API_URLS.getListTag}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const parseContactsFile = (formData: any) => {
  return httpRequest()
    .post(`${API_URLS.parseContactsFile}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const saveContacts = (formData: any) => {
  return httpRequest()
    .post(`${API_URLS.saveContacts}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res.data, "message", ""),
        data: _.get(res, "data.data", {}),
        numberOfFailedContacts: _.get(res, "data.numberOfFailedContacts", 0),
				numberOfSavedContacts: _.get(res, "data.numberOfSavedContacts", 0),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const fetchContacts = (payload: any) => {
  const queryString = objectToParams(payload);
  return httpRequest()
    .get(`${API_URLS.fetchContacts}?${queryString}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const marketingSolutionServices = {
  addSubscriber,
  addNewTag,
  addBulkContacts,
  getListTag,
  parseContactsFile,
  saveContacts, 
  fetchContacts,
};

export default marketingSolutionServices;
