import React from "react";

import { useLocation } from "react-router-dom";
import List from "./components/List";
import Schedule from "./components/Schedule";

const PublicScheduler = () => {
  const { pathname } = useLocation();

  return (
    <div>
      <div>{pathname.split("/")[3] ? <Schedule /> : <List />}</div>
    </div>
  );
};

export default PublicScheduler;
