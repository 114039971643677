import React, { useState } from "react";
import _ from "lodash";
import { ReactComponent as ShareIcon } from "../../../assets/svg/share-icon.svg";
import { ReactComponent as CopyLink } from "../../../assets/svg/copy-link.svg";
import { ReactComponent as Heart } from "../../../assets/svg/heart.svg";
import { ReactComponent as HeartActive } from "../../../assets/svg/heart-active.svg";
import Comment from "../components/FeedComment";
import Comments from "./Comments";

interface footerProps {
  data: any;
  postLike: (data: any) => void;
  handleCopyPostLink: (data: any) => void;
}

const FeedCommonFooter = ({
  data,
  postLike,
  handleCopyPostLink,
}: footerProps) => {
  const [shareDropDown, setShareDropDown] = useState(false);
  const ShareDropDownStatus = () => {
    shareDropDown ? setShareDropDown(false) : setShareDropDown(true);
  };

  return (
    <div>
      <div className="feed-footer">
        <ul>
          <li
            className={_.get(data, "liked", 0) === 1 ? "active" : ""}
            onClick={() => {
              if (typeof postLike === "function") {
                postLike(data);
              }
            }}
          >
            {_.get(data, "liked", 0) === 1 ? <HeartActive /> : <Heart />}{" "}
            <span>{data.post_likes_count}</span>
          </li>
          <li>
            <Comment data={data} />{" "}
          </li>
        </ul>
        <div className="feed-share">
          <div className="share-button" onClick={() => ShareDropDownStatus()}>
            <ShareIcon /> <span>Share</span>
            {shareDropDown ? (
              <ul className="share-dropDown">
                <li
                  onClick={() => {
                    if (typeof handleCopyPostLink === "function") {
                      handleCopyPostLink(data);
                    }
                  }}
                >
                  {" "}
                  <CopyLink /> <span> Copy Link</span>{" "}
                </li>
                {/* <li><ShareFeed /> <span> Share to feed (Public)</span> </li> */}
                {/* <li><ShareMessage /> <span> Share to Messaging</span> </li> */}
              </ul>
            ) : null}
          </div>
        </div>
      </div>
      <Comments data={data} />
    </div>
  );
};

export default FeedCommonFooter;
