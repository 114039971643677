import React from "react";
import { Container, Grid } from "@material-ui/core";

const Intro = () => {

	return (
		<Container maxWidth="sm">
			<Grid container justify="center" xs={12}>
				<Grid item spacing={2} xs={12}>
					{/* <div className="intro-header">
            <div className="intro-logo">
              {companyName}  
            </div>
            <div className="address">
              <div className="indus-name"> { loading ? <Skeleton width={100} /> : industryName} </div>
              <span>|</span>
              <div className="location-name">{ loading ? <Skeleton width={50} /> : `${locationCityName}, ${locationRegion}`} </div>
              <span>|</span>
              <div className="emp-size"> { loading ? <Skeleton width={80} /> : employeeSize}</div>
            </div>
            <div className="external-link">
              <LanguageIcon /> { loading ? <Skeleton width={300} className="external-link-skeleton" /> :  <a target="_blank" href={sanitizeLink}>{companyWebsite}</a> }
            </div>
          </div> */}
				</Grid>
			</Grid>
		</Container>
	)
}

export default Intro;
