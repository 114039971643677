import _ from "lodash";
import React, { useState } from "react";
import { Button, Input } from "../../../components/common";
import DeclineRequestModal from "./DeclineRequestModal";
import { updateCandidateConnectionRequest } from "../../../reducers/SearchAndConnect/searchAndConnect.reducer";
import { useDispatch } from "react-redux";
import CancelRequestModal from "./CancelRequestModal";
import Avatar from "../../../components/common/AvtarImage";
import { openUrlInNewTab } from "../../../utils/helper";
import appRoutes from "../../../routes/app.routes";
interface ConnectionItemProps {
  connection: any | null;
  isRequester: boolean;
  setDeclineModal?: any;
}

const ConnectionItem = ({ connection, isRequester }: ConnectionItemProps) => {
  const requestOrSender = isRequester ? "requester" : "follower";
  const dispatch = useDispatch();
  const [description, setDescription] = useState(false);
  const [showDeclineModal, setDeclineModal] = useState(false);
  const [showCancelModal, setCancelModal] = useState(false);

  const _handleAccept = () => {
    dispatch(
      updateCandidateConnectionRequest({
        id: connection.id,
        type: "accepted",
        reject_reason: "",
      })
    );
  };
  const candidateShowModal = (connection) => {
    openUrlInNewTab(
      (requestOrSender === "requester") ? (
        appRoutes.candidatePublicProfile.generateFullPath(connection.requester.slug)
      ) : (
        appRoutes.candidatePublicProfile.generateFullPath(connection.follower.slug)
      )

    );
  };

  return (
    <div className="connection-item-wrapper">
      <div className="avatar">
        <Avatar
          src={_.get(connection, `${requestOrSender}.profile_image`, "")}
        />
      </div>
      <div>
        <div className="details" onClick={() => candidateShowModal(connection)}>
          <div onClick={() => setDescription(!description)}>
            <h2>{`${_.get(
              connection,
              `${requestOrSender}.first_name`,
              ""
            )} ${_.get(connection, `${requestOrSender}.last_name`, "")}`}</h2>
          </div>
          <div>
            {isRequester ? (
              <>
                <Button
                  onClick={(e) => {
                    setDeclineModal(true);
                  }}
                  color="secondary"
                  className="text-uppercase"
                >
                  DECLINE
                </Button>
                <Button
                  onClick={_handleAccept}
                  color="primary"
                  className="text-uppercase"
                >
                  ACCEPT
                </Button>
              </>
            ) : (
              <Button
                onClick={(e) => {
                  setCancelModal(true);
                }}
                color="secondary"
                className="text-uppercase"
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
        {description && (
          <Input
            value={_.get(connection, "note", "")}
            name="description"
            placeholder="Description..."
            rows={5}
            multiline={true}
          />
        )}
      </div>
      <DeclineRequestModal
        data={connection}
        status={showDeclineModal}
        handleOnClose={() => setDeclineModal(false)}
      />
      <CancelRequestModal
        data={connection}
        status={showCancelModal}
        handleOnClose={() => setCancelModal(false)}
      />
    </div>
  );
};

export default ConnectionItem;
