/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenterRounded";
import CloudUploadRounded from "@material-ui/icons/CloudUploadRounded";

import {
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Button } from "../../../components/common";
import { useHistory } from "react-router-dom";
import appRoutes from "../../../routes/app.routes";
import { useSelector } from "react-redux";
import { rootReducersState } from "../../../reducers";
import UploadContact from "./upload";
import AddSubscriber from "./addSubscriber";

const AddContacts = () => {
  const history = useHistory();
  const [value, setValue] = React.useState("manually");
  const [pathName, setPathName] = useState<string>("");
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const companySlug = sessionReducer.currentCompany.slug;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue((event.target as HTMLInputElement).value);

  const handleContinue = () => {
    if (value === "file") {
      history.push(appRoutes.uploadContact.generatePath(companySlug));
    } else {
      history.push(appRoutes.manuallyContact.generatePath(companySlug));
    }
  };

  const handleBack = () => {
    history.push(appRoutes.marketingSolution.generatePath(companySlug));
  };

  useEffect(() => {
    const path = window?.location?.pathname.split("/");
    setPathName(path[4]);
  }, []);

  return (
    <>
      <Paper>
        {pathName === "contact-manually" && (
          <div>
            <div className="text-right">
              <Button onClick={handleBack} variant="text" color="none">
                Back
              </Button>
            </div>
            <div>
              <AddSubscriber />
            </div>
          </div>
        )}
        {pathName === "contact-upload" && (
            <UploadContact />
        )}
        {!pathName && (
          <div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <Paper className="radio-card m-20">
                  <div className="m-10">
                    <BusinessCenterIcon />{" "}
                    <FormControlLabel
                      value="manually"
                      control={<Radio />}
                      label="Add Subscriber manually"
                      labelPlacement="start"
                    />
                    <p>
                      Lorem Ipsum simply dummy text printing been typesetting a
                      industry. lorem Ipsum has been industry's anstanda dummy
                      text ever since
                    </p>
                  </div>
                </Paper>
                <Paper className="radio-card m-20">
                  <div className="m-10">
                    <CloudUploadRounded />
                    <FormControlLabel
                      value="file"
                      control={<Radio />}
                      label="Upload a file"
                      labelPlacement="start"
                    />
                    <p>
                      Lorem Ipsum simply dummy text printing been typesetting a
                      industry. lorem Ipsum has been industry's anstanda dummy
                      text ever since
                    </p>
                  </div>
                </Paper>
              </RadioGroup>
            </FormControl>
            <br />
            <div className="m-10">
              <Button onClick={handleContinue}>Continue</Button>
            </div>
            <br />
          </div>
        )}
      </Paper>
    </>
  );
};
export default AddContacts;
