import httpRequest from "../utils/httpRequest";
import { get, omit } from "lodash";
import API_URLS from "../../src/utils/apiUrls";
import { objectToParams } from "../../src/utils/helper";

const _ = { get, omit };

export const updateCandidate = (payload: any) => {
  return httpRequest()
    .patch(`${API_URLS.candidates}update`, payload)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const candidateApplyJob = ({ id, formData }: any) => {
  return httpRequest()
    .patch(`${API_URLS.jobApplications}apply/${id}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const candidateSaveJob = (payload: any) => {
  return httpRequest()
    .patch(`${API_URLS.jobApplications}save/${payload}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const candidateJobApplicationList = (payload: any) => {
  const params = objectToParams(payload);
  return httpRequest()
    .get(`${API_URLS.jobApplications}?${params}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
        pagination: _.get(res, "data.pagination", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const companyCandidatesList = (fields: any) => {
  //full BE route api/company/candidates/list
  return httpRequest()
    .get(`${API_URLS.candidates}list`, fields)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: true,
        message: _.get(
          err,
          "response.data.message",
          "Something went wrong fetching company candidates"
        ),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const uploadCandidateFile = (payload: any) => {
  const type = _.get(payload, "type", "image");
  const formData = _.get(payload, "payload", {});
  return httpRequest()
    .post(`${API_URLS.candidates}profile/${type}`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const deleteVideo = () => {
  return httpRequest()
    .delete(`${API_URLS.candidates}profile/video`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const fetchCandidatePublicProfile = (slug: string) => {
  return httpRequest()
    .get(`${API_URLS.candidates}profile/public/${slug}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};
export const updateCandidateBasicInfo = (form_Data: any) => {
  return httpRequest()
    .patch(`${API_URLS.candidates}profile/basic-profile`, form_Data)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};
export const updateCandidatePersonalValue = (payload: any) => {
  const formData = _.get(payload, "payload", {});
  return httpRequest()
    .patch(`${API_URLS.candidates}profile/personal-value`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const updateCandidateProfessionalPassion = (payload: any) => {
  const formData = _.get(payload, "payload", {});
  return httpRequest()
    .patch(`${API_URLS.candidates}profile/professional-passion`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};
export const updateCandidateWorkingExperience = (payload: any) => {
  const formData = _.get(payload, "experiences", {});
  return httpRequest()
    .patch(`${API_URLS.candidates}profile/work-experience`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const updateCandidateProjects = (payload: any) => {
  const formData = _.get(payload, "projects", {});
  return httpRequest()
    .patch(`${API_URLS.candidates}profile/projects`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};
export const updateCandidateSkills = (payload: any) => {
  const formData = _.get(payload, "skills", {});
  return httpRequest()
    .patch(`${API_URLS.candidates}profile/skills`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};
export const updateCandidateEducation = (payload: any) => {
  const formData = _.get(payload, "education", {});
  return httpRequest()
    .patch(`${API_URLS.candidates}profile/education`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const updateCandidateVisibility = (payload: any) => {
  return httpRequest()
    .patch(`${API_URLS.candidates}profile/visibility`, payload)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "data.message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const deactivateAccount = (payload: any) => {
  return httpRequest()
    .patch(`${API_URLS.candidates}/deactivate-account`, payload)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "data.message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const deleteAccount = (payload: any) => {
  return httpRequest()
    .patch(`${API_URLS.candidates}/delete-account`, payload)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "data.message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const updateNotifications = (payload: any) => {
  return httpRequest()
    .patch(`${API_URLS.candidates}update-notifications`, payload)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "data.message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const updateSlug = (payload: any) => {
  return httpRequest()
    .put(`${API_URLS.candidates}profile/slug`, payload)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "data.message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const uploadCandidateResume = (payload: any) => {
  
  const formData = _.get(payload, "payload", {});
  return httpRequest()
    .post(`${API_URLS.candidates}profile/resume`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        resumeParsedMessage: _.get(res, "data.resumeParsedMessage", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};


export const parseCandidateResume = (payload: any) => {
  
  const formData = _.get(payload, "payload", {});
  return httpRequest()
    .post(`${API_URLS.candidates}profile/resume-parse`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.parsed", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};


export const deleteCandidateResume = () => {
  return httpRequest()
    .delete(`${API_URLS.candidates}profile/resume`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};
const candidateServices = {
  updateCandidate,
  companyCandidatesList,
  uploadCandidateFile,
  candidateApplyJob,
  deleteVideo,
  fetchCandidatePublicProfile,
  candidateJobApplicationList,
  candidateSaveJob,
  updateCandidateEducation,
  updateCandidateSkills,
  updateCandidateProjects,
  updateCandidateWorkingExperience,
  updateCandidateProfessionalPassion,
  updateCandidatePersonalValue,
  updateCandidateBasicInfo,
  updateCandidateVisibility,
  deactivateAccount,
  deleteAccount,
  updateNotifications,
  updateSlug,
  uploadCandidateResume,
  deleteCandidateResume,
  parseCandidateResume
};

export default candidateServices;
