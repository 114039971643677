import React from "react";
import { Avatar } from "@material-ui/core";
import { getInitialLetter } from "../../utils/helper";
import Image from "../common/Image";
interface UserAvatarProps {
  size?: "xsm" | "sm" | "md" | "lg";
  color?: "dark-pink" | "light-gray-color";
  children?: React.ReactNode;
  className?: string;
  src?: string;
  alt?: string;
  variant?: "circle" | "circular" | "rounded" | "square";
  loading?: Boolean;
  loaderSize?: number;
  name?: string;
  [key: string]: any;
}

const UserAvatar = ({
  size,
  color,
  children,
  className,
  src,
  alt,
  variant,
  loading,
  loaderSize,
  name,
  ...props
}: UserAvatarProps) => {
  const fullName = getInitialLetter(name ? name : "", 2);

  return (
    <Avatar
      className={`user-avatar user-avatar-${size} ${className}`}
      color="dark-pink"
      src={src}
      alt={alt ? alt : ""}
      variant={variant}
      {...props}
    >
      {!src ? (
        <span className="user-name">{fullName.toUpperCase()}</span>
      ) : src && !children ? (
        <Image src={src} width="100%" alt={alt || "image"} />
      ) : (
        children
      )}
    </Avatar>
  );
};

// Default props of the component
UserAvatar.defaultProps = {
  size: "sm",
  color: "dark-pink",
  className: "",
  src: "",
  alt: "avatar",
  variant: "circle",
  loading: false,
  loaderSize: 24,
};

export default UserAvatar;
