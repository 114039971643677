import React from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setTags } from "../../../reducers/company/companyMarketingSolution.reducer"

const useStyles = makeStyles({
  inputFieldWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    color: "#666666cc !important",
    fontSize: "18px",
    lineHeight: "normal",
  },
  tagField: {
    maxWidth: "407px",
    width: "100%",
    borderRadius: "10px",
    border: "1px solid rgba(102, 102, 102, 0.30)",
    outline: "none !important",
    "& .MuiOutlinedInput-root": {
      "& .PrivateNotchedOutline-root-1": {
        border: "none",
      },
    },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
      backgroundColor: "#fff !important",
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
      backgroundColor: "#fff !important",
    },
  },
});

interface TagInputType {
  tag: string;
}

const TagInput = () => {
  const { register, handleSubmit, setValue } = useForm<TagInputType>();
  // const [tags, setTags] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    // const tagsData = [];
    // tagsData.push(data.tag);
    // setTags((prev) => [...prev, ...tagsData]);
    dispatch(setTags(data.tag));
    setValue("tag", "");
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.inputFieldWrapper}>
          <InputLabel
            htmlFor="custom-textfield"
            className={classes.label}
            style={{ fontWeight: "500" }}
          >
            Create a tag
          </InputLabel>
          <TextField
            className={classes.tagField}
            name="tag"
            inputRef={register({
              required: {
                value: true,
              },
            })}
            id="custom-textfield"
            variant="outlined"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit(onSubmit)();
              }
            }}
          />
        </div>
      </form>
    </>
  );
};

export default TagInput;
