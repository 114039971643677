import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Menu,
  MenuItem,
  TableRow,
  IconButton,
  Popover,
} from "@material-ui/core";
import { MoreVert as MoreVertIcon } from "@material-ui/icons";
import {
  Button,
  Modal,
  Input,
  FlashMessage,
  ConfirmDialog,
  EmployHERName,
} from "../../components/common";
import { EMAIL_REGEX, referFriendStatus } from "../../utils/appConstants";
import httpRequest from "../../utils/httpRequest";
import API_URLS from "../../utils/apiUrls";
import { formatDate } from "../../utils/helper";

interface ReferFriendProps {
  visible: boolean;
  onClose: () => void;
}

interface ReferFriendDetail {
  id: number;
  invited_email: string;
  status: number;
  profile_progress: number;
  invited_at: string;
}

const MENU_OPTIONS = [
  { key: "RESEND_INVITE", value: "Resend invite" },
  { key: "CANCEL_INVITE", value: "Cancel invite" },
];

const ReferFriend = ({ visible, onClose }: ReferFriendProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showRewardModal, setShowRewardModal] = useState<boolean>(false);
  const [inviteEmail, setInviteEmail] = useState<Record<number, string>>({});
  const [invitesList, setInvitesList] = useState<ReferFriendDetail[]>([]);
  const [showInviteList, setShowInviteList] = useState<boolean>(false);
  const [currentInvite, setCurrentInvite] = useState<ReferFriendDetail>(
    {} as ReferFriendDetail
  );
  const [cancelInviteConfirm, setCancelInviteConfirm] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Fetch the invited list on the load
  useEffect(() => {
    if (visible) {
      fetchAllInvitation();
    }
  }, [visible]);

  // Show hide the invited list
  useEffect(() => {
    if (invitesList.length > 0) {
      setShowInviteList(true);
    } else {
      setShowInviteList(false);
    }
  }, [invitesList]);

  const fetchAllInvitation = async () => {
    setLoading(true);
    try {
      const { data } = await httpRequest().get<{
        data: ReferFriendDetail[];
      }>(API_URLS.fetchReferFriendInvites);
      setInvitesList(data.data);
    } catch (error) {
      setInvitesList([]);
    } finally {
      setLoading(false);
    }
  };

  const handleOnInvite = async () => {
    const allEmails = Object.values(inviteEmail)
      .filter((email: string) => {
        if (!email.trim() || !EMAIL_REGEX.test(email)) {
          return false;
        }
        return true;
      })
      .filter(function (x, i, a) {
        return a.indexOf(x) === i;
      });

    if (allEmails.length === 0) {
      FlashMessage("Please enter at lease one valid email", "error");
      return;
    }

    setLoading(true);
    try {
      const { data } = await httpRequest().post<{
        data: ReferFriendDetail[];
      }>(API_URLS.inviteReferFriendInvites, {
        emails: allEmails,
      });

      FlashMessage("Invitation sent successfully");

      if (data?.data.length > 0) {
        setInvitesList((prevState) => {
          return [...data.data, ...prevState];
        });
      } else {
        FlashMessage("Something went wrong", "error");
      }
      setInviteEmail({});
    } catch (error) {
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "Something went wrong";

      FlashMessage(errMsg, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleMenuClick = (event, data: ReferFriendDetail): void => {
    setAnchorEl(event.currentTarget);
    setCurrentInvite(data);
  };

  const handleMenuClose = (option: string) => {
    if (option === "CANCEL_INVITE") {
      setCancelInviteConfirm(true);
    } else if (option === "RESEND_INVITE") {
      handleResendInvite();
    }
    setAnchorEl(null);
  };

  const handleCancelInvite = async (): Promise<void> => {
    if (!currentInvite?.id) {
      FlashMessage("Please select valid invitation", "error");
      return;
    }

    setLoading(true);
    setCancelInviteConfirm(false);
    try {
      await httpRequest().delete(
        API_URLS.deleteUploadInviteRefer(currentInvite.id)
      );

      FlashMessage("Invitation cancelled successfully");
      setInvitesList((prevState) =>
        prevState.filter((row) => row.id !== currentInvite.id)
      );
    } catch (error) {
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "Something went wrong";

      FlashMessage(errMsg, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleResendInvite = async (): Promise<void> => {
    if (!currentInvite?.id) {
      FlashMessage("Please select valid invitation", "error");
      return;
    }

    setLoading(true);
    try {
      await httpRequest().post(API_URLS.resendInviteRefer(currentInvite.id));

      FlashMessage("Invitation resend successfully");
    } catch (error) {
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "Something went wrong";

      FlashMessage(errMsg, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        size="large"
        loading={loading}
        onClose={() => handleCloseModal()}
        title="Refer a Friend"
        className="refer-friend-modal"
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <span className="user-none">
              Unlock exclusive rewards with <EmployHERName /> and own an NFT
              from our IAMHER NFT collection.{" "}
              <span
                className="span-link"
                onClick={() => setShowRewardModal(true)}
              >
                Click here
              </span>{" "}
              to see how it works.
            </span>
          </Grid>
          {!showInviteList ? (
            <Grid item className="invite-input" xs={12}>
              <label>
                Enter an invite emails (Invalid emails will be ignored)
              </label>
              {[1, 2, 3, 4, 5].map((row) => (
                <Grid item xs={12} key={row}>
                  <Input
                    fullWidth={false}
                    value={inviteEmail[row] || ""}
                    name={`email_${row}`}
                    placeholder="Enter an invite email"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setInviteEmail((prevState) => ({
                        ...prevState,
                        [row]: e?.target?.value || "",
                      }))
                    }
                  />
                  {row === 5 && (
                    <>
                      <Button onClick={() => handleOnInvite()}>
                        Send Invite
                      </Button>
                      {invitesList.length > 0 && (
                        <>
                          &nbsp;&nbsp;<span>or</span>
                          <Button
                            color="secondary"
                            onClick={() => setShowInviteList(true)}
                          >
                            Show Invited
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid item xs={12} className="invite-table-container">
              <span
                className="span-link"
                onClick={() => setShowInviteList(false)}
              >
                Invite more?
              </span>
              <TableContainer>
                <Table
                  stickyHeader
                  size="small"
                  aria-label="refer a friend table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Invited Email</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center" width={90}>
                        Invited on
                      </TableCell>
                      <TableCell align="center" width={10}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invitesList.length === 0 && loading === false && (
                      <TableRow>
                        {" "}
                        <TableCell colSpan={4} className="no-rows-cell">
                          You don't have any invited friends, send invite to
                          unlock the exclusive rewards.
                        </TableCell>{" "}
                      </TableRow>
                    )}
                    {[...invitesList].map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.invited_email}
                        </TableCell>
                        <TableCell align="center">
                          {row.profile_progress > 0
                            ? `${row.profile_progress}% completed`
                            : referFriendStatus.find(
                                (statusRow) => statusRow.id === row.status
                              )?.title || "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {formatDate(row.invited_at)}
                        </TableCell>
                        <TableCell align="center" width={10}>
                          {row.status === 1 && (
                            <IconButton
                              aria-label="more"
                              aria-controls="invite-action-menu"
                              aria-haspopup="true"
                              onClick={(event: MouseEvent<HTMLButtonElement>) =>
                                handleMenuClick(event, row)
                              }
                            >
                              <MoreVertIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
          <Grid item xs={12} className="form-actions">
            <Button color="secondary" onClick={() => handleCloseModal()}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <Popover open={open} id="refer-menu-popover">
        <Menu
          id="invite-action-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleMenuClose}
        >
          {[...MENU_OPTIONS].map((option) => (
            <MenuItem
              key={option.key}
              onClick={() => handleMenuClose(option.key)}
            >
              {option.value}
            </MenuItem>
          ))}
        </Menu>
      </Popover>
      <ConfirmDialog
        visible={cancelInviteConfirm}
        bodyText="Are you sure want to Cancel this invite?"
        onConfirm={() => handleCancelInvite()}
        onCancel={() => setCancelInviteConfirm(false)}
      />

      <Modal
        visible={showRewardModal}
        size="large"
        onClose={() => setShowRewardModal(false)}
        className="refer-how-works"
      >
        <h5>How reward works</h5>
        <Grid item xs={12}>
          <span>
            <strong>Step 1</strong>: Create a complete profile on the employHER
            platform.
          </span>
          <span>
            <strong>Step 2</strong>: Refer 5 friends to join the employHER
            community (You can invite as many as you want) and create a complete
            profile.
          </span>
          <span>
            <strong>Step 3</strong>: Receive an exclusive NFT from the IAMHER
            art collection as a reward for being an active member of the
            community.
          </span>
          <span>
            <strong>Step 4</strong>: Enjoy and showcase your unique digital art
            piece or even sell it as it's 100% yours to keep.
          </span>
          <span>
            <strong>Step 5</strong>: You will be contacted by our support team
            to reward you.
          </span>
          <span>
            <strong>Step 6</strong>: Continue to engage with the employHER
            community and unlock more rewards.
          </span>
        </Grid>
        <div className="text-center">
          <Button color="secondary" onClick={() => setShowRewardModal(false)}>
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ReferFriend;
