import { get } from "lodash";
import { call } from "typed-redux-saga";
import { put, takeLatest } from 'redux-saga/effects';
import industryServices from '../services/industry.services';
import { industryListRequest, industryListSuccess, industryListFailed } from '../reducers/industry/industry.reducer';

const _ = { get };

// Industry List
function* getIndustryListWorker() {
	// calling the API
	const response = yield* call(industryServices.getIndustries);

	try {
		if (response.flag === true) {
			const data = _.get(response, 'data', []);
			if (data) {
				yield put(industryListSuccess({ message: _.get(response, "message", ""), data: _.get(response, "data", []) }));
			} else {
				yield put(industryListFailed({
					message: _.get(response, "message", ""),
					errors: _.get(response, "errors", {})
				}));
			}
		} else {
			yield put(industryListFailed({ message: _.get(response, "message", "") }));
		}
	} catch (error) {
		yield put(industryListFailed({ message: _.get(error, "message", "") }));
	}
}

export function* getIndustryListWatcher() {
	yield takeLatest(industryListRequest.type, getIndustryListWorker);
}
