import React, { useState } from "react";
import HelpIcon from "@material-ui/icons/Help";
import { openUrlInNewTab } from "./../../utils/helper";
import _ from "lodash";
import appRoutes from "../../routes/app.routes";
import { companyApplicationStatuses } from "../../utils/appConstants";
import { Tooltip } from "@material-ui/core";
import Avatar from "../common/AvtarImage"
import Image from "../common/Image";
import dots from "../../assets/svg/dots.svg";
import Report from "../../components/common/Report";
interface JobPostingProps {
  className?: string;
  job: any;
  showStatus?: boolean;
  applicationStatus?: number;
  rejectionReason?: string;
  onClick?: (status: any) => void | null;
}

const Card = ({
  className,
  job,
  showStatus,
  applicationStatus,
  rejectionReason,
  onClick,
}: JobPostingProps) => {

  const [showMenu, setShowMenu] = useState<number | null>(null);
  const [openJobReport, setOpenJobReport] = useState(false);

  const handleRouting = () =>
    openUrlInNewTab(appRoutes.jobDetail.generatePath(_.get(job, "slug", "")));

  const getCandidateJobStatus = () => {
    let status = { id: 0, title: "Submitted" };
    Object.values(companyApplicationStatuses).forEach((obj: any) => {
      if (obj.id === applicationStatus) {
        status = obj;
      }
    });
    return status;
  };

  const _handleOnClick = () => {
    handleRouting();
  };

  return (
    <>    <div onClick={_handleOnClick} className="job-item">
      <div className="job-item-inner">
        <div className="job-item-header">
          <div className="avatar">
            <Avatar
              src={_.get(job, "va_company_logo", "")}
              // size="md"
              type="job"
            />
          </div>
          <div className="meta">
            <h4>{_.get(job, "title", "")}</h4>
            <div>
              <p className="meta-location">{_.get(job, "city.location", "")}</p>
              <p className="meta-company">{_.get(job, "company_name", "")}</p>
            </div>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation(); // Prevent card click from being triggered
              setShowMenu((prevIndex) => (prevIndex === job.id ? null : job.id));
            }}
            className="feed-header-more"
          >
            <Image src={dots} alt="job options" className="dot-icon" />
            {showMenu === job.id && (
              <ul>
                <li onClick={() => setOpenJobReport(true)}>Report Job</li>
              </ul>
            )}
          </div>

        </div>

        <div
          dangerouslySetInnerHTML={{
            __html: _.get(job, "description", ""),
          }}
          className="job-item-content wysiwyg-data"
        ></div>
        <div className="job-item-footer">
          <div className="status">
            {showStatus && (
              <>
                <label className={getCandidateJobStatus().title.toLowerCase()}>
                  {getCandidateJobStatus().title}
                </label>
                {/* For rejected status 6 */}
                {getCandidateJobStatus().id === 6 && (
                  <Tooltip title={rejectionReason!} placement="top">
                    <HelpIcon />
                  </Tooltip>
                )}
              </>
            )}
          </div>
          <p>Posted on {_.get(job, "posted_on", "N/A")}</p>
        </div>
      </div>
    </div>
      {openJobReport && (
        <Report
          referenceId={job.id}
          type="jobs"
          onClose={() => setOpenJobReport(false)}
          openReports={true}
        />
      )}
    </>
  );
};

export default Card;
