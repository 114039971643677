import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import fallBackImage from "../../assets/images/avatar.png";
import fallBackBanner from "../../assets/images/no-banner.png";
import blankAvatar from "../../assets/images/blank-avatar.png";

interface ImageProps {
  src: string;
  alt: string;
  className?: string;
  fbImage?: string;
  onClick?: () => void;
  size?: "small" | "medium" | "large";
  width?: string;
  height?: string;
  banner?: boolean;
}

const Image = ({
  src,
  alt,
  className,
  size,
  width,
  height,
  banner,
  fbImage,
  ...props
}: ImageProps) => {
  const onErrorImageHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    if (fbImage) {
      event.currentTarget.src = blankAvatar;
    } else {
      event.currentTarget.src = banner ? fallBackBanner : fallBackImage;
    }

  };

  return (
    <LazyLoadImage
      className={`${className} ${width === "" || height === "" ? `eh-image-${size}` : `eh-image`
        }`}
      src={src}
      alt={alt}
      width={width}
      height={height}
      onError={onErrorImageHandler}
      {...props}
    />
  );
};

// Default props of the component
Image.defaultProps = {
  src: "",
  alt: "",
  size: "small",
  width: "",
  height: "",
};

export default Image;
