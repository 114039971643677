import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Link, useHistory } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import { Button } from "../../components/common";

import HeroMain from "../../assets/images/eh-home.png";

import LIntroImg1 from "../../assets/svg/l-intro-img-1.svg";
import LIntroImg2 from "../../assets/svg/l-intro-img-2.svg";
import LIntroImg3 from "../../assets/svg/l-intro-img-3.svg";
import LandingAboutUs from "../../assets/images/about-us-bg-2.png";

import User1 from "../../assets/images/user-avatar1.png";
import User2 from "../../assets/images/user-avatar2-new.png";
import User3 from "../../assets/images/user-avatar3-new.png";
import User4 from "../../assets/images/user-avatar4-new.png";
import User5 from "../../assets/images/user-avatar5.png";

import { PlayArrow as PlayArrowIcon } from "@material-ui/icons";
import Modal from "../../components/common/Modal";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { rootReducersState } from "../../reducers";
import appRoutes from "../../routes/app.routes";
import CandidateStorySlider from "./components/CandidateStorySlider";
import EmployerStorySlider from "./components/EmployerStorySlider";
import Image from "../../../src/components/common/Image";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [candidatevisible1, candidateVisible1] = useState(false);
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const tokenUser = _.get(sessionReducer, "currentUser", {});

  const handleVideoModal = () => {
    setVisible(!visible);
  };
  const candidateContentModal1 = () => {
    candidateVisible1(!candidatevisible1);
  };
  useEffect(() => {
    if (!_.isEmpty(tokenUser)) {
      history.push(appRoutes.Feed.path);
    } else {
      history.push(appRoutes.home.path);
    }

    // eslint-disable-next-line
  }, []);

  console.log("Welcome to employHER");

  return (
    <>
      <Container fixed>
        <div className="bg-pink-circle"></div>
        <div className="landing-hero">
          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className="landing-hero-text">
                {/* <h5 className="m-0">WELCOME</h5> */}
                <h1>
                  Introducing the <br />
                  employ<span>HER</span> Platform
                </h1>
                {/* <h6 className="m-0">
                  Building a community for women to thrive.
                </h6> */}
                <Button onClick={() => history.push(appRoutes.userSignUp.path)}>
                  Join Us
                </Button>
              </div>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className="landing-hero-img">
                <div className="play-icon" onClick={handleVideoModal}>
                  <PlayArrowIcon className="cursor-pointer" />
                </div>
                <Image src={HeroMain} alt="eh-im-test" />
                <div className="user-avtars">
                  <ul>
                    <li>
                      <div>
                        <Image src={User1} alt="eh-small-image-1" />
                      </div>
                    </li>
                    <li>
                      <div>
                        <Image src={User2} alt="eh-small-image-2" />
                      </div>
                    </li>
                    <li>
                      <div>
                        <Image src={User5} alt="eh-small-image-3" />
                      </div>
                    </li>
                    <li>
                      <div>
                        <Image src={User3} alt="eh-small-image-4" />
                      </div>
                    </li>
                    <li>
                      <div>
                        <Image src={User4} alt="eh-small-image-5" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="landing-intro">
          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <div className="l-intro-wrap left">
                <div className="intro-img">
                  <Image src={LIntroImg1} alt="eh-video-platform-icon" />
                </div>
                <p>
                  A professional video platform for women and gender diverse
                  people to connect and network with each other.
                </p>
                <div>
                  <Link to={appRoutes.userLogin.path}>Explore</Link>
                </div>
              </div>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <div className="l-intro-wrap middle">
                <div className="intro-img">
                  <Image src={LIntroImg2} alt="eh-apply-for-job-icon" />
                </div>
                <p>
                  Apply for jobs with your
                  <b>
                    {" "}
                    employ<span>HER</span>
                  </b>{" "}
                  video profile showcasing your personality, soft-skills,
                  experience, and personal branding.
                </p>
                <div>
                  <Link to={appRoutes.userLogin.path}>Explore</Link>
                </div>
              </div>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <div className="l-intro-wrap right">
                <div className="intro-img">
                  <Image src={LIntroImg3} alt="eh-video-profile-icon" />
                </div>
                <p>
                  <b>
                    employ<span>HER</span>
                  </b>{" "}
                  video profile adds a level of trust and familiarity to your
                  professional network.
                </p>
                <div>
                  <Link to={appRoutes.userLogin.path}>Explore</Link>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>

      <div className="left-bg-container">
        <Container fixed>
          <div className="landing-about-us">
            <Grid
              item
              xs={12}
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="landing-about-us-img">
                  <Image src={LandingAboutUs} alt="eh-im" />
                </div>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="landing-hero-text landing-about-us-text">
                  {/* <h5 className="m-0">About Us</h5> */}
                  <h1>
                    Let’s Make A Change <br />
                    Together
                  </h1>
                  {/* <h4>empower | inspire | employ<span>HER</span></h4> */}
                  <div className="tagline-text">
                    <p>
                      <strong>Our mission:</strong> Creating <span>HER</span>{" "}
                      pathway to success.
                    </p>
                    <p>
                      <strong>Our vision:</strong> Building a community for
                      women to <span>THRIVE.</span>
                    </p>
                  </div>
                  <Button
                    color="secondary"
                    onClick={() => history.push(appRoutes.contactUs.path)}
                  >
                    Contact Us
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>

      <Container fixed>
        <div className="landing-states text-center">
          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item sm={12} xs={12}>
              <h1>
                Diversity <span>market</span> size nationwide
              </h1>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <div className="l-states-wrap">
                <div className="number">240K</div>
                <h3>OPEN JOBS</h3>
                <p>IT jobs posted daily</p>
              </div>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <div className="l-states-wrap middle">
                <div className="number">60M</div>
                <h3>TOTAL RESUMES</h3>
                <p>Resumes submitted per posting</p>
              </div>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <div className="l-states-wrap">
                <div className="number">15M</div>
                <h3>DIVERSE RESUMES</h3>
                <p>Resumes submitted by diverse talent</p>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <div className="right-bg-container landing-candidate-testimonial">
        <CandidateStorySlider />
      </div>
      <Container fixed>
        <div className="landing-employer-testimonial">
          <Grid item sm={12} xs={12}>
            <div className="section-title  text-center">
              <h5>Employer</h5>
              <h1>What our active employers have to say about us</h1>
            </div>
          </Grid>
          <EmployerStorySlider />
        </div>
      </Container>
      <Container fixed>
        <div className="landing-our-mission">
          <div className="text">
            {/* <h1>Our Mission</h1> */}
            <h4>
              empower | inspire | employ
              <span className="text-primary">HER</span>
            </h4>
            <p>
              We are on a mission to balance diversity and equity in the
              workplace
            </p>
          </div>
          <Button onClick={() => history.push(appRoutes.userLogin.path)}>
            Explore
          </Button>
        </div>
      </Container>
      <Modal
        className={"homepage-video-modal"}
        visible={visible}
        size="medium"
        closeButton={true}
        closeOnBackDrop={true}
        onClose={handleVideoModal}
      >
        <div className="landing-s1-vid">
          {window.innerWidth > 768 ? (
            <ReactPlayer
              url="https://eh-public-assets.s3.us-west-2.amazonaws.com/employHERIntroVideo.MP4"
              width="500px"
              height="375px"
              playing={false}
              controls={true}
            />
          ) : (
            <ReactPlayer
              url="https://eh-public-assets.s3.us-west-2.amazonaws.com/employHERIntroVideo.MP4"
              width="280px"
              height="200px"
              playing={false}
              controls={true}
            />
          )}
        </div>
      </Modal>
      <Modal
        className={"candidate-content-modal"}
        visible={candidatevisible1}
        size="medium"
        closeButton={true}
        closeOnBackDrop={true}
        onClose={candidateContentModal1}
      >
        <div className="candidate-content">
          <h5>Candidate</h5>
          <h4>
            {" "}
            Candidate <br />
            success stories
          </h4>
          <h6>
            Wow where do I begin. Preet is more than an agent, she truly becomes
            your biggest cheerleader, supporter and life coach. Regardless of
            whatever position I take it was an honor to have worked with her
            because she increased my confidence tenfold. Preet genuinely cares
            and despite tragedy also hitting her she still checked in to see how
            things were going.
          </h6>
          <h3>- Sharron Allen</h3>
        </div>
      </Modal>
    </>
  );
};

export default Home;
