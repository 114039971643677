import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { ReactComponent as BulbGearIcon } from "../../assets/images/bulb-gear.svg";
import {
  Button,
  ConfirmDialog,
  FlashMessage,
  Modal,
} from "../../components/common";
import { validateFileSize } from "../../components/helpers/formValidation";
// import PreviewVideo from "../../assets/images/media-placholder.jpg";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import {
  DeleteOutline as DeleteIcon,
  CloudDownload as DownloadIcon,
  Info as InfoIcon,
  CloudUpload as UploadIcon,
} from "@material-ui/icons";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import useSessionUser from "../../hooks/useSessionUser";
import InspirationModal from "../../pages/CandidateProfile/InspirationModal";
import { rootReducersState } from "../../reducers";
import { updateCandidateResumeFile } from "../../reducers/auth/session.reducer";
import {
  deleteCandidateResumeRequest,
  parseCandidateResumeRequest,
  parseCandidateResumeReset,
  resetDeleteCandidateResume,
  uploadCandidateResumeRequest,
  uploadCandidateResumeReset,
} from "../../reducers/candidate/candidate.reducer";
import appRoutes from "../../routes/app.routes";
import { ReducerFlag } from "../../types/reducer.types";
import {
  FINAL_VIDEO_DURATION_LIMIT,
  VIDEO_STUDIO_UPLOAD_LIMIT,
} from "../../utils/appConstants";
import { fetchVideoDuration, openUrlInNewTab } from "../../utils/helper";
import httpRequest from "../../utils/httpRequest";
import ResumeModal from "./Profile/ResumeModal";

const VideoUploader: React.FC<any> = (props) => {
  const inputFileRef: any = useRef();
  const inputResumeParseRef: any = useRef();
  const inputResumeRef: any = useRef();
  const history = useHistory();
  const { currentSlug } = useSessionUser();
  const [videoFilePath, setVideoFilePath] = useState<any>(null);
  const [inspirationModal, setInspirationModal] = useState(false);
  const [videoModal, setVideoModal] = useState<boolean>(false);
  const [resumeUploadModal, setResumeUploadModal] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [resumeViewModal, setResumeViewModal] = useState<boolean>(false);
  const [resumePreviewPath, setResumePreviewPath] = useState<string>("");
  const [loadingResumePreview, setLoadingResumePreview] =
    useState<boolean>(false);
  const [confirmVideo, setConfirmVideo] = useState(false);
  const [resumeFilePath, setResumeFilePath] = useState<any>(null);
  const [deleteResumeModal, setDeleteResumeModal] = useState<boolean>(false);
  const [parsedModal, setParsedModal] = useState<boolean>(false);
  const userProfile = props.candidateProfileData; // for now we are getting data from profile will uppdate when parsed API is ready
  const dispatch = useDispatch();
  const [isParsed, setIsParsed] = useState(true);

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    const fileSize = validateFileSize(file.size, 10);
    if (!_.isEmpty(fileSize)) {
      FlashMessage(fileSize, "error");
      return;
    }

    // Check for video duration
    const res: any = await fetchVideoDuration(file, FINAL_VIDEO_DURATION_LIMIT);
    if (res?.status === true) {
      await props.uploadVideo(file, "video");
      setVideoFilePath(URL.createObjectURL(file));
    } else {
      FlashMessage(
        `video duration must be less than ${FINAL_VIDEO_DURATION_LIMIT} seconds`,
        "error"
      );
    }
  };

  const handleFileUploadResume = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    const fileSize = validateFileSize(file.size, 10);
    if (!_.isEmpty(fileSize)) {
      FlashMessage(fileSize, "error");
      return;
    }

    const payload = new FormData();
    payload.append("resume", file);
    dispatch(parseCandidateResumeRequest({ payload }));
  };

  const candidate = useSelector(
    ({ candidate }: rootReducersState) => candidate
  );

  const session = useSelector(({ session }: rootReducersState) => session);
  const url = videoFilePath || props.profileVideoUrl;

  const handleCreateClick = () => {
    if (props.totalVideos < VIDEO_STUDIO_UPLOAD_LIMIT) {
      history.push(appRoutes.candidateCreateVideo.path);
    } else {
      FlashMessage(
        `Max ${VIDEO_STUDIO_UPLOAD_LIMIT} video can be upload`,
        "error"
      );
    }
  };

  const {
    resumeFile,
    deleteResumeLoading,
    deleteResumeFlag,
    uploadResumeLoading,
    resumeDataFlag,
    resumeData,
    parseResumeLoading,
    resumeParsedMessage,
    uploadResumeFlag,
    parseResumeFlag,
    message
  } = useSelector(({ candidate }: rootReducersState) => candidate.candidates);

  const closeResumeModal = () => {
    setResumeUploadModal(false);
    setIsParsed(true);
  };

  // Upload resume flag
  useEffect(() => {
    const filePath = _.get(session, "currentUser.profile.resume_file", "");
    if (resumeFile !== "" || filePath !== "") {
      setResumeFilePath(
        resumeFile || _.get(session, "currentUser.profile.resume_file", "")
      );
      closeResumeModal();
    } else {
      setResumeFilePath(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeFile]);

  useEffect(() => {
    if (resumeFile) {
      dispatch(updateCandidateResumeFile(resumeFile));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeFile]);

  useEffect(() => {
    if (resumeDataFlag) {
      setParsedModal(true);
    }
  }, [resumeDataFlag]);

  useEffect(() => {
    if (deleteResumeFlag === ReducerFlag.SUCCESS) {
      dispatch(updateCandidateResumeFile(""));
      setResumeFilePath(null);
    } else if (deleteResumeFlag === ReducerFlag.FAILED) {
      FlashMessage("Unable to delete resume", "error");
      dispatch(resetDeleteCandidateResume());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteResumeFlag]);


  useEffect(() => {
    if (uploadResumeFlag === ReducerFlag.SUCCESS) {
      if(!_.isEmpty(resumeParsedMessage)){
        FlashMessage(resumeParsedMessage, "error");
        dispatch(uploadCandidateResumeReset());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadResumeFlag]);

  useEffect(() => {

    if (parseResumeFlag === ReducerFlag.SUCCESS) {
      dispatch(parseCandidateResumeReset());
    }
    if (parseResumeFlag === ReducerFlag.FAILED) {
        FlashMessage(message, "error");
        dispatch(parseCandidateResumeReset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parseResumeFlag]);
  
  useEffect(() => {
    if (candidate.candidates.videoLoading === "Success") {
      setConfirmVideo(false);
    }
  }, [candidate.candidates.videoLoading]);

  const handleModal = () => {
    setVideoModal(!videoModal);
  };

  const handleResumeUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    const fileSize = validateFileSize(file.size, 2);
    if (!_.isEmpty(fileSize)) {
      FlashMessage(fileSize, "error");
      return;
    }

    const payload = new FormData();
    payload.append("resume", file);
    payload.append("isParsedRequired", String(isParsed));
    dispatch(uploadCandidateResumeRequest({ payload }));
  };

  const handleRemoveFile = () => {
    dispatch(deleteCandidateResumeRequest());
    setDeleteResumeModal(false);
  };

  const handleDownload = () => {
    const url = _.get(session, "currentUser.profile.resume_file", "");
    openUrlInNewTab(url);
  };

  const handleShowResume = async () => {
    setLoadingResumePreview(true);
    try {
      const { data } = await httpRequest().post(
        `candidates/profile/resume/${currentSlug}/preview`
      );
      const path = data.path;
      if (path) {
        setResumePreviewPath(path);
        setResumeViewModal(true);
      } else {
        FlashMessage("Resume not found", "error");
      }
    } catch (error) {
      FlashMessage("Resume not found", "error");
    } finally {
      setLoadingResumePreview(false);
    }
  };

  return (
    <>
      <div className="video-uploader-wrapper">
        <div className="video-uploader-row">
          <div className="video-uploader-col">
            <div className="button-box">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <div className="info-wrapper display-flex">
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6}>
                        <div className="her-profile mt-10">
                          <Typography>
                            My employ<span>HER</span> Video
                          </Typography>
                          <InfoIcon
                            className="cursor-pointer"
                            onClick={() => {
                              handleModal();
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="group">
                          <Button
                            className="loader-icon"
                            onClick={() => setConfirmVideo(true)}
                            color="secondary"
                            disabled={!props.showEditButton}
                          >
                            Upload
                          </Button>
                          <span className="ml-10 mr-10">or</span>
                          <Button
                            color="secondary"
                            onClick={() => handleCreateClick()}
                            disabled={!props.showEditButton}
                          >
                            Create
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="info-wrapper resume-container">
                    {resumeFilePath ? (
                      <>
                        <div className="her-profile">
                          <Typography>
                            My employ<span>HER</span> Resume
                          </Typography>
                        </div>
                        <Button
                          color="secondary"
                          onClick={() => handleShowResume()}
                          loading={loadingResumePreview}
                        >
                          View Resume
                        </Button>
                        <div className="resume-download">
                          <Tooltip
                            title="Upload/Replace resume"
                            placement="top"
                            arrow
                          >
                            <span
                              className="text-info cursor-pointer"
                              onClick={() => setResumeUploadModal(true)}
                            >
                              <UploadIcon />
                            </span>
                          </Tooltip>
                          <Tooltip
                            title="Download resume"
                            placement="top"
                            arrow
                          >
                            <span
                              onClick={handleDownload}
                              className="text-info cursor-pointer"
                            >
                              <DownloadIcon />
                            </span>
                          </Tooltip>
                          <Tooltip title="Delete resume" placement="top" arrow>
                            <span
                              onClick={() => setDeleteResumeModal(true)}
                              className="text-danger cursor-pointer"
                            >
                              <DeleteIcon />
                            </span>
                          </Tooltip>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="her-profile">
                          <Typography>
                            My employ<span>HER</span> Resume
                          </Typography>
                        </div>
                        <Button
                          onClick={() => setResumeUploadModal(true)}
                          className="upload-resume loader-icon"
                          color="secondary"
                        >
                          Upload
                        </Button>
                      </>
                    )}
                    <input
                      ref={inputResumeRef}
                      onChange={handleResumeUpload}
                      accept=".pdf,.docx,.doc"
                      type="file"
                      hidden
                      name="upload"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={confirmVideo}
        onClose={() => {
          setConfirmVideo(false);
        }}
        title="Upload Video"
        className="feed-edit-modal feed-delete-modal confirm-video-modal dicard-modal "
        size="large"
      >
        <p>
          <span>Please Select a video file up to 10MB</span>
          <br />
          <small>(Best dimension for video is 1280×720)</small>
        </p>
        <div className="flex justify-content-center">
          <div className="group">
            <Button
              onClick={() => inputFileRef.current.click()}
              color="secondary"
              className="loader-icon"
              disabled={!props.showEditButton}
              loading={candidate.candidates.uploadLoading}
            >
              Upload
            </Button>
            <input
              ref={inputFileRef}
              onChange={handleFileUpload}
              type="file"
              hidden
              name="upload_video"
            />
          </div>
          <div className="group">
            <Button
              disabled={candidate.candidates.uploadLoading}
              onClick={() => {
                setConfirmVideo(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        visible={videoModal}
        onClose={() => setVideoModal(false)}
        title="empoyHER Video"
        className="profile-modal editable-profile-modal editable-modal info-wrapper basic-info-wrapper"
      >
        <div>
          <span className="separator" />
          <div className="video-uploader-row">
            <div className="instructions info-text">
              <h5 className="title">Video Instructions</h5>
              <strong>
                First, we would love to hear about your passions and hobbies
                outside of your employment, here are some examples
              </strong>
              <ul>
                <li>What makes your heart happy?</li>
                <li>What makes you want to wake up every day?</li>
              </ul>
              <strong>
                Second, we would love to hear about what you’re looking for in
                your next dream career or employer, here are some examples
              </strong>
              <ul>
                <li>What motivates you?</li>
                <li>What are you passionate about?</li>
                <li>What are you looking for in your next employer?</li>
              </ul>
            </div>
          </div>
          <span className="separator" />
          <Grid container spacing={3} className="info-video mt-20">
            <Grid item spacing={2} md={6}>
              {_.isEmpty(url) || _.isUndefined(url) ? (
                <h5 className="title">Watch The Demos</h5>
              ) : null}
              <div className="img-preview">
                <ReactPlayer
                  width="530px"
                  height="287px"
                  url={
                    "https://eh-public-assets.s3.us-west-2.amazonaws.com/employHERIntroVideo.MP4"
                  }
                  controls
                />
              </div>
            </Grid>
            <Grid item spacing={2} lg={5} md={6}>
              <div>
                <h5 className="title">Read The Prompts</h5>
                <div className="box">
                  <p>
                    Need help getting creative on your employHER video?
                    <br /> Check out our get-to-know questions for some
                    inspiration and creativity.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Button
                      onClick={() => setInspirationModal(true)}
                      color="secondary"
                    >
                      Questions for some Inspiration
                    </Button>
                  </div>
                  <div className="bulb-gear-icon">
                    <BulbGearIcon />
                  </div>
                  <InspirationModal
                    open={inspirationModal}
                    handleClose={() => setInspirationModal(false)}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Modal>

      <ConfirmDialog
        visible={deleteResumeModal}
        bodyText="Are you sure want to Delete this resume?"
        confirmText="Delete"
        loading={deleteResumeLoading}
        onConfirm={() => handleRemoveFile()}
        onCancel={() => setDeleteResumeModal(false)}
      />

      <Modal
        visible={resumeUploadModal}
        onClose={closeResumeModal}
        title="Upload Resume"
        className="resume-upload-modal"
      >
        <div className="flex-column align-items-center mb-10 mt-10">
          Before sharing your resume, remove personal contact details to
          safeguard your privacy.
        </div>
        <div className="d-flex align-items-center input-wrapper">
          <input
            type="checkbox"
            onChange={(e) => setIsParsed(e.target.checked)}
            checked={isParsed}
            name="parse"
            id="parse"
          />
          <label htmlFor="parse" className="mb-0 ml-1 font-inherit">
            Parse Resume
          </label>
        </div>
        {isParsed && (
          <div className="d-flex mt-2">
            <InfoIcon className="font-md-2 text-primary" />{" "}
            <small className="ml-1">
              Our resume parsing feature is currently in the beta stage. While
              efforts are made for accuracy, we advise users to carefully review
              parsed information and make any necessary adjustments as we work
              towards improvement.
            </small>
          </div>
        )}
        <small className="mt-2 text-primary">(only pdf upto 2MB)</small>
        <div className="flex justify-content-center gap-2 mt-3">
          <div className="group">
            <Button
              onClick={() => inputResumeRef.current.click()}
              loading={uploadResumeLoading}
              className="loader-icon"
              disabled={!props.showEditButton}
            >
              {isParsed ? "Upload and Parse" : "Upload Resume"}
            </Button>
            <input
              ref={inputResumeRef}
              onChange={handleResumeUpload}
              type="file"
              hidden
              name="upload_resume"
            />
          </div>
          <div className="group">
            <Button
              onClick={() => inputResumeParseRef.current.click()}
              loading={parseResumeLoading}
              className="loader-icon"
              disabled={!props.showEditButton}
            >
              Only Parse Resume
            </Button>
            <input
              ref={inputResumeParseRef}
              onChange={handleFileUploadResume}
              type="file"
              hidden
              name="parse_resume"
            />
          </div>
          <div className="group">
            <Button
              disabled={uploadResumeLoading}
              onClick={closeResumeModal}
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        visible={resumeViewModal}
        onClose={() => {
          setResumeViewModal(false);
        }}
        title="View Resume"
        size="x-large"
        className="resume-upload-modal"
      >
        <div className="flex-column align-items-center mb-20">
          <DocViewer
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: true,
              },
            }}
            style={{ width: "100%", height: "auto" }}
            documents={[
              {
                uri: resumePreviewPath,
              },
            ]}
            pluginRenderers={DocViewerRenderers}
          />
        </div>
        {/* <div className="flex justify-content-center gap-2">
          <div className="group">
            <Button
              disabled={uploadResumeLoading}
              onClick={() => {
                setResumeViewModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div> */}
      </Modal>

      <ResumeModal
        parsedModal={parsedModal}
        setParsedModal={setParsedModal}
        resumeData={resumeData}
        userProfile={userProfile}
        closeResumeModal={closeResumeModal}
      />
    </>
  );
};
export default VideoUploader;
