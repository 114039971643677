import _ from "lodash";
import React, { useEffect } from "react";
import iconImage from "../icons/pen_icon.svg";
import { Modal, Button, RichTextEditor } from "../../common";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Image from "../../common/Image";
import { updateCandidateProfessionalPassionRequest } from "../../../reducers/candidate/candidate.reducer";
import { stripHTMLTags } from "../../../utils/helper";

type Inputs = {
  early_career: string;
  mid_career: string;
  recent_life: string;
};

function ProfessionalPassions({
  candidateProfileData,
  showProfessionalPassion,
  publicPage,
  passionModal,
  setPassionModal,
  showEditButton,
}: any) {
  const dispatch = useDispatch();

  const { control, handleSubmit, setValue, watch, setError, errors } =
    useForm<Inputs>({
      defaultValues: {
        early_career: "",
        mid_career: "",
        recent_life: "",
      },
    });

  const getProfessionalPasiion = (formData) => {
    const payload = {
      ...formData,
    };
    setPassionModal(false);
    dispatch(updateCandidateProfessionalPassionRequest({ payload }));
  };

  useEffect(() => {
    setTimeout(() => {
      setValue(
        "early_career",
        _.get(candidateProfileData, "profile.early_career", "")
      );
      setValue(
        "mid_career",
        _.get(candidateProfileData, "profile.mid_career", "")
      );
      setValue(
        "recent_life",
        _.get(candidateProfileData, "profile.recent_life", "")
      );
    }, 100);

    // eslint-disable-next-line
  }, [passionModal]);

  const maxLimit = 1000;
  const earlyCareerValue = watch("early_career");
  const midCareerValue = watch("mid_career");
  const recentCareerValue = watch("recent_life");

  const personalValueError = (value: number, field) => {
    if (value > maxLimit) {
      const maxLengthError = {
        type: "maxLength",
        message: `Text must be between 2 to ${maxLimit} characters long`,
      };
      setError(field, maxLengthError);
    } else {
      setError(field, {});
    }
  };

  useEffect(() => {
    personalValueError(stripHTMLTags(earlyCareerValue).length, "early_career");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earlyCareerValue]);

  useEffect(() => {
    personalValueError(stripHTMLTags(midCareerValue).length, "mid_career");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [midCareerValue]);

  useEffect(() => {
    personalValueError(stripHTMLTags(recentCareerValue).length, "recent_life");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentCareerValue]);

  return (
    <>
      <div className="edit-wrapper">
        {publicPage && <h1>Professional Passions</h1>}
        {showEditButton && showProfessionalPassion && !publicPage && (
          <span onClick={() => setPassionModal(true)}>
            <Image src={iconImage} alt="pen icon" />
          </span>
        )}
      </div>
      <div
        className={`profile-view-card user-description ${
          publicPage && "profile-public-card"
        }`}
      >
        {publicPage && !showProfessionalPassion ? (
          <h2 className="bold text-center">No data available</h2>
        ) : (
          !showProfessionalPassion && (
            <h2
              className="bold link-text"
              onClick={() => setPassionModal(true)}
            >
              Add Your Professional Passion
            </h2>
          )
        )}
        {!_.isEmpty(
          _.get(candidateProfileData, "profile.early_career", "")
        ) && (
          <>
            <h2 className="bold">Earlier years</h2>
            <p
              className="card-desc wysiwyg-data"
              dangerouslySetInnerHTML={{
                __html: _.get(candidateProfileData, "profile.early_career", ""),
              }}
            />
          </>
        )}
        {!_.isEmpty(_.get(candidateProfileData, "profile.mid_career", "")) && (
          <>
            <h2 className="bold">Mid journey</h2>
            <p
              className="card-desc wysiwyg-data"
              dangerouslySetInnerHTML={{
                __html: _.get(candidateProfileData, "profile.mid_career", ""),
              }}
            />
          </>
        )}
        {!_.isEmpty(_.get(candidateProfileData, "profile.recent_life", "")) && (
          <>
            <h2 className="bold">Recent career</h2>
            <p
              className="card-desc wysiwyg-data"
              dangerouslySetInnerHTML={{
                __html: _.get(candidateProfileData, "profile.recent_life", ""),
              }}
            />
          </>
        )}
      </div>
      <Modal
        title="Edit Professional Passion"
        visible={passionModal}
        className="profile-modal share-profile-modal editable-modal edit-size-model-passion"
        onClose={() => setPassionModal(false)}
      >
        <div className="edit-modal-wrapper ">
          <form onSubmit={handleSubmit(getProfessionalPasiion)}>
            <div className="mt-2">
              <RichTextEditor
                name="early_career"
                externalLabel={{
                  label: "Earlier years",
                  className: "required",
                }}
                placeholder="What did you do as your entry-level profession?"
                validationObj={errors}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter Earlier years",
                  },
                  validate: {
                    validLength: (value) => {
                      const tmpText = stripHTMLTags(value || "");
                      return (
                        (tmpText.length >= 2 && tmpText.length <= maxLimit) ||
                        `Please enter between 2 to ${maxLimit} characters`
                      );
                    },
                  },
                }}
                showCount
                maxLimit={maxLimit}
                counterAlign="right"
              />
            </div>
            <div className="mt-2">
              <RichTextEditor
                name="mid_career"
                externalLabel={{
                  label: "Mid-career",
                  className: "required",
                }}
                placeholder="What did you do when you gained some experience and expertise?"
                validationObj={errors}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter mid-career",
                  },
                  validate: {
                    validLength: (value) => {
                      const tmpText = stripHTMLTags(value || "");
                      return (
                        tmpText.length <= maxLimit ||
                        `Please enter between 2 to ${maxLimit} characters`
                      );
                    },
                  },
                }}
                showCount
                counterAlign="right"
                maxLimit={maxLimit}
              />
            </div>
            <div className="mt-2">
              <RichTextEditor
                name="recent_life"
                externalLabel={{
                  label: "Recent profession",
                  className: "required",
                }}
                placeholder="What are you doing right now?"
                validationObj={errors}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter recent profession",
                  },
                  validate: {
                    validLength: (value) => {
                      const tmpText = stripHTMLTags(value || "");
                      return (
                        tmpText.length <= maxLimit ||
                        `Please enter between 2 to ${maxLimit} characters`
                      );
                    },
                  },
                }}
                showCount
                counterAlign="right"
                maxLimit={maxLimit}
              />
            </div>
            <div className="d-flex justify-content-center mt-30">
              <Button
                type="submit"
                color="primary"
                className="submit-button text-uppercase"
              >
                Save
              </Button>
              <Button
                variant="text"
                color="secondary"
                className="cancel-button text-uppercase"
                onClick={() => setPassionModal(false)}
              >
                cancel
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default ProfessionalPassions;
