export const readFile = (file: Blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Crop the image and get final output
 *
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(
  imageSrc: any,
  pixelCrop: any,
  rotation = 0
): Promise<Blob> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  let blobURL: any = await new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, "image/png");
  });

  let blob: any = await fetch(blobURL).then((r) => r.blob());
  blob.name = "croppedImage.png";

  return blob as Blob;
}

// TODO - reduce the image size after crop
// -------------------
// // @ts-ignore
// import imageReducer from "image-blob-reduce";

// export const readFile = (file: Blob) => {
//   return new Promise((resolve) => {
//     const reader = new FileReader();
//     reader.addEventListener("load", () => resolve(reader.result), false);
//     reader.readAsDataURL(file);
//   });
// };

// const createImage = (url: string): Promise<HTMLImageElement> =>
//   new Promise((resolve, reject) => {
//     const image = new Image();
//     image.addEventListener("load", () => resolve(image));
//     image.addEventListener("error", (error) => reject(error));
//     image.setAttribute("crossOrigin", "anonymous");
//     image.src = url;
//   });

// function getRadianAngle(degreeValue: number) {
//   return (degreeValue * Math.PI) / 180;
// }

// /**
//  * Crop the image and get final output
//  *
//  * @param {File} image - Image File url
//  * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
//  * @param {number} rotation - optional rotation parameter
//  */
// export async function getCroppedImg(
//   imageSrc: any,
//   pixelCrop: any,
//   rotation = 0
// ): Promise<Blob> {
//   const image = await createImage(imageSrc);
//   const canvas = document.createElement("canvas");
//   const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

//   const maxSize = Math.max(image.width, image.height);
//   const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

//   // set each dimensions to double largest dimension to allow for a safe area for the
//   // image to rotate in without being clipped by canvas context
//   canvas.width = safeArea;
//   canvas.height = safeArea;

//   // translate canvas context to a central location on image to allow rotating around the center.
//   ctx.translate(safeArea / 2, safeArea / 2);
//   ctx.rotate(getRadianAngle(rotation));
//   ctx.translate(-safeArea / 2, -safeArea / 2);

//   // draw rotated image and store data.
//   ctx.drawImage(
//     image,
//     safeArea / 2 - image.width * 0.5,
//     safeArea / 2 - image.height * 0.5
//   );
//   const data = ctx.getImageData(0, 0, safeArea, safeArea);

//   // set canvas width to final desired crop size - this will clear existing context
//   canvas.width = pixelCrop.width;
//   canvas.height = pixelCrop.height;

//   // paste generated rotate image with correct offsets for x,y crop values.
//   ctx.putImageData(
//     data,
//     Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
//     Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
//   );

//   // As Base64 string
//   // return canvas.toDataURL('image/jpeg');

//   // As a blob
//   let blobURL: any = await new Promise((resolve) => {
//     canvas.toBlob((file) => {
//       resolve(URL.createObjectURL(file));
//     }, "image/png");
//   });

//   let blob: any = await fetch(blobURL).then((r) => r.blob());
//   blob.name = "croppedImage.png";

//   const newBlob = await imageReducer().toBlob(blob, { max: 500 });
//   newBlob.name = "croppedImage.png";

//   console.log("newBlob", newBlob);
//   console.log("blob", blob);

//   return newBlob as Blob;
// }
