import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import appRoutes from "../../routes/app.routes";
import JobLoading from "../JobSearch/JobLoading";
import NoJobsFound from "../../components/common/NotFoundPage";
import Card from "../../components/Jobs/Card";
import { Button, Tabs } from "../../components/common";
import PaginationComponent from "../../components/common/Pagination";
import { jobTypes } from "../../utils/appConstants";
import { useDispatch, useSelector } from "react-redux";
import { getCandidateJobApplicationsRequest } from "../../reducers/candidate/candidate.reducer";
import { scrollToTop } from "../../utils/helper";
// import TrackJobStepper from "./components/TrackJobStepper";

const TABS = [
  {
    id: 1,
    label: "Saved Jobs",
    value: "saved",
  },
  {
    id: 2,
    label: "Applied Jobs",
    value: "applied",
  },
];

const defaultFilter = {
  page: 1,
  pageSize: 12,
  jobType: jobTypes.FULL_TIME.id,
};

// let searchDelay: any = null;

const JobHub = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const appliedPagination = useSelector((state) =>
    _.get(state, "candidate.candidates.jobApplications.appliedPagination", {})
  );
  const savedPagination = useSelector((state) =>
    _.get(state, "candidate.candidates.jobApplications.savedPagination", {})
  );
  const appliedJobs = useSelector((state) =>
    _.get(state, "candidate.candidates.jobApplications.data.applied", [])
  );
  const savedJobs = useSelector((state) =>
    _.get(state, "candidate.candidates.jobApplications.data.saved", [])
  );
  const loading = useSelector((state) =>
    _.get(state, "candidate.candidates.jobApplications.loading", false)
  );
  const [activeTab, setActiveTab] = useState<any>(TABS[0]);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(defaultFilter);
  // eslint-disable-next-line
  const [activeJobApplicationStatus, setActiveJobStatus] = useState(0);

  useEffect(() => {
    dispatch(getCandidateJobApplicationsRequest(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    if (pathname.split("/")[2] === "applied") {
      setActiveTab(TABS[1]);
    } else {
      setActiveTab(TABS[0]);
    }
    // On tab change reset the filter
    setFilter(defaultFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  // const _handleJobTypeOption = (type: any) => {
  //   const jobType = parseInt(type);
  //   const param = { ...filter, page: 1, jobType };
  //   setPage(1);
  //   setFilter(param);
  // };

  // const _handleSearch = (q: string) => {
  //   clearTimeout(searchDelay);
  //   searchDelay = setTimeout(async () => {
  //     const param = { ...filter, page: 1, q: q };
  //     setPage(1);
  //     setFilter(param);
  //   }, 1000);
  // };

  const _handlePaginationChange = (value: number) => {
    setPage(value);
    const param = { ...filter, page: value };
    setFilter(param);
    scrollToTop();
  };

  const _handleJobListingRoute = () =>
    history.push(appRoutes.candidateJobSearch.path);

  const _handleActiveJobApplicationStatus = (status: number) => {
    setActiveJobStatus(status);
  };

  const jobPostings = activeTab.id === 2 ? appliedJobs : savedJobs;
  const pagination = activeTab.id === 2 ? appliedPagination : savedPagination;
  return (
    <div className="max-container">
      <div className="job-hub-wrapper">
        <div className="filter-wrapper">
          <div className="filter-row">
            <div className="tab-group-wrapper">
              <Tabs
                tabs={TABS}
                active={activeTab}
                setActive={(value) => {
                  setActiveTab(value);
                  history.push(
                    appRoutes.candidateJobHub.generatePath(
                      _.get(value, "value", "saved").toLowerCase()
                    )
                  );
                }}
              />
            </div>
            <Button
              onClick={_handleJobListingRoute}
              variant="contained"
              color="primary"
            >
              Job Listings
            </Button>
          </div>
          {/* Temporarily hidden until further instructions */}
          {/* <span className="separator" />
          <div className={`filter-row ${activeTab.id === 2 && "justify-content-center"}`}>
            <div className="select-item">
              <SearchBar className={activeTab.id === 2 ? "apply-search-bar" : ""} handleSearch={_handleSearch} />
            </div>
            {activeTab.id === 1 && (
              <div className="select-item">
                <div>
                  <label htmlFor="job-type">Job Type</label>
                </div>
                <Radio
                  name="job-type"
                  options={JOB_TYPE_OPTIONS}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => _handleJobTypeOption(e.target.value)}
                  defaultValue={JOB_TYPE_OPTIONS.find((type) => parseInt(type.value) === filter.jobType)?.value}
                />
              </div>
            )}
          </div> */}
        </div>
        {/* Hide to enhancement EH-392 */}
        {/* {activeTab.id === 2 && (
					<div className="stepper-wrapper">
						<h2>Track Your applied Jobs</h2>
						<TrackJobStepper applicationStatus={activeJobApplicationStatus} />
					</div>
				)} */}
        {_.isEmpty(jobPostings) && !loading ? (
          <NoJobsFound />
        ) : (
          <div className="list-wrapper">
            {loading ? (
              <JobLoading />
            ) : (
              jobPostings.map((data: any) => (
                <Card
                  showStatus={
                    activeTab.id === 2
                  } /**For only Applied jobs show status*/
                  applicationStatus={_.get(data, "application_status", 0)}
                  key={data.id}
                  onClick={_handleActiveJobApplicationStatus}
                  rejectionReason={_.get(data, "reject_reason", "")}
                  job={data}
                />
              ))
            )}
          </div>
        )}
        <div className="pagination-wrapper">
          <PaginationComponent
            count={_.get(pagination, "totalPages", 0)}
            page={page}
            variant="outlined"
            color="secondary"
            onChange={(value) => _handlePaginationChange(value)}
          />
        </div>
      </div>
    </div>
  );
};

export default JobHub;
