import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CompanyHeader from "../DefaultLayout/DefaultHeader";
import CompanyFooter from "./CompanyFooter"; import { getCurrentTab } from "../../utils/helper";
import { getCompanyDetailRequest } from "../../reducers/company/companyProfile.reducer";
import { isEmpty } from 'lodash';

const _ = { isEmpty };

interface Props {
	children: React.ReactChild
	[key: string]: any
}

const CompanyLayout = ({ children }: Props) => {

	const dispatch = useDispatch();
	const { companyProfile: { data } } = useSelector(({ company }: any) => company);
	const currentTab = getCurrentTab(children);

	useEffect(() => {
		if (_.isEmpty(data)) {
			if (currentTab !== "profile") {
				dispatch(getCompanyDetailRequest())
			}
		}

		// eslint-disable-next-line
	}, [data]);

	return (
		<div className="company-public-container">
			<div className="company-layout">
				<CompanyHeader />
				<div className="public-company-wrapper">
					{children}
				</div>
				<CompanyFooter />
			</div>
		</div>
	)
}

export default CompanyLayout;
