import React from "react";

const IntroPolicy = () => {
  return (
    <>
      <h4 className="dot-title" >Your Privacy Matters</h4>
      <div className="privacy-statement text-left">
        <p>     employHER’s mission is to connect the world’s professionals to allow them to be more productive and successful. Central to this mission is our commitment to be transparent about the data we collect about you, how it is used, and with whom it is shared.<br /><br />
          This Privacy Policy applies when you use our Services (described below). We offer our users choices about the data we collect, use, and share as described in this Privacy Policy, Cookie Policy, and Settings.
        </p>
      </div>

      <h4 className="dot-title">Introduction</h4>
      <div className="privacy-statement text-left">
        <p> We are a social network and online platform for professionals. People use our Services to find and be found for business opportunities, to connect with others and find information. Our Privacy Policy applies to any Member or Visitor to our Services.<br /><br />
          Our registered users (“Members”) share their professional identities, engage with their network, exchange knowledge and professional insights, post and view relevant content, learn and develop skills, and find business and career opportunities. Content and data on some of our Services is viewable to non-members (“Visitors”).
        </p>
      </div>

      <h4 className="dot-title" >Services</h4>
      <div className="privacy-statement text-left">
        <p>     This Privacy Policy, including our Cookie Policy applies to your use of our Services.	<br /><br />
          This Privacy Policy applies to employHER.com, employHER-branded apps, and other employHER-branded sites, apps, communications and services offered by employHER (“Services”), including off-site Services, such as our ad services and the “Apply with employHER” and “Share with employHER” plugins, but excluding services that state that they are offered under a different privacy policy. For California residents, additional disclosures required by California law may be found in our California Privacy Disclosure.
        </p>
      </div>


      <h4 className="dot-title">Data Controllers and Contracting Parties</h4>
      <div className="privacy-statement text-left">
        <p>   If you use employHER, employHER will be the controller of your personal data provided to, collected by or for, or processed in connection with our Services, regardless of your location.<br /><br />
          As a Visitor or Member of our Services, the collection, use, and sharing of your personal data is subject to this Privacy Policy and other documents referenced in this Privacy Policy, as well as any updates.
        </p>
      </div>

      <h4 className="dot-title">Change</h4>
      <div className="privacy-statement text-left">
        <p>  Changes to the Privacy Policy apply to your use of our Services after the “effective date.”
          employHER (“we” or “us”) can modify this Privacy Policy, and if we make material changes to it, we will provide notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. If you object to any changes, you may close your account.<br /><br />
          You acknowledge that your continued use of our Services after we publish or send a notice about our changes to this Privacy Policy means that the collection, use and sharing of your personal data is subject to the updated Privacy Policy, as of its effective date.
        </p>
      </div>

    </>
  )
};
export default IntroPolicy;