import React, { useState } from "react";
import { ReactComponent as EditIcon } from "./icon.svg";
import { Controller } from "react-hook-form";
import PhoneNumberInput from "../PhoneNumberInput";

interface IEditableText {
  name: string;
  control: any;
  rules?: any;
  textProps?: any;
  inputClassName?: string;
  error?: any;
  country: string;
  defaultValue?: string;
}

const EditableText = (props: IEditableText) => {
  const [isFocused, setFocused] = useState(false);
  return (
    <div className="d-flex flex-col">
      <Controller
        control={props.control}
        name={props.name}
        rules={props.rules}
        defaultValue={props?.defaultValue}
        render={({ name, ref, value, onChange, onBlur }) => {
          return !isFocused && props.name !== "contact_info" ? (
            <>
              <div className="d-flex align-items-center">
                <input type="text" name={name} ref={ref} hidden />
                <p className="m-0" {...props.textProps}>
                  {value}
                </p>

                <EditIcon
                  className="ml-2"
                  onClick={() => {
                    setFocused(true);
                  }}
                />
              </div>
            </>
          ) : props.name === "contact_info" ? (
            <>
              <PhoneNumberInput
                country={"us"}
                autoFocus
                onChange={(e) => onChange(e)}
                value={value}
                onBlur={() => {
                  if (value !== "") {
                    setFocused(false);
                    onBlur();
                  }
                }}
              />
            </>
          ) : (
            <input
              autoFocus
              className={props.inputClassName}
              name={name}
              ref={ref}
              onChange={onChange}
              value={value}
              onBlur={() => {
                if (value !== "") {
                  setFocused(false);
                  onBlur();
                }
              }}
            />
          );
        }}
      />
      {props.error && (
        <span className="error-helper ml-0">{props.error.message}</span>
      )}
    </div>
  );
};
export default EditableText;
