import React, { useEffect } from "react";
import { ConfirmDialog } from "../../../components/common";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdminRequest } from "../../../reducers/company/companyAdmins.reducer";
import FlashMessage from "../../../components/common/FlashMessage";
import { rootReducersState } from "../../../reducers";
import { companyUserAccessLevel } from "../../../utils/appConstants";

type data = {
  id?: number;
  profile_image?: string;
  name?: string;
  role?: string;
};
interface Props {
  data: data;
  loading: boolean;
  visible: boolean;
  handleClose: () => void;
}

const AdminDeleteModal = ({ loading, visible, handleClose, data }: Props) => {
  const dispatch = useDispatch();
  const {
    companyAdmins: { deleteflag, deleteMessage, deleteLoading },
  } = useSelector(({ company }: rootReducersState) => company);

  //  Delete admin success Hook
  useEffect(() => {
    if (deleteflag === true) {
      handleClose();
      FlashMessage(deleteMessage);
    } else {
      FlashMessage(deleteMessage, "error");
    }

    // eslint-disable-next-line
  }, [deleteflag, deleteMessage]);

  const accessRole =
    companyUserAccessLevel.find((row) => row.value === data.role)?.label || "";

  return (
    <ConfirmDialog
      visible={visible}
      title="Revoke Access "
      onCancel={handleClose}
      bodyText={
        <>
          Do you want to revoke <strong>{accessRole}</strong> access of{" "}
          <b>{data?.name}?</b>
        </>
      }
      loading={deleteLoading}
      onConfirm={() => dispatch(deleteAdminRequest(data))}
    />
  );
};

export default AdminDeleteModal;
