import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  companyAdminListRequest,
  resetCompanyAdminReducer,
} from "../../../reducers/company/companyAdmins.reducer";
import { Grid } from "@material-ui/core";
import { isEmpty, get } from "lodash";
import FlashMessage from "../../../components/common/FlashMessage";
import AddAdminForm from "./AddAdminForm";
import CompanyAdmin from "./CompanyAdmin";
import EditAdminModal from "./EditAdminModal";
import adminServices from "../../../services/companyAdmin.services";
import AdminDeleteModal from "./Remove";

export type UserDataProps = {
  id?: number;
  profile_image?: string;
  name?: string;
  role?: string;
};

const _ = { isEmpty, get };

const List = (props) => {
  const { open, setOpen, handleButtonClick, handleAdminDetailModal } = props;
  const dispatch = useDispatch();

  const [isDeleteModalShow, setDeleteModalShow] = useState<boolean>(false);
  const [isEditModalShow, setEditModalShow] = useState<boolean>(false);
  const [currentAdminUser, setCurrentAdminUser] = useState<UserDataProps>({});
  const {
    companyAdmins: { data, loading, message },
  } = useSelector(({ company }: any) => company);

  useEffect(() => {
    dispatch(companyAdminListRequest());

    return () => {
      dispatch(resetCompanyAdminReducer());
    };
    // eslint-disable-next-line
  }, []);

  // API error message Hook
  useEffect(() => {
    if (!_.isEmpty(message)) {
      FlashMessage(message, "error");
      // dispatch(adminDetailReset())
    }

    // eslint-disable-next-line
  }, [message]);

  const handleAdminCreateModal = () => {
    setOpen(false);
  };

  const handleResendInvitation = async (id: number | undefined) => {
    const defaultErrMsg = "Unable to resend invite";

    try {
      const result = await adminServices.resendAdminInvite(id);

      if (result.flag === true) {
        FlashMessage("Invitation resend successfully");
      } else if (result.flag === false) {
        FlashMessage(result.message || defaultErrMsg, "error");
      } else {
        FlashMessage(defaultErrMsg, "error");
      }
    } catch (error) {
      FlashMessage(defaultErrMsg, "error");
    }
  };

  const handleEdit = (data: UserDataProps) => {
    setCurrentAdminUser(data);
    setEditModalShow(true);
  };

  const handleDelete = (data: UserDataProps) => {
    setCurrentAdminUser(data);
    setDeleteModalShow(true);
  };

  return (
    <div className="admin-list">
      <Grid container xs={12} spacing={2} className="admin-block-wrap">
        <CompanyAdmin
          data={data}
          loading={loading}
          resendInvitation={(id) => handleResendInvitation(id)}
          handleOpen={(id) => handleAdminDetailModal(id)}
          handleAdd={() => handleButtonClick("Add", "")}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
        <AddAdminForm
          showModal={open}
          handleClose={() => handleAdminCreateModal()}
        />
        <EditAdminModal
          loading={loading}
          visible={isEditModalShow}
          handleClose={() => setEditModalShow(false)}
          data={currentAdminUser}
        />
        <AdminDeleteModal
          loading={loading}
          visible={isDeleteModalShow}
          handleClose={() => setDeleteModalShow(false)}
          data={currentAdminUser}
        />
      </Grid>
    </div>
  );
};

export default List;
