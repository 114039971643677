import { combineReducers, createSlice } from "@reduxjs/toolkit";

const messageSendType = createSlice({
  name: "messageSendType",
  initialState: "clickToSend",
  reducers: {
    setMessageSendType: (state, { payload }) => {
      return payload;
    },
  },
});

// Export actions
export const { setMessageSendType } = {
  ...messageSendType.actions,
};

// Export reducers
export default combineReducers({
  messageSendType: messageSendType.reducer,
});
