import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import List from './components/List'
import Actions from './components/Actions'
import { getConpamyAdminRequest } from '../../reducers/company/companyAdmins.reducer'

const Admins = (props) => {
  const [open, setOpen] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const dispatch = useDispatch()

  const handleButtonClick = (type: string, text: any) => {
    switch (type) {
      case 'Add':
        setOpen(true)
        break
      case 'Search':
        // handleSearch(text)
        break
      default:
        break
    }
  }

  const handleAdminDetailModal = (id = 0) => {
    if (id !== 0) {
      dispatch(getConpamyAdminRequest(id))
    } else {
      // dispatch(adminDetailReset())
    }
    setShowDetail(!showDetail)
  }

  return (
    <>
      <Actions
        showDetail={showDetail}
        handleAdminDetailModal={() => handleAdminDetailModal()}
        handleClick={handleButtonClick}
      />
      <div className="page-admins">
        <div className="admin-list-wrapper">
          <List
            {...props}
            open={open}
            setOpen={setOpen}
            showDetail={showDetail}
            handleButtonClick={handleButtonClick}
            handleAdminDetailModal={() => handleAdminDetailModal()}
          />
        </div>
      </div>
    </>
  )
}
export default Admins
