import httpRequest from "../utils/httpRequest";
import { get, omit } from "lodash";
import API_URLS from "../utils/apiUrls";

const _ = { get, omit };

export const getCompanyAdminsList = async () => {
  return httpRequest()
    .get(`${API_URLS.company}/admins/all`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};
// /company/admins?search_text=de&limit=5
export const searchCompanyAdmins = (text: string) => {
  return httpRequest()
    .get(`${API_URLS.company}/admins?search_text=${text}&limit=5`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const getAdminDetail = (id: number) => {
  return httpRequest()
    .get(`${API_URLS.company}/admin/${id}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

// export const getAdminHistory = (id: number) => {
// 	return httpRequest().get(`${API_URLS.company}/admin-audit-logs/${id}`)
// 		.then(res => {
// 			return {
// 				flag: true,
// 				message: _.get(res, "message", ""),
// 				data: _.get(res, "data.data", {})
// 			};
// 		})
// 		.catch(err => {
// 			return {
// 				flag: false,
// 				message: _.get(err, "response.data.message", "Something went wrong!"),
// 				errors: _.get(err, "response.data.errors", {})
// 			};
// 		});
// }

export const createCompanyAdmin = (formData: any) => {
  return httpRequest()
    .post(`${API_URLS.company}/admins`, formData)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "data.message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const deleteAdmin = (payload: any) => {
  const formData = {
    access_level: payload.role,
  };
  return httpRequest()
    .delete(`${API_URLS.company}/admins/${payload.id}`, { data: formData })
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "data.message", ""),
        data: _.get(res, "data.data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong!"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

export const editAdmin = (payload: any) => {
  return httpRequest()
    .put(`${API_URLS.company}/admins/${payload.id}`, {
      accessLevel: payload.role,
    })
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "data.message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const resendAdminInvite = (id: any) => {
  return httpRequest()
    .post(`${API_URLS.company}/admins/resend-invite/${id}`)
    .then((res) => {
      return {
        flag: true,
        message: _.get(res, "data.message", ""),
        data: _.get(res, "data", {}),
      };
    })
    .catch((err) => {
      return {
        flag: false,
        message: _.get(err, "response.data.message", "Something went wrong"),
        errors: _.get(err, "response.data.errors", {}),
      };
    });
};

const adminServices = {
  createCompanyAdmin,
  deleteAdmin,
  getCompanyAdminsList,
  getAdminDetail,
  searchCompanyAdmins,
  editAdmin,
  resendAdminInvite,
};

export default adminServices;
