import React from "react"

const CandidateIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="256px" height="256px" viewBox="0 0 256 256" version="1.1" style={{ transform: 'scale(1.27)' }}>
      <g id="surface1">
        <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(100%,3.921569%,56.470588%)', fillOpacity: 1 }} d="M 229.324219 128 C 229.324219 183.960938 183.960938 229.324219 128 229.324219 C 72.039062 229.324219 26.675781 183.960938 26.675781 128 C 26.675781 72.039062 72.039062 26.675781 128 26.675781 C 183.960938 26.675781 229.324219 72.039062 229.324219 128 Z M 229.324219 128 " />
        <path style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(98.823529%,92.156863%,96.078431%)', fillOpacity: 1 }} d="M 128 228.265625 C 183.378906 228.265625 228.265625 183.378906 228.265625 128 C 228.265625 72.621094 183.378906 27.734375 128 27.734375 C 72.621094 27.734375 27.734375 72.621094 27.734375 128 C 27.734375 183.378906 72.621094 228.265625 128 228.265625 Z M 158.078125 107.945312 C 158.078125 124.558594 144.613281 138.027344 128 138.027344 C 111.386719 138.027344 97.921875 124.558594 97.921875 107.945312 C 97.921875 91.332031 111.386719 77.867188 128 77.867188 C 144.613281 77.867188 158.078125 91.332031 158.078125 107.945312 Z M 67.839844 178.132812 C 82.03125 159.183594 104.324219 148.035156 128 148.054688 C 151.675781 148.035156 173.96875 159.183594 188.160156 178.132812 C 173.96875 197.082031 151.675781 208.230469 128 208.214844 C 104.324219 208.230469 82.03125 197.082031 67.839844 178.132812 Z M 67.839844 178.132812 " />
      </g>
    </svg>
  );
}

export default CandidateIcon