import React from 'react';
import { ReactComponent as PageNotfoundImg } from "../../assets/svg/not-found-404.svg";

import Container from '@material-ui/core/Container';
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";

interface INotFoundPage {
	heading?: string;
	title?: string
	buttonText?: string
	redirectTo?: string
}

const NotFoundPage = ({ heading, title, buttonText, redirectTo }: INotFoundPage) => {

	const history = useHistory()

	return (
		<Container maxWidth="lg">
			<div className="page-not-found">
				<div className="nfi-wrap">
					<div className="nf-img-wrap">
						<PageNotfoundImg />
					</div>
					<div className="nfc-wrap">
						<h2>{heading || "Oops!"}</h2>
						<p>{title || "We cannot find the page you are looking for."}</p>
						{redirectTo && (
							<Button
								variant="outlined"
								color="secondary"
								className="border-button btn-transparent"
								onClick={() => {
									history.push(redirectTo)
								}}
							>
								{buttonText || "Go Back"}
							</Button>
						)}
					</div>
				</div>
			</div>
		</Container>
	);
}
export default NotFoundPage;
