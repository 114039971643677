import tmpNbsp from "./Nbsp";
import tmpTabs from "./Tabs";
import tmpRadio from "./Radio";
import tmpInput from "./Input";
import tmpModal from "./Modal";
import tmpButton from "./Button";
import tmpSelect from "./Select";
import tmpSpinner from "./Spinner";
import tmpLocation from "./Location";
import tmpCheckbox from "./Checkbox";
import tmpListItem from "./ListItem";
import tmpPageTitle from "./PageTitle";
import tmpAccordion from "./Accordion";
import tmpSelectNew from "./SelectNew";
import tmpUserAvatar from "./UserAvatar";
import tmpAsyncSelect from "./AsyncSelect";
import tmpFlashMessage from "./FlashMessage";
import tmpEmployHERName from "./EmployHERName";
import tmpMessageHelper from "./MessageHelper";
import tmpConfirmDialog from "./ConfirmDialog";
import tmpRichTextEditor from "./RichTextEditor";
import tmpErrorTextHelper from "./ErrorTextHelper";
import tmpProfileImageCrop from "./ProfileImageCrop";
import tmpSingleFileDropZone from "./SingleFileDropZone";
import tmpAsyncCreatableSelect from "./AsyncCreatableSelect";
import tmpEditableText from "./EditableText/EditableText";

export const Tabs = tmpTabs;
export const Nbsp = tmpNbsp;
export const Input = tmpInput;
export const Modal = tmpModal;
export const Radio = tmpRadio;
export const Select = tmpSelect;
export const Button = tmpButton;
export const Spinner = tmpSpinner;
export const Checkbox = tmpCheckbox;
export const Location = tmpLocation;
export const ListItem = tmpListItem;
export const SelectNew = tmpSelectNew;
export const PageTitle = tmpPageTitle;
export const Accordion = tmpAccordion;
export const UserAvatar = tmpUserAvatar;
export const AsyncSelect = tmpAsyncSelect;
export const EditableText = tmpEditableText;
export const FlashMessage = tmpFlashMessage;
export const EmployHERName = tmpEmployHERName;
export const ConfirmDialog = tmpConfirmDialog;
export const MessageHelper = tmpMessageHelper;
export const RichTextEditor = tmpRichTextEditor;
export const ErrorTextHelper = tmpErrorTextHelper;
export const ProfileImageCrop = tmpProfileImageCrop;
export const SingleFileDropZone = tmpSingleFileDropZone;
export const AsyncCreatableSelect = tmpAsyncCreatableSelect;
