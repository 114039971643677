import React, { useEffect, useState } from "react";
import { rootReducersState } from "../../reducers";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  EmployHERName,
  FlashMessage,
  Input,
  Modal,
} from "../../components/common";
import _ from "lodash";
import candidateServices from "../../services/candidate.services";
import { sessionOutRequest } from "../../reducers/auth/session.reducer";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { FormControlLabel } from "@material-ui/core";

const deactivateReasonsList = [
  "I need a break from employHER",
  "There is a privacy concern for me",
  "There is a safety concern for me",
];
const deleteReasonsList = [
  "I have a duplicate account on employHER",
  "There is a privacy and safety concern for me",
  "I’m receiving unwanted contact",
];

const AccountPreferences = () => {
  const dispatch = useDispatch();
  const [showDeactivateModal, setShowDeactivateModal] =
    useState<boolean>(false);
  const [deactivateConfirmModal, setDeactivateConfirmModal] =
    useState<boolean>(false);
  const [deactivateReasonStatus, setDeactivateReasonStatus] =
    useState<string>("0");
  const [deactivateReason, setDeactivateReason] = useState<string>("");
  const [deactivateReasons, setDeactivateReasons] = useState<Array<string>>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState<boolean>(false);
  const [deleteReasonStatus, setDeleteReasonStatus] = useState<string>("0");
  const [deleteReason, setDeleteReason] = useState<string>("");
  const [deleteReasons, setDeleteReasons] = useState<Array<string>>([]);
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session.currentUser
  );

  useEffect(() => {
    setDeactivateReasons(deactivateReasonsList);
    setDeleteReasons(deleteReasonsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDeactivateCancel = () => {
    setDeactivateReason("");
    setShowDeactivateModal(false);
  };

  const handleDeleteCancel = () => {
    setDeleteReason("");
    setShowDeleteModal(false);
  };

  const handleConfirmDeactivate = () => {
    setDeactivateConfirmModal(true);
  };

  const handleConfirmDelete = () => {
    setDeleteConfirmModal(true);
  };

  const handleDeactivate = async () => {
    let reason = deactivateReasons[parseInt(deactivateReasonStatus)];

    if (reason === undefined) {
      reason = deactivateReason;
    }

    try {
      const result = await candidateServices.deactivateAccount({
        reason,
      });

      if (result.flag) {
        dispatch(sessionOutRequest());
      } else {
        FlashMessage(result.message, "error");
      }
    } catch (error) {
      FlashMessage(error?.message, "error");
    }
  };

  const handleDelete = async () => {
    let reason = deleteReasons[parseInt(deleteReasonStatus)];

    if (reason === undefined) {
      reason = deleteReason;
    }

    try {
      const result = await candidateServices.deleteAccount({
        reason,
      });

      if (result.flag) {
        dispatch(sessionOutRequest());
      } else {
        FlashMessage(result.message, "error");
      }
    } catch (error) {
      FlashMessage(error?.message, "error");
    }
  };

  return (
    <>
      <div className="profile-container">
        <div>
          <h2 className="title">Account Preferences</h2>
          <p>Control settings related to your account.</p>
          <hr />
          <div>
            <h3 className="mt-15">Deactivate account</h3>
            <div>
              <span>
                If you wish to take a break from <EmployHERName />, you can
                deactivate your account. In this case, you <br /> will have no
                access to your account and will not be able to participate in
                any activities. <br />
                In addition, almost everything you've shared will be removed
                from your profile.
              </span>
            </div>
            <div className="btn-deactivate">
              <Button
                variant="outlined"
                color="secondary"
                className="text-uppercase"
                onClick={() => setShowDeactivateModal(true)}
              >
                Deactivate account
              </Button>
            </div>
          </div>
          <hr />
          <div>
            <h3 className="mt-15">Delete account</h3>
            <div>
              <span>
                Your <EmployHERName /> account will be deleted once you delete
                it. You can't get back any <br /> connection or information that
                you shared on <EmployHERName /> after you delete it. You will
                also lose your connections and all of your messages.
              </span>
            </div>
            <div className="btn-deactivate">
              <Button
                variant="outlined"
                color="secondary"
                className="text-uppercase"
                onClick={() => setShowDeleteModal(true)}
              >
                Delete account
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          visible={showDeactivateModal}
          closeButton={true}
          title="Deactivate Account"
          onClose={() => setShowDeactivateModal(false)}
        >
          <p className="pb-12">Reason to deactivate account</p>
          <div>
            <RadioGroup
              value={deactivateReasonStatus}
              onChange={(e: React.ChangeEvent<any>) => {
                setDeactivateReasonStatus(_.get(e, "target.value", ""));
              }}
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="I need a break from employHER"
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="There is a privacy concern for me"
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="There is a safety concern for me"
              />
              <FormControlLabel value="3" control={<Radio />} label="Other" />
            </RadioGroup>
            <Input
              name="reason"
              className="reason-text"
              placeholder="Add your custom reason to deactivate account"
              onChange={(e) => {
                setDeactivateReason(e.target.value);
              }}
            />
          </div>
          <div className="btn-deactivate">
            <Button
              variant="outlined"
              className="text-uppercase"
              color="primary"
              onClick={() => handleConfirmDeactivate()}
            >
              Continue
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className="text-uppercase ml-20"
              onClick={() => handleDeactivateCancel()}
            >
              Cancel
            </Button>
          </div>
        </Modal>
        <Modal
          className=""
          visible={deactivateConfirmModal}
          closeButton={true}
          title=""
          onClose={() => setDeactivateConfirmModal(false)}
        >
          <div className="btn-deactivate">
            <p className="pb-12">
              <strong>
                {_.get(sessionReducer, "first_name", "")}{" "}
                {_.get(sessionReducer, "last_name", "")}
              </strong>
              , Hope to see you soon on <EmployHERName />
              <br />
              <span>
                Once you <span className="span-link">Deactivate</span> your
                account, you will loose the visibility on <EmployHERName />
              </span>
            </p>

            <Button
              variant="outlined"
              className="text-uppercase"
              color="primary"
              onClick={() => handleDeactivate()}
            >
              Deactivate
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className="text-uppercase ml-20"
              onClick={() => setDeactivateConfirmModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>
        <Modal
          visible={showDeleteModal}
          closeButton={true}
          title="Delete Account"
          onClose={() => setShowDeleteModal(false)}
        >
          <p className="pb-12">Reason to delete account</p>
          <div>
            <RadioGroup
              value={deleteReasonStatus}
              onChange={(e: React.ChangeEvent<any>) => {
                setDeleteReasonStatus(_.get(e, "target.value", ""));
              }}
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="I have a duplicate account on employHER"
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="There is a privacy and safety concern for me"
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="I’m receiving unwanted contact"
              />
              <FormControlLabel value="3" control={<Radio />} label="Other" />
            </RadioGroup>
            <Input
              name="reason"
              className="reason-text"
              placeholder="Add your custom reason to delete account"
              onChange={(e) => {
                setDeleteReason(e.target.value);
              }}
            />
          </div>
          <div className="btn-deactivate">
            <Button
              variant="outlined"
              className="text-uppercase"
              color="primary"
              onClick={() => handleConfirmDelete()}
            >
              Continue
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className="text-uppercase ml-20"
              onClick={() => handleDeleteCancel()}
            >
              Cancel
            </Button>
          </div>
        </Modal>
        <Modal
          className=""
          visible={deleteConfirmModal}
          closeButton={true}
          title=""
          onClose={() => setDeleteConfirmModal(false)}
        >
          <div className="btn-deactivate">
            <p className="pb-12">
              {_.get(sessionReducer, "first_name", "")}{" "}
              {_.get(sessionReducer, "last_name", "")}, Hope to see you soon{" "}
              <br />
              on employ<span className="title">HER</span>, we are waiting <br />
              Are you sure you want to{" "}
              <span className="title">Delete Account</span>?
            </p>

            <Button
              variant="outlined"
              className="text-uppercase"
              color="primary"
              onClick={() => handleDelete()}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className="text-uppercase ml-20"
              onClick={() => setDeleteConfirmModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AccountPreferences;
