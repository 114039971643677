import React, { useState, useCallback, useEffect } from "react";
import { Slider } from "@material-ui/core";
import { Point } from "react-easy-crop/types";
import Cropper from "react-easy-crop";

import Modal from "./Modal";
import Button from "./Button";
import { getCroppedImg } from "../../utils/cropImageHelper";

export type ProfileImageLayout = "profileImage" | "banner";
interface IProfileImageCrop {
  layout?: ProfileImageLayout;
  visible: boolean;
  cropImage: any;
  btnCropText?: string;
  onClose: () => void;
  onCrop: (data: Blob) => void;
}

const ProfileImageCrop = ({
  layout,
  visible,
  cropImage,
  btnCropText,
  onClose,
  onCrop,
}: IProfileImageCrop) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [cropping, setCropping] = useState<boolean>(false);
  const [aspectRatio, setAspectRatio] = useState<number>(1);
  const [cropShape, setCropShape] = useState<"round" | "rect" | undefined>(
    "round"
  );
  const [rotation, setRotation] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    if (layout === "profileImage") {
      setAspectRatio(1);
      setCropShape("round");
    } else if (layout === "banner") {
      setAspectRatio(8 / 2);
      setCropShape("rect");
    } else {
      setAspectRatio(1);
      setCropShape("round");
    }
  }, [layout]);

  const handleOnClose = () => {
    onClose();

    setTimeout(() => {
      // Reset the params
      setCrop({ x: 0, y: 0 });
      setRotation(0);
      setZoom(1);
      setCroppedAreaPixels(null);
    }, 500);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    setCropping(true);
    try {
      const croppedImage = await getCroppedImg(
        cropImage,
        croppedAreaPixels,
        rotation
      );

      onCrop(croppedImage);
      handleOnClose();
    } catch (e) {
      console.error(e);
    } finally {
      setCropping(false);
    }

    // eslint-disable-next-line
  }, [croppedAreaPixels, rotation]);

  return (
    <>
      <Modal
        visible={visible}
        onClose={() => handleOnClose()}
        className="img-crop-modal"
      >
        <div className="crop-container">
          <div className="cropper-image">
            <Cropper
              image={cropImage}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={aspectRatio}
              cropShape={cropShape}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <span className="note">Drag image to reposition</span>
          <div className="cropper-edit-wrapper">
            <div className="cropper-edits controls">
              <div className="control-inner">
                <label>Zoom</label>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(zoom as number)}
                />
              </div>
              <div className="control-inner">
                <label>Rotation</label>
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotation"
                  onChange={(e, rotation) => setRotation(rotation as number)}
                />
              </div>
            </div>
            <div className="cropper-edits cropper-btns">
              <span className="span-link" onClick={() => handleOnClose()}>
                Discard
              </span>
              <Button
                type="button"
                className="primary-btn"
                loading={cropping}
                onClick={() => showCroppedImage()}
              >
                {btnCropText || "Crop"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

ProfileImageCrop.defaultProps = {
  layout: "profileImage",
};

export default ProfileImageCrop;
