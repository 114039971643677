import httpRequest from "../utils/httpRequest"
import { get, omit } from "lodash";
import API_URLS from "../utils/apiUrls";

const _ = { get, omit };


const addWorkingHours = (formData: any) => {
    return httpRequest().post(`${API_URLS.addWorkingHours}`, formData)
      .then(res => {
        return {
          flag: true,
          message: _.get(res.data, "message", ""),
          data: _.get(res, "data.data", {})
        };
      })
      .catch(err => {
        return {
          flag: false,
          message: _.get(err, "response.data.message", "Something went wrong"),
          errors: _.get(err, "response.data.errors", {})
        };
      });
  }

  const getWorkingHours = (id: string) => {
    return httpRequest().get(`${API_URLS.getWorkingHours}/${id}`)
      .then(res => {
        return {
          flag: true,
          message: _.get(res.data, "message", ""),
          data: _.get(res, "data.data", {})
        };
      })
      .catch(err => {
        return {
          flag: false,
          message: _.get(err, "response.data.message", "Something went wrong"),
          errors: _.get(err, "response.data.errors", {})
        };
      });
  }

  const addEventType = (formData: any) => {
    return httpRequest().post(`${API_URLS.addEventType}`, formData)
      .then(res => {
        return {
          flag: true,
          message: _.get(res.data, "message", ""),
          data: _.get(res, "data.data", {})
        };
      })
      .catch(err => {
        return {
          flag: false,
          message: _.get(err, "response.data.message", "Something went wrong"),
          errors: _.get(err, "response.data.errors", {})
        };
      });
  }
  
  const getEventTypeBySlug = (slug: string) => {
    return httpRequest().get(`${API_URLS.getEventTypeBySug}/${slug}`)
      .then(res => {
        return {
          flag: true,
          message: _.get(res.data, "message", ""),
          data: _.get(res, "data.data", {})
        };
      })
      .catch(err => {
        return {
          flag: false,
          message: _.get(err, "response.data.message", "Something went wrong"),
          errors: _.get(err, "response.data.errors", {})
        };
      });
  }

  const getAllEventTypesBySlug = (slug: string) => {
    return httpRequest().get(`${API_URLS.getAllEventTypes}/${slug}`)
      .then(res => {
        return {
          flag: true,
          message: _.get(res.data, "message", ""),
          data: _.get(res, "data.data", {})
        };
      })
      .catch(err => {
        return {
          flag: false,
          message: _.get(err, "response.data.message", "Something went wrong"),
          errors: _.get(err, "response.data.errors", {})
        };
      });
  }
  
  const getAllEventTypes = () => {
    return httpRequest().get(`${API_URLS.getAllEventTypes}`)
      .then(res => {
        return {
          flag: true,
          message: _.get(res.data, "message", ""),
          data: _.get(res, "data.data", {})
        };
      })
      .catch(err => {
        return {
          flag: false,
          message: _.get(err, "response.data.message", "Something went wrong"),
          errors: _.get(err, "response.data.errors", {})
        };
      });
  }

  const updateEventType = (formData: any, id: any) => {
    return httpRequest().patch(`${API_URLS.updateEventType}/${id}`, formData)
      .then(res => {
        return {
          flag: true,
          message: _.get(res.data, "message", ""),
          data: _.get(res, "data.data", {})
        };
      })
      .catch(err => {
        return {
          flag: false,
          message: _.get(err, "response.data.message", "Something went wrong"),
          errors: _.get(err, "response.data.errors", {})
        };
      });
  }

  const removeEventType = (id: any) => {
    return httpRequest().delete(`${API_URLS.removeEventType}/${id}`)
      .then(res => {
        return {
          flag: true,
          message: _.get(res.data, "message", ""),
          data: _.get(res, "data.data", {})
        };
      })
      .catch(err => {
        return {
          flag: false,
          message: _.get(err, "response.data.message", "Something went wrong"),
          errors: _.get(err, "response.data.errors", {})
        };
      });
  }

  const removeMultipleEventTypes = (ids: any) => {
    return httpRequest().patch(`${API_URLS.removeMultipleEventTypes}`, {ids: ids})
      .then(res => {
        return {
          flag: true,
          message: _.get(res.data, "message", ""),
          data: _.get(res, "data.data", {})
        };
      })
      .catch(err => {
        return {
          flag: false,
          message: _.get(err, "response.data.message", "Something went wrong"),
          errors: _.get(err, "response.data.errors", {})
        };
      });
  }

  const addSchedule = (formData: any) => {
    return httpRequest().post(`${API_URLS.addSchedule}`, formData)
      .then(res => {
        return {
          flag: true,
          message: _.get(res.data, "message", ""),
          data: _.get(res, "data.data", {})
        };
      })
      .catch(err => {
        return {
          flag: false,
          message: _.get(err, "response.data.message", "Something went wrong"),
          errors: _.get(err, "response.data.errors", {})
        };
      });
  }
  
  const getAssignedSlots = (id: string) => {
    return httpRequest().get(`${API_URLS.getAssignedSlots}/${id}`)
      .then(res => {
        return {
          flag: true,
          message: _.get(res.data, "message", ""),
          data: _.get(res, "data.data", {})
        };
      })
      .catch(err => {
        return {
          flag: false,
          message: _.get(err, "response.data.message", "Something went wrong"),
          errors: _.get(err, "response.data.errors", {})
        };
      });
  }

const schedulerServices = { addWorkingHours, getWorkingHours, addEventType, getAllEventTypes, getEventTypeBySlug, getAllEventTypesBySlug, removeEventType, removeMultipleEventTypes, updateEventType, addSchedule, getAssignedSlots }

export default schedulerServices
