import React, { FunctionComponent, SVGProps } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "./Button";
import { ReactComponent as NoJobFound } from "../../assets/svg/no-job-found.svg";

interface NotFoundPageProps {
  handleClick?: () => void;
  displayAddNewButton?: boolean;
  buttonText?: string;
  description?: string;
  size?: "small" | "medium" | "large";
  content?: string;
  image?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

const NotFoundPage = (props: NotFoundPageProps) => {
  return (
    <Grid container xs={12} className="no-jobs-found-wrapper">
      <Grid item spacing={8} xs={5} className="no-jobs-found-svg">
        {props?.image ? <props.image /> : <NoJobFound />}
      </Grid>
      <Grid item spacing={8} xs={7} className="no-jobs-found-text">
        <div className="no-jobs-found">
          <h3 className="no-open-jobs">
            {props?.content ? props?.content : "No jobs found"}
          </h3>
          {props.description && (
            <p className="no-open-jobs-text">{props?.description}</p>
          )}
        </div>
        {props.displayAddNewButton && (
          <div className="add-new-job-button">
            <Button
              variant={"outlined"}
              className={"btn-transparent text-uppercase"}
              onClick={props.handleClick}
            >
              {props?.buttonText}
            </Button>
          </div>
        )}
      </Grid>
    </Grid>
  );
};
export default NotFoundPage;
