import React, { useEffect } from "react";
import {
  // useDispatch,
  useSelector,
} from "react-redux";
// import { fetchAffirmationOfTheDayRequest } from "../../reducers/common/affirmation.reducer";
import _ from "lodash";
import Skeleton from "@material-ui/lab/Skeleton";
import quotebg from "../../assets/images/quote-bg-img.png";
import quotemark from "../../assets/images/quotemark.png";
import { rootReducersState } from "../../reducers";
import Image from "./Image";

const Affirmation = () => {
  // const dispatch = useDispatch();
  const commonStore = useSelector(({ common }: rootReducersState) => common);
  const affirmation = _.get(commonStore, "affirmation.data", {});
  const loading = _.get(commonStore, "affirmation.loading", false);
  const fallBackQuote = "Your life is about to be incredible";

  useEffect(() => {
    // Fetch quote only if not called
    if (_.isEmpty(affirmation)) {
      // TODO - fix this on backend side
      // dispatch(fetchAffirmationOfTheDayRequest({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="quote-bg">
        <Image src={quotebg} alt="Quote background" />
      </div>
      <div className="quote-container">
        <Image src={quotemark} alt="Quote mark" />
        <div className="quote-content">
          <p>
            {loading ? (
              <>
                {[1, 2].map((key) => (
                  <Skeleton variant="text" key={key} />
                ))}
                <Skeleton variant="text" width={250} height={40} />
              </>
            ) : (
              <>
                {!_.isEmpty(affirmation)
                  ? affirmation.affirmation_text
                  : fallBackQuote}
              </>
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default Affirmation;
