import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { InputAdornment, CircularProgress } from "@material-ui/core/";
import { Input, Button } from "../../../components/common";
import SearchIcon from "@material-ui/icons/Search";
import Modal from "../../../components/common/Modal";
import unknownProfile from "../../../assets/images/unknownProfile.jpeg";
import {
  clearAllRequest,
  searchListRequest,
} from "../../../reducers/SearchAndConnect/searchAndConnect.reducer";
import appRoutes from "../../../routes/app.routes";
import { ReactComponent as CloseButton } from "../../../assets/svg/searchbar-close.svg";
import { ReactComponent as PlayButton } from "../../../assets/svg/play-button.svg";
import { openUrlInNewTab } from "../../../utils/helper";
import useSessionUser from "../../../hooks/useSessionUser";
import Avatar from "../../../components/common/AvtarImage";

let searchDelay: any = null;
interface searchProps {
  className?: string;
  searchLabel?: boolean;
  modalClass?: string;
}
const HeaderSearchBar = ({
  className,
  searchLabel,
  modalClass,
}: searchProps) => {
  const { onCompanyRoute, IS_COMPANY } = useSessionUser();
  const [openSearchModal, setSearchModal] = useState(false);
  const [searchTerms, setSearchTerms] = useState("");
  const {
    search: { data, searchModalLoader },
  } = useSelector(({ search }: any) => search);
  const users = _.get(data, "user", {});
  const companies = _.get(data, "company", {});
  const jobs = _.get(data, "job", {});
  const dispatch = useDispatch();
  const history = useHistory();

  const handleOpenSearchModal = () => {
    setSearchModal(true);
  };

  const viewPublicProfile = (slug: string) => {
    openUrlInNewTab(appRoutes.candidatePublicProfile.generateFullPath(slug));
  };

  const viewCompanyPublicProfile = (slug: string) => {
    if (slug) {
      openUrlInNewTab(appRoutes.CompanyPublicView.generatePath(slug));
    }
  };

  const viewJobPublicProfile = (slug: string) => {
    if (slug) {
      openUrlInNewTab(appRoutes.jobDetail.generatePath(slug));
    }
  };

  const renderUserCard = (data: any) => {
    return (
      <div
        onClick={() => viewPublicProfile(_.get(data, "slug", ""))}
        className={`search-data-details`}
      >
        <div className="search-data-details-left-wrapper">
          <div className="search-data-details-left">
            <Avatar
              src={_.get(data, "profile_image")}
              size="xsm"
            >
            </Avatar>
          </div>
          <div className="search-data-details-middle">
            <h4>{`${_.get(data, "first_name", "")} ${_.get(
              data,
              "last_name",
              ""
            )}`}</h4>
            <p>
              {_.get(data, "candidateProfile.job_title_custom", "") === "" ||
                _.get(data, "candidateProfile.job_title_custom", "") === null
                ? _.get(data, "candidateProfile.jobTitle.title", "")
                : _.get(data, "candidateProfile.job_title_custom", "")}
            </p>
          </div>
        </div>
        <div className="search-data-details-right">
          <PlayButton />
        </div>
      </div>
    );
  };

  const getCompanyCard = (data: any) => {
    return (
      <div
        onClick={() => viewCompanyPublicProfile(_.get(data, "slug", ""))}
        className="search-data-details"
      >
        <div className="search-data-details-left-wrapper">
          <div className="search-data-details-left">

            <Avatar
              src={_.get(data, "company_logo", "")}
              size="xsm"
              type="company"
            />
          </div>

          <div className="search-data-details-middle">
            <h4>{_.get(data, "company_name", "")}</h4>
            <p>{_.get(data, "location", "")}</p>
          </div>
        </div>
        <div className="search-data-details-right">
          <PlayButton />
        </div>
      </div>
    );
  };

  const getJobCard = (data: any) => {
    return (
      <div
        onClick={() => viewJobPublicProfile(_.get(data, "slug", ""))}
        className="search-data-details"
      >
        <div className="search-data-details-left-wrapper">
          <div className="search-data-details-left">

            <Avatar
              src={_.get(data, "company_logo", { unknownProfile })}
              size="xsm"
              type="job"
            />
          </div>
          <div className="search-data-details-middle">
            <h4>{`${_.get(data, "title", "")} `}</h4>
            <p>{_.get(data, "location", "")}</p>
          </div>
        </div>
        <div className="search-data-details-right">
          <PlayButton />
        </div>
      </div>
    );
  };

  const handleCloseSearchModal = () => {
    setSearchModal(false);
    setSearchTerms("");
  };

  const handleInstantSearch = (text: string) => {
    clearTimeout(searchDelay);
    setSearchTerms(text);
    searchDelay = setTimeout(() => {
      const searchType = onCompanyRoute || IS_COMPANY ? "users" : "all";
      dispatch(searchListRequest({ q: text, type: searchType, pageSize: 2 }));
    }, 1000);
  };

  const getAllResult = (resultType: string) => {
    dispatch(clearAllRequest());
    let type;
    let path;
    if (resultType === "company") {
      type = "company";
      path = appRoutes.searchAndConnect.path;
    } else if (resultType === "user") {
      type = "candidate";
      path = appRoutes.searchAndConnect.path;
    } else if (resultType === "job") {
      type = "";
      path = appRoutes.candidateJobSearch.path;
    }
    setSearchModal(false);
    const url = `${path}?q=${searchTerms}${type && "&type=" + type}`;
    history.push(url);
  };

  // getting only searched value from URL
  const { search, pathname } = useLocation();
  const getQuerry = () => {
    if (pathname === "/connect") {
      const data = search?.split("&")?.[0]?.split("=")?.[1];
      return data || "";
    }
    return "";
  };

  const getSearchModal = () => {
    return (
      <Modal
        visible={openSearchModal}
        closeOnBackDrop={true}
        onClose={handleCloseSearchModal}
        className={
          searchTerms && users
            ? "search-modal-container modal-result-container"
            : `search-modal-container ${modalClass}`
        }
      >
        <div className="search-modal-header">
          <Input
            name="input_search"
            className="navbar-searchBox"
            type="text"
            variant="outlined"
            size="small"
            placeholder="Search"
            autoComplete="off"
            value={searchTerms}
            autoFocus
            onChange={(e: { target: { value: string } }) =>
              handleInstantSearch(e.target.value)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="end"
                  className="navbar-searchBoxIconContainer"
                >
                  <SearchIcon className="navbar-searchBoxIcon" />
                </InputAdornment>
              ),
            }}
          />
          <CloseButton
            className="navbar-searchBox-close"
            onClick={handleCloseSearchModal}
          />
        </div>
        {searchModalLoader ? (
          <div className="search-loading">
            {" "}
            <CircularProgress size={30} />{" "}
          </div>
        ) : (users.count || companies.count || jobs.count) && searchTerms ? (
          <>
            <div className="devider" />
            <div className="search-modal-content">
              {users.count ? (
                <>
                  <div className="result-type-and-count">
                    <span className="result-type">Candidates</span>
                    <div className="result-count">
                      {" "}
                      <span onClick={() => getAllResult("user")}>
                        {" "}
                        All candidates ({_.get(users, "count", 0)}+){" "}
                      </span>{" "}
                    </div>
                  </div>
                  {(users.rows || []).map((row: any) => {
                    return renderUserCard(row);
                  })}
                </>
              ) : (
                ""
              )}

              {companies.count ? (
                <>
                  <div className="result-type-and-count">
                    <span className="result-type">Companies</span>
                    <div className="result-count">
                      {" "}
                      <span onClick={() => getAllResult("company")}>
                        {" "}
                        All companies ({_.get(companies, "count", 0)}+){" "}
                      </span>{" "}
                    </div>
                  </div>
                  {(companies.rows || []).map((row: any) => {
                    return getCompanyCard(row);
                  })}
                </>
              ) : (
                ""
              )}

              {jobs.count ? (
                <>
                  <div className="result-type-and-count">
                    <span className="result-type">Jobs</span>
                    <div className="result-count">
                      {" "}
                      <span onClick={() => getAllResult("job")}>
                        {" "}
                        All Jobs ({_.get(jobs, "count", 0)}+){" "}
                      </span>{" "}
                    </div>
                  </div>
                  {(jobs.rows || []).map((row: any) => {
                    return getJobCard(row);
                  })}
                </>
              ) : (
                ""
              )}
            </div>

            <div className="search-modal-footer">
              <Button
                onClick={() => getAllResult("user")}
                className="all-result-button"
              >
                ALL RESULTS (
                {_.get(users, "count", 0) +
                  _.get(companies, "count", 0) +
                  _.get(jobs, "count", 0)}
                +)
              </Button>
            </div>
          </>
        ) : (
          <div className="search-modal-content">
            {searchTerms && !users.count ? (
              <div className="result-not-found">No results found</div>
            ) : (
              ""
            )}

            {!searchTerms ? (
              <div className="search-result-note">
                Searching for Candidates or Companies
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </Modal>
    );
  };

  return (
    <div className={`navbar-search-redesign ${className}`}>
      <Input
        name="input_search"
        className="navbar-searchBox"
        type="text"
        variant="outlined"
        size="small"
        placeholder="Search"
        value={searchTerms || getQuerry()}
        autoComplete="off"
        onClick={() => handleOpenSearchModal()}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="end"
              className="navbar-searchBoxIconContainer"
            >
              <SearchIcon className="navbar-searchBoxIcon" />
              {searchLabel && <span>Search</span>}
            </InputAdornment>
          ),
        }}
      />
      {getSearchModal()}
    </div>
  );
};

export default HeaderSearchBar;
